import React from "react";
import logo from "./logo.svg";

import { ScreenOne } from "@cxm/ui";
import Navbar from "./Navbar";
import Footer from "./Footer";

function App() {
  return <ScreenOne />;
}

export default App;
