import { QueryClient, QueryClientProvider } from "react-query";
import { init } from "@cxm/lib";
import axios, { isAxiosError } from "axios";
import ReactDOM from "react-dom";
import App from "./App";
import "@cxm/ui/styles.css";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL + "/api",
  withCredentials: true,
});

init(client, isAxiosError);

const queryClient = new QueryClient();

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.getElementById("root") as HTMLElement,
);
