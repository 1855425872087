"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  AttestTerms: () => attest_terms_default,
  Attestation: () => attestation_default,
  BorrowerOnboarder: () => borrower_onboarder_default,
  BorrowerProfileEditor: () => borrower_profile_editor_default,
  BorrowerTerms: () => borrower_terms_default,
  Button: () => Button,
  Header: () => header_default,
  InjectedTargetBox: () => injected_target_box_default,
  LenderDashboard: () => lender_dashboard_default,
  LenderLoanTable: () => lender_loan_table_default,
  LenderProfileEditor: () => lender_profile_editor_default,
  LenderReferrals: () => lender_referrals_default,
  LoanAppSelector: () => loan_app_selector_default,
  LoanApplicationView: () => loan_application_view_default,
  LoanApplicationsTable: () => loan_applications_default,
  LoanOfferDetail: () => offer_detail_default,
  MessageForm: () => messaging_default,
  Messaging: () => messaging_default,
  Navbar: () => navbar_default,
  OnboardWidget: () => onboard_widget_default,
  ProfileDisplay: () => profile_display_default,
  ProgressBar: () => progress_bar_default,
  RevertAcceptedButton: () => revert_accepted_button_default,
  ReviewOffersDialog: () => review_offers_dialog_default,
  ScreenOne: () => screenone_default,
  SpecialistDashboard: () => specialist_dashboard_default,
  SpecialistLoanTable: () => specialist_loan_table_default,
  SpecialistReferrals: () => specialist_referrals_default,
  SubmitLoanDialog: () => submit_loan_dialog_default,
  Table: () => Table,
  TableBody: () => TableBody,
  TableCaption: () => TableCaption,
  TableCell: () => TableCell,
  TableFooter: () => TableFooter,
  TableHead: () => TableHead,
  TableHeader: () => TableHeader,
  TableRow: () => TableRow,
  TemplateTable: () => template_table_default,
  TermSheetTable: () => term_sheet_table_default,
  UpdateProfileDialog: () => update_profile_dialog_default,
  UserInvite: () => user_invite_default,
  UtilityNavbar: () => utility_bar_default,
  buttonVariants: () => buttonVariants
});
module.exports = __toCommonJS(src_exports);

// src/elements/onboard-widget.tsx
var import_zod2 = require("zod");
var import_lib = require("@cxm/lib");
var import_react3 = require("react");
var import_react_hook_form3 = require("react-hook-form");

// src/utils/form-helpers.tsx
var import_react = require("react");
var import_ai = require("react-icons/ai");
var import_react_hook_form2 = require("react-hook-form");
var import_zod = require("@hookform/resolvers/zod");
var import_lodash = require("lodash");

// src/components/select.tsx
var React = __toESM(require("react"));
var import_react_select = __toESM(require("react-select"));
var import_jsx_runtime = require("react/jsx-runtime");
var Select = React.forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
      import_react_select.default,
      {
        ...props,
        classNames: {
          control: (state) => "flex w-full rounded-md border border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        },
        ref
      }
    );
  }
);
Select.displayName = "Select";

// src/components/slider.tsx
var React2 = __toESM(require("react"));
var SliderPrimitive = __toESM(require("@radix-ui/react-slider"));

// src/cn.ts
var import_clsx = require("clsx");
var import_tailwind_merge = require("tailwind-merge");
function cn(...inputs) {
  return (0, import_tailwind_merge.twMerge)((0, import_clsx.clsx)(inputs));
}

// src/components/slider.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var Slider = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
  SliderPrimitive.Root,
  {
    ref,
    className: cn(
      "relative flex w-full touch-none select-none items-center",
      className
    ),
    ...props,
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(SliderPrimitive.Track, { className: "relative h-2 w-full grow overflow-hidden rounded-full bg-secondary/50", children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(SliderPrimitive.Range, { className: "absolute h-full bg-primary" }) }),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        SliderPrimitive.Thumb,
        {
          style: {},
          className: "block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
        }
      )
    ]
  }
));
Slider.displayName = SliderPrimitive.Root.displayName;

// src/components/input.tsx
var React3 = __toESM(require("react"));
var import_jsx_runtime3 = require("react/jsx-runtime");
var Input = React3.forwardRef(
  ({ className, type, ...props }, ref) => {
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
      "input",
      {
        type,
        className: cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        ),
        ref,
        ...props
      }
    );
  }
);
Input.displayName = "Input";

// src/components/form.tsx
var React5 = __toESM(require("react"));
var import_react_slot = require("@radix-ui/react-slot");
var import_react_hook_form = require("react-hook-form");

// src/components/label.tsx
var React4 = __toESM(require("react"));
var LabelPrimitive = __toESM(require("@radix-ui/react-label"));
var import_class_variance_authority = require("class-variance-authority");
var import_jsx_runtime4 = require("react/jsx-runtime");
var labelVariants = (0, import_class_variance_authority.cva)(
  "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);
var Label = React4.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
  LabelPrimitive.Root,
  {
    ref,
    className: cn(labelVariants(), className),
    ...props
  }
));
Label.displayName = LabelPrimitive.Root.displayName;

// src/components/form.tsx
var import_jsx_runtime5 = require("react/jsx-runtime");
var Form = import_react_hook_form.FormProvider;
var FormFieldContext = React5.createContext(
  {}
);
var FormField = ({
  ...props
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(FormFieldContext.Provider, { value: { name: props.name }, children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_react_hook_form.Controller, { ...props }) });
};
var useFormField = () => {
  const fieldContext = React5.useContext(FormFieldContext);
  const itemContext = React5.useContext(FormItemContext);
  const { getFieldState, formState } = (0, import_react_hook_form.useFormContext)();
  const fieldState = getFieldState(fieldContext.name, formState);
  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }
  const { id } = itemContext;
  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};
var FormItemContext = React5.createContext(
  {}
);
var FormItem = React5.forwardRef(({ className, ...props }, ref) => {
  const id = React5.useId();
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(FormItemContext.Provider, { value: { id }, children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("div", { ref, className: cn("", className), ...props }) });
});
FormItem.displayName = "FormItem";
var FormLabel = React5.forwardRef(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    Label,
    {
      ref,
      className: cn(error && "text-destructive", className),
      htmlFor: formItemId,
      ...props
    }
  );
});
FormLabel.displayName = "FormLabel";
var FormControl = React5.forwardRef(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    import_react_slot.Slot,
    {
      ref,
      id: formItemId,
      "aria-describedby": !error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`,
      "aria-invalid": !!error,
      ...props
    }
  );
});
FormControl.displayName = "FormControl";
var FormDescription = React5.forwardRef(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    "p",
    {
      ref,
      id: formDescriptionId,
      className: cn("text-sm text-muted-foreground", className),
      ...props
    }
  );
});
FormDescription.displayName = "FormDescription";
var FormMessage = React5.forwardRef(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;
  if (!body) {
    return null;
  }
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    "p",
    {
      ref,
      id: formMessageId,
      className: cn("text-sm font-medium text-destructive", className),
      ...props,
      children: body
    }
  );
});
FormMessage.displayName = "FormMessage";
var RootFormMessage = React5.forwardRef(({ className, children, ...props }, ref) => {
  const { formState } = (0, import_react_hook_form.useFormContext)();
  const root = formState.errors.root;
  const body = root ? String(root?.message) : children;
  if (!body) {
    return null;
  }
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    "p",
    {
      ref,
      id: "root-error",
      className: cn("text-sm font-medium text-destructive", className),
      ...props,
      children: body
    }
  );
});
RootFormMessage.displayName = "RootFormMessage";

// src/utils/numbers.ts
var roundToPlaces = (input, places) => {
  var multiplier = Math.pow(10, places);
  return Math.round(input * multiplier) / multiplier;
};

// src/utils/strings.ts
function stringSorter(a, b) {
  if (a < b) {
    return -1;
  }
  if (b < a) {
    return 1;
  }
  return 0;
}
function timeSince(millisSince) {
  var seconds = Math.floor(millisSince / 1e3);
  var interval = seconds / 31536e3;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592e3;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
function capitalize(input) {
  const str = input.toString();
  const camelSplit = str.split(/(?<=[a-z])(?=[A-Z ])/);
  return camelSplit.map((word) => {
    var firstChar = word[0];
    if (!firstChar) {
      return "";
    }
    return firstChar.toUpperCase() + word.slice(1);
  }).join(" ");
}
var formatterCache = {};
function formatPercentNumber(val) {
  return roundToPlaces(val * 100, 2) + "%";
}
function currency(input, fractionDigits = 0, locale = "en-US", currency4 = "USD") {
  let localeCache = formatterCache[locale];
  if (!localeCache) {
    localeCache = formatterCache[locale] = {};
  }
  let formatter = localeCache[currency4];
  if (!formatter) {
    formatter = localeCache[currency4] = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency4,
      maximumFractionDigits: fractionDigits
    });
  }
  return formatter.format(input);
}
function formatSSN(ssn) {
  var val = ssn.replace(/[^\d-]/g, "");
  val = val.replace(/^(\d{3})-?(\d{1,2})/, "$1-$2");
  val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, "$1-$2-$3");
  val = val.split("").filter((val2, idx) => {
    return val2 !== "-" || idx === 3 || idx === 6;
  }).join("");
  return val.substring(0, 11);
}
function formatPercent(val) {
  if (typeof val == "number") {
    return val + "%";
  } else if (typeof val != "string") {
    return "0%";
  }
  val = val.replace(/[^\d]/g, "");
  val = val.replace(/^0+/, "");
  if (val.length == 0) {
    return "0";
  }
  var value = parseInt(val);
  if (value > 100) {
    return "100";
  }
  return val.substring(0, 3);
}
function displayMoney(val, allowNegative = true) {
  return val == "-" ? "-" : val == "" ? currency(0) : currency(allowNegative ? parseInt(val) : Math.max(0, parseInt(val)));
}
function formatMoney(val) {
  var val = val.replace(/[^\d-]/g, "");
  val = val.replace(/(?<=.)-/, "");
  val = val.replace(/^0+/, "");
  if (val.length == 0) {
    return "0";
  }
  return val == "-" ? "-" : val;
}

// src/components/button.tsx
var React6 = __toESM(require("react"));
var import_react_slot2 = require("@radix-ui/react-slot");
var import_class_variance_authority2 = require("class-variance-authority");
var import_jsx_runtime6 = require("react/jsx-runtime");
var buttonVariants = (0, import_class_variance_authority2.cva)(
  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        success: "bg-success text-primary-foreground hover:bg-success/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline"
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);
var Button = React6.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? import_react_slot2.Slot : "button";
    return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
      Comp,
      {
        className: cn(buttonVariants({ variant, size, className })),
        ref,
        ...props
      }
    );
  }
);
Button.displayName = "Button";

// src/utils/form-helpers.tsx
var import_lucide_react = require("lucide-react");

// src/components/tooltip.tsx
var React7 = __toESM(require("react"));
var TooltipPrimitive = __toESM(require("@radix-ui/react-tooltip"));
var import_jsx_runtime7 = require("react/jsx-runtime");
var TooltipProvider = TooltipPrimitive.Provider;
var Tooltip = TooltipPrimitive.Root;
var TooltipTrigger = TooltipPrimitive.Trigger;
var TooltipContent = React7.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
  TooltipPrimitive.Content,
  {
    ref,
    sideOffset,
    className: cn(
      "z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

// src/utils/form-helpers.tsx
var import_jsx_runtime8 = require("react/jsx-runtime");
var IconTooltip = ({ content }) => {
  const [hovered, setHovered] = (0, import_react.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(Tooltip, { open: hovered, children: [
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(TooltipTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      "div",
      {
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
        className: "ml-2 text-gray-500",
        children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_ai.AiFillInfoCircle, {})
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(TooltipContent, { className: "max-w-[300px]", children: content })
  ] });
};
function displayMoney2(val, allowNegative = true) {
  return val == "-" ? "-" : val == "" ? currency(0) : currency(allowNegative ? parseInt(val) : Math.max(0, parseInt(val)));
}
function formatNumber(val) {
  var val = val.replace(/[^\d]/g, "");
  console.log(val);
  val = val.replace(/^0+/, "");
  if (val.length == 0) {
    return "0";
  }
  return val == "-" ? "-" : val;
}
function formatMoney2(val) {
  var val = val.replace(/[^\d-]/g, "");
  val = val.replace(/(?<=.)-/, "");
  val = val.replace(/^0+/, "");
  if (val.length == 0) {
    return "0";
  }
  return val == "-" ? "-" : val;
}
var FieldControl = (props) => {
  const [focused, setFocused] = (0, import_react.useState)(false);
  const [stringValue, setStringValue] = (0, import_react.useState)(
    props.field.value ? props.field.value : "0"
  );
  const { field, zodFieldType, zodField, fieldConfig, defaultValue } = props;
  const configOnChange = fieldConfig.onChange;
  const onChange = configOnChange ? (v) => {
    configOnChange(v);
    field.onChange(v);
  } : field.onChange;
  if (
    // We are an enum select (option object type)
    fieldConfig.altDisplay?.type === "select"
  ) {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      Select,
      {
        className: props.className,
        options: fieldConfig.altDisplay.options,
        ...field,
        value: (0, import_lodash.isObject)(field.value) ? field.value : fieldConfig.altDisplay.options.find(
          (opt) => opt.value == field.value
        ),
        onChange: configOnChange ? (v) => {
          configOnChange(v);
          field.onChange(v);
        } : (v) => field.onChange(v.value)
      }
    );
  } else if (zodFieldType === "ZodNumber" && fieldConfig.altDisplay?.type === "slider") {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", { className: cn(props.className, "flex flex-row space-x-2"), children: [
      /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
        Slider,
        {
          ...field,
          value: [field.value],
          defaultValue: [field.value],
          onValueChange: (e) => {
            if (field.name.includes("Years")) {
              var stringNumber = formatNumber((e[0] ?? 0).toString());
              console.log(stringNumber);
              setStringValue(
                stringNumber
              );
              field.onChange(parseInt(stringNumber));
              return;
            }
            var stringVal = formatMoney2(
              (e[0] ?? 0).toString()
            );
            setStringValue(
              stringVal
            );
            if (stringVal == "-" || stringVal == "") {
              field.onChange(0);
            } else {
              field.onChange(parseInt(stringVal));
            }
          },
          step: fieldConfig.altDisplay.step ?? 1,
          max: fieldConfig.altDisplay.max ?? 100,
          min: fieldConfig.altDisplay.min ?? 0,
          onChange: (v) => {
            if (field.name.includes("Years")) {
              setStringValue(
                v.target.value
              );
              field.onChange(v.target.value);
              return;
            }
            var stringVal = formatMoney2(
              v.target.value
            );
            setStringValue(
              stringVal
            );
            if (stringVal == "-" || stringVal == "") {
              field.onChange(0);
            } else {
              field.onChange(parseInt(stringVal));
            }
          }
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
        Input,
        {
          className: "w-30",
          type: "text",
          ...field,
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
          value: field.name.includes("Years") ? stringValue : displayMoney2(stringValue),
          onChange: (e) => {
            if (field.name.includes("Years")) {
              var stringNumber = formatNumber(
                e.target.value.toString()
              );
              console.log(stringNumber);
              setStringValue(
                stringNumber
              );
              field.onChange(parseInt(stringNumber));
              return;
            }
            var stringVal = formatMoney2(
              e.target.value
            );
            setStringValue(
              stringVal
            );
            if (stringVal == "-" || stringVal == "") {
              field.onChange(0);
            } else {
              field.onChange(parseInt(stringVal));
            }
          },
          placeholder: fieldConfig.placeholder || ""
        }
      )
    ] });
  } else if (zodFieldType === "ZodBoolean") {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      Input,
      {
        type: "checkbox",
        ...field,
        onChange: (e) => onChange(e.target.checked),
        className: cn("w-[20px] h-[20px]", props.className)
      }
    );
  } else {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      Input,
      {
        className: props.className,
        type: fieldConfig.altDisplay?.type == "textarea" ? "textarea" : "text",
        ...field,
        value: fieldConfig.format && !focused ? fieldConfig.format(field.value) : field.value || defaultValue,
        onChange: (e) => {
          if (zodFieldType === "ZodNumber") {
            const value = e.target.value;
            if (value === "") {
              onChange(0);
            } else {
              const num = parseInt(value);
              onChange(isNaN(num) ? 0 : num);
            }
          } else {
            onChange(e.target.value);
          }
        },
        placeholder: fieldConfig.placeholder || ""
      }
    );
  }
};
var renderField = (zodForm, key, form, config) => {
  const zodField = zodForm.shape[key];
  const zodFieldType = zodField._def.typeName;
  if (!(0, import_lodash.isString)(key))
    return void 0;
  const fieldConfig = config.fields[key] || {};
  const tooltipLocation = fieldConfig.tooltipLocation ?? config.tooltipLocation ?? "field";
  const label = /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormLabel, { children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", { className: cn("flex flex-row", config.classNames?.label), children: [
    fieldConfig.label ?? capitalize(key),
    tooltipLocation == "label" && fieldConfig.tooltip && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("span", { className: "ml-2 text-gray-500", children: fieldConfig.tooltip }),
    config.showRequired && zodField._def.typeName != "ZodOptional" && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("span", { children: "*" }),
    tooltipLocation == "icon" && fieldConfig.tooltip && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(IconTooltip, { content: fieldConfig.tooltip })
  ] }) });
  const defaultValue = zodFieldType === "ZodNumber" ? 0 : "";
  const innerField = (field) => zodFieldType === "ZodBoolean" ? /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_jsx_runtime8.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(
      FormItem,
      {
        className: cn(
          "flex flex-row align-middle items-center space-x-2 space-y-0",
          config.classNames?.field
        ),
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormControl, { className: config.classNames?.input, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
            FieldControl,
            {
              field,
              zodField,
              zodFieldType,
              fieldConfig,
              defaultValue
            }
          ) }),
          label,
          tooltipLocation == "field" && fieldConfig.tooltip && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormDescription, { children: fieldConfig.tooltip })
        ]
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormMessage, { className: "text-right" })
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_jsx_runtime8.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(
      FormItem,
      {
        className: config.classNames?.field,
        children: [
          label,
          /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormControl, { className: config.classNames?.input, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
            FieldControl,
            {
              field,
              zodField,
              zodFieldType,
              fieldConfig,
              defaultValue
            }
          ) }),
          tooltipLocation == "field" && fieldConfig.tooltip && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormDescription, { children: fieldConfig.tooltip })
        ]
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormMessage, { className: "text-right" })
  ] });
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
    FormField,
    {
      control: form.control,
      name: key,
      render: ({ field }) => {
        if (fieldConfig.renderField) {
          return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_jsx_runtime8.Fragment, { children: fieldConfig.renderField(innerField(field), fieldConfig, field) });
        } else if (fieldConfig.renderFieldFromValues) {
          return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_jsx_runtime8.Fragment, { children: fieldConfig.renderFieldFromValues(
            innerField(field),
            fieldConfig,
            form.getValues(),
            field
          ) });
        } else {
          return innerField(field);
        }
      }
    },
    key
  );
};
var FormGenerator = ({
  className,
  zodForm,
  config,
  onSubmit
}) => {
  const form = (0, import_react_hook_form2.useForm)({
    resolver: (0, import_zod.zodResolver)(zodForm),
    defaultValues: config.defaultValues
  });
  const [submitLoading, setSubmitLoading] = (0, import_react.useState)(false);
  const fieldsConfig = config.fields;
  const fields = (0, import_react.useMemo)(() => {
    return Object.keys(zodForm.shape).sort(
      (key, key2) => (fieldsConfig && fieldsConfig[key]?.order || 0) - (fieldsConfig && fieldsConfig[key2]?.order || 0)
    ).map((key) => {
      const field = renderField(
        zodForm,
        key,
        form,
        config
      );
      return field;
    });
  }, [zodForm, fieldsConfig]);
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(TooltipProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(Form, { ...form, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(
    "form",
    {
      onSubmit: form.handleSubmit(async (v) => {
        setSubmitLoading(true);
        try {
          await onSubmit(v);
        } catch (err) {
          if (err?.name == "AxiosError") {
            form.setError("root", { message: err.response.data });
          } else {
            form.setError("root", err);
          }
          setSubmitLoading(false);
        }
        setSubmitLoading(false);
      }),
      className,
      children: [
        fields,
        config.submitButton ?? /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
          Button,
          {
            type: "submit",
            className: "mt-4 w-full",
            children: submitLoading ? /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_lucide_react.Loader2, { className: "animate-spin" }) : config.submitText ?? "Submit"
          }
        ),
        config.showRequired && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(FormDescription, { children: "Required fields are marked with an asterisk (*)." }),
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(RootFormMessage, {})
      ]
    }
  ) }) });
};

// src/components/table.tsx
var React9 = __toESM(require("react"));
var import_jsx_runtime9 = require("react/jsx-runtime");
var Table = React9.forwardRef(({ className, parentClassName, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("div", { className: cn("w-full", parentClassName), children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
  "table",
  {
    ref,
    className: cn("w-full caption-bottom text-sm", className),
    ...props
  }
) }));
Table.displayName = "Table";
var TableHeader = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("thead", { ref, className: cn("[&_tr]:border-b", className), ...props }));
TableHeader.displayName = "TableHeader";
var TableBody = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
  "tbody",
  {
    ref,
    className: cn("[&_tr:last-child]:border-0", className),
    ...props
  }
));
TableBody.displayName = "TableBody";
var TableFooter = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
  "tfoot",
  {
    ref,
    className: cn("bg-primary font-medium text-primary-foreground", className),
    ...props
  }
));
TableFooter.displayName = "TableFooter";
var TableRow = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
  "tr",
  {
    ref,
    className: cn(
      "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
      className
    ),
    ...props
  }
));
TableRow.displayName = "TableRow";
var TableHead = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
  "th",
  {
    ref,
    className: cn(
      "h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0",
      className
    ),
    ...props
  }
));
TableHead.displayName = "TableHead";
var TableCell = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
  "td",
  {
    ref,
    className: cn("px-4 align-middle [&:has([role=checkbox])]:pr-0", className),
    ...props
  }
));
TableCell.displayName = "TableCell";
var TableCaption = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
  "caption",
  {
    ref,
    className: cn("mt-4 text-sm text-muted-foreground", className),
    ...props
  }
));
TableCaption.displayName = "TableCaption";

// src/utils/table-helpers.tsx
var import_jsx_runtime10 = require("react/jsx-runtime");
var DataRows = ({ data, caption, fields: config, className }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(Table, { className, children: [
    caption && /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(TableCaption, { className: "text-white", children: caption }),
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(TableHeader, {}),
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(TableBody, { children: Object.keys(data).filter(
      (x) => !config || !config[x]?.hidden
    ).map(
      (objKey) => {
        const key = objKey;
        const render = config && config[key]?.render;
        return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(TableRow, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(TableCell, { className: "px-0 content-start", children: (config && config[key]?.label) ?? capitalize(key) }),
          /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(TableCell, { className: "px-0", children: render ? render(data[key]) : data[key]?.toString() || "" })
        ] }, objKey);
      }
    ) })
  ] });
};

// src/components/wheel.tsx
var d3 = __toESM(require("d3"));
var import_react2 = __toESM(require("react"));
var import_jsx_runtime11 = require("react/jsx-runtime");
var Wheel = class extends import_react2.default.Component {
  glossary;
  state = {
    hoveredId: void 0,
    rotation: 0,
    slices: [
      {
        name: "ABL",
        color: "#7f47de",
        id: 0
      },
      {
        name: "A/R Factoring",
        color: "#93268f",
        id: 1
      },
      {
        name: "SBA",
        color: "#425dc8",
        id: 2
      },
      {
        name: "Equipment",
        color: "#4abee4",
        id: 3
      },
      {
        name: "SBIC",
        color: "#95de47",
        id: 4
      },
      {
        name: "Cash Flow",
        color: "#f37d0a",
        id: 5
      },
      {
        name: "Junior/Mezz",
        color: "#e44a72",
        id: 6
      },
      {
        name: "Venture/Rev",
        color: "#4ae4cc",
        id: 7
      }
    ]
  };
  selectedId = 0;
  maxWheelSize = 600;
  wheelSize = 400;
  mouseEnabled = false;
  colorThreshold = 0.3;
  constructor(props) {
    super(props);
    this.state.slices = props.slices;
    this.glossary = props.glossary;
    if (props.size) {
      this.wheelSize = props.size;
    }
  }
  componentDidMount() {
    this.renderWheel();
    d3.select(`.wheel-injection-point #arc-${this.props.selectedType}`).transition().attr(
      "transform",
      function(d) {
        let dist = 4;
        var midAngle = (d.endAngle - d.startAngle) / 2 + d.startAngle;
        let x = Math.sin(midAngle) * dist;
        let y = -Math.cos(midAngle) * dist;
        return "translate(" + x + "," + y + ") scale(1.08)";
      }
    );
    if (this.props.selectedType != void 0) {
      this.spinToSliceById(this.props.selectedType);
    }
  }
  transRotate(rot, trans) {
    return `rotate(${rot}), translate(${trans})`;
  }
  spinToSliceById(sliceId) {
    this.mouseEnabled = false;
    const wheel = this;
    d3.select("svg g").attr("transform-origin", "center").transition().duration(800).ease(d3.easeBackInOut).attrTween("transform", function(d, i) {
      let sliceDeg = 430 - 360 / wheel.state.slices.length * sliceId;
      if (wheel.state.rotation === sliceDeg) {
        let r = d3.interpolate(
          sliceDeg || 70,
          sliceDeg ? sliceDeg + 360 : 430
        );
        let xy = wheel.wheelSize / 2;
        return (t) => wheel.transRotate(r(t), `${xy}, ${xy}`);
      } else {
        let r = d3.interpolate(wheel.state.rotation || 70, sliceDeg);
        wheel.state.rotation = sliceDeg;
        let xy = wheel.wheelSize / 2;
        return (t) => wheel.transRotate(r(t), `${xy}, ${xy}`);
      }
    }).on("end", () => {
      this.mouseEnabled = true;
      this.updateSliceColors();
    });
  }
  updateSliceColors() {
    const wheel = this;
    this.state.slices.forEach(function(slice) {
      const isColored = 1 >= wheel.colorThreshold || slice.id === wheel.props.selectedType;
      wheel.colorSliceById(slice.id, isColored);
    });
  }
  colorSliceById(id, setColored) {
    d3.selectAll(".arc path").filter((s) => s.data.id === id).each(
      function(s) {
        if (setColored === void 0)
          setColored = !s.data.colored;
        s.data.colored = setColored;
        if (s.data.colored) {
          d3.select(this).style("fill", s.data.color);
        } else {
          d3.select(this).style("fill", "#999");
        }
      }
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedType != prevProps.selectedType) {
      if (this.props.selectedType != void 0) {
        console.log(
          "selected type changed to:",
          this.state.slices[this.props.selectedType]?.name
        );
        this.spinToSliceById(this.props.selectedType);
      }
      d3.select(`.wheel-injection-point #arc-${prevProps.selectedType}`).transition().attr(
        "transform",
        function(d) {
          let dist = 4;
          var midAngle = (d.endAngle - d.startAngle) / 2 + d.startAngle;
          let x = Math.sin(midAngle) * dist;
          let y = -Math.cos(midAngle) * dist;
          return "translate(0,0) scale(1)";
        }
      );
      d3.select(`.wheel-injection-point #arc-${this.props.selectedType}`).transition().attr(
        "transform",
        function(d) {
          let dist = 4;
          var midAngle = (d.endAngle - d.startAngle) / 2 + d.startAngle;
          let x = Math.sin(midAngle) * dist;
          let y = -Math.cos(midAngle) * dist;
          return "translate(" + x + "," + y + ") scale(1.08)";
        }
      );
    }
  }
  modHexLuminosity(hex, lum) {
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      var firstHex = hex[0] || "0";
      var secondHex = hex[1] || "0";
      var thirdHex = hex[2] || "0";
      hex = firstHex + firstHex + secondHex + secondHex + thirdHex + thirdHex;
    }
    lum = lum || 0;
    let newHex = "#";
    for (let i = 0; i < 3; i++) {
      let c = parseInt(hex.substr(i * 2, 2), 16);
      let cstr = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(
        16
      );
      newHex += ("00" + c).substr(cstr.length);
    }
    return newHex;
  }
  renderWheel() {
    var div = d3.select(".wheel-injection-point").node();
    let wheel = this;
    const width = this.wheelSize;
    const height = this.wheelSize;
    const data = wheel.state.slices;
    let d3div = d3.select(div).append("svg").attr("class", "d3").attr("width", width).attr("height", height).append("g").attr("transform-origin", "center").attr("transform", "translate(" + width / 2 + ", " + height / 2 + ")").style("z-index", "10");
    d3.select(".wheel-injection-point svg").insert("circle", ":first-child").style("fill", "white").attr("cx", "50%").attr("cy", "50%").attr("r", 0).transition().delay(750).duration(500).attr("r", width * 0.42);
    const outerRadius = width / 2.4;
    const innerRadius = width / 20;
    const arc2 = d3.arc().outerRadius(
      outerRadius
    ).innerRadius(
      innerRadius
    );
    const pie2 = d3.pie().value((d) => {
      return 1;
    });
    const g = d3div.selectAll(".arc").data(pie2(data)).enter().append("g").attr("class", "arc").each((d, i, nodes) => {
      var node = nodes[i];
      if (node) {
        node.id = "arc-" + d.data.id;
      }
    }).on("click", onClicked).on("select", onSelected).on("mouseover", function(evt, s) {
      wheel.setState({
        hoveredId: s.data.id
      });
      if (wheel.mouseEnabled && wheel.props.selectedType != s.data.id) {
        let path = d3.select(this).select("path");
        var wheelStateData = wheel.state.slices[s.data.id];
        d3.select(this).transition().attr(
          "transform",
          function(d) {
            let dist = 4;
            var midAngle = (d.endAngle - d.startAngle) / 2 + d.startAngle;
            let x = Math.sin(midAngle) * dist;
            let y = -Math.cos(midAngle) * dist;
            return "translate(" + x + "," + y + ") scale(1.05)";
          }
        );
        wheel.props.onHoverType(s.data.name);
      }
    }).on("mouseout", function(evt, s) {
      wheel.setState({
        hoveredId: void 0
      });
      if (wheel.props.selectedType == s.data.id) {
        return;
      }
      d3.select(this).transition().attr(
        "transform",
        function(d) {
          let dist = 4;
          var midAngle = (d.endAngle - d.startAngle) / 2 + d.startAngle;
          let x = Math.sin(midAngle) * dist;
          let y = -Math.cos(midAngle) * dist;
          return "translate(0,0) scale(1)";
        }
      );
      wheel.props.onHoverType(void 0);
    });
    function onClicked(evt, s) {
      if (wheel.mouseEnabled) {
        wheel.spinToSliceById(s.data.id);
        wheel.props.onSelectType(s.data.name);
        wheel.setState({
          selectedId: s.data.id
        });
      }
    }
    function onSelected(evt, s) {
    }
    g.append("div").html("");
    g.append("path").style("fill", function(s) {
      return s.data.color;
    }).style("stroke", "white").style("stroke-width", `${wheel.wheelSize * 8e-3}px`).attr("cursor", "pointer").attr("s", 100).transition().delay(function(s, i) {
      return i * 75;
    }).duration(120).attrTween(
      "d",
      function(d) {
        let i = d3.interpolate(d.startAngle, d.endAngle);
        return function(t) {
          d.endAngle = i(t);
          return arc2(d) || "";
        };
      }
    ).call(endall, () => {
      wheel.mouseEnabled = true;
    });
    g.filter(function(d) {
      return d.endAngle - d.startAngle > 0.1;
    }).append("text").style("fill", "#fff").attr("opacity", "0").attr("transform", function(d, i) {
      let rotation = i * (360 / wheel.state.slices.length) + 291;
      return `rotate(${rotation})`;
    }).transition().delay(800).duration(400).ease(d3.easeBackOut).attr("opacity", "1").attr("transform", function(d, i) {
      let rotation = i * (360 / wheel.state.slices.length) + 291;
      return "translate(" + arc2.centroid(d) + "), rotate(" + rotation + ")";
    }).attr("cursor", "pointer").attr("text-anchor", "middle").attr("dy", ".35em").text(function(d) {
      return d.data.name;
    });
    d3.selectAll(".wheel-injection-point .arc text").each(
      function(e) {
        const data2 = e.data;
        const glossaryEntry = wheel.glossary[data2.name];
        if (glossaryEntry) {
        }
      }
    );
    function endall(transition, callback) {
      if (typeof callback !== "function") {
        throw new Error("Wrong callback in endall");
      }
      if (transition.size() === 0)
        callback();
      transition.on("end", function(s, i) {
        if (!i)
          callback(arguments);
      });
    }
  }
  render() {
    return /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)("div", { className: "relative", children: [
      /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)(
        "div",
        {
          className: "border z-20 transition-opacity duration-100 border-gray-300 rounded absolute top-[100%] p-2 w-full bg-white shadow-black text-black" + (this.state.hoveredId != void 0 ? " opacity-100" : " opacity-0"),
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("b", { children: this.state.hoveredId != void 0 && this.props.glossary[this.state.slices[this.state.hoveredId]?.name || ""]?.Item1 }) }),
            this.state.hoveredId != void 0 && /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
              "div",
              {
                dangerouslySetInnerHTML: {
                  __html: this.props.glossary[this.state.slices[this.state.hoveredId]?.name || ""]?.Item2 || ""
                }
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("div", { className: "flex flex-col items-center wheel-injection-point" })
    ] });
  }
};
var wheel_default = Wheel;

// src/elements/onboard-widget.tsx
var import_zod3 = require("@hookform/resolvers/zod");
var import_jsx_runtime12 = require("react/jsx-runtime");
var screenOneMetrics = [
  {
    Key: "TTMRevenue",
    Name: "Previous 12 Months Revenue",
    Min: 0,
    Max: 5e7
  },
  {
    Key: "TTMEbitda",
    Name: "Previous 12 Months Pre-Tax Earnings",
    Min: -5e6,
    Max: 5e7
  },
  {
    Key: "AR",
    Name: "Accounts Receivable (AR)",
    Min: 0,
    Max: 4e7
  },
  {
    Key: "InventoryValue",
    Name: "Inventory Value",
    Min: 0,
    Max: 2e7,
    GlossaryName: "Inventory Value"
  },
  {
    Key: "EstEquipmentValue",
    Name: "Estimated Equipment Value",
    Min: 0,
    Max: 1e7,
    GlossaryName: "Est. Equipment Value"
  },
  {
    Key: "EstRealEstateValue",
    Name: "Est. Real Estate Value",
    Min: 0,
    Max: 2e7
  },
  {
    Key: "CashBalance",
    Name: "Cash Balance",
    Min: 0,
    Max: 1e7
  },
  {
    Key: "AvgMonthlyCash",
    Name: "Average Monthly Cash Balance",
    Min: 0,
    Max: 1e7
  },
  {
    Key: "CurrentLiabilities",
    Name: "Current Liabilities",
    Min: 0,
    Max: 1e7
  },
  {
    Key: "OutstandingDebt",
    Name: "Outstanding Debt Balance",
    Min: 0,
    Max: 2e7
  },
  {
    Key: "YearsInBusiness",
    Name: "Years in Business",
    Min: 0,
    Max: 10
  },
  {
    Name: "Loan Amount",
    Key: "LoanAmount",
    Min: 0,
    Max: 2e7
  }
];
var industryNames = Object.values(import_lib.Industries).map((industry) => industry.displayName);
var industryEnum = import_zod2.z.enum(industryNames.sort());
var formOneModel = import_zod2.z.object({
  Industry: industryEnum,
  ZipCode: import_zod2.z.string().min(3),
  LoanUse: import_zod2.z.number()
});
var formatMoney3 = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 3
});
var formTwoModel = import_zod2.z.object({
  loanType: import_zod2.z.number(),
  dummyField: import_zod2.z.literal("dummy")
});
var defaults = {
  LoanUse: 3,
  Industry: industryNames[10]
};
var formOneConfig = {
  submitText: "Calculate",
  submitButton: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(Button, { className: "w-full mt-[39px] text-lg font-medium p-6 bg-green-500 hover:bg-green-600", children: "Calculate" }),
  defaultValues: defaults,
  tooltipLocation: "icon",
  classNames: {
    field: "",
    label: "sm:text-[16px] mb-1",
    input: "mt-0"
  },
  fields: {
    Industry: {
      altDisplay: {
        type: "select",
        options: industryNames.map((industry) => ({
          value: industry,
          label: industry
        }))
      }
    },
    LoanUse: {
      altDisplay: {
        type: "select",
        options: import_lib.loanUseOptions
      }
    }
  }
};
function OnboardWidget() {
  const [loading, setLoading] = (0, import_react3.useState)(false);
  const [hoveredType, setHoveredType] = (0, import_react3.useState)();
  const [onboardState, setOnboardState] = (0, import_react3.useState)("start");
  const [lastEvalId, setLastEvalId] = (0, import_react3.useState)();
  const [selectedLoanType, setSelectedLoanType] = (0, import_react3.useState)();
  const [hasSelectedLoanType, setHasSelectedLoanType] = (0, import_react3.useState)(false);
  const [drawBar, setDrawBar] = (0, import_react3.useState)(false);
  const [isMobile, setIsMobile] = (0, import_react3.useState)(false);
  const [resultCalculation, setResultCalculation] = (0, import_react3.useState)();
  const [recommendedLoanType, setRecommendedLoanType] = (0, import_react3.useState)(0);
  const [loanApplication, setLoanApplication] = (0, import_react3.useState)();
  const [formOneLastData, setFormOneLastData] = (0, import_react3.useState)();
  const [showDebugDetails, setShowDebugDetails] = (0, import_react3.useState)(false);
  const glossaryQuery = (0, import_lib.useApiQuery)(
    "glossary",
    import_lib.getApiGlossary
  );
  const evalQuery = (0, import_lib.useApiMutation)(
    import_lib.submitScreenOneEvaluation
  );
  const formData = (0, import_react3.useMemo)(() => {
    return glossaryQuery.data && {
      metrics: screenOneMetrics,
      formConfig: {
        ...formOneConfig,
        defaultValues: {
          ...defaults,
          ...Object.fromEntries(
            screenOneMetrics.map(
              (metric) => {
                return [metric.Key, 0];
              }
            )
          )
        },
        fields: {
          ...formOneConfig.fields,
          ...Object.fromEntries(
            screenOneMetrics.map(
              (metric) => {
                const isYearsInBusiness = metric.Name === "Years in Business";
                const glossaryEntry = Object.values(glossaryQuery.data).find(
                  (glosar) => metric.GlossaryName ? glosar.Item1 === metric.GlossaryName : glosar.Item1 === metric.Name
                );
                return [
                  metric.Key,
                  {
                    label: metric.Name,
                    tooltip: glossaryEntry ? /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                      "div",
                      {
                        dangerouslySetInnerHTML: {
                          __html: glossaryEntry.Item2
                        }
                      }
                    ) : void 0,
                    altDisplay: {
                      type: "slider",
                      min: metric.Min,
                      max: metric.Max,
                      step: isYearsInBusiness ? 1 : 25e4
                    },
                    format: isYearsInBusiness ? void 0 : (val) => formatMoney3.format(val)
                  }
                ];
              }
            )
          )
        }
      },
      formZod: import_zod2.z.object({
        ...formOneModel.shape,
        ...Object.fromEntries(
          screenOneMetrics.map(
            (metric) => {
              return [
                metric.Key,
                import_zod2.z.number()
              ];
            }
          )
        )
      })
    };
  }, [glossaryQuery.data]);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  (0, import_react3.useEffect)(() => {
    if (loading) {
      setTimeout(() => {
        setDrawBar(true);
      }, 10);
    } else {
      setDrawBar(false);
    }
  }, [loading]);
  (0, import_react3.useEffect)(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const secondForm = (0, import_react_hook_form3.useForm)({
    resolver: (0, import_zod3.zodResolver)(formTwoModel),
    defaultValues: {
      loanType: recommendedLoanType,
      dummyField: "dummy"
    }
  });
  if (!formData) {
    return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { children: "Loading..." });
  }
  const submitApply = async () => {
    if (!loanApplication)
      return;
    setLoading(true);
    try {
      window.location.href = process.env.REACT_APP_BASE_BORROWER_URL + `/Loan/LandingPageApply?evalId=${lastEvalId}`;
    } catch (e) {
      setLoading(false);
    }
  };
  const submitButton = onboardState === "calculated" ? /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
    Button,
    {
      onClick: submitApply,
      className: "w-full text-lg font-medium p-6 bg-green-500 hover:bg-green-600 mb-4",
      children: "Apply Now"
    }
  ) : /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_jsx_runtime12.Fragment, {});
  const calculatedOrDash = (s) => onboardState === "calculated" ? s : "---";
  let loanType = hoveredType || secondForm.getValues().loanType;
  if (typeof loanType == "object") {
    loanType = loanType.value;
  }
  const termsPage = /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(
    "div",
    {
      className: "-mt-8 space-y-2 " + (isMobile && onboardState !== "calculated" ? " hidden" : " "),
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
          wheel_default,
          {
            selectedType: selectedLoanType,
            onSelectType: (t) => {
              setHasSelectedLoanType(true);
              const loanTypeId = import_lib.loanTypes.find((x) => x.name == t)?.id ?? 0;
              secondForm.setValue("loanType", loanTypeId);
              const data = formOneLastData || {};
              data["LoanType"] = loanTypeId;
              setSelectedLoanType(loanTypeId);
              if (formOneLastData && loanApplication != void 0) {
                calculateScore(data, false);
              }
            },
            slices: import_lib.loanTypes,
            glossary: glossaryQuery.data,
            onHoverType: (t) => setHoveredType(t)
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "bg-secondary/10 rounded text-black p-4 -mt-[16px]", children: [
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("h1", { className: "text-2xl font-bold text-center", children: "CXM Financial Health Score" }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("h1", { className: "w-full font-bold text-center text-5xl", children: resultCalculation?.CxmScore != void 0 ? /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex flex-row items-center mt-[60px]", children: [
            /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "w-[50px] text-sm", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("b", { children: "0" }) }),
            /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "flex-grow", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 h-[10px] w-full relative rounded-full", children: [
              /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                "div",
                {
                  className: "bg-black border border-white rounded-none -top-[3px] h-[16px] w-[6px] absolute",
                  style: {
                    left: (resultCalculation.CxmScore / 1e3 * 100).toString() + "%"
                  }
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                "div",
                {
                  className: "absolute hidden",
                  style: {
                    width: "0px",
                    height: "0px",
                    borderLeft: "20px solid transparent",
                    borderRight: "20px solid transparent",
                    borderTop: "20px solid #000",
                    left: `calc(${resultCalculation.CxmScore / 1e3 * 100} - 50px)%`
                  }
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                "div",
                {
                  className: "w-[100px] text-center -top-[60px] absolute",
                  style: {
                    left: `calc(${resultCalculation.CxmScore / 1e3 * 100}% - 50px)`
                  },
                  children: Math.round(resultCalculation?.CxmScore)
                }
              )
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "w-[50px] text-sm", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("b", { children: "1000" }) })
          ] }) : /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("b", { children: "---" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("h2", { className: "text-sm", children: "CXM Financial Health Score ranges from 0 to 1000 and provides a measure of the overall financial health of your company based on your inputs." })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "bg-secondary rounded text-white p-4", children: [
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("h1", { className: "text-2xl font-bold text-center", children: "Estimated Chance of Securing This Loan Type" }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "font-bold text-center text-5xl", children: resultCalculation?.ApprovalProbability != void 0 ? /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "w-full text-center", children: [
            Math.round(resultCalculation?.ApprovalProbability * 100),
            "%"
          ] }) : "---" }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("h2", { className: "text-sm", children: "CXM Loans calculates the probability of securing a loan from the lenders on the platform based on your inputs." })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "bg-primary text-white p-4 rounded", children: [
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("h1", { className: "text-2xl font-bold mb-4", children: "Target Loan Terms" }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(Form, { ...secondForm, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(
            "form",
            {
              onSubmit: secondForm.handleSubmit(async (values) => {
              }),
              children: [
                /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                  FormField,
                  {
                    name: "loanType",
                    render: ({ field }) => /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(FormItem, { children: [
                      /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(FormLabel, { children: "Selected Loan Type" }),
                      /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                        Select,
                        {
                          className: "text-black",
                          options: import_lib.loanTypeOptions,
                          value: import_lib.loanTypeOptions.find(
                            (opt) => opt.value == selectedLoanType
                          ),
                          onChange: (v) => {
                            setHasSelectedLoanType(true);
                            field.onChange(v.value);
                            setSelectedLoanType(v.value);
                            const data = formOneLastData || {};
                            data["LoanType"] = v.value;
                            if (formOneLastData && resultCalculation != void 0) {
                              calculateScore(data, false);
                            }
                          }
                        }
                      ) }),
                      /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(FormMessage, { className: "text-right" })
                    ] })
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(import_jsx_runtime12.Fragment, { children: [
                  /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                    DataRows,
                    {
                      className: "mt-4 text-xl",
                      data: resultCalculation || {
                        ApprovalProbability: 0,
                        LoanAmount: 0,
                        InterestRate: -1,
                        MaturityInMonths: 0,
                        MonthlyPayment: 0,
                        MatchedLenders: 0,
                        LoanType: -1
                      },
                      fields: {
                        Id: {
                          hidden: true
                        },
                        DebugLenderList: {
                          hidden: true
                        },
                        ProbabilityTraceJson: {
                          hidden: true
                        },
                        MatchedLendersForTargetType: {
                          hidden: true
                        },
                        MetricAssessments: {
                          hidden: true
                        },
                        MatchedLenders: {
                          render: (value) => calculatedOrDash(
                            value.toString()
                          )
                        },
                        CxmScore: {
                          hidden: true,
                          render: (value) => calculatedOrDash(
                            Number(value).toLocaleString(void 0, {
                              maximumFractionDigits: 0
                            })
                          )
                        },
                        ApprovalProbability: {
                          hidden: true,
                          label: "% Chance",
                          render: (value) => value != void 0 ? roundToPlaces(
                            value,
                            2
                          ).toString() + "%" : "---"
                        },
                        LoanAmount: {
                          render: (value) => calculatedOrDash(currency(value)),
                          label: "Loan Amount"
                        },
                        MaturityInMonths: {
                          label: "Maturity",
                          render: (value) => calculatedOrDash(`${value} Months`)
                        },
                        InterestRate: {
                          render: (value) => value != -1 ? roundToPlaces(
                            value,
                            1
                          ).toString() + "%" : "---"
                        },
                        MonthlyPayment: {
                          label: "Estimated Monthly Payment",
                          render: (value) => calculatedOrDash(currency(value))
                        },
                        LoanType: {
                          render: (v) => v == -1 ? "---" : import_lib.loanTypes.find((x) => x.id == v)?.name
                        }
                      }
                    }
                  ),
                  showDebugDetails && resultCalculation && resultCalculation.DebugLenderList && /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex flex-col text-red-400 text-red", children: [
                    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("b", { children: [
                      "Debug Lender Matches (",
                      resultCalculation.DebugLenderList.length,
                      ")"
                    ] }) }),
                    resultCalculation.DebugLenderList.map((x) => /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { children: x.LenderName }, x.LenderName))
                  ] }),
                  showDebugDetails && resultCalculation && resultCalculation.ProbabilityTraceJson && resultCalculation.ProbabilityTraceJson.steps.find(
                    (x) => x.name == "Narrative"
                  )?.details,
                  resultCalculation && resultCalculation.DebugLenderList && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
                    Button,
                    {
                      variant: "destructive",
                      onClick: () => setShowDebugDetails(!showDebugDetails),
                      children: showDebugDetails ? "Hide Debug" : "Show Debug"
                    }
                  )
                ] })
              ]
            }
          ) })
        ] }),
        isMobile && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
          Button,
          {
            className: "mx-auto w-full text-lg font-medium",
            onClick: () => {
              setOnboardState("start");
            },
            children: "Adjust Metrics"
          }
        ),
        submitButton
      ]
    }
  );
  const onboard = () => {
    if (loading) {
      return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "w-full min-h-[800px] h-full bg-gray-300 text-center flex align-middle justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "h-32 my-auto w-60", children: [
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("h2", { className: "text-xl", children: "Loading..." }),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
          "div",
          {
            className: "transition-width duration-500 bg-blue-600 h-2.5 rounded-full mt-6",
            style: { width: drawBar ? "100%" : "0%" }
          }
        ) })
      ] }) });
    } else {
      return termsPage;
    }
  };
  const calculateScore = async (values, shouldFindBestLoanType = true) => {
    setLoading(true);
    setFormOneLastData(values);
    console.log(values);
    const resp = await evalQuery.mutateAsync({
      evalId: void 0,
      data: values,
      findBestLoanType: shouldFindBestLoanType && !hasSelectedLoanType
    });
    const result = {
      LoanAmount: values["LoanAmount"],
      CxmScore: resp.result.CxmScore,
      ApprovalProbability: resp.result.ApprovalProbability,
      MonthlyPayment: resp.result.MonthlyPayment,
      InterestRate: resp.result.InterestRate,
      MaturityInMonths: resp.result.MaturityInMonths,
      MatchedLenders: resp.result.MatchedLendersForTargetType,
      Id: resp.result.Id,
      MatchedLendersForTargetType: resp.result.MatchedLendersForTargetType,
      LoanType: resp.result.LoanType,
      DebugLenderList: resp.result.DebugLenderList,
      ProbabilityTraceJson: resp.result.ProbabilityTraceJson,
      MetricAssessments: resp.result.MetricAssessments
    };
    setLastEvalId(resp.id);
    var newLoanType = import_lib.loanTypes.find(
      (x) => x.id == resp.result.BestMatchLoanType
    )?.name;
    if (newLoanType != void 0) {
      setSelectedLoanType(resp.result.BestMatchLoanType);
    }
    setLoanApplication(result);
    setResultCalculation(result);
    setLoading(false);
    setOnboardState("calculated");
  };
  if (onboardState === "done") {
    return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { children: "Done!" });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(
    "div",
    {
      className: "container h-full grid grid-cols-1 md:grid-cols-2 gap-x-6 pt-2 bg-white",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
          FormGenerator,
          {
            className: (isMobile && (onboardState !== "start" || loading) ? "hidden " : " ") + " space-y-2",
            zodForm: formData.formZod,
            onSubmit: async (v) => {
              v["LoanType"] = selectedLoanType;
              calculateScore(v);
            },
            config: formData.formConfig
          }
        ),
        onboard()
      ]
    }
  );
}
var onboard_widget_default = OnboardWidget;

// src/elements/template-table.tsx
var import_react4 = require("react");
var import_react_table = require("@tanstack/react-table");
var import_lucide_react2 = require("lucide-react");
var import_jsx_runtime13 = require("react/jsx-runtime");
var SortedHeader = (title) => (ctx) => {
  let sortIcon = /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_jsx_runtime13.Fragment, {});
  if (!ctx.header.column.getCanSort()) {
  } else {
    switch (ctx.header.column.getIsSorted()) {
      case "asc":
        sortIcon = /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_lucide_react2.ArrowDown, { className: "ml-2 h-4 w-4" });
        break;
      case "desc":
        sortIcon = /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_lucide_react2.ArrowUp, { className: "ml-2 h-4 w-4" });
        break;
      default:
        sortIcon = /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_lucide_react2.ArrowUpDown, { className: "ml-2 h-4 w-4 text-gray-400" });
        break;
    }
  }
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(
    Button,
    {
      variant: "ghost",
      className: "px-1",
      onClick: (e) => {
        switch (ctx.header.column.getIsSorted()) {
          case "asc":
            ctx.header.column.toggleSorting(true);
            break;
          case "desc":
            ctx.header.column.toggleSorting(void 0);
            break;
          default:
            ctx.header.column.toggleSorting(false);
            break;
        }
      },
      children: [
        title,
        sortIcon
      ]
    }
  );
};
var TemplateTable = ({ columns, data, loading, maxEntriesBeforeSearch, rowLink, prefix }) => {
  const [sorting, setSorting] = (0, import_react4.useState)([]);
  const [columnFilters, setColumnFilters] = (0, import_react4.useState)(
    () => {
      try {
        return JSON.parse(
          localStorage.getItem(prefix + "-table-column-filters") ?? "[]"
        );
      } catch (e) {
        return [];
      }
    }
  );
  const [columnVisibility, setColumnVisibility] = (0, import_react4.useState)(
    () => {
      try {
        return JSON.parse(
          localStorage.getItem(prefix + "termsheet-table-column-visibility") ?? "{}"
        );
      } catch (e) {
        return {};
      }
    }
  );
  (0, import_react4.useEffect)(() => {
    localStorage.setItem(
      "termsheet-table-column-filters",
      JSON.stringify(columnFilters)
    );
  }, [columnFilters]);
  (0, import_react4.useEffect)(() => {
    localStorage.setItem(
      "termsheet-table-column-visibility",
      JSON.stringify(columnVisibility)
    );
  }, [columnVisibility]);
  const table = (0, import_react_table.useReactTable)({
    data: data ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      columnFilters
    },
    onSortingChange: setSorting,
    getSortedRowModel: (0, import_react_table.getSortedRowModel)(),
    getCoreRowModel: (0, import_react_table.getCoreRowModel)(),
    getFilteredRowModel: (0, import_react_table.getFilteredRowModel)(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility
  });
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)("div", { className: "w-full max-w-full p-1 h-full flex flex-col space-y-1", children: [
    maxEntriesBeforeSearch && data && data?.length > maxEntriesBeforeSearch && /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("div", { className: "w-full", children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
      Input,
      {
        className: "w-1/3 h-[42px]",
        placeholder: "Search",
        onChange: (e) => table.setGlobalFilter(e.target.value)
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("div", { className: "h-full w-full overflow-auto flex-grow rounded-md border relative", children: /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(Table, { parentClassName: "", className: "", children: [
      /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(TableHeader, { className: "", children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(TableRow, { className: "", children: headerGroup.headers.map((header) => /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
        TableCell,
        {
          className: "bg-white whitespace-nowrap text-center",
          children: header.isPlaceholder ? null : (0, import_react_table.flexRender)(
            header.column.columnDef.header,
            header.getContext()
          )
        },
        header.id
      )) }, headerGroup.id)) }),
      loading ? /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(TableBody, { className: "text-center", children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("div", { className: "absolute w-full h-full text-center loading-animation" }) }) : /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(TableBody, { className: "justify-start", children: table.getRowModel().rows.map((row, idx) => /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
        TableRow,
        {
          className: "hover:bg-gray-200 h-[60px]",
          onClick: () => {
            if (rowLink) {
              console.log(rowLink(row.original));
              var link = rowLink && rowLink(row.original);
              if (link) {
                window.location.href = link;
              }
            }
          },
          children: row.getVisibleCells().map((cell) => /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
            TableCell,
            {
              className: "text-center",
              style: {
                paddingLeft: "0.5rem !important",
                paddingRight: "0.5rem !important"
              },
              children: (0, import_react_table.flexRender)(
                cell.column.columnDef.cell,
                cell.getContext()
              )
            },
            cell.id
          ))
        },
        idx
      )) })
    ] }) })
  ] });
};
var template_table_default = TemplateTable;

// src/elements/borrower-profile-editor.tsx
var import_react10 = require("react");
var import_react_hook_form5 = require("react-hook-form");
var import_zod4 = require("@hookform/resolvers/zod");
var import_lib4 = require("@cxm/lib");
var import_react_phone_input_2 = __toESM(require("react-phone-input-2"));
var import_lodash2 = require("lodash");

// src/components/textarea.tsx
var React11 = __toESM(require("react"));
var import_jsx_runtime14 = require("react/jsx-runtime");
var Textarea = React11.forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      "textarea",
      {
        className: cn(
          "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        ),
        ref,
        ...props
      }
    );
  }
);
Textarea.displayName = "Textarea";

// src/utils/states.ts
var states = [
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "American Samoa",
    "abbreviation": "AS"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "District Of Columbia",
    "abbreviation": "DC"
  },
  {
    "name": "Federated States Of Micronesia",
    "abbreviation": "FM"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Guam",
    "abbreviation": "GU"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Marshall Islands",
    "abbreviation": "MH"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Northern Mariana Islands",
    "abbreviation": "MP"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Palau",
    "abbreviation": "PW"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Puerto Rico",
    "abbreviation": "PR"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virgin Islands",
    "abbreviation": "VI"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  }
];
var states_default = states;

// src/components/dialog.tsx
var React12 = __toESM(require("react"));
var DialogPrimitive = __toESM(require("@radix-ui/react-dialog"));
var import_lucide_react3 = require("lucide-react");
var import_jsx_runtime15 = require("react/jsx-runtime");
var Dialog = ({ ...props }) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(DialogPrimitive.Root, { ...props });
var DialogTrigger = DialogPrimitive.Trigger;
var DialogPortal = ({
  ...props
}) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(DialogPrimitive.Portal, { ...props });
DialogPortal.displayName = DialogPrimitive.Portal.displayName;
var DialogOverlay = React12.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
  DialogPrimitive.Overlay,
  {
    ref,
    className: cn(
      "fixed inset-0 z-[20000] bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    ),
    ...props
  }
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
var DialogContent = React12.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(DialogPortal, { children: [
  /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(DialogOverlay, {}),
  /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(
    DialogPrimitive.Content,
    {
      ref,
      style: { transform: "translateX(-53%) translateY(-50%)" },
      className: cn(
        "fixed left-[50%] top-[50%] z-[20001] grid max-w-lg gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
        className
      ),
      ...props,
      children: [
        children,
        /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(DialogPrimitive.Close, { className: "absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground", children: [
          /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_lucide_react3.X, { className: "h-4 w-4" }),
          /* @__PURE__ */ (0, import_jsx_runtime15.jsx)("span", { className: "sr-only", children: "Close" })
        ] })
      ]
    }
  )
] }));
DialogContent.displayName = DialogPrimitive.Content.displayName;
var DialogHeader = ({
  className,
  ...props
}) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
  "div",
  {
    className: cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    ),
    ...props
  }
);
DialogHeader.displayName = "DialogHeader";
var DialogFooter = ({
  className,
  ...props
}) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
  "div",
  {
    className: cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    ),
    ...props
  }
);
DialogFooter.displayName = "DialogFooter";
var DialogTitle = React12.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
  DialogPrimitive.Title,
  {
    ref,
    className: cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    ),
    ...props
  }
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;
var DialogDescription = React12.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
  DialogPrimitive.Description,
  {
    ref,
    className: cn("text-sm text-muted-foreground", className),
    ...props
  }
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

// src/components/datepicker.tsx
var import_date_fns = require("date-fns");
var import_lucide_react5 = require("lucide-react");

// src/components/calendar.tsx
var import_lucide_react4 = require("lucide-react");
var import_react_day_picker = require("react-day-picker");
var import_jsx_runtime16 = require("react/jsx-runtime");
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
    import_react_day_picker.DayPicker,
    {
      showOutsideDays,
      className: cn("p-3", className),
      classNames: {
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        day_range_end: "day-range-end",
        day_selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside: "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames
      },
      components: {
        IconLeft: ({ ...props2 }) => /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_lucide_react4.ChevronLeft, { className: "h-4 w-4" }),
        IconRight: ({ ...props2 }) => /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_lucide_react4.ChevronRight, { className: "h-4 w-4" })
      },
      ...props
    }
  );
}
Calendar.displayName = "Calendar";

// src/components/popover.tsx
var React13 = __toESM(require("react"));
var PopoverPrimitive = __toESM(require("@radix-ui/react-popover"));
var import_jsx_runtime17 = require("react/jsx-runtime");
var Popover = PopoverPrimitive.Root;
var PopoverTrigger = PopoverPrimitive.Trigger;
var PopoverContent = React13.forwardRef(({ className, align = "center", sideOffset = 4, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(PopoverPrimitive.Portal, { children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
  PopoverPrimitive.Content,
  {
    ref,
    align,
    sideOffset,
    className: cn(
      "z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
) }));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

// src/components/datepicker.tsx
var import_react5 = require("react");
var import_jsx_runtime18 = require("react/jsx-runtime");
function DatePicker({ className, value: date, onChange }) {
  const [open, setOpen] = (0, import_react5.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(Popover, { open, onOpenChange: setOpen, children: [
    /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(PopoverTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(
      Button,
      {
        variant: "outline",
        className: cn(
          "w-[280px] justify-start text-left font-normal",
          !date && "text-muted-foreground",
          className ?? ""
        ),
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_lucide_react5.Calendar, { className: "mr-2 h-4 w-4" }) }),
          date ? (0, import_date_fns.format)(date, "PPP") : /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("span", { children: "Pick a date" })
        ]
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(PopoverContent, { className: "w-auto p-0 z-[40000]", children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
      Calendar,
      {
        mode: "single",
        selected: date,
        onSelect: (e) => {
          setOpen(false);
          onChange(e);
        },
        initialFocus: true
      }
    ) }) })
  ] }) });
}

// src/elements/borrower-profile-editor.tsx
var import_lucide_react6 = require("lucide-react");

// src/components/auto-save.tsx
var import_react6 = require("react");
var import_debounce = __toESM(require("debounce"));
var import_react_hook_form4 = require("react-hook-form");
var import_use_deep_compare_effect = __toESM(require("use-deep-compare-effect"));
var import_jsx_runtime19 = require("react/jsx-runtime");
var AutoSave = (0, import_react6.memo)(({ onSubmit }) => {
  const methods = (0, import_react_hook_form4.useFormContext)();
  const debouncedSave = (0, import_react6.useCallback)(
    (0, import_debounce.default)(() => {
      console.log("Saving");
      methods.handleSubmit(onSubmit)();
    }, 1e3),
    []
  );
  const watchedData = (0, import_react_hook_form4.useWatch)({
    control: methods.control
  });
  (0, import_use_deep_compare_effect.default)(() => {
    debouncedSave();
  }, [watchedData]);
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("div", {});
});
AutoSave.displayName = "AutoSave";
var auto_save_default = AutoSave;

// src/components/switch.tsx
var React15 = __toESM(require("react"));
var SwitchPrimitives = __toESM(require("@radix-ui/react-switch"));
var import_jsx_runtime20 = require("react/jsx-runtime");
var Switch = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
  SwitchPrimitives.Root,
  {
    className: cn(
      "peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
      className
    ),
    ...props,
    ref,
    children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      SwitchPrimitives.Thumb,
      {
        className: cn(
          "pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"
        )
      }
    )
  }
));
Switch.displayName = SwitchPrimitives.Root.displayName;

// src/elements/attestation.tsx
var import_lib2 = require("@cxm/lib");
var import_react7 = require("react");
var import_react_dialog = require("@radix-ui/react-dialog");
var import_jsx_runtime21 = require("react/jsx-runtime");
var Attestation = ({ id, isreattest, disabled }) => {
  const [attestDialogOpen, setAttestDialogOpen] = (0, import_react7.useState)(false);
  const attestMutation = (0, import_lib2.useApiMutation)(import_lib2.attestLoanApplication, []);
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)(import_jsx_runtime21.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
      Dialog,
      {
        open: attestDialogOpen,
        onOpenChange: (v) => setAttestDialogOpen(v),
        children: /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)(DialogContent, { className: "md:max-w-xl max-h-[100vh] overflow-auto", children: [
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(DialogHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_react_dialog.DialogTitle, { children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("b", { className: "text-lg", children: "Borrower Attestation" }) }) }),
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("p", { children: "By checking the box below and submitting this loan application (the \u201CApplication\u201D), you hereby represent to Connex Markets, Inc. (\u201CCXM\u201D) and its affiliates that:" }),
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("p", { children: "You are authorized to submit the Application on behalf of the entity whose name is listed as the borrower in the Application (the \u201CBorrower\u201D); You and the Borrower attest to the completeness, accuracy and veracity of all information concerning the Borrower that is included in the Application, and attest that you and the Borrower have not failed to disclose any material facts concerning the Borrower\u2019s operations and financial condition; and You have the requisite authority to bind the Borrower to the Borrower\u2019s obligations in the paragraphs below." }),
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("p", { children: "By submitting the Application, the Borrower agrees that to the maximum extent permitted by applicable law, the Borrower shall indemnify, defend and hold harmless CXM and its affiliates, and their respective officers, directors, employees, representatives, owners, partners, members, managers, parent companies, subsidiaries, other affiliates, successors and assigns from any claims, demands, losses, liability, damages, interest, penalties, payments, costs or expenses in connection with, arising out of, or related to any error, false statement or omission in the Application." }),
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("p", { children: "Borrower understands and agrees that for every transaction for which either the borrower or the lender uses our Services (a \u201CTransaction\u201D), CXM shall be entitled to receive, and shall receive, a cash fee (the \u201CTransaction Fee\u201D) that is equal to 1% of the aggregate notional principal amount of the loan and other financial accommodations (including letters of credit and undrawn revolving loans) that are provided to the Borrower (or its affiliates) by any lender (or its affiliates) in the Transaction. The Transaction Fee shall be paid by the Borrower. The Transaction Fee will be deducted from the total proceeds that the Borrower receives upon the closing of each Transaction, and remitted to CXM. By using our Services, the Borrower and its respective affiliates, representatives and agents agree that CXM is entitled to receive the Transaction Fee." }),
          /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)("div", { className: "flex flex-row space-x-4", children: [
            /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
              Button,
              {
                disabled: attestMutation.isLoading,
                className: "ml-auto",
                onClick: async () => {
                  await attestMutation.mutateAsync({
                    loanAppId: id,
                    isReattest: isreattest == true
                  });
                  setAttestDialogOpen(false);
                  window.location.href = `/Loan/Details/${id}?tabId=1`;
                },
                children: attestMutation.isLoading ? "Submitting Application..." : "I Attest"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
              Button,
              {
                variant: "secondary",
                onClick: () => {
                  setAttestDialogOpen(false);
                },
                children: "I Decline"
              }
            )
          ] })
        ] })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
      Button,
      {
        className: "w-full",
        variant: "success",
        disabled,
        onClick: () => setAttestDialogOpen(true),
        children: isreattest ? "Attest and Final Submission" : "Submit Application"
      }
    )
  ] });
};
var attestation_default = Attestation;

// src/components/confirmdialog.tsx
var import_react8 = require("react");
var import_jsx_runtime22 = require("react/jsx-runtime");
var ConfirmDialogContext = (0, import_react8.createContext)({
  isOpen: false,
  title: "Confirm",
  message: "",
  openDialog: (_, __) => {
  },
  openDialogAsync: (_) => new Promise(() => {
  }),
  closeDialog: (_) => {
  }
});
var useConfirmDialogContext = () => {
  return (0, import_react8.useContext)(ConfirmDialogContext);
};
var ConfirmDialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = (0, import_react8.useState)(false);
  const [message, setMessage] = (0, import_react8.useState)("Are you sure?");
  const [title, setTitle] = (0, import_react8.useState)("Confirm");
  const [onResolve, setOnResolve] = (0, import_react8.useState)(
    null
  );
  const [resolveAsync, setResolveAsync] = (0, import_react8.useState)(
    null
  );
  const openDialog = ({ title: title2, message: message2 }, newOnResolve) => {
    setIsOpen(true);
    setTitle(title2 || "Confirm");
    setMessage(message2 || "Are you sure?");
    setOnResolve(newOnResolve);
  };
  const openDialogAsync = (opts) => {
    const promise = new Promise((resolve) => {
      const resolveFunc = (val) => {
        console.trace();
        resolve(val);
      };
      setResolveAsync(() => (val) => resolveFunc(val));
    });
    openDialog(opts, null);
    return promise;
  };
  const closeDialog = (confirmed) => {
    setIsOpen(false);
    if (resolveAsync) {
      resolveAsync(confirmed);
    } else if (onResolve) {
      onResolve(confirmed);
    }
    setOnResolve(null);
    setResolveAsync(null);
  };
  const contextValue = {
    isOpen,
    title,
    message,
    openDialog,
    openDialogAsync,
    closeDialog
  };
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(ConfirmDialogContext.Provider, { value: contextValue, children: [
    /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
      Dialog,
      {
        open: isOpen,
        onOpenChange: (open) => {
          if (!open) {
            closeDialog(false);
          } else {
            throw new DOMException(
              "Confirm dialog should not be opened directly"
            );
          }
        },
        children: /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(DialogContent, { className: "sm:max-w-[425px] sm:min-w-[400px]", children: [
          /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(DialogHeader, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(DialogTitle, { children: title }),
            /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(DialogDescription, { children: message })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(DialogFooter, { className: "w-full grid grid-cols-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
              Button,
              {
                type: "submit",
                variant: "outline",
                onClick: () => closeDialog(false),
                children: "Cancel"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(Button, { type: "submit", onClick: () => closeDialog(true), children: "Confirm" })
          ] })
        ] })
      }
    ),
    children
  ] });
};

// src/elements/borrower-profile-editor.tsx
var import_search_js_react = require("@mapbox/search-js-react");
var import_awesome_phonenumber = require("awesome-phonenumber");

// src/components/tabs.tsx
var React16 = __toESM(require("react"));
var TabsPrimitive = __toESM(require("@radix-ui/react-tabs"));
var import_jsx_runtime23 = require("react/jsx-runtime");
var Tabs = TabsPrimitive.Root;
var TabsList = React16.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
  TabsPrimitive.List,
  {
    ref,
    className: cn(
      "inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground",
      className
    ),
    ...props
  }
));
TabsList.displayName = TabsPrimitive.List.displayName;
var TabsTrigger = React16.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
  TabsPrimitive.Trigger,
  {
    ref,
    className: cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm",
      className
    ),
    ...props
  }
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;
var TabsContent = React16.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
  TabsPrimitive.Content,
  {
    ref,
    className: cn(
      "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    ),
    ...props
  }
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

// src/components/target-box.tsx
var import_lib3 = require("@cxm/lib");
var import_react9 = require("react");
var import_jsx_runtime24 = require("react/jsx-runtime");
var TargetBox = ({ results }) => {
  const [showDebugDetails, setShowDebugDetails] = (0, import_react9.useState)(false);
  const internalResults = {
    LoanType: results.LoanType,
    ApprovalProbability: results.ApprovalProbability,
    LoanAmount: results.LoanAmount,
    CxmScore: results.CxmScore,
    MatchedLenders: results.MatchedLenders,
    MatchedLendersForTargetType: results.MatchedLendersForTargetType,
    MonthlyPayment: results.MonthlyPayment,
    InterestRate: results.InterestRate,
    MaturityInMonths: results.MaturityInMonths,
    Id: results.Id,
    DebugLenderList: results.DebugLenderList,
    ProbabilityTraceJson: results.ProbabilityTraceJson,
    MetricAssessments: []
  };
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("div", { className: "bg-primary text-white text-lg p-4 rounded md:w-[400px] max-w-[400px] border-primary border shadow-md shadow-primary/40", children: [
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("h1", { className: "text-2xl text-center", children: "CXM Financial Health Score" }),
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("h1", { className: "w-full text-center text-3xl", children: results?.CxmScore != void 0 ? /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("div", { className: "flex flex-row items-center mt-[40px] mb-4", children: [
      /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { className: "w-[50px] text-sm", children: "0" }),
      /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { className: "flex-grow", children: /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("div", { className: "bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 h-[10px] w-full relative rounded-full", children: [
        /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
          "div",
          {
            className: "bg-black border border-white rounded-none -top-[3px] h-[16px] w-[6px] absolute",
            style: {
              left: (results.CxmScore / 1e3 * 100).toString() + "%"
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
          "div",
          {
            className: "absolute hidden",
            style: {
              width: "0px",
              height: "0px",
              borderLeft: "20px solid transparent",
              borderRight: "20px solid transparent",
              borderTop: "20px solid #000",
              left: `calc(${results.CxmScore / 1e3 * 100} - 50px)%`
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
          "div",
          {
            className: "w-[100px] text-center -top-[40px] absolute",
            style: {
              left: `calc(${results.CxmScore / 1e3 * 100}% - 50px)`
            },
            children: Math.round(results?.CxmScore)
          }
        )
      ] }) }),
      /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { className: "w-[50px] text-sm", children: "1000" })
    ] }) : /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("b", { children: "---" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("h1", { className: "text-2xl mb-4 text-center", children: "Target Loan Terms" }),
    /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)(import_jsx_runtime24.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
        DataRows,
        {
          className: "mt-4 text-lg",
          data: internalResults || {
            LoanType: 0,
            ApprovalProbability: 0,
            LoanAmount: 0,
            InterestRate: 0,
            MaturityInMonths: 0,
            MonthlyPayment: 0,
            MatchedLenders: 0
          },
          fields: {
            Id: {
              hidden: true
            },
            MatchedLendersForTargetType: {
              hidden: true
            },
            MetricAssessments: {
              hidden: true
            },
            MatchedLenders: {
              render: (value) => value.toString()
            },
            ProbabilityTraceJson: {
              hidden: true
            },
            DebugLenderList: {
              hidden: true
            },
            CxmScore: {
              hidden: true,
              render: (value) => Number(value).toLocaleString(void 0, {
                maximumFractionDigits: 0
              })
            },
            ApprovalProbability: {
              hidden: true,
              label: "% Chance",
              render: (value) => value != void 0 ? roundToPlaces(
                value,
                2
              ).toString() + "%" : "---"
            },
            LoanAmount: {
              render: (value) => currency(value),
              label: "Loan Amount"
            },
            LoanType: {
              render: (value) => import_lib3.loanTypes.find((x) => x.id == value)?.name,
              label: "Loan Type"
            },
            MaturityInMonths: {
              label: "Maturity",
              render: (value) => `${value} Months`
            },
            InterestRate: {
              render: (value) => value != 0 ? roundToPlaces(
                value,
                2
              ).toString() + "%" : "---"
            },
            MonthlyPayment: {
              label: "Estimated Monthly Payment",
              render: (value) => currency(value)
            }
          }
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("div", { className: "max-h-[300px] overflow-auto", children: [
        showDebugDetails && internalResults && internalResults.DebugLenderList && /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("div", { className: "flex flex-col text-red-400 text-red", children: [
          /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("b", { children: [
            "Debug Lender Matches (",
            internalResults.DebugLenderList.length,
            ")"
          ] }) }),
          internalResults.DebugLenderList.map((x) => /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { children: x.LenderName }, x.LenderName))
        ] }),
        showDebugDetails && internalResults && internalResults.ProbabilityTraceJson && internalResults.ProbabilityTraceJson.steps.find(
          (x) => x.name == "Narrative"
        )?.details
      ] }),
      internalResults && internalResults.DebugLenderList && /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
        Button,
        {
          variant: "destructive",
          onClick: () => setShowDebugDetails(!showDebugDetails),
          children: showDebugDetails ? "Hide Debug" : "Show Debug"
        }
      )
    ] })
  ] });
};
var target_box_default = TargetBox;

// src/elements/borrower-profile-editor.tsx
var import_jsx_runtime25 = require("react/jsx-runtime");
var monthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];
var FixedAddressAutofill = import_search_js_react.AddressAutofill;
var steps = [
  {
    label: "Company Info",
    requiredFields: [
      "CompanyInformation.CompanyName",
      "CompanyInformation.CompanyWebsite",
      "CompanyInformation.YearFormed",
      "CompanyInformation.CompanyType",
      "CompanyInformation.HeadQuartersAddress.Line1",
      "CompanyInformation.HeadQuartersAddress.ZipCode",
      "CompanyInformation.HeadQuartersAddress.State",
      "CompanyInformation.HeadQuartersAddress.City",
      "CompanyInformation.NumberOfEmployees",
      "CompanyInformation.Industry",
      "CompanyInformation.SubIndustry",
      "CompanyInformation.EmployerIdentificationNumber"
    ]
  },
  {
    label: "Business Overview",
    requiredFields: [
      "CompanyInformation.BusinessDescription",
      "CompanyInformation.BusinessPlanFile",
      "CompanyInformation.OrgChartFile",
      "CompanyInformation.GrowthPlansDescription",
      "CompanyInformation.RevenueMix",
      "CompanyInformation.TotalRevenuePercentOfTopFiveCustomers",
      "CompanyInformation.CustomerContractFiles"
    ]
  },
  {
    label: "Contacts & Officers",
    requiredFields: [
      "CompanyInformation.PrimaryContact",
      "CompanyInformation.Officers"
    ]
  },
  {
    label: "Tax & Audit Information",
    requiredFields: [
      "TaxInformation.TaxReturnFiles",
      "TaxInformation.LastYearBankStatementFiles"
    ]
  },
  {
    label: "P&L Metrics",
    requiredFields: [
      "ProfitLossMetrics.FiscalYearEnd",
      "ProfitLossMetrics.PreviousYearsFiles",
      "ProfitLossMetrics.CurrentAndNextYearProjectionFiles",
      "ProfitLossMetrics.TtmMetrics.Revenues",
      "ProfitLossMetrics.TtmMetrics.Sga",
      "ProfitLossMetrics.TtmMetrics.CostOfSales",
      "ProfitLossMetrics.TtmMetrics.GrossProfit",
      "ProfitLossMetrics.TtmMetrics.Ebitda",
      "ProfitLossMetrics.TtmMetrics.InterestExpense",
      "ProfitLossMetrics.TtmMetrics.TotalOtherIncome",
      "ProfitLossMetrics.TtmMetrics.TotalOtherExpenses",
      "ProfitLossMetrics.TtmMetrics.NetIncome",
      "ProfitLossMetrics.YtdMetrics.Revenues",
      "ProfitLossMetrics.YtdMetrics.Sga",
      "ProfitLossMetrics.YtdMetrics.CostOfSales",
      "ProfitLossMetrics.YtdMetrics.GrossProfit",
      "ProfitLossMetrics.YtdMetrics.Ebitda",
      "ProfitLossMetrics.YtdMetrics.InterestExpense",
      "ProfitLossMetrics.YtdMetrics.TotalOtherIncome",
      "ProfitLossMetrics.YtdMetrics.TotalOtherExpenses",
      "ProfitLossMetrics.YtdMetrics.NetIncome"
    ]
  },
  {
    label: "Balance Sheet Metrics",
    requiredFields: [
      "BalanceSheetMetrics.BalanceSheetAsOfDate",
      "BalanceSheetMetrics.MostRecentBalanceSheetFile",
      "BalanceSheetMetrics.PreviousYearsBalanceSheetFiles",
      "BalanceSheetMetrics.BalanceSheetAsOfDate",
      "BalanceSheetMetrics.CurrentCash",
      "BalanceSheetMetrics.CurrentAssets",
      "BalanceSheetMetrics.TotalAssets",
      "BalanceSheetMetrics.CurrentLiabilities",
      "BalanceSheetMetrics.TotalLiabilities",
      "BalanceSheetMetrics.AccountsReceivableScheduleFiles",
      "BalanceSheetMetrics.AccountsReceivable30Days",
      "BalanceSheetMetrics.AccountsReceivable3160Days",
      "BalanceSheetMetrics.AccountsReceivable6190Days",
      "BalanceSheetMetrics.AccountsReceivable90PlusDays",
      "BalanceSheetMetrics.AccountsPayableScheduleFiles",
      "BalanceSheetMetrics.AccountsPayable30Days",
      "BalanceSheetMetrics.AccountsPayable3160Days",
      "BalanceSheetMetrics.AccountsPayable6190Days",
      "BalanceSheetMetrics.AccountsPayable90PlusDays"
    ]
  },
  {
    label: "Inventory & Equipment",
    requiredFields: [
      "BalanceSheetMetrics.InventoryAmount",
      "BalanceSheetMetrics.EstEquipmentValue",
      "BalanceSheetMetrics.EstRealEstateValue"
    ]
  },
  { label: "Additional Docs", requiredFields: [] }
];
var allRequiredFields = steps.map((x) => x.requiredFields).flat();
var stateOptions = states_default.map((state) => ({
  label: state.name,
  value: state.abbreviation
}));
var revenueMixOptions = [
  {
    label: "Point Of Sale (B2B)",
    value: 1
  },
  {
    label: "Point Of Sale (B2C)",
    value: 2
  },
  {
    label: "Project-based/Contractual",
    value: 3
  },
  {
    label: "Recurring",
    value: 4
  }
];
var OfficerProfileDialog = ({
  officerEditorState,
  setOfficerEditorState,
  target,
  form,
  borrowerId
}) => {
  const officerForm = (0, import_react_hook_form5.useForm)({
    resolver: (0, import_zod4.zodResolver)(import_lib4.officerModel),
    defaultValues: officerEditorState.initialData
  });
  const [percentFocused, setPercentFocused] = (0, import_react10.useState)(false);
  (0, import_react10.useEffect)(() => {
    if (officerEditorState.open && officerEditorState.target === target) {
      officerForm.reset(officerEditorState.initialData || {});
    }
  }, [officerEditorState.open && officerEditorState.target === target]);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    Dialog,
    {
      open: officerEditorState.open && officerEditorState.target === target,
      onOpenChange: (v) => setOfficerEditorState({
        ...officerEditorState,
        open: v
      }),
      children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(DialogContent, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(DialogHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(DialogTitle, { children: `${target != void 0 ? "Edit" : "Add"} Officer` }) }),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Form, { ...officerForm, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
          "form",
          {
            className: "space-y-1 px-4",
            onSubmit: officerForm.handleSubmit((v) => {
              let officers = form.getValues().CompanyInformation?.Officers;
              if (!officers) {
                officers = [];
              }
              if (target == void 0) {
                officers?.push(v);
              } else {
                officers[target] = v;
              }
              form.setValue(
                "CompanyInformation.Officers",
                officers,
                {
                  shouldDirty: true
                }
              );
              setOfficerEditorState({
                ...officerEditorState,
                open: false
              });
            }),
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "py-2 space-y-2", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "FirstName",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "First Name" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              value: field.value || ""
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "LastName",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Last Name" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              value: field.value || ""
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "Title",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Title" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              value: field.value || ""
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "PercentageShare",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Percent Share" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              onBlur: () => setPercentFocused(false),
                              onFocus: () => setPercentFocused(true),
                              value: percentFocused ? (field.value || 0).toString().replace("%", "") : formatPercent(field.value?.toString() || "0") + "%",
                              onKeyUp: (e) => {
                                field.onChange(
                                  parseInt(formatPercent(
                                    e.target.value.toString().replace(
                                      "%",
                                      ""
                                    )
                                  ))
                                );
                              },
                              onChange: (e) => {
                                field.onChange(
                                  parseInt(formatPercent(
                                    e.target.value.toString().replace(
                                      "%",
                                      ""
                                    )
                                  ))
                                );
                              }
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "SocialSecurityNumber",
                    render: ({ field }) => {
                      const [stringValue, setStringValue] = (0, import_react10.useState)(
                        formatSSN(field.value?.toString() || "")
                      );
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Social Security Number" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              placeholder: "XXX-XX-XXXX",
                              onKeyUp: (e) => {
                                setStringValue(
                                  formatSSN(
                                    e.target.value
                                  )
                                );
                              },
                              type: "text",
                              ...field,
                              value: stringValue || "",
                              onChange: (e) => {
                                const value = e.target.value;
                                setStringValue(formatSSN(value));
                                if (value == "") {
                                  field.onChange(void 0);
                                } else {
                                  field.onChange(
                                    value.replaceAll("-", "")
                                  );
                                }
                              }
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  ProfileFileUploadField,
                  {
                    name: "DriversLicenseFile",
                    label: "Driver's License",
                    buttonText: "Upload Driver's License",
                    borrowerId
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(RootFormMessage, {})
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(Button, { children: [
                target != void 0 ? "Save" : "Add",
                " Officer"
              ] }) })
            ]
          }
        ) })
      ] })
    }
  );
};
var OfficerProfileEditor = ({
  form,
  deleteOfficer,
  setOfficerEditorState,
  officerEditorState,
  borrowerId
}) => {
  const confirm = useConfirmDialogContext();
  const OfficerView = (officer, key) => {
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "border border-gray-200 rounded p-2", children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        OfficerProfileDialog,
        {
          borrowerId,
          target: key,
          deleteOfficer,
          form,
          officerEditorState,
          setOfficerEditorState
        },
        key
      ),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "grid grid-cols-2", children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "font-medium", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "font-medium", children: [
            officer.FirstName,
            " ",
            officer.LastName
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Title" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Share" })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "text-right", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Button,
              {
                type: "button",
                className: "h-[18px] px-1",
                variant: "outline",
                onClick: () => {
                  setOfficerEditorState({
                    open: true,
                    initialData: officer,
                    target: key
                  });
                },
                children: "Edit"
              }
            ),
            key != -1 && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Button,
              {
                type: "button",
                className: "ml-2 h-[18px] px-1",
                variant: "destructive",
                onClick: async () => {
                  if (await confirm.openDialogAsync({
                    title: "Delete Officer",
                    message: "Are you sure you want to delete this officer?"
                  })) {
                    deleteOfficer(key);
                  }
                },
                children: "Delete"
              }
            )
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: officer.Title }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { children: [
            officer.PercentageShare,
            "%"
          ] })
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-row" })
    ] }, key);
  };
  var officers = form.getValues().CompanyInformation?.Officers;
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      "div",
      {
        className: "font-medium text-md" + (isFieldRequiredAndMissing(
          "CompanyInformation.Officers",
          officers
        ) ? "" : ""),
        children: "Officers"
      }
    ),
    officers && officers.map((x, idx) => OfficerView(x, idx)),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      OfficerProfileDialog,
      {
        borrowerId,
        target: void 0,
        deleteOfficer,
        form,
        officerEditorState,
        setOfficerEditorState
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-row-reverse", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
      Button,
      {
        className: "w-full " + (isFieldRequiredAndMissing(
          "CompanyInformation.Officers",
          officers
        ) ? " border-orange-500" : ""),
        variant: "outline",
        type: "button",
        onClick: () => setOfficerEditorState({
          open: true,
          initialData: void 0,
          target: void 0
        }),
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_lucide_react6.Plus, { height: 14, width: 14 }),
          " Add Officer"
        ]
      }
    ) })
  ] });
};
var ContactEditorDialog = ({ form, contactEditorState, target, setContactEditorState }) => {
  const contactForm = (0, import_react_hook_form5.useForm)({
    resolver: (0, import_zod4.zodResolver)(import_lib4.contactModel),
    defaultValues: contactEditorState.initialData
  });
  (0, import_react10.useEffect)(() => {
    if (contactEditorState.open && contactEditorState.target === target) {
      contactForm.reset(contactEditorState.initialData);
    }
  }, [contactEditorState.open && contactEditorState.target === target]);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    Dialog,
    {
      open: contactEditorState.open && contactEditorState.target == target,
      onOpenChange: (v) => setContactEditorState({
        ...contactEditorState,
        open: v
      }),
      children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(DialogContent, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(DialogHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(DialogTitle, { children: `${contactEditorState.initialData ? "Edit" : "Add"} ${contactEditorState.target == -1 ? "Primary" : "Secondary"} Contact` }) }),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Form, { ...contactForm, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
          "form",
          {
            className: "space-y-1 px-4",
            onSubmit: contactForm.handleSubmit((v) => {
              if (contactEditorState.target == -1) {
                form.setValue(
                  "CompanyInformation.PrimaryContact",
                  v,
                  {
                    shouldDirty: true
                  }
                );
              } else {
                let secondaryContacts = form.getValues().CompanyInformation?.SecondaryContacts;
                if (!secondaryContacts) {
                  secondaryContacts = [];
                }
                if (contactEditorState.target == void 0) {
                  secondaryContacts?.push(v);
                } else {
                  secondaryContacts[contactEditorState.target] = v;
                }
                form.setValue(
                  "CompanyInformation.SecondaryContacts",
                  secondaryContacts,
                  {
                    shouldDirty: true
                  }
                );
              }
              setContactEditorState({
                ...contactEditorState,
                open: false
              });
            }),
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "py-2 space-y-2", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "FirstName",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "First Name" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              value: field.value || ""
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "LastName",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Last Name" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              value: field.value || ""
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "Title",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Title" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              value: field.value || ""
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "Email",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Email" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Input,
                            {
                              type: "text",
                              ...field,
                              value: field.value || ""
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    name: "PhoneNumber",
                    render: ({ field }) => {
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "space-y-0 grid grid-cols-4 items-center gap-4", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "text-right", children: "Phone" }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            import_react_phone_input_2.default,
                            {
                              specialLabel: "",
                              country: "us",
                              inputProps: {
                                className: "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                              },
                              value: field.value,
                              onChange: (e) => {
                                field.onChange(e);
                              }
                            }
                          ) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                      ] });
                    }
                  }
                )
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(Button, { children: [
                contactEditorState.initialData ? "Save" : "Add",
                " Contact"
              ] }) })
            ]
          }
        ) })
      ] })
    }
  );
};
var currentYear = (/* @__PURE__ */ new Date()).getFullYear();
var yearOptions = [...Array(50).keys()].map((x) => currentYear - 1 + x).map(
  (x) => ({ label: x, value: x })
);
var AdditionalDebtEditorDialog = ({
  debtEditorState,
  setDebtEditorState,
  target,
  form
}) => {
  const debtForm = (0, import_react_hook_form5.useForm)({
    resolver: (0, import_zod4.zodResolver)(import_lib4.additionalDebtModel),
    defaultValues: debtEditorState.initialData
  });
  (0, import_react10.useEffect)(() => {
    if (debtEditorState.open && debtEditorState.target === target) {
      debtForm.reset(debtEditorState.initialData);
    }
  }, [debtEditorState.open && debtEditorState.target === target]);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    Dialog,
    {
      open: debtEditorState.open && debtEditorState.target === target,
      onOpenChange: (v) => setDebtEditorState({
        ...debtEditorState,
        open: v
      }),
      children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(DialogContent, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(DialogHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(DialogTitle, { children: `${target != void 0 ? "Edit" : "Add"} Debt` }) }),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Form, { ...debtForm, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
          "form",
          {
            className: "space-y-1 px-4",
            onSubmit: debtForm.handleSubmit((v) => {
              let debt = form.getValues().BalanceSheetMetrics?.AdditionalDebt ?? [];
              if (target == void 0) {
                debt?.push(v);
              } else {
                debt[target] = v;
              }
              form.setValue(
                "BalanceSheetMetrics.AdditionalDebt",
                debt,
                {
                  shouldDirty: true
                }
              );
              setDebtEditorState({
                ...debtEditorState,
                open: false
              });
            }),
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "py-2 space-y-2", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  ProfileSelectField,
                  {
                    name: "DebtType",
                    options: import_lib4.loanTypeOptions,
                    label: "Loan Type"
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  ProfileSmallTextField,
                  {
                    name: "Lender",
                    label: "Lender"
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  ProfileMoneyField,
                  {
                    name: "InitialAmount",
                    label: "Total Amount"
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  ProfileMoneyField,
                  {
                    name: "RemainingAmount",
                    label: "Remaining Balance"
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  ProfileMoneyField,
                  {
                    name: "MonthlyPayment",
                    label: "Monthly Payment"
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  FormField,
                  {
                    render: ({ field }) => {
                      const [maturityDate, setMaturityDate] = (0, import_react10.useState)(
                        field.value ? new Date(field.value) : /* @__PURE__ */ new Date()
                      );
                      return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: "Maturity Date" }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "grid grid-cols-2 space-x-2", children: [
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-col", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Select,
                            {
                              options: monthOptions,
                              value: monthOptions.find(
                                (x) => x.value == maturityDate.getMonth() + 1
                              ),
                              onChange: (x) => {
                                var date = maturityDate;
                                date.setMonth(
                                  x.value - 1
                                );
                                setMaturityDate(date);
                                field.onChange(date.getTime());
                              }
                            }
                          ) }) }),
                          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-col", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                            Select,
                            {
                              options: yearOptions,
                              value: yearOptions.find(
                                (x) => x.value == maturityDate.getFullYear()
                              ),
                              onChange: (x) => {
                                var date = maturityDate;
                                date.setFullYear(
                                  x.value
                                );
                                setMaturityDate(date);
                                field.onChange(date.getTime());
                              }
                            }
                          ) }) })
                        ] }),
                        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, {})
                      ] });
                    },
                    name: "MaturityDate"
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  ProfilePercentField,
                  {
                    name: "InterestRate",
                    label: "Interest Rate"
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(RootFormMessage, {})
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(Button, { variant: "outline", children: [
                target != void 0 ? "Save" : "Add",
                " Debt"
              ] }) })
            ]
          }
        ) })
      ] })
    }
  );
};
var AdditionalDebtEditor = ({
  form,
  setDebtEditorState,
  deleteDebt,
  debtEditorState
}) => {
  const confirm = useConfirmDialogContext();
  const DebtView = (debt2, key) => {
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
      "div",
      {
        className: "flex flex-col border border-gray-200 rounded",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            AdditionalDebtEditorDialog,
            {
              target: key,
              debtEditorState,
              setDebtEditorState,
              form
            },
            key
          ),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "p-2 grid grid-cols-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "font-medium", children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "font-medium", children: [
                debt2.Lender,
                " - ",
                currency(debt2.InitialAmount ?? 0)
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Type" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Initial Amount" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Remaining Amount" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Monthly Payment" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Interest Rate" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Maturity Date" })
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "text-right", children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "space-x-2", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  Button,
                  {
                    type: "button",
                    className: "px-1 h-[18px]",
                    variant: "outline",
                    onClick: () => {
                      setDebtEditorState({
                        open: true,
                        initialData: debt2,
                        target: key
                      });
                    },
                    children: "Edit"
                  }
                ),
                key != -1 && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  Button,
                  {
                    type: "button",
                    className: "px-1 h-[18px]",
                    variant: "destructive",
                    onClick: async () => {
                      if (deleteDebt && await confirm.openDialogAsync({
                        title: "Delete Contact",
                        message: "Are you sure you want to delete this contact?"
                      })) {
                        deleteDebt(key);
                      }
                    },
                    children: "Delete"
                  }
                )
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: import_lib4.loanTypes.find((x) => x.id == debt2.DebtType)?.fullName }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: currency(debt2.InitialAmount ?? 0) }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: currency(debt2.RemainingAmount ?? 0) }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: currency(debt2.MonthlyPayment ?? 0) }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: formatPercentNumber((debt2.InterestRate ?? 0) / 100) }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: (debt2.MaturityDate ? new Date(debt2.MaturityDate) : /* @__PURE__ */ new Date()).toLocaleString("en", {
                month: "long",
                year: "numeric"
              }) })
            ] })
          ] })
        ]
      },
      key + "-debt"
    );
  };
  var debt = form.getValues().BalanceSheetMetrics?.AdditionalDebt ?? [];
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
    debt.map((x, idx) => DebtView(x, idx)),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      AdditionalDebtEditorDialog,
      {
        target: void 0,
        form,
        debtEditorState,
        setDebtEditorState
      },
      void 0
    ),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-row-reverse", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
      Button,
      {
        className: "w-full",
        variant: "outline",
        type: "button",
        onClick: () => setDebtEditorState({
          open: true,
          initialData: void 0,
          target: void 0
        }),
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_lucide_react6.Plus, { height: 14, width: 14 }),
          " Add Additional Debt"
        ]
      }
    ) })
  ] });
};
var ContactProfileEditor = ({
  form,
  setContactEditorState,
  deleteContact,
  contactEditorState
}) => {
  const confirm = useConfirmDialogContext();
  const ContactView = (contact, key) => {
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
      "div",
      {
        className: "flex flex-col border border-gray-200 rounded",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            ContactEditorDialog,
            {
              target: key,
              contactEditorState,
              setContactEditorState,
              form
            },
            key
          ),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "p-2 grid grid-cols-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "font-medium", children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "font-medium", children: [
                contact.FirstName,
                " ",
                contact.LastName
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Title" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Phone Number" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Email" })
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "text-right", children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "space-x-2", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  Button,
                  {
                    type: "button",
                    className: "px-1 h-[18px]",
                    variant: "outline",
                    onClick: () => {
                      setContactEditorState({
                        open: true,
                        initialData: contact,
                        target: key
                      });
                    },
                    children: "Edit"
                  }
                ),
                key != -1 && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  Button,
                  {
                    type: "button",
                    className: "px-1 h-[18px]",
                    variant: "destructive",
                    onClick: async () => {
                      if (await confirm.openDialogAsync({
                        title: "Delete Contact",
                        message: "Are you sure you want to delete this contact?"
                      })) {
                        deleteContact(key);
                      }
                    },
                    children: "Delete"
                  }
                )
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: contact.Title }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: (0, import_awesome_phonenumber.parsePhoneNumber)("+" + contact.PhoneNumber).number?.national }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: contact.Email })
            ] })
          ] })
        ]
      },
      key + "map"
    ) });
  };
  var primaryContact = form.getValues().CompanyInformation?.PrimaryContact;
  var secondaryContacts = form.getValues().CompanyInformation?.SecondaryContacts;
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      "div",
      {
        className: "font-medium text-md" + (isFieldRequiredAndMissing(
          "CompanyInformation.PrimaryContact",
          primaryContact
        ) ? " text-primary" : ""),
        children: "Primary Contact"
      }
    ),
    primaryContact ? ContactView(primaryContact, -1) : /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-row-reverse", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
      Button,
      {
        className: "w-full " + (isFieldRequiredAndMissing(
          "CompanyInformation.PrimaryContact",
          primaryContact
        ) ? " border-orange-500" : ""),
        variant: "outline",
        type: "button",
        onClick: () => setContactEditorState({
          open: true,
          initialData: void 0,
          target: -1
        }),
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_lucide_react6.Plus, { height: 14, width: 14 }),
          " Add Primary Contact"
        ]
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      "div",
      {
        className: "font-medium text-md" + (isFieldRequiredAndMissing(
          "CompanyInformation.SecondaryContacts",
          primaryContact
        ) ? " text-primary" : ""),
        children: "Secondary Contacts"
      }
    ),
    secondaryContacts && secondaryContacts.map((x, idx) => ContactView(x, idx)),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      ContactEditorDialog,
      {
        target: -1,
        form,
        contactEditorState,
        setContactEditorState
      },
      -1
    ),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      ContactEditorDialog,
      {
        target: void 0,
        form,
        contactEditorState,
        setContactEditorState
      },
      void 0
    ),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-row-reverse", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
      Button,
      {
        className: "w-full",
        variant: "outline",
        type: "button",
        onClick: () => setContactEditorState({
          open: true,
          initialData: void 0,
          target: void 0
        }),
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_lucide_react6.Plus, { height: 14, width: 14 }),
          " Add Secondary Contact"
        ]
      }
    ) })
  ] });
};
var ProfileAddressForm = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "font-medium text-md", children: "Headquarters Address" }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "p-2 border border-gray-200 rounded", children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        FormField,
        {
          name: "CompanyInformation.HeadQuartersAddress.Line1",
          render: ({ field }) => {
            const form = (0, import_react_hook_form5.useFormContext)();
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: "Street Address" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "mt-2", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                FixedAddressAutofill,
                {
                  accessToken: process.env.REACT_APP_MAPBOX_KEY,
                  onRetrieve: (e) => {
                    const feature = e.features[0];
                    if (feature) {
                      var state = feature.properties.address_level1;
                      var realState = stateOptions.find(
                        (x) => x.value == state
                      );
                      if (realState) {
                        form.setValue(
                          "CompanyInformation.HeadQuartersAddress.State",
                          realState.value
                        );
                      }
                    }
                  },
                  children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                    Input,
                    {
                      autoComplete: "address-line1",
                      className: isFieldRequiredAndMissing(
                        field.name,
                        field.value
                      ) ? " border-[1px] border-orange-500" : "",
                      type: "text",
                      ...field,
                      value: field.value || ""
                    }
                  )
                }
              ) }) }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
            ] });
          }
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        FormField,
        {
          name: "CompanyInformation.HeadQuartersAddress.Line2",
          render: ({ field }) => {
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: "Street Address Line 2" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                Input,
                {
                  autoComplete: "address-line2",
                  className: isFieldRequiredAndMissing(
                    field.name,
                    field.value
                  ) ? " border-[1px] border-orange-500" : "",
                  ...field,
                  value: field.value || ""
                }
              ) }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
            ] });
          }
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "grid grid-cols-2 w-full space-x-2", children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          FormField,
          {
            name: "CompanyInformation.HeadQuartersAddress.City",
            render: ({ field }) => {
              return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: "City" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  Input,
                  {
                    autoComplete: "address-level2",
                    className: isFieldRequiredAndMissing(
                      field.name,
                      field.value
                    ) ? " border-[1px] border-orange-500" : "",
                    ...field,
                    value: field.value || ""
                  }
                ) }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
              ] });
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          FormField,
          {
            name: "CompanyInformation.HeadQuartersAddress.State",
            render: ({ field }) => {
              return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: "State" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  Select,
                  {
                    className: isFieldRequiredAndMissing(
                      field.name,
                      field.value
                    ) ? " border-[1px] border-orange-500 rounded-md" : "",
                    options: stateOptions,
                    ...field,
                    isDisabled: field.disabled,
                    value: (0, import_lodash2.isObject)(field.value) ? field.value : stateOptions.find((opt) => opt.value == field.value),
                    onChange: (v) => field.onChange(v.value)
                  }
                ) }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
              ] });
            }
          }
        )
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        FormField,
        {
          name: "CompanyInformation.HeadQuartersAddress.ZipCode",
          render: ({ field }) => {
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: "Zip Code" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                Input,
                {
                  autoComplete: "postal-code",
                  className: isFieldRequiredAndMissing(
                    field.name,
                    field.value
                  ) ? " border-[1px] border-orange-500" : "",
                  ...field,
                  value: field.value || ""
                }
              ) }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
            ] });
          }
        }
      )
    ] })
  ] });
};
var FileView = ({ onDelete, borrowerId, setFileId, fileId }) => {
  const confirmDialog = useConfirmDialogContext();
  const [file, setFile] = (0, import_react10.useState)();
  const fileQuery = (0, import_lib4.useApiQuery)(
    `file-${fileId}`,
    () => {
      if (file == void 0 && fileId != null && fileId != void 0) {
        try {
          return (0, import_lib4.getBorrowerFile)({
            borrowerId,
            fileId
          })();
        } catch (e) {
          setFile(void 0);
          setFileId(void 0);
        }
      }
    },
    {
      options: {},
      success: (data) => {
        setFile(data);
      },
      error: async () => {
        if (onDelete) {
          try {
            await onDelete();
          } catch (e) {
          }
        }
        setFile(void 0);
        setFileId(void 0);
      },
      enabled: file == void 0 && fileId != null && fileId != void 0
    }
  );
  if (fileQuery.isLoading) {
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Loading..." });
  }
  if (file) {
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "border items-center rounded border-gray-300 p-2 flex flex-row text-sm", children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h2", { className: "flex flex-grow font-medium overflow-ellipsis min-w-0 ml-2", children: file.Name }),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "flex flex-row ml-auto space-x-1", children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          "a",
          {
            className: cn(
              "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
              "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
              "py-2 px-1 h-[20px]"
            ),
            target: "_blank",
            href: `${process.env.REACT_APP_BASE_API_URL}/api/borrower/DownloadBorrowerProfileFile?borrowerId=${borrowerId}&fileId=${file.Id}`,
            children: "Download"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          Button,
          {
            type: "button",
            className: "px-1 h-[20px]",
            variant: "destructive",
            onClick: async () => {
              if (onDelete) {
                var result = await confirmDialog.openDialogAsync({
                  title: "Delete File",
                  message: `Are you sure you want to delete ${file.Name}?`
                });
                if (result) {
                  await onDelete();
                  setFile(void 0);
                }
              }
            },
            children: "Delete"
          }
        )
      ] })
    ] });
  }
};
var ProfileFileMultiUploadField = ({ label, name, borrowerId, buttonText }) => {
  const [progress, setProgress] = (0, import_react10.useState)(0);
  const uploadMutation = (0, import_lib4.useApiMutation)(import_lib4.uploadBorrowerFile, [], {});
  const deleteMutation = (0, import_lib4.useApiMutation)(import_lib4.deleteBorrowerFile, [], {});
  const uploadInputRef = (0, import_react10.useRef)(null);
  const formValue = (0, import_react_hook_form5.useFormContext)();
  const value = formValue.watch(name);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const getFiles = () => {
          if (value == void 0 || value == null) {
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", {});
          }
          return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-col space-y-2", children: value.map((x) => /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            FileView,
            {
              borrowerId,
              fileId: x,
              setFileId: (id) => {
                if (!id) {
                  field.onChange(value.filter((y) => y != x));
                }
              },
              onDelete: async () => {
                setProgress(0);
                await deleteMutation.mutateAsync({
                  fileId: x,
                  borrowerId
                });
                field.onChange(value.filter((y) => y != x));
              }
            },
            x
          )) });
        };
        const getControl = () => {
          if (uploadMutation.isLoading && progress > 0) {
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
              "div",
              {
                className: "bg-primary rounded text-center text-primary-foreground transition-all transition-500",
                style: { width: progress + "%" },
                children: [
                  progress,
                  "%"
                ]
              }
            ) });
          }
          return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
              Button,
              {
                className: "px-3 mt-2 " + (isFieldRequiredAndMissing(field.name, value) ? " border-[1px] border-orange-500" : ""),
                variant: "outline",
                onClick: () => uploadInputRef.current?.click(),
                children: [
                  /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_lucide_react6.Upload, { className: "mr-2", width: 14, height: 14 }),
                  buttonText
                ]
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Input,
              {
                multiple: true,
                className: "hidden",
                type: "file",
                ...field,
                ref: uploadInputRef,
                value: "",
                onChange: async (e) => {
                  if (e.target.files?.length == 0 || !e.target.files)
                    return;
                  var splitName = name.split(".");
                  var lastFileName = splitName[splitName.length - 1];
                  const formData = new FormData();
                  for (var i = 0; i < e.target.files.length; i++) {
                    formData.append("file", e.target.files.item(i));
                  }
                  const result = await uploadMutation.mutateAsync({
                    formData,
                    fileName: lastFileName,
                    borrowerId,
                    onUploadProgress: (progressEvent) => {
                      const progress2 = progressEvent.loaded / (progressEvent.total || 100) * 50;
                      setProgress(progress2);
                    },
                    onDownloadProgress: (progressEvent) => {
                      const progress2 = 50 + progressEvent.loaded / (progressEvent.total || 100) * 50;
                      setProgress(progress2);
                    }
                  });
                  field.onChange(
                    value ? [
                      ...value,
                      ...result.map((x) => x.Id)
                    ] : [
                      ...result.map((x) => x.Id)
                    ]
                  );
                }
              }
            )
          ] });
        };
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { children: [
            getFiles(),
            getControl()
          ] }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfileFileUploadField = ({ label, name, borrowerId, buttonText, subtitle }) => {
  const [progress, setProgress] = (0, import_react10.useState)(0);
  const uploadMutation = (0, import_lib4.useApiMutation)(import_lib4.uploadBorrowerFile, [], {});
  const deleteMutation = (0, import_lib4.useApiMutation)(import_lib4.deleteBorrowerFile, [], {});
  const uploadInputRef = (0, import_react10.useRef)(null);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const getFile = () => {
          if (field.value == void 0 || field.value == null) {
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", {});
          }
          return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            FileView,
            {
              borrowerId,
              fileId: field.value,
              setFileId: () => {
              },
              onDelete: async () => {
                setProgress(0);
                try {
                  await deleteMutation.mutateAsync({
                    fileId: field.value,
                    borrowerId
                  });
                  field.onChange(null);
                } catch (e) {
                  field.onChange(null);
                }
              }
            }
          );
        };
        const getControl = () => {
          if (progress > 0) {
            if (!uploadMutation.isLoading) {
              return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", {});
            }
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
              "div",
              {
                className: "bg-primary rounded text-center text-primary-foreground transition-all transition-500",
                style: { width: progress + "%" },
                children: [
                  progress,
                  "%"
                ]
              }
            ) });
          }
          if (field.value != void 0 && field.value != null) {
            return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, {});
          }
          return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
              Button,
              {
                type: "button",
                className: "px-3 mt-2 " + (isFieldRequiredAndMissing(field.name, field.value) ? " border-[1px] border-orange-500" : ""),
                variant: "outline",
                onClick: () => uploadInputRef.current?.click(),
                children: [
                  /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_lucide_react6.Upload, { className: "mr-2", width: 14, height: 14 }),
                  buttonText
                ]
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Input,
              {
                className: "hidden",
                type: "file",
                ...field,
                ref: uploadInputRef,
                value: "",
                onChange: async (e) => {
                  if (e.target.files?.length == 0 || !e.target.files)
                    return;
                  var splitName = name.split(".");
                  var lastFileName = splitName[splitName.length - 1];
                  const formData = new FormData();
                  for (var i = 0; i < e.target.files.length; i++) {
                    formData.append("file", e.target.files.item(i));
                  }
                  const result = await uploadMutation.mutateAsync({
                    formData,
                    fileName: lastFileName,
                    borrowerId,
                    onUploadProgress: (progressEvent) => {
                      const progress2 = (0, import_lib4.roundToPlaces)(
                        progressEvent.progress * 100,
                        0
                      );
                      setProgress(progress2);
                    }
                  });
                  field.onChange(result[0].Id);
                }
              }
            )
          ] });
        };
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: label }),
          subtitle && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "text-muted-foreground text-xs", children: subtitle }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { children: [
            getFile(),
            getControl()
          ] }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfileDatePicker = ({ label, name }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "grid grid-cols-2 space-y-0", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center", children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            DatePicker,
            {
              className: isFieldRequiredAndMissing(field.name, field.value) ? " border-[1px] border-orange-500" : "",
              onChange: (d) => field.onChange(d?.getTime() ?? (/* @__PURE__ */ new Date()).getTime()),
              value: typeof field.value == "number" ? new Date(field.value) : field.value
            }
          ) }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfileTextareaField = ({ label, name }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            Textarea,
            {
              rows: 5,
              className: isFieldRequiredAndMissing(field.name, field.value) ? " border-[1px] border-orange-500" : "",
              ...field,
              value: field.value || ""
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfileSmallTextField = ({ label, name, format: format2, alwaysFormat }) => {
  const [focused, setFocused] = (0, import_react10.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "grid grid-cols-2 space-y-0", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center", children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            Input,
            {
              className: isFieldRequiredAndMissing(field.name, field.value) ? "border-[1px] border-orange-500" : "",
              ...field,
              onFocus: () => setFocused(true),
              onBlur: () => {
                field.onBlur();
                setFocused(false);
              },
              type: "text",
              value: (!focused || alwaysFormat) && format2 ? format2(field.value) : field.value || ""
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfileSelectField = ({ label, name, options }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "grid grid-cols-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center", children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: field.disabled ? /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "text-heavy", children: options.find((opt) => opt.value == field.value)?.label }) : /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            Select,
            {
              className: "text-black " + (isFieldRequiredAndMissing(field.name, field.value) ? " rounded-md border-[1px] border-orange-500" : " border-none"),
              options,
              ...field,
              value: (0, import_lodash2.isObject)(field.value) ? field.value : options.find((opt) => opt.value == field.value),
              onChange: (v) => field.onChange(v.value)
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfilePercentField = ({ label, name }) => {
  const [focused, setFocused] = (0, import_react10.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [stringValue, setStringValue] = (0, import_react10.useState)(
          field.value ? field.value : ""
        );
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "max-w-[300px]", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            Input,
            {
              className: "text-black" + (isFieldRequiredAndMissing(field.name, field.value) ? " border-[1px] border-orange-500" : ""),
              type: "text",
              ...field,
              onFocus: () => setFocused(true),
              onBlur: () => {
                field.onBlur();
                setFocused(false);
              },
              value: focused ? stringValue : stringValue == "" ? "0%" : stringValue + "%",
              onChange: (e) => {
                var stringVal = e.target.value;
                setStringValue(
                  stringVal
                );
                if (stringVal == "-" || stringVal == "") {
                  field.onChange(0);
                } else {
                  field.onChange(parseInt(stringVal));
                }
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfileSwitchField = ({ label, name }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
          FormItem,
          {
            className: "flex flex-row",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center mr-4", children: label }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "ml-auto flex flex-row justify-end space-x-2 items-center", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: field.value == true ? "Yes" : "No" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                  Switch,
                  {
                    ...field,
                    checked: field.value,
                    value: field.value || false,
                    onCheckedChange: (v) => {
                      field.onChange(v);
                    }
                  }
                ) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
            ]
          }
        );
      }
    }
  );
};
var isRequiredValueInvalid = (value) => {
  if (value === void 0 || value === null) {
    return true;
  } else if (Array.isArray(value)) {
    return value.length == 0;
  } else if (typeof value == "string") {
    return value.trim().length < 1;
  }
  return false;
};
var isFieldRequiredAndMissing = (fieldName, value) => {
  if (fieldName.includes("PreviousYearsMetrics")) {
    return isRequiredValueInvalid(value);
  }
  if (!allRequiredFields.includes(fieldName)) {
    return false;
  }
  return isRequiredValueInvalid(value);
};
var ProfileMoneyField = ({ label, name }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [stringValue, setStringValue] = (0, import_react10.useState)(
          field.value ? field.value : ""
        );
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "grid grid-cols-2 space-y-0", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center", children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            Input,
            {
              className: "text-black" + (isFieldRequiredAndMissing(field.name, field.value) ? " border-orange-500 border-[1px]" : ""),
              type: "text",
              ...field,
              value: displayMoney(stringValue),
              onChange: (e) => {
                var stringVal = formatMoney(
                  e.target.value
                );
                setStringValue(
                  stringVal
                );
                if (stringVal == "-" || stringVal == "") {
                  field.onChange(0);
                } else {
                  field.onChange(parseInt(stringVal));
                }
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var ProfileNumberField = ({ label, name, format: format2 }) => {
  const [focused, setFocused] = (0, import_react10.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "grid grid-cols-2 space-y-0", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center", children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            Input,
            {
              className: isFieldRequiredAndMissing(field.name, field.value) ? "border-[1px] border-orange-500" : "",
              type: "number",
              ...field,
              onFocus: () => setFocused(true),
              onBlur: () => {
                field.onBlur();
                setFocused(false);
              },
              value: !focused && format2 ? format2(field.value) : field.value || "",
              onChange: (e) => {
                const value = e.target.value;
                if (value === "") {
                  field.onChange(0);
                } else {
                  const num = parseInt(value);
                  field.onChange(isNaN(num) ? 0 : num);
                }
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var inputClass = "rounded-none text-right h-full px-1";
var MetricsTableMoneyField = ({ name }) => {
  const [focused, setFocused] = (0, import_react10.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [stringValue, setStringValue] = (0, import_react10.useState)(
          field.value ? field.value : ""
        );
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormItem, { className: "", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          Input,
          {
            className: inputClass + (isFieldRequiredAndMissing(field.name, field.value) ? " border-[1px] border-orange-500" : " border-none"),
            type: "text",
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                const form = e.target.form;
                const index = Array.prototype.indexOf.call(
                  form,
                  e.target
                );
                for (let i = index + 1; i < form.elements.length; i++) {
                  if (form.elements[i].disabled) {
                    continue;
                  }
                  form.elements[i].focus();
                  break;
                }
                e.preventDefault();
              }
            },
            ...field,
            onFocus: () => setFocused(true),
            onBlur: () => {
              field.onBlur();
              setFocused(false);
            },
            value: focused && (stringValue == "0" || stringValue == "") ? "" : displayMoney(stringValue),
            onChange: (e) => {
              var stringVal = formatMoney(
                e.target.value
              );
              setStringValue(
                stringVal
              );
              if (stringVal == "-" || stringVal == "") {
                field.onChange(0);
              } else {
                field.onChange(parseInt(stringVal));
              }
            }
          }
        ) }) }) });
      }
    }
  );
};
var gridPrimaryClass = "font-bold bg-primary text-sm text-white h-[40px] justify-center text-center flex flex-row items-center border border-gray-200 leading-4";
var YearProfitLossMetrics = ({ title, namePrefix }) => {
  const inputParentClass = "h-[40px] border border-gray-200 rounded-none flex flex-row justify-center items-center";
  const form = (0, import_react_hook_form5.useFormContext)();
  const metricEntry = form.watch(namePrefix);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "", children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: title }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(MetricsTableMoneyField, { name: namePrefix + ".Revenues" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(MetricsTableMoneyField, { name: namePrefix + ".CostOfSales" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      FormField,
      {
        name: namePrefix + ".GrossProfit",
        render: ({ field }) => {
          (0, import_react10.useEffect)(() => {
            field.onChange(
              (metricEntry?.Revenues ?? 0) - (metricEntry?.CostOfSales ?? 0)
            );
          }, [
            metricEntry?.Revenues,
            metricEntry?.CostOfSales
          ]);
          return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Input,
              {
                className: inputParentClass + " px-1 text-right font-medium text-black disabled:opacity-100 disabled:bg-gray-100",
                disabled: true,
                value: currency(field.value)
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
          ] });
        }
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(MetricsTableMoneyField, { name: namePrefix + ".Sga" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      FormField,
      {
        name: namePrefix + ".Ebitda",
        render: ({ field }) => {
          (0, import_react10.useEffect)(() => {
            field.onChange(
              (metricEntry?.GrossProfit ?? 0) - (metricEntry?.Sga ?? 0)
            );
          }, [
            metricEntry?.GrossProfit,
            metricEntry?.Sga
          ]);
          return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Input,
              {
                className: inputParentClass + " px-1 text-right font-medium text-black disabled:opacity-100 disabled:bg-gray-100",
                disabled: true,
                value: currency(field.value)
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
          ] });
        }
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      MetricsTableMoneyField,
      {
        name: namePrefix + ".InterestExpense"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      MetricsTableMoneyField,
      {
        name: namePrefix + ".TotalOtherIncome"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      MetricsTableMoneyField,
      {
        name: namePrefix + ".TotalOtherExpenses"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: inputParentClass, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      FormField,
      {
        name: namePrefix + ".NetIncome",
        render: ({ field }) => {
          (0, import_react10.useEffect)(() => {
            field.onChange(
              (metricEntry?.Ebitda ?? 0) - (metricEntry?.InterestExpense ?? 0) + (metricEntry?.TotalOtherIncome ?? 0) - (metricEntry?.TotalOtherExpenses ?? 0)
            );
          }, [
            metricEntry?.Ebitda,
            metricEntry?.InterestExpense,
            metricEntry?.TotalOtherIncome,
            metricEntry?.TotalOtherExpenses
          ]);
          return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Input,
              {
                className: inputParentClass + " px-1 text-right font-medium text-black disabled:opacity-100 disabled:bg-gray-100",
                disabled: true,
                value: currency(field.value)
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
          ] });
        }
      }
    ) })
  ] });
};
var LoanAppForm = ({ initialLoanApp, onSave, visible }) => {
  const isPreSubmit3 = [
    import_lib4.LoanApplicationStatus.Screen1,
    import_lib4.LoanApplicationStatus.Screen2,
    import_lib4.LoanApplicationStatus.Screen3,
    import_lib4.LoanApplicationStatus.InProgress,
    import_lib4.LoanApplicationStatus.SpecialistReview
  ].includes(initialLoanApp.Status ?? import_lib4.LoanApplicationStatus.InProgress);
  const loanAppForm = (0, import_react_hook_form5.useForm)({
    resolver: (0, import_zod4.zodResolver)(import_lib4.loanApplicationModel),
    mode: "onBlur",
    defaultValues: (0, import_react10.useMemo)(() => {
      if (initialLoanApp.LoanUse == null || initialLoanApp.LoanUse == void 0 || initialLoanApp.LoanUse == 0) {
        initialLoanApp.LoanUse = 2;
      }
      return initialLoanApp;
    }, [initialLoanApp]),
    disabled: !isPreSubmit3
  });
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Form, { ...loanAppForm, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
    "form",
    {
      className: "mx-auto h-full flex flex-col space-y-2 " + (visible ? "" : " hidden"),
      onSubmit: loanAppForm.handleSubmit(async () => {
      }),
      onError: (e) => {
        console.log(e);
      },
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          ProfileSelectField,
          {
            name: "LoanType",
            options: import_lib4.loanTypeOptions,
            label: "Loan Type"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          ProfileMoneyField,
          {
            name: "LoanAmount",
            label: "Loan Amount"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          ProfileSwitchField,
          {
            name: "PersonalGuarantee",
            label: "Personal Guarantee"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          ProfileSelectField,
          {
            options: import_lib4.loanUseOptions,
            name: "LoanUse",
            label: "Loan Use"
          }
        ),
        visible && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          auto_save_default,
          {
            onSubmit: (v) => {
              onSave(v);
            }
          }
        )
      ]
    }
  ) });
};
var BorrowerProfileEditorInner = ({
  initialProfile,
  borrowerAccess,
  borrowerId,
  glossary,
  loanApps,
  initialSelectedAppId
}) => {
  const currentMonth = (/* @__PURE__ */ new Date()).getMonth();
  const currentMonthName = monthOptions.find((x) => x.value == currentMonth + 1)?.label;
  const lastMonthName = monthOptions.find(
    (x) => x.value == (currentMonth + 11) % 12 + 1
  )?.label;
  const currentDate = /* @__PURE__ */ new Date();
  const currentYear2 = currentDate.getFullYear();
  const lastMonth = /* @__PURE__ */ new Date();
  lastMonth.setMonth(currentDate.getMonth() - 1);
  const lastShortMonthString = lastMonth.toLocaleString("en", {
    month: "short"
  });
  const [selectedLoanAppId, setSelectedLoanAppId] = (0, import_react10.useState)(
    initialSelectedAppId
  );
  const [selectedLoanApp, setSelectedLoanApp] = (0, import_react10.useState)(
    loanApps.find((x) => initialSelectedAppId == x.Id)
  );
  const form = (0, import_react_hook_form5.useForm)({
    resolver: (0, import_zod4.zodResolver)(import_lib4.fullBorrowerProfileModel),
    mode: "onBlur",
    disabled: borrowerAccess == import_lib4.BorrowerAccess.ReadOnly,
    defaultValues: (0, import_react10.useMemo)(() => {
      const finalInitialProfile = initialProfile || { ProfitLossMetrics: { PreviousYearsMetrics: [] } };
      if (!finalInitialProfile.ProfitLossMetrics) {
        finalInitialProfile.ProfitLossMetrics = {
          PreviousYearsMetrics: []
        };
      }
      if (!finalInitialProfile.ProfitLossMetrics.FiscalYearEnd) {
        finalInitialProfile.ProfitLossMetrics.FiscalYearEnd = 12;
      }
      if (!finalInitialProfile.ProfitLossMetrics.PreviousYearsMetrics) {
        finalInitialProfile.ProfitLossMetrics.PreviousYearsMetrics = [];
      }
      const annualMetrics = finalInitialProfile.ProfitLossMetrics.PreviousYearsMetrics;
      if (!annualMetrics.find(
        (x) => x.Year == currentYear2 - 1
      )) {
        annualMetrics.push({
          Year: currentYear2 - 1
        });
      }
      if (!annualMetrics.find(
        (x) => x.Year == currentYear2 - 2
      )) {
        annualMetrics.push({
          Year: currentYear2 - 2
        });
      }
      if (!annualMetrics.find(
        (x) => x.Year == currentYear2 - 3
      )) {
        annualMetrics.push({
          Year: currentYear2 - 3
        });
      }
      if (!annualMetrics.find(
        (x) => x.Year == currentYear2
      )) {
        annualMetrics.push({
          Year: currentYear2
        });
      }
      if (!annualMetrics.find(
        (x) => x.Year == currentYear2 + 1
      )) {
        annualMetrics.push({
          Year: currentYear2 + 1
        });
      }
      return finalInitialProfile;
    }, [initialProfile])
  });
  const saveProfile = async (v) => {
    if (v.ProfitLossMetrics?.PreviousYearsMetrics) {
      v.ProfitLossMetrics.PreviousYearsMetrics = v.ProfitLossMetrics?.PreviousYearsMetrics.filter((x) => x.Year != null);
    }
    return await borrowerProfileMutation.mutateAsync({
      borrowerId,
      profile: v
    });
  };
  const [currentStepIdx, setCurrentStepIdx] = (0, import_react10.useState)(() => {
    try {
      const value = parseInt(
        localStorage.getItem("borrower-profile-step") ?? "0",
        10
      );
      if (isNaN(value) || value < 0 || value >= steps.length) {
        return 0;
      }
      return value;
    } catch (e) {
    }
    return 0;
  });
  (0, import_react10.useEffect)(() => {
    localStorage.setItem("borrower-profile-step", currentStepIdx.toString());
  }, [currentStepIdx]);
  const pageCount = steps.length;
  const borrowerProfileMutation = (0, import_lib4.useApiMutation)(
    import_lib4.updateBorrowerProfile,
    ["borrowerProfile"]
  );
  const loanAppMutation = (0, import_lib4.useApiMutation)(
    import_lib4.updateLoanApplication,
    ["loanApp"]
  );
  const loanEvaluation = (0, import_lib4.useApiMutation)(
    import_lib4.submitApiEvaluation,
    ["evaluation"],
    {
      success: (v) => {
        setLastLoanEvaluation(v);
      }
    }
  );
  const [lastLoanEvaluation, setLastLoanEvaluation] = (0, import_react10.useState)();
  (0, import_react10.useEffect)(() => {
    if (selectedLoanAppId) {
      loanEvaluation.mutate({
        loanAppId: selectedLoanAppId,
        portalType: import_lib4.PortalType.Borrower
      });
    }
  }, [selectedLoanAppId]);
  const [debtEditorState, setDebtEditorState] = (0, import_react10.useState)({
    open: false,
    initialData: void 0,
    target: -1
  });
  const [contactEditorState, setContactEditorState] = (0, import_react10.useState)({
    open: false,
    initialData: void 0,
    target: -1
  });
  const [officerEditorState, setOfficerEditorState] = (0, import_react10.useState)({
    open: false,
    initialData: void 0,
    target: 0
  });
  const deleteDebt = (idx) => {
    const debt = form.getValues()?.BalanceSheetMetrics?.AdditionalDebt;
    if (!debt) {
      return;
    }
    form.setValue("BalanceSheetMetrics.AdditionalDebt", [
      ...debt.filter((_, i) => i !== idx)
    ], { shouldDirty: true });
  };
  const deleteContact = (idx) => {
    const sContacts = form.getValues()?.CompanyInformation?.SecondaryContacts;
    if (!sContacts) {
      return;
    }
    form.setValue("CompanyInformation.SecondaryContacts", [
      ...sContacts.filter((_, i) => i !== idx)
    ], { shouldDirty: true });
  };
  const deleteOfficer = (idx) => {
    const officers = form.getValues()?.CompanyInformation?.Officers;
    if (!officers) {
      return;
    }
    form.setValue("CompanyInformation.Officers", [
      ...officers.filter((_, i) => i !== idx)
    ], { shouldDirty: true });
  };
  const watchedForm = form.watch();
  const bsState = watchedForm.BalanceSheetMetrics;
  const industryState = watchedForm.CompanyInformation?.Industry;
  const getPageContents = () => {
    switch (currentStepIdx) {
      case 0: {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Company Information" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSmallTextField,
              {
                label: "Company Name",
                name: "CompanyInformation.CompanyName"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSmallTextField,
              {
                label: "Company Website",
                name: "CompanyInformation.CompanyWebsite"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              FormField,
              {
                name: "CompanyInformation.CompanyPhone",
                render: ({ field }) => {
                  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "grid grid-cols-2 space-y-0", children: [
                      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center", children: "Company Phone" }),
                      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { className: "", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                        import_react_phone_input_2.default,
                        {
                          specialLabel: "",
                          country: "us",
                          inputProps: {
                            className: "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 "
                          },
                          value: field.value,
                          onChange: (e) => {
                            field.onChange(e);
                          }
                        }
                      ) })
                    ] }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                  ] });
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileNumberField,
              {
                label: "Year Formed",
                name: "CompanyInformation.YearFormed"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(ProfileAddressForm, {}),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSelectField,
              {
                label: "Number Of Employees",
                name: "CompanyInformation.NumberOfEmployees",
                options: [
                  { label: "1-10", value: "1-10" },
                  { label: "11-50", value: "11-50" },
                  { label: "51-200", value: "51-200" },
                  { label: "200+", value: "200+" }
                ]
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSelectField,
              {
                label: "Company Type",
                name: "CompanyInformation.CompanyType",
                options: import_lib4.companyTypeOptions
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSelectField,
              {
                label: "Industry",
                name: "CompanyInformation.Industry",
                options: import_lib4.industryOptions
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              FormField,
              {
                name: "CompanyInformation.SubIndustry",
                render: ({ field }) => {
                  (0, import_react10.useEffect)(() => {
                    if (!industryState || !field.value) {
                      return;
                    }
                    if (!industryState && field.value) {
                      field.onChange(null);
                    }
                    var subIndustryEntry = import_lib4.subIndustryOptions.find(
                      (value2) => {
                        if (value2.value == field.value) {
                          return true;
                        }
                        return false;
                      }
                    );
                    if (subIndustryEntry) {
                      if (industryState != subIndustryEntry.parent) {
                        field.onChange(null);
                      }
                    }
                  }, [industryState]);
                  const value = import_lib4.subIndustryOptions.find(
                    (opt) => opt.value == field.value
                  ) ?? "";
                  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { className: "grid grid-cols-2", children: [
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { className: "flex items-center", children: "Sub Industry" }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: field.disabled ? /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "text-heavy", children: import_lib4.subIndustryOptions.find(
                      (opt) => opt.value == field.value
                    )?.label }) : /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                      Select,
                      {
                        className: "text-black " + (isFieldRequiredAndMissing(
                          field.name,
                          field.value
                        ) ? " rounded-md border-[1px] border-orange-500" : " border-none"),
                        options: import_lib4.subIndustryOptions.filter(
                          (x) => x.parent == industryState
                        ),
                        ...field,
                        value,
                        onChange: (v) => field.onChange(v.value)
                      }
                    ) }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                  ] });
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSmallTextField,
              {
                label: "Employer Identification Number",
                name: "CompanyInformation.EmployerIdentificationNumber",
                alwaysFormat: true,
                format: (v) => {
                  if (!v)
                    return "";
                  var str = v.toString();
                  str = str.replace(/[^\d]/g, "");
                  if (str.length > 9) {
                    str = str.slice(0, 9);
                  }
                  if (str.length > 2 && !str.includes("-")) {
                    return str.slice(0, 2) + "-" + str.slice(2);
                  }
                  return str;
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSwitchField,
              {
                label: "Does the company have a full-time CFO?",
                name: "TaxInformation.FullTimeCFO"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSwitchField,
              {
                label: "Are the financials reviewed by an outside accountant?",
                name: "TaxInformation.OutsideAccountant"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSwitchField,
              {
                label: "Is this a Veteran-Owned Business?",
                name: "CompanyInformation.VeteranOwned"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSwitchField,
              {
                label: "Is this a Female-Owned Business?",
                name: "CompanyInformation.FemaleOwned"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSwitchField,
              {
                label: "Is this a Disability-Owned Business?",
                name: "CompanyInformation.DisabilityOwned"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSwitchField,
              {
                label: "Is this a Minority-Owned Business?",
                name: "CompanyInformation.MinorityOwned"
              }
            )
          ] })
        ] });
      }
      case 1: {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Business Overview" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileTextareaField,
              {
                label: "Business Description",
                name: "CompanyInformation.BusinessDescription"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileUploadField,
              {
                label: "Business Plan and/or Executive Summary",
                name: "CompanyInformation.BusinessPlanFile",
                borrowerId,
                buttonText: "Upload Business Plan"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileTextareaField,
              {
                label: "Description of Growth Plans",
                name: "CompanyInformation.GrowthPlansDescription"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileUploadField,
              {
                label: "Organizational Chart",
                buttonText: "Upload Organizational Chart",
                name: "CompanyInformation.OrgChartFile",
                borrowerId
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              FormField,
              {
                name: "CompanyInformation.RevenueMix",
                render: ({ field }) => {
                  const [multiSelectValue, setMultiSelectValue] = (0, import_react10.useState)(
                    (field.value || []).map(
                      (x) => revenueMixOptions.find((y) => y.value == x)
                    )
                  );
                  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormItem, { children: [
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormLabel, { children: "Revenue Mix" }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                      Select,
                      {
                        className: isFieldRequiredAndMissing(
                          field.name,
                          field.value
                        ) ? " border-orange-500 border-[1px] rounded-md" : "",
                        isMulti: true,
                        options: revenueMixOptions,
                        ...field,
                        value: multiSelectValue,
                        onChange: (v) => {
                          setMultiSelectValue(v);
                          field.onChange(
                            v.map((x) => x.value)
                          );
                        }
                      }
                    ) }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                  ] });
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfilePercentField,
              {
                label: "Total Revenue % of Top 5 Customers",
                name: "CompanyInformation.TotalRevenuePercentOfTopFiveCustomers"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: "Top Customer Contracts",
                buttonText: "Upload Customer Contract Files",
                name: "CompanyInformation.CustomerContractFiles"
              }
            )
          ] })
        ] });
      }
      case 2:
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Contacts & Officers" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ContactProfileEditor,
              {
                deleteContact,
                form,
                contactEditorState,
                setContactEditorState
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              OfficerProfileEditor,
              {
                borrowerId,
                deleteOfficer,
                form,
                officerEditorState,
                setOfficerEditorState
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSwitchField,
              {
                label: "Do you have non-management shareholders with >10% ownership?",
                name: "CompanyInformation.NonManagementShareholdersOverTenPercent"
              }
            )
          ] })
        ] });
      case 3:
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Tax & Audit Information" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: `Tax Returns (Since ${currentYear2 - 3})`,
                buttonText: "Upload Tax Returns",
                name: "TaxInformation.TaxReturnFiles"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: `Company Bank Statements (Since ${currentMonthName} ${currentYear2 - 1})`,
                buttonText: "Upload Bank Statements",
                name: "TaxInformation.LastYearBankStatementFiles"
              }
            )
          ] })
        ] });
      case 4:
        const yearMetrics = form.getValues(
          "ProfitLossMetrics.PreviousYearsMetrics"
        );
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Profit & Loss Metrics" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileSelectField,
              {
                label: "Fiscal Year End",
                name: "ProfitLossMetrics.FiscalYearEnd",
                options: monthOptions
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: `Monthly P&L for 2021 through ${lastMonthName} ${currentYear2}`,
                buttonText: "Upload Monthly P&Ls",
                name: "ProfitLossMetrics.PreviousYearsFiles"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h2", { className: "font-medium", children: "Historical P&L Data" }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "text-muted-foreground text-sm", children: "Years refer to the calendar year in which the fiscal year ended." }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "text-muted-foreground text-sm", children: "Enter $0 for non-applicable entries." })
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full overflow-auto", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "grid grid-cols-6 w-[600px]", children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Revenues" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "COGS" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Gross Profit" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "SG&A" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "EBITDA" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Interest Expense" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Total Other Income" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Total Other Expenses" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Net Income" })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                YearProfitLossMetrics,
                {
                  namePrefix: `ProfitLossMetrics.PreviousYearsMetrics.${yearMetrics.findIndex((v) => v.Year == currentYear2 - 3)}`,
                  title: (currentYear2 - 3).toString()
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                YearProfitLossMetrics,
                {
                  namePrefix: `ProfitLossMetrics.PreviousYearsMetrics.${yearMetrics.findIndex((v) => v.Year == currentYear2 - 2)}`,
                  title: (currentYear2 - 2).toString()
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                YearProfitLossMetrics,
                {
                  namePrefix: `ProfitLossMetrics.PreviousYearsMetrics.${yearMetrics.findIndex((v) => v.Year == currentYear2 - 1)}`,
                  title: (currentYear2 - 1).toString()
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                YearProfitLossMetrics,
                {
                  namePrefix: `ProfitLossMetrics.TtmMetrics`,
                  title: `Since ${lastShortMonthString} '${(currentYear2 - 1) % 100}`
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                YearProfitLossMetrics,
                {
                  namePrefix: `ProfitLossMetrics.YtdMetrics`,
                  title: "Since Jan '" + currentYear2 % 100
                }
              )
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h2", { className: "font-medium", children: "Forecasted P&L Data" }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full overflow-auto", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "grid grid-cols-3 w-[300px]", children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "", children: [
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Revenues" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "COGS" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Gross Profit" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "SG&A" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "EBITDA" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Interest Expense" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Total Other Income" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Total Other Expenses" }),
                /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: gridPrimaryClass, children: "Net Income" })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                YearProfitLossMetrics,
                {
                  namePrefix: `ProfitLossMetrics.PreviousYearsMetrics.${yearMetrics.findIndex((v) => v.Year == currentYear2)}`,
                  title: currentYear2.toString()
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                YearProfitLossMetrics,
                {
                  namePrefix: `ProfitLossMetrics.PreviousYearsMetrics.${yearMetrics.findIndex((v) => v.Year == currentYear2 + 1)}`,
                  title: (currentYear2 + 1).toString()
                }
              )
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: `P&L Projections (through ${currentYear2 + 1})`,
                buttonText: "Upload Projections",
                name: "ProfitLossMetrics.CurrentAndNextYearProjectionFiles"
              }
            )
          ] })
        ] });
      case 5:
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Balance Sheet" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileUploadField,
              {
                borrowerId,
                label: "Most Recent Balance Sheet",
                buttonText: "Upload Balance Sheet",
                name: "BalanceSheetMetrics.MostRecentBalanceSheetFile"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileDatePicker,
              {
                label: "Balance Sheet As Of Date",
                name: "BalanceSheetMetrics.BalanceSheetAsOfDate"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: `Annual Balance Sheets (Since ${currentYear2 - 3})`,
                buttonText: "Upload Balance Sheets",
                name: "BalanceSheetMetrics.PreviousYearsBalanceSheetFiles"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Current Cash & Money-Market Balance",
                name: "BalanceSheetMetrics.CurrentCash"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Current Assets",
                name: "BalanceSheetMetrics.CurrentAssets"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Total Assets",
                name: "BalanceSheetMetrics.TotalAssets"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Current Liabilities",
                name: "BalanceSheetMetrics.CurrentLiabilities"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Total Liabilities",
                name: "BalanceSheetMetrics.TotalLiabilities"
              }
            )
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Accounts Receivable" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: "Accounts Receivable Schedule",
                buttonText: "Upload Accounts Receivable Schedule",
                name: "BalanceSheetMetrics.AccountsReceivableScheduleFiles"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Receivable 0-30 Days",
                name: "BalanceSheetMetrics.AccountsReceivable30Days"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Receivable 31-60 Days",
                name: "BalanceSheetMetrics.AccountsReceivable3160Days"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Receivable 61-90 Days",
                name: "BalanceSheetMetrics.AccountsReceivable6190Days"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Receivable 90+ Days",
                name: "BalanceSheetMetrics.AccountsReceivable90PlusDays"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h2", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              FormField,
              {
                name: "BalanceSheetMetrics.AccountsReceivableTotal",
                render: ({ field }) => {
                  (0, import_react10.useEffect)(() => {
                    field.onChange(
                      (bsState?.AccountsReceivable90PlusDays ?? 0) + (bsState?.AccountsReceivable6190Days ?? 0) + (bsState?.AccountsReceivable3160Days ?? 0) + (bsState?.AccountsReceivable30Days ?? 0)
                    );
                  }, [
                    bsState?.AccountsReceivable90PlusDays,
                    bsState?.AccountsReceivable6190Days,
                    bsState?.AccountsReceivable3160Days,
                    bsState?.AccountsReceivable30Days
                  ]);
                  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormLabel, { children: [
                      "Total Accounts Receivable: ",
                      currency(field.value)
                    ] }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, {}),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                  ] });
                }
              }
            ) })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Accounts Payable" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: "Accounts Payable Schedule",
                buttonText: "Upload Accounts Payable Schedule",
                name: "BalanceSheetMetrics.AccountsPayableScheduleFiles"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Payable 30 Days",
                name: "BalanceSheetMetrics.AccountsPayable30Days"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Payable 31-60 Days",
                name: "BalanceSheetMetrics.AccountsPayable3160Days"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Payable 61-90 Days",
                name: "BalanceSheetMetrics.AccountsPayable6190Days"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                label: "Accounts Payable 90+ Days",
                name: "BalanceSheetMetrics.AccountsPayable90PlusDays"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h2", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              FormField,
              {
                name: "BalanceSheetMetrics.AccountsPayableTotal",
                render: ({ field }) => {
                  (0, import_react10.useEffect)(() => {
                    field.onChange(
                      (bsState?.AccountsPayable90PlusDays ?? 0) + (bsState?.AccountsPayable6190Days ?? 0) + (bsState?.AccountsPayable3160Days ?? 0) + (bsState?.AccountsPayable30Days ?? 0)
                    );
                  }, [
                    bsState?.AccountsPayable90PlusDays,
                    bsState?.AccountsPayable6190Days,
                    bsState?.AccountsPayable3160Days,
                    bsState?.AccountsPayable30Days
                  ]);
                  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormLabel, { children: [
                      "Total Accounts Payable: ",
                      currency(field.value)
                    ] }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, {}),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                  ] });
                }
              }
            ) })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Additional Debt" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h2", { className: "text-xl", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              FormField,
              {
                name: "BalanceSheetMetrics.TotalAdditionalDebt",
                render: ({ field }) => {
                  (0, import_react10.useEffect)(() => {
                    field.onChange(
                      (bsState?.AdditionalDebt ?? []).reduce((x, a) => {
                        return x + (a.RemainingAmount ?? 0);
                      }, 0)
                    );
                  }, [
                    bsState?.AdditionalDebt
                  ]);
                  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(FormLabel, { children: [
                      "Total Additional Debt: ",
                      currency(field.value)
                    ] }),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormControl, {}),
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(FormMessage, { className: "text-right" })
                  ] });
                }
              }
            ) }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              AdditionalDebtEditor,
              {
                form,
                deleteDebt,
                debtEditorState,
                setDebtEditorState
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileFileMultiUploadField,
              {
                borrowerId,
                label: "Debt schedule and description",
                buttonText: "Upload Debt Schedule",
                name: "BalanceSheetMetrics.AdditionalDebtScheduleFiles"
              }
            )
          ] })
        ] });
      case 6:
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Inventory" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Inventory Value",
                name: "BalanceSheetMetrics.InventoryAmount"
              }
            ),
            bsState?.InventoryAmount ? /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileFileMultiUploadField,
                {
                  borrowerId,
                  label: "Inventory Supporting Files",
                  buttonText: "Upload Inventory Supporting Files",
                  name: "BalanceSheetMetrics.InventorySupportingFiles"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileFileUploadField,
                {
                  borrowerId,
                  label: "Inventory Description",
                  subtitle: "Inventory Description should include fair market value, condition, quantity, unit cost, and be broken down between work in progress, raw, and finished.",
                  buttonText: "Upload Inventory Description",
                  name: "BalanceSheetMetrics.InventoryBreakdownFile"
                }
              )
            ] }) : /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, {})
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Equipment" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Estimated Equipment Value",
                name: "BalanceSheetMetrics.EstEquipmentValue"
              }
            ),
            bsState?.EstEquipmentValue ? /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileFileUploadField,
                {
                  borrowerId,
                  label: "Equipment Breakdown",
                  buttonText: "Upload Equipment Breakdown",
                  name: "BalanceSheetMetrics.EquipmentBreakdownFile"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileFileUploadField,
                {
                  borrowerId,
                  label: "Equipment External Appraisal",
                  buttonText: "Upload Equipment Appraisal",
                  name: "BalanceSheetMetrics.EquipmentExternalAppraisalFile"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileSwitchField,
                {
                  label: "Has Equipment Debt",
                  name: "BalanceSheetMetrics.HasEquipmentDebt"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileMoneyField,
                {
                  dontAllowNegative: true,
                  label: "Total Debt Secured By Equipment",
                  name: "BalanceSheetMetrics.TotalDebtSecuredByEquipment"
                }
              )
            ] }) : /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, {})
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Real Estate" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "pl-4 space-y-2 my-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              ProfileMoneyField,
              {
                dontAllowNegative: true,
                label: "Estimated Real Estate Value",
                name: "BalanceSheetMetrics.EstRealEstateValue"
              }
            ),
            bsState?.EstRealEstateValue ? /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileFileUploadField,
                {
                  borrowerId,
                  buttonText: "Upload Real Estate Description",
                  label: "Real Estate Description",
                  subtitle: "Description should include addresses, fair market value, purchase prices and dates, and mortage balances.",
                  name: "BalanceSheetMetrics.RealEstateBreakdownFile"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileFileUploadField,
                {
                  borrowerId,
                  label: "Real Estate Eternal Appraisal",
                  buttonText: "Upload Real Estate Appraisal",
                  name: "BalanceSheetMetrics.RealEstateExternalAppraisalFile"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileSwitchField,
                {
                  label: "Do you have debt associated with the real estate?",
                  name: "BalanceSheetMetrics.HasRealEstateDebt"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                ProfileMoneyField,
                {
                  dontAllowNegative: true,
                  label: "Total Mortgages",
                  name: "BalanceSheetMetrics.TotalMortgages"
                }
              )
            ] }) : /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, {})
          ] })
        ] });
      case 7:
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { children: "Additional Documents" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "w-full border border-b-2 border-gray-200 mb-4" }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            ProfileFileMultiUploadField,
            {
              borrowerId,
              buttonText: "Upload Additional Documents",
              label: "",
              name: "AdditionalDocs"
            }
          )
        ] });
    }
  };
  const loanAppOptions = loanApps.map((x) => ({
    label: (0, import_lib4.getLoanAppTitle)(x),
    value: x.Id
  }));
  (0, import_react10.useEffect)(() => {
    if (form.formState.isSubmitSuccessful) {
      form.reset(void 0, {
        keepValues: true,
        keepDirty: false,
        keepDefaultValues: false
      });
    }
  }, [form.formState.isSubmitSuccessful, form.reset]);
  const setActiveLoanAppMutation = (0, import_lib4.useApiMutation)(import_lib4.setActiveLoanApplication, []);
  const profileEditorRef = (0, import_react10.useRef)(null);
  const [editorWidth, setEditorWidth] = (0, import_react10.useState)(0);
  (0, import_react10.useEffect)(() => {
    setEditorWidth(profileEditorRef.current?.clientWidth ?? 0);
  }, [profileEditorRef.current?.clientWidth]);
  (0, import_react10.useLayoutEffect)(() => {
    function updateSize() {
      setEditorWidth(profileEditorRef.current?.clientWidth ?? 0);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const saveLoanApp = (0, import_react10.useCallback)(async (v) => {
    await loanAppMutation.mutateAsync({
      loanAppId: selectedLoanAppId,
      loanApp: v
    });
    try {
      await loanEvaluation.mutateAsync(
        { loanAppId: selectedLoanAppId, portalType: import_lib4.PortalType.Borrower }
      );
    } catch (e) {
    }
  }, [selectedLoanAppId]);
  const getLoanActions = () => {
    const loanStatus = selectedLoanApp?.Status ?? import_lib4.LoanApplicationStatus.InProgress;
    if ([
      import_lib4.LoanApplicationStatus.Screen1,
      import_lib4.LoanApplicationStatus.Screen2,
      import_lib4.LoanApplicationStatus.Screen3,
      import_lib4.LoanApplicationStatus.InProgress
    ].includes(loanStatus)) {
      if (stepComplete.find((x) => x != 100)) {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          attestation_default,
          {
            id: selectedLoanAppId,
            isreattest: false,
            disabled: true
          }
        ) });
      } else {
        return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          attestation_default,
          {
            id: selectedLoanAppId,
            isreattest: false
          }
        );
      }
    } else if (loanStatus == import_lib4.LoanApplicationStatus.SpecialistReview) {
      return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        attestation_default,
        {
          id: selectedLoanAppId,
          isreattest: true
        }
      );
    } else {
      return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, {});
    }
  };
  const stepCompletePercent = (step) => {
    let totalFields = steps[step]?.requiredFields.length ?? 0;
    const requiredFields = steps[step]?.requiredFields ?? [];
    let incompletedFields = requiredFields.filter(
      (field) => isRequiredValueInvalid(form.getValues(field))
    ).length;
    if (step == 4) {
      const prevYears = form.getValues(
        "ProfitLossMetrics.PreviousYearsMetrics"
      );
      for (var i = -3; i <= 1; i++) {
        const prevYear = prevYears.find((x) => x.Year == currentYear2 + i);
        if (prevYear) {
          incompletedFields += Object.values(prevYear).filter(
            (x) => isRequiredValueInvalid(x)
          ).length;
        } else {
          incompletedFields += 9;
        }
        totalFields += 9;
      }
    }
    if (totalFields == 0) {
      return 100;
    }
    return Math.round(
      (totalFields - incompletedFields) / totalFields * 100
    );
  };
  const stepComplete = steps.map((_, idx) => stepCompletePercent(idx));
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "w-full flex flex-col", ref: profileEditorRef, children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "md:px-4 flex lg:flex-row flex-col justify-center md:space-x-2", children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "flex lg:flex-row flex-col", children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          Tabs,
          {
            orientation: "vertical",
            onValueChange: (v) => {
              setCurrentStepIdx(parseInt(v));
            },
            value: currentStepIdx.toString(),
            children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(TabsList, { className: "mx-auto flex flex-col h-auto bg-transparent w-full space-y-1 p-2", children: steps.map((step, idx) => {
              const completePercent = stepComplete[idx] == void 0 ? 100 : stepComplete[idx];
              return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                TabsTrigger,
                {
                  className: "relative w-full text-left justify-start border border-gray-300 data-[state=active]:text-primary data-[state=active]:bg-primary/10 data-[state=active]:shadow-primary data-[state=active]:shadow-sm transition-all duration-300",
                  value: idx.toString(),
                  children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "flex flex-col w-full", children: [
                    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { children: [
                      idx + 1,
                      ". ",
                      step.label
                    ] }),
                    (steps[idx]?.requiredFields.length ?? 0) > 0 && /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
                      "div",
                      {
                        className: "w-full text-center text-xs whitespace-nowrap" + (completePercent >= 100 ? " text-green-400" : completePercent >= 50 ? " text-orange-400" : " text-red-400"),
                        children: [
                          stepComplete[idx],
                          "% Complete"
                        ]
                      }
                    )
                  ] })
                },
                idx
              );
            }) })
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-col xl:w-[700px] h-full flex-grow space-y-2 p-2 lg:mb-[60px]", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Form, { ...form, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(
          "form",
          {
            className: "flex flex-col space-y-2",
            onSubmit: form.handleSubmit(async () => {
            }),
            onError: (e) => {
              console.log(e);
            },
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "p-4 border border-gray-300 shadow-sm shadow-black/10 rounded-md ", children: getPageContents() }),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(RootFormMessage, {}),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                auto_save_default,
                {
                  onSubmit: async (v) => {
                    await saveProfile(v);
                    try {
                      await loanEvaluation.mutateAsync({
                        loanAppId: selectedLoanAppId,
                        portalType: import_lib4.PortalType.Borrower
                      });
                    } catch (e) {
                    }
                  }
                }
              )
            ]
          }
        ) }) })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: selectedLoanApp && /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "space-y-2 py-2 flex flex-col max-w-[400px] mb-24", children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "w-full rounded text-black p-4 bg-white border border-gray-300 shadow-sm shadow-black/10 flex flex-col y-2 space-y-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("b", { className: "text-lg", children: "Loan Ask" }),
          loanAppOptions.length > 1 && /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "", children: [
            "Select Loan",
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Select,
              {
                className: " text-black",
                options: loanAppOptions,
                value: loanAppOptions.find(
                  (x) => x.value == selectedLoanAppId
                ),
                onChange: async (v) => {
                  const selectedId = v.value;
                  var selectedApp = loanApps.find(
                    (x) => x.Id == selectedId
                  );
                  setSelectedLoanApp(
                    selectedApp
                  );
                  setSelectedLoanAppId(selectedId);
                  await setActiveLoanAppMutation.mutateAsync(selectedId);
                  try {
                    await loanEvaluation.mutateAsync({
                      loanAppId: selectedId,
                      portalType: import_lib4.PortalType.Borrower
                    });
                  } catch (e) {
                  }
                }
              }
            )
          ] }),
          loanApps.map((x) => /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
            LoanAppForm,
            {
              visible: x.Id == selectedLoanAppId,
              glossary,
              initialLoanApp: x,
              onSave: saveLoanApp
            },
            x.Id
          ))
        ] }),
        lastLoanEvaluation && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(target_box_default, { results: lastLoanEvaluation })
      ] }) })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      "div",
      {
        className: "z-[30] fixed bottom-0 border-t border-t-gray-300 bg-white py-2 px-2",
        style: {
          width: editorWidth
        },
        children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "flex flex-row mx-auto items-center justify-center max-w-[800px] space-x-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "flex flex-row items-center", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Button,
              {
                variant: "outline",
                type: "button",
                className: "",
                disabled: currentStepIdx == 0,
                onClick: (e) => {
                  e.preventDefault();
                  setCurrentStepIdx(currentStepIdx - 1);
                },
                children: "Prev"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "font-medium text-gray-500 mx-4 hidden md:block", children: [
              currentStepIdx + 1,
              " / ",
              pageCount
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
              Button,
              {
                variant: "outline",
                type: "button",
                disabled: currentStepIdx == pageCount - 1,
                onClick: (e) => {
                  e.preventDefault();
                  setCurrentStepIdx(currentStepIdx + 1);
                },
                children: "Next"
              }
            )
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex-grow", children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "text-primary font-medium", children: [
            /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "w-full text-center h-6 relative bg-secondary/50 rounded", children: [
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
                "div",
                {
                  style: { width: stepComplete[currentStepIdx] + "%" },
                  className: "bg-primary h-full rounded"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "text-white absolute w-full top-0 text-center", children: stepComplete[currentStepIdx] + "%" })
            ] }),
            stepComplete[currentStepIdx] != 100 && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "text-sm text-orange-500 text-center", children: "Missing required fields are highlighted" })
          ] }) }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "", children: getLoanActions() })
        ] })
      }
    )
  ] });
};
var BorrowerProfileEditor = () => {
  const [borrowerId, setBorrowerId] = (0, import_react10.useState)();
  const [borrowerAccess, setBorrowerAccess] = (0, import_react10.useState)();
  const [currentLoanApp, setCurrentLoanApp] = (0, import_react10.useState)();
  (0, import_lib4.useApiQuery)("me", import_lib4.getSelf, {
    success: (v) => {
      const borrower = v.Borrowers.find(
        (x) => x.Id == v.ActiveBorrowerId
      );
      if (borrower) {
        setCurrentLoanApp(borrower.ActiveLoanApplication);
        setBorrowerAccess(borrower.BorrowerAccess);
      }
      setBorrowerId(v.ActiveBorrowerId);
    }
  });
  const borrowerQuery = (0, import_lib4.useApiQuery)(
    "borrowerProfile",
    (0, import_lib4.getBorrowerProfile)(import_lib4.PortalType.Borrower, borrowerId ?? ""),
    {
      enabled: borrowerId != void 0,
      success: (_) => {
      }
    }
  );
  const glossaryQuery = (0, import_lib4.useApiQuery)(
    "glossary",
    import_lib4.getApiGlossary
  );
  const loanAppMutation = (0, import_lib4.useApiMutation)(import_lib4.createLoanApplication, ["loanApps"]);
  const loanAppQuery = (0, import_lib4.useApiQuery)(
    "loanApps",
    (0, import_lib4.getLoanApplications)(borrowerId ?? ""),
    {
      enabled: borrowerId != void 0,
      success: (data) => {
        if (data.length < 1) {
          loanAppMutation.mutate({
            borrowerId,
            application: {
              LoanType: 0,
              LoanAmount: 1e6,
              PersonalGuarantee: false,
              LoanUse: 0
            }
          });
        }
      }
    }
  );
  if (!borrowerId || borrowerQuery.isLoading || glossaryQuery.isLoading || loanAppQuery.isLoading || loanAppMutation.isLoading) {
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Loading..." });
  }
  if (!loanAppQuery.isSuccess) {
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { children: "Something went wrong." });
  }
  const inProgressLoanApps = loanAppQuery.data.filter((x) => {
    return (0, import_lib4.isPreSubmit)(x.Status ?? import_lib4.LoanApplicationStatus.InProgress);
  });
  if (loanAppQuery.data.length == 0) {
    window.location.reload();
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, {});
  }
  const initialLoanAppId = currentLoanApp != void 0 ? currentLoanApp : inProgressLoanApps.length > 0 ? inProgressLoanApps[0].Id : loanAppQuery.data[0].Id;
  const fiscalYearEndDate = borrowerQuery.data?.ProfitLossMetrics?.FiscalYearEnd;
  if (fiscalYearEndDate && typeof fiscalYearEndDate == "string") {
    borrowerQuery.data.ProfitLossMetrics.FiscalYearEnd = new Date(
      fiscalYearEndDate
    );
  }
  const balanceSheetAsOfDate = borrowerQuery.data?.BalanceSheetMetrics?.BalanceSheetAsOfDate;
  if (balanceSheetAsOfDate && typeof balanceSheetAsOfDate == "string") {
    borrowerQuery.data.BalanceSheetMetrics.BalanceSheetAsOfDate = new Date(
      balanceSheetAsOfDate
    );
  }
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    BorrowerProfileEditorInner,
    {
      borrowerAccess: borrowerAccess ?? import_lib4.BorrowerAccess.ReadOnly,
      initialSelectedAppId: initialLoanAppId,
      glossary: glossaryQuery.data,
      initialProfile: borrowerQuery.data,
      borrowerId,
      loanApps: loanAppQuery.data
    }
  ) });
};
var borrower_profile_editor_default = BorrowerProfileEditor;

// src/elements/lender-profile-editor.tsx
var import_react11 = require("react");
var import_react_hook_form6 = require("react-hook-form");
var import_zod5 = require("@hookform/resolvers/zod");
var import_lib5 = require("@cxm/lib");
var import_lodash3 = require("lodash");
var import_dangerously_set_html_content = __toESM(require("dangerously-set-html-content"));
var import_react_tooltip = require("@radix-ui/react-tooltip");
var import_jsx_runtime26 = require("react/jsx-runtime");
var revenueMixOptions2 = [
  {
    label: "Point Of Sale (B2B)",
    value: 1
  },
  {
    label: "Point Of Sale (B2C)",
    value: 2
  },
  {
    label: "Project-based/Contractual",
    value: 3
  },
  {
    label: "Recurring",
    value: 4
  }
];
var rateReferenceOptions = [
  {
    label: "LIBOR",
    value: import_lib5.RateReference.LIBOR
  },
  {
    label: "SOFR",
    value: import_lib5.RateReference.SOFR
  }
];
var ProfileSwitchField2 = ({ label, name }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(
          FormItem,
          {
            className: "flex flex-row align-middle items-center space-y-0 max-w-[600px]",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormLabel, { children: label }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: "ml-auto flex flex-row space-x-2 items-center", children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { children: field.value == true ? "Yes" : "No" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
                  Switch,
                  {
                    checked: field.value,
                    value: field.value || false,
                    onCheckedChange: (v) => {
                      console.log(v);
                      field.onChange(v);
                    }
                  }
                ) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormMessage, { className: "text-right" })
            ]
          }
        );
      }
    }
  );
};
function formatPercent2(val) {
  var val = val.replace(/[^\d]/g, "");
  val = val.replace(/^0+/, "");
  if (val.length == 0) {
    return "0";
  }
  var value = parseInt(val);
  if (value > 100) {
    return "100";
  }
  return val.substring(0, 3);
}
function displayMoney3(val, allowNegative = true) {
  return val == "-" ? "-" : val == "" ? currency(0) : currency(allowNegative ? parseInt(val) : Math.max(0, parseInt(val)));
}
function formatMoney4(val) {
  var val = val.replace(/[^\d-]/g, "");
  val = val.replace(/(?<=.)-/, "");
  val = val.replace(/^0+/, "");
  if (val.length == 0) {
    return "0";
  }
  return val == "-" ? "-" : val;
}
var ProfileMultiSelectField = ({ label, name, options }) => {
  const fullOptions = [
    {
      label: "All",
      value: "select_all"
    },
    {
      label: "None",
      value: "select_none"
    },
    ...options
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [multiSelectValue, setMultiSelectValue] = (0, import_react11.useState)(
          (field.value || []).map(
            (x) => options.find((r) => r.value == x)
          )
        );
        return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(FormItem, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormLabel, { children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            Select,
            {
              isMulti: true,
              maxMenuHeight: 320,
              styles: {
                valueContainer: (styles) => ({
                  ...styles,
                  maxHeight: "140px",
                  overflowY: "auto",
                  width: "100%"
                })
              },
              options: fullOptions,
              ...field,
              value: multiSelectValue,
              onChange: (v) => {
                let values = v;
                const requestedSelectAll = values.find((x) => x.value == "select_all") != void 0;
                if (requestedSelectAll) {
                  values = options;
                }
                setMultiSelectValue(values);
                const requestedSelectNone = values.find(
                  (x) => x.value == "select_none"
                ) != void 0;
                if (requestedSelectNone) {
                  values = [];
                }
                setMultiSelectValue(values);
                field.onChange(
                  values.map((x) => x.value)
                );
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var TableProfilePercentField = ({ name }) => {
  const [focused, setFocused] = (0, import_react11.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [stringValue, setStringValue] = (0, import_react11.useState)(
          field.value ? field.value : ""
        );
        return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(FormItem, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            Input,
            {
              className: tableInputClass,
              type: "text",
              ...field,
              onFocus: () => setFocused(true),
              onBlur: () => {
                field.onBlur();
                setFocused(false);
              },
              value: focused ? stringValue : (stringValue == "" ? "0" : stringValue) + "%",
              onChange: (e) => {
                var stringVal = formatPercent2(
                  e.target.value
                );
                setStringValue(
                  stringVal
                );
                if (stringVal == "-" || stringVal == "") {
                  field.onChange(0);
                } else {
                  field.onChange(parseInt(stringVal));
                }
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var tableCellClass = "px-0 border-l border-r border-l-gray-300 border-r-gray-300";
var tableInputClass = "w-full border-none rounded-none";
var TableProfileMoneyField = ({ name }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [stringValue, setStringValue] = (0, import_react11.useState)(
          field.value ? field.value : ""
        );
        return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(FormItem, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            Input,
            {
              className: tableInputClass,
              type: "text",
              ...field,
              value: displayMoney3(stringValue),
              onChange: (e) => {
                var stringVal = formatMoney4(
                  e.target.value
                );
                setStringValue(
                  stringVal
                );
                if (stringVal == "-" || stringVal == "") {
                  field.onChange(0);
                } else {
                  field.onChange(parseInt(stringVal));
                }
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var TableProfileNumberField = ({ name }) => {
  const [focused, setFocused] = (0, import_react11.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [stringValue, setStringValue] = (0, import_react11.useState)(
          field.value ? field.value : ""
        );
        return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(FormItem, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            Input,
            {
              className: tableInputClass,
              type: "text",
              ...field,
              onFocus: () => setFocused(true),
              onBlur: () => {
                field.onBlur();
                setFocused(false);
              },
              value: focused ? stringValue : stringValue == "" ? "0" : stringValue,
              onChange: (e) => {
                var stringVal = e.target.value;
                setStringValue(
                  stringVal
                );
                if (stringVal == "-" || stringVal == "") {
                  field.onChange(0);
                } else {
                  field.onChange(parseInt(stringVal));
                }
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var LenderProfileCriteriaEditor = ({ visible, saveProfile, selectedLoanType, initialCriteria }) => {
  const form = (0, import_react_hook_form6.useForm)({
    resolver: (0, import_zod5.zodResolver)(import_lib5.lenderCriteriaModel),
    mode: "onBlur",
    defaultValues: initialCriteria
  });
  const [templatePartial, setTemplatePartial] = (0, import_react11.useState)();
  (0, import_lib5.useApiQuery)(
    "templatePartial-" + selectedLoanType,
    (0, import_lib5.getLenderTemplatePartial)(
      import_lib5.loanTypes.find((x) => x.id == selectedLoanType)?.key || ""
    ),
    {
      success: (v) => {
        setTemplatePartial(v);
      }
    }
  );
  const watchedState = form.watch();
  const industryState = watchedState.Industries;
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(Form, { ...form, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(
    "form",
    {
      className: "w-full space-y-2 h-full flex flex-col pb-[300px]" + (visible ? "" : " hidden"),
      onSubmit: form.handleSubmit(async () => {
      }),
      onError: (e) => {
        console.log(e);
      },
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("h1", { className: "text-xl", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("b", { children: "Loan" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "w-full border border-b-gray-300" })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: "px-4 space-y-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            ProfileMultiSelectField,
            {
              options: import_lib5.loanUseOptions,
              name: "Loan_Uses",
              label: "Use of Proceeds"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            ProfileSwitchField2,
            {
              name: "Personal_Guarantee",
              label: "Require Personal Guarantee"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            ProfileSwitchField2,
            {
              name: "Audit_Review",
              label: "Require Audit"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(Table, { className: "table-fixed", children: [
            /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Loan Metric" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Min" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Max" })
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableBody, { className: "border border-b-gray-300", children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Loan Amount" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "Loan_Amount_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "Loan_Amount_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Rate" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Rate_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Rate_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Maturity Range (Months)" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Maturity_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Maturity_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Loan To Revenue" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Loan_To_Revenue_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Loan_To_Revenue_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Loan To EBITDA" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Loan_To_EBITDA_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Loan_To_EBITDA_Max" }) })
              ] })
            ] })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(Table, { className: "table-fixed", children: [
            /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Advanced Rate Metric" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Min" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Max" })
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableBody, { className: "border border-b-gray-300", children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Cash" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_Cash_Rate_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_Cash_Rate_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Accounts Receivable" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_AR_Rate_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_AR_Rate_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Inventory" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_Inventory_Rate_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_Inventory_Rate_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Equipment" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_Equip_Rate_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Advance_Equip_Rate_Max" }) })
              ] })
            ] })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(Table, { className: "table-fixed", children: [
            /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Equipment Liquidation Metric" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Min" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Max" })
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableBody, { className: "border border-b-gray-300", children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Equipment Orderly Liquidation Value" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Equipment_Orderly_Liquidation_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Equipment_Orderly_Liquidation_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Equipment Forced Liquidation Value" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Equipment_Forced_Liquidation_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfilePercentField, { name: "Equipment_Forced_Liquidation_Max" }) })
              ] })
            ] })
          ] })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("h1", { className: "text-xl", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("b", { children: "Borrower" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "w-full border border-b-gray-300" })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: "px-4", children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            ProfileSwitchField2,
            {
              name: "Has_CFO",
              label: "Require Full-Time CFO"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            ProfileSwitchField2,
            {
              name: "External_Accountant",
              label: "Require External Accountant"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            ProfileMultiSelectField,
            {
              options: revenueMixOptions2,
              name: "Revenue_Mixes",
              label: "Revenue Mix"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(Table, { className: "table-fixed", children: [
            /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Metric" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Min" }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableHead, { children: "Max" })
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableBody, { className: "border border-b-gray-300", children: [
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Years In Business" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Years_In_Business_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileNumberField, { name: "Years_In_Business_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "TTM Revenue" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "TTM_Revenue_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "TTM_Revenue_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "TTM EBITDA" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "TTM_EBITDA_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "TTM_EBITDA_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Accounts Receivable" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "AR_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "AR_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "Inventory" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "Inventory_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "Inventory_Max" }) })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(TableRow, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { children: "FMV (Net) Equipment" }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "FMV_PPE_Min" }) }),
                /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableCell, { className: tableCellClass, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(TableProfileMoneyField, { name: "FMV_PPE_Max" }) })
              ] })
            ] })
          ] })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("h1", { className: "text-lg", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("b", { children: "Industries" }) }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "w-full border border-b-gray-300" }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: "px-4", children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            ProfileMultiSelectField,
            {
              options: import_lib5.numberedIndustryOptions,
              name: "Industries",
              label: "Industries"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
            FormField,
            {
              name: "Sub_Industries",
              render: ({ field }) => {
                const [multiSelectValue, setMultiSelectValue] = (0, import_react11.useState)(
                  (field.value || []).map(
                    (x) => import_lib5.numberedSubIndustryOptions.find((r) => r.value == x)
                  )
                );
                (0, import_react11.useEffect)(() => {
                  if (!industryState || !field.value) {
                    return;
                  }
                  if (!industryState && field.value) {
                    field.onChange([]);
                  }
                  var filteredValue = multiSelectValue.filter(
                    (x) => industryState.includes(x.parent + 1)
                  );
                  setMultiSelectValue(filteredValue);
                  field.onChange(filteredValue.map((x) => x.value));
                }, [industryState]);
                const options = import_lib5.numberedSubIndustryOptions.filter(
                  (x) => industryState && industryState.includes(x.parent + 1)
                );
                const fullOptions = [
                  {
                    label: "All",
                    value: -1
                  },
                  {
                    label: "None",
                    value: -2
                  },
                  ...options
                ];
                return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(FormItem, { children: [
                  /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormLabel, { children: "Sub Industries" }),
                  /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
                    Select,
                    {
                      isMulti: true,
                      maxMenuHeight: 320,
                      styles: {
                        valueContainer: (styles) => ({
                          ...styles,
                          maxHeight: "140px",
                          overflowY: "auto",
                          width: "100%"
                        })
                      },
                      options: fullOptions,
                      ...field,
                      value: multiSelectValue,
                      onChange: (v) => {
                        let values = v;
                        const requestedSelectAll = values.find((x) => x.value == -1) != void 0;
                        if (requestedSelectAll) {
                          values = options;
                        }
                        setMultiSelectValue(values);
                        const requestedSelectNone = values.find(
                          (x) => x.value == -2
                        ) != void 0;
                        if (requestedSelectNone) {
                          values = [];
                        }
                        setMultiSelectValue(values);
                        field.onChange(
                          values.map((x) => x.value)
                        );
                      }
                    }
                  ) }),
                  /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(FormMessage, { className: "text-right" })
                ] });
              }
            }
          )
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("h1", { className: "text-lg", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("b", { children: "Templates" }) }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "w-full border border-b-gray-300" }),
        templatePartial && /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "row", id: "profileContactContainer", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_dangerously_set_html_content.default, { html: templatePartial }) }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(auto_save_default, { onSubmit: saveProfile })
      ]
    }
  ) });
};
var LenderProfileEditorInner = ({ initialProfile, lenderId, selectedLoanTypes }) => {
  const saveProfile = (loanType) => (v) => {
    const newFullState = { ...fullState };
    if (loanType == -1) {
      fullState.DefaultCriteria = v;
    } else {
      var otherLoanTypes = fullState.LoanTypeOverrides?.filter(
        (x) => x.LoanType != loanType
      ) || [];
      newFullState.LoanTypeOverrides = [
        ...otherLoanTypes,
        {
          LoanType: loanType,
          Criteria: v
        }
      ];
    }
    setFullState(newFullState);
    lenderProfileMutation.mutate({
      lenderId,
      profile: newFullState
    });
  };
  const [fullState, setFullState] = (0, import_react11.useState)(
    initialProfile
  );
  const lenderProfileMutation = (0, import_lib5.useApiMutation)(
    import_lib5.updateLenderCriteria,
    ["lenderProfile"]
  );
  const [selectedLoanType, setSelectedLoanType] = (0, import_react11.useState)(() => {
    var saved = parseInt(localStorage.getItem("lender-loantype") ?? "0", 10);
    if (selectedLoanTypes.includes(saved)) {
      return saved;
    } else {
      return selectedLoanTypes[0] ?? -1;
    }
  });
  const selectedLoanTypeDetails = (0, import_react11.useMemo)(
    () => selectedLoanType != -1 ? import_lib5.loanTypes.find((x) => x.id == selectedLoanType) : void 0,
    [selectedLoanType]
  );
  const loanTypeOptions4 = [
    /*{
      label: "All Loan Types",
      value: -1,
    },*/
    ...import_lib5.loanTypes.filter((x) => selectedLoanTypes.includes(x.id)).map((loanType) => ({
      label: loanType.name,
      value: loanType.id
    }))
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "w-full h-full", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "flex flex-col", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: "text-lg container flex flex-col space-y-8 max-w-[1000px]", children: [
    /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: "flex flex-col", children: [
      /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("h1", { children: [
        "Loan Criteria for",
        " ",
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("span", { className: "text-primary", children: selectedLoanTypeDetails ? `${selectedLoanTypeDetails.name} Loans` : "All Loan Types" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: "relative", children: [
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "text-xs absolute top-[100%]", children: "Select Loan Type" }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
          Select,
          {
            className: "w-40",
            value: loanTypeOptions4.find((x) => x.value == selectedLoanType),
            options: loanTypeOptions4,
            onChange: (v) => {
              var loanType = v.value;
              if (loanType != -1) {
                var criteria = fullState.LoanTypeOverrides?.find(
                  (x) => x.LoanType == loanType
                );
                if (!criteria) {
                  var newFullState = { ...fullState };
                  if (!fullState.LoanTypeOverrides) {
                    newFullState.LoanTypeOverrides = [];
                  }
                  newFullState.LoanTypeOverrides.push({
                    LoanType: loanType,
                    Criteria: {}
                  });
                  setFullState(newFullState);
                }
              }
              localStorage.setItem("lender-loantype", loanType.toString());
              setSelectedLoanType(loanType);
            }
          }
        )
      ] })
    ] }),
    selectedLoanTypes.map((loanType) => /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
      LenderProfileCriteriaEditor,
      {
        visible: selectedLoanType == loanType,
        initialCriteria: selectedLoanType == -1 ? fullState.DefaultCriteria : fullState.LoanTypeOverrides?.find(
          (x) => x.LoanType == loanType
        )?.Criteria ?? {},
        selectedLoanType: loanType,
        defaultCriteria: fullState.DefaultCriteria,
        saveProfile: saveProfile(loanType)
      },
      loanType
    ))
  ] }) }) });
};
var LenderProfileEditor = () => {
  const meQuery = (0, import_lib5.useApiQuery)(
    "me",
    import_lib5.getSelf
  );
  const profileQuery = (0, import_lib5.useApiQuery)(
    "lenderProfile",
    (0, import_lib5.getLenderCriteria)(meQuery.data?.ActiveLenderId),
    {
      enabled: meQuery.isSuccess && meQuery.data && meQuery.data.ActiveLenderId != void 0
    }
  );
  if (meQuery.isLoading || profileQuery.isLoading) {
    return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { children: "Loading..." });
  }
  var activeLender = meQuery.data.ActiveLenderId;
  if (activeLender == void 0) {
    return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { children: "Must select an active lender" });
  }
  if (!profileQuery.data.LoanTypes || profileQuery.data.LoanTypes.length < 1) {
    return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { children: "Must select at least one loan type on the lender profile." });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_react_tooltip.TooltipProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    LenderProfileEditorInner,
    {
      initialProfile: profileQuery.data.Criteria || import_lib5.defaultLenderCriteria,
      selectedLoanTypes: profileQuery.data.LoanTypes,
      lenderId: activeLender
    }
  ) });
};
var lender_profile_editor_default = LenderProfileEditor;

// src/elements/messaging.tsx
var import_react12 = require("react");
var import_lib6 = require("@cxm/lib");
var import_lucide_react7 = require("lucide-react");
var import_react_responsive = require("react-responsive");
var import_jsx_runtime27 = require("react/jsx-runtime");
function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}
var MessageView = (props) => {
  const isSelf = props.userId == props.message.FromUser.Id && (0, import_lib6.PortalTypeNumber)(props.message.MessageRole) == props.portaltype;
  const sendDate = new Date(props.message.MessageSendTime);
  const todaysDate = /* @__PURE__ */ new Date();
  const nextMessage = props.idx > 0 ? props.allMessages[props.idx - 1] : void 0;
  const isNextSelf = nextMessage && nextMessage.FromUser.Id == props.message.FromUser.Id && nextMessage.MessageRole == props.message.MessageRole;
  const getMessageColor = () => {
    switch ((0, import_lib6.PortalTypeNumber)(props.message.MessageRole)) {
      case import_lib6.PortalType.Management:
        return "bg-orange-500/90";
      case import_lib6.PortalType.Lender:
        return "bg-secondary/70";
      case import_lib6.PortalType.Borrower:
        return "bg-primary/70";
    }
  };
  const getRoleSubtitle = () => {
    switch ((0, import_lib6.PortalTypeNumber)(props.message.MessageRole)) {
      case import_lib6.PortalType.Management:
        return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("span", { className: "text-orange-500/90", children: "Loan Specialist" });
      case import_lib6.PortalType.Lender:
        return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("span", { className: "text-secondary/70", children: "Lender" });
      case import_lib6.PortalType.Borrower:
        return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("span", { className: "text-primary/70", children: "Borrower" });
    }
  };
  const isMobile = props.portaltype && (0, import_react_responsive.useMediaQuery)({ query: "(max-width: 1224px)" });
  if (isMobile) {
    return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_jsx_runtime27.Fragment, {});
  }
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
    "div",
    {
      className: "flex flex-col" + (props.idx == 0 ? " mt-1" : ""),
      children: [
        props.message.Content && props.message.Content.trim().length > 0 && /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
          "p",
          {
            className: "break-words text-sm max-w-[80%] rounded-md p-2 text-white " + (isSelf ? " ml-auto" : " mr-auto") + " " + getMessageColor(),
            children: props.message.Content
          }
        ),
        props.message.Attachments && props.message.Attachments.length > 0 && props.message.Attachments.map((attachment) => /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
          "div",
          {
            className: (isSelf ? "ml-auto" : "mr-auto") + " flex flex-row space-x-1 items-center",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.File, { height: 20 }),
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
                "a",
                {
                  href: process.env.REACT_APP_BASE_API_URL + `/api/messages/DownloadLoanAppMessageAttachment?portalType=${props.portaltype}&loanApplicationId=${props.loanAppId}&messageId=${props.message.Id}&attachmentId=${attachment.AttachmentId}`,
                  children: attachment.AttachmentName
                }
              )
            ]
          },
          attachment.AttachmentId
        )),
        (!isNextSelf || nextMessage && (new Date(nextMessage.MessageSendTime).getTime() - sendDate.getTime()) / 1e3 > 180) && /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex flex-col", children: [
          /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
            "div",
            {
              className: "text-xs text-black/70 " + (isSelf ? "ml-auto" : "mr-auto"),
              children: [
                props.message.FromUser.Firstname,
                " ",
                props.message.FromUser.Lastname,
                " ",
                sameDay(todaysDate, sendDate) ? sendDate.toLocaleString("en", {
                  hour: "numeric",
                  minute: "numeric"
                }) : sendDate.toLocaleString("en", {
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric"
                })
              ]
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
            "div",
            {
              className: "text-xs " + (isSelf ? "ml-auto" : "mr-auto"),
              children: getRoleSubtitle()
            }
          )
        ] })
      ]
    }
  );
};
var MessageForm = (props) => {
  const meQuery = (0, import_lib6.useApiQuery)("me", import_lib6.getSelf);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = (0, import_react12.useState)(0);
  const confirmDialog = useConfirmDialogContext();
  const [nextMessageAttachments, setNextMessageAttachments] = (0, import_react12.useState)([]);
  const uploadAttachmentMutation = (0, import_lib6.useApiMutation)(import_lib6.uploadMessageAttachment);
  const loanAppMessagesQuery = (0, import_lib6.useApiQuery)(
    "loanAppMessages-" + props.loanappid,
    (0, import_lib6.getLoanAppMessages)({
      loanAppId: props.loanappid ?? "",
      portalType: props.portaltype
    }),
    {
      enabled: props.loanappid != void 0
    }
  );
  const loanAppSendMutation = (0, import_lib6.useApiMutation)(
    import_lib6.sendLoanAppMessage,
    ["loanAppMessages-" + props.loanappid]
  );
  const [open, setOpen] = (0, import_react12.useState)(
    localStorage.getItem("messages-open") == "true" ? true : false
  );
  const [textInput, setTextInput] = (0, import_react12.useState)("");
  const sendMessage = async () => {
    await loanAppSendMutation.mutateAsync({
      portalType: props.portaltype,
      loanAppId: props.loanappid,
      message: {
        Content: textInput,
        Attachments: nextMessageAttachments.map((x) => x.AttachmentId)
      }
    });
    setTextInput("");
    setNextMessageAttachments([]);
  };
  const fileInputRef = (0, import_react12.useRef)(null);
  const getMessageSubtitle = () => {
    if (props.loanappid == void 0) {
      return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_jsx_runtime27.Fragment, {});
    }
    if (props.portaltype == import_lib6.PortalType.Borrower) {
      return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("span", { children: "Chat directly with our loan specialists." });
    } else if (props.portaltype == import_lib6.PortalType.Management) {
      return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("span", { children: "Chat directly with the borrower." });
    }
    return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_jsx_runtime27.Fragment, {});
  };
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: "absolute overflow-hidden w-screen h-screen top-0 left-0 pointer-events-none", children: /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
    "div",
    {
      className: "absolute right-[30px] w-[360px] bottom-0 border border-gray-300 rounded-t bg-[#f6fbff] transition-transform pointer-events-auto",
      style: {
        transform: !open ? "translateY(502px)" : ""
      },
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
          "div",
          {
            className: "px-3 flex flex-row justify-center items-center text-xl h-[40px] border-b border-b-gray-300",
            onClick: () => {
              localStorage.setItem("messages-open", (!open).toString());
              setOpen(!open);
            },
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.Mail, {}),
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("b", { className: "ml-[10px]", children: "Messages" }),
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("span", { className: "ml-auto", children: open ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.ChevronDown, {}) : /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.ChevronUp, {}) })
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "h-[500px] w-full flex flex-col", children: [
          /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: "p-2 border-b border-b-gray-300 text-sm", children: getMessageSubtitle() }),
          /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
            "div",
            {
              className: "bg-white w-full h-full flex-grow flex flex-col-reverse p-2 space-y-1 overflow-y-auto",
              style: {
                scrollbarWidth: "none"
              },
              children: meQuery.isSuccess && loanAppMessagesQuery.isSuccess && loanAppMessagesQuery.data.map((x, idx) => /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
                MessageView,
                {
                  loanAppId: props.loanappid ?? "",
                  userId: meQuery.data.Id,
                  message: x,
                  allMessages: loanAppMessagesQuery.data,
                  idx,
                  portaltype: props.portaltype
                },
                x.Id
              ))
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex flex-col px-3 py-1", children: [
            /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex flex-row space-x-2", children: [
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
                Textarea,
                {
                  style: {
                    resize: "none"
                  },
                  className: "min-h-[0px]",
                  rows: 1,
                  value: textInput,
                  onKeyDown: (e) => {
                    if (e.keyCode == 13 && !e.shiftKey) {
                      sendMessage();
                    }
                  },
                  onChange: (e) => setTextInput(e.target.value),
                  placeholder: "Type here...",
                  disabled: loanAppSendMutation.isLoading
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
                Input,
                {
                  ref: fileInputRef,
                  type: "file",
                  value: "",
                  className: "hidden",
                  onChange: async (e) => {
                    if (!e.target.files || e.target.files?.length == 0)
                      return;
                    const formData = new FormData();
                    for (var i = 0; i < e.target.files.length; i++) {
                      var file = e.target.files.item(i);
                      if (file != null) {
                        formData.append("file" + i, file);
                      }
                    }
                    const result = await uploadAttachmentMutation.mutateAsync({
                      formData,
                      onUploadProgress: (progressEvent) => {
                        const progress = progressEvent.loaded / (progressEvent.total || 100) * 50;
                        setAttachmentUploadProgress(progress);
                      },
                      onDownloadProgress: (progressEvent) => {
                        const progress = 50 + progressEvent.loaded / (progressEvent.total || 100) * 50;
                        setAttachmentUploadProgress(progress);
                      }
                    });
                    setNextMessageAttachments(result);
                  }
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
                Button,
                {
                  variant: "ghost",
                  className: "px-1",
                  onClick: () => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  },
                  children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.PaperclipIcon, {})
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
                Button,
                {
                  disabled: textInput.trim() == "" && nextMessageAttachments.length == 0,
                  variant: "ghost",
                  className: "px-1",
                  onClick: sendMessage,
                  children: loanAppSendMutation.isLoading ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.Circle, {}) : /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.Send, {})
                }
              )
            ] }),
            nextMessageAttachments.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex flex-col", children: [
              /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("b", { children: "Attachments" }),
              nextMessageAttachments.map((x) => /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex flex-row items-center", children: [
                /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.File, { height: 20 }),
                x.AttachmentName,
                /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
                  Button,
                  {
                    variant: "ghost",
                    className: "ml-auto text-red-300 py-0 px-1 h-[24px]",
                    onClick: async () => {
                      if (!await confirmDialog.openDialogAsync({
                        title: "Remove Attachment",
                        message: "Remove Message Attachment " + x.AttachmentName + "?"
                      })) {
                        return;
                      }
                      setNextMessageAttachments((y) => {
                        const newAttachments = [...y].filter(
                          (attachment) => attachment.AttachmentId != x.AttachmentId
                        );
                        return newAttachments;
                      });
                    },
                    children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_lucide_react7.X, {})
                  }
                )
              ] }))
            ] })
          ] })
        ] })
      ]
    }
  ) });
};
var MessageFormOuter = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(MessageForm, { ...props }) });
};
var messaging_default = MessageFormOuter;

// src/elements/user-invite.tsx
var import_lodash4 = require("lodash");
var import_react13 = require("react");
var import_zod6 = require("zod");
var import_lib7 = require("@cxm/lib");
var import_lib8 = require("@cxm/lib");
var import_lib9 = require("@cxm/lib");
var import_react_phone_input_22 = __toESM(require("react-phone-input-2"));
var import_jsx_runtime28 = require("react/jsx-runtime");
var roles = [
  "Specialist",
  "Borrower",
  "Lender",
  "Delegate",
  "LenderDelegate"
];
var delegateAccessOptions = [
  {
    label: "Read Only",
    value: "ReadOnly",
    description: "User can view profile and application details."
  },
  {
    label: "Editor",
    value: "Editor",
    description: "User can view and edit profile and application details but cannot manage other team members."
  },
  {
    label: "Owner",
    value: "Owner",
    description: "User has full access."
  }
];
var inviteFormModel = import_zod6.z.object({
  email: import_zod6.z.string().email(),
  firstName: import_zod6.z.string().min(1),
  lastName: import_zod6.z.string().min(1),
  phone: import_zod6.z.string().optional(),
  delegateType: import_zod6.z.string().optional(),
  type: import_zod6.z.enum(roles),
  lenderId: import_zod6.z.number().optional(),
  loanApplicationId: import_zod6.z.number().optional()
});
var inviteFormWithRoleModel = inviteFormModel.extend({});
var UserInvite = ({
  initialrole,
  lenderid,
  lendername,
  loanapplicationid,
  onInvite,
  triggerOverride
}) => {
  const [dialogOpen, setDialogOpen] = (0, import_react13.useState)(false);
  const [sentInvite, setSentInvite] = (0, import_react13.useState)();
  const lendersQuery = (0, import_lib7.useApiQuery)("lenders", import_lib8.getLenders, {
    enabled: initialrole == "Lender"
  });
  const inviteMutation = (0, import_lib7.useApiMutation)(import_lib9.inviteUser, [], {});
  const actionText = initialrole && roles.includes(initialrole) ? initialrole == "Delegate" ? "Invite Team Member" : `Invite ${initialrole} User` : `Invite User`;
  const getDescription = () => {
    if (initialrole == "Delegate") {
      return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, { children: "Invite a team member to your company profile. Select access level to control what they can change." });
    }
    return /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(import_jsx_runtime28.Fragment, { children: [
      "Invite a ",
      initialrole ? /* @__PURE__ */ (0, import_jsx_runtime28.jsx)("b", { children: initialrole }) : "user ",
      "to join the plaform. They will receive an email with login instructions."
    ] });
  };
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(
    Dialog,
    {
      open: dialogOpen,
      onOpenChange: (v) => setDialogOpen(v),
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(DialogTrigger, { asChild: true, children: triggerOverride ?? /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
          Button,
          {
            onClick: () => {
              setSentInvite(void 0);
            },
            children: actionText
          }
        ) }),
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
          DialogContent,
          {
            className: "sm:max-w-[425px]",
            onInteractOutside: (e) => {
              e.preventDefault();
            },
            children: sentInvite ? /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(import_jsx_runtime28.Fragment, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(DialogHeader, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(DialogTitle, { children: "Invite Sent" }),
                /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(DialogDescription, { children: [
                  "An invite has been sent to ",
                  sentInvite,
                  "."
                ] })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
                Button,
                {
                  onClick: () => {
                    setDialogOpen(false);
                  },
                  children: "Done"
                }
              )
            ] }) : /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(import_jsx_runtime28.Fragment, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(DialogHeader, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(DialogTitle, { children: actionText }),
                /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(DialogDescription, { children: getDescription() })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
                FormGenerator,
                {
                  config: {
                    fields: {
                      loanApplicationId: {
                        renderField: (x, y, z4) => /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, {})
                      },
                      type: {
                        altDisplay: {
                          type: "select",
                          options: ["Lender", "Specialist"].map((role) => ({
                            label: role,
                            value: role
                          }))
                        },
                        renderField: (field, config, fieldProps) => {
                          return initialrole && roles.includes(initialrole) ? /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, {}) : field;
                        }
                      },
                      lenderId: {
                        renderFieldFromValues: (field, config, values, fieldProps) => {
                          if (lenderid && lendername) {
                            return /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)("div", { children: [
                              "This user will be added to the lender",
                              " ",
                              /* @__PURE__ */ (0, import_jsx_runtime28.jsx)("b", { children: lendername }),
                              "."
                            ] });
                          }
                          return values.type === "Lender" ? /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(import_jsx_runtime28.Fragment, { children: [
                            /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(
                              FormItem,
                              {
                                className: "grid grid-cols-4 items-center gap-4",
                                children: [
                                  /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(FormLabel, { className: "mt-2", children: "Lender" }),
                                  /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
                                    Select,
                                    {
                                      options: lendersQuery.isSuccess ? lendersQuery.data.map((lender) => ({
                                        value: lender.Id,
                                        label: lender.LenderName
                                      })) : lenderid && lendername ? [{
                                        value: lenderid,
                                        label: lendername
                                      }] : [],
                                      placeholder: !lendersQuery.isLoading ? lendersQuery.isSuccess ? "Select Lender..." : "Failed to load lenders." : "Loading lenders...",
                                      isDisabled: !lendersQuery.isSuccess,
                                      value: (0, import_lodash4.isObject)(fieldProps.value) ? fieldProps.value : void 0,
                                      onChange: (e) => {
                                        fieldProps.onChange(e.value);
                                      }
                                    }
                                  ) })
                                ]
                              }
                            ),
                            /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(FormMessage, {})
                          ] }) : /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, {});
                        }
                      },
                      delegateType: {
                        altDisplay: {
                          type: "select",
                          options: delegateAccessOptions
                        },
                        renderField: (field, config, fieldProps) => {
                          return initialrole && initialrole === "Delegate" ? /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(import_jsx_runtime28.Fragment, { children: [
                            field,
                            /* @__PURE__ */ (0, import_jsx_runtime28.jsx)("div", { className: "text-sm text-primary", children: delegateAccessOptions.find(
                              (x) => x.value == fieldProps.value
                            )?.description })
                          ] }) : /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, {});
                        }
                      },
                      phone: {
                        renderField: (field, config, fieldProps) => {
                          return /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(import_jsx_runtime28.Fragment, { children: [
                            /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(
                              FormItem,
                              {
                                className: "grid grid-cols-4 items-center gap-4 mt-2",
                                children: [
                                  /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(FormLabel, { className: "mt-2", children: "Phone" }),
                                  /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
                                    import_react_phone_input_22.default,
                                    {
                                      specialLabel: "",
                                      country: "us",
                                      inputProps: {
                                        className: "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                      },
                                      value: fieldProps.value,
                                      onChange: (e) => {
                                        fieldProps.onChange(e);
                                      }
                                    }
                                  ) })
                                ]
                              }
                            ),
                            /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(FormMessage, {})
                          ] });
                        }
                      }
                    },
                    classNames: {
                      label: "mt-2",
                      field: "grid grid-cols-4 items-center gap-4 mt-2",
                      input: "col-span-3"
                    },
                    submitText: "Send Invite",
                    showRequired: true,
                    defaultValues: initialrole && roles.includes(initialrole) ? {
                      type: initialrole,
                      lenderId: initialrole === "Lender" && lenderid ? parseInt(lenderid, 10) : void 0,
                      delegateType: initialrole === "Delegate" ? "ReadOnly" : void 0,
                      loanApplicationId: initialrole === "Delegate" && loanapplicationid ? parseInt(loanapplicationid, 10) : void 0
                    } : {}
                  },
                  zodForm: inviteFormWithRoleModel,
                  onSubmit: async (values) => {
                    await inviteMutation.mutateAsync(values);
                    setSentInvite(values.email);
                    if (onInvite) {
                      onInvite();
                    }
                  }
                }
              )
            ] })
          }
        )
      ]
    }
  ) });
};
var user_invite_default = UserInvite;

// src/elements/update-profile-dialog.tsx
var import_zod7 = require("zod");
var import_lib10 = require("@cxm/lib");
var import_lib11 = require("@cxm/lib");
var import_react_phone_input_23 = __toESM(require("react-phone-input-2"));
var import_jsx_runtime29 = require("react/jsx-runtime");
var updateProfileModel = import_zod7.z.object({
  firstName: import_zod7.z.string().min(1),
  lastName: import_zod7.z.string().min(1),
  phone: import_zod7.z.string().refine((v) => v.length >= 9, {
    message: "Invalid phone number."
  })
});
var UpdateProfileDialog = ({ dialogOpen, setDialogOpen, isForced, initialProfile }) => {
  const updateProfileMutation = (0, import_lib10.useApiMutation)(import_lib11.updateProfile, ["me"], {
    success: () => {
      setDialogOpen(false);
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_jsx_runtime29.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Dialog, { open: dialogOpen, onOpenChange: () => {
  }, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(DialogContent, { className: "sm:max-w-[425px]", children: [
    /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(DialogHeader, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(DialogTitle, { children: "Update your profile" }),
      /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(DialogDescription, { children: isForced ? "Fill in a few missing items from your profile. Click update when you're done." : "" })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
      FormGenerator,
      {
        config: {
          fields: {
            phone: {
              renderField: (_, config, fieldProps) => {
                return /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_jsx_runtime29.Fragment, { children: [
                  /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(
                    FormItem,
                    {
                      className: "grid grid-cols-4 items-center gap-4 mt-2",
                      children: [
                        /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(FormLabel, { className: "", children: "Phone" }),
                        /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(FormControl, { className: "col-span-3", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                          import_react_phone_input_23.default,
                          {
                            specialLabel: "",
                            country: "us",
                            inputProps: {
                              className: "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            },
                            value: fieldProps.value,
                            onChange: (e) => {
                              fieldProps.onChange(e);
                            }
                          }
                        ) })
                      ]
                    }
                  ),
                  /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(FormMessage, {})
                ] });
              }
            }
          },
          classNames: {
            label: "mt-2",
            field: "grid grid-cols-4 items-center gap-4",
            input: "mt-2 col-span-3"
          },
          submitText: "Update",
          defaultValues: {
            firstName: initialProfile?.Firstname ?? "",
            lastName: initialProfile?.Lastname ?? "",
            phone: initialProfile?.PhoneNumber ?? "+1"
          }
        },
        zodForm: updateProfileModel,
        onSubmit: async (values) => {
          await updateProfileMutation.mutateAsync(values);
        }
      }
    )
  ] }) }) });
};
var update_profile_dialog_default = UpdateProfileDialog;

// src/elements/profile-display.tsx
var import_lib12 = require("@cxm/lib");
var import_jsx_runtime30 = require("react/jsx-runtime");
var ProfileDisplay = (props) => {
  const meQuery = (0, import_lib12.useApiQuery)("me", import_lib12.getSelf);
  if (!meQuery.isSuccess) {
    return /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_jsx_runtime30.Fragment, {});
  }
  if (props.portal == "Lender") {
    return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)("div", { children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)("div", { children: [
        "Hello, ",
        meQuery.data.Firstname,
        "!"
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("br", {}),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { children: meQuery.data.ActiveLenderName ?? "No Active Lender" })
    ] });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)("div", { children: [
    "Hello, ",
    meQuery.data.Firstname,
    "!"
  ] });
};
var profile_display_default = ProfileDisplay;

// src/elements/borrower-onboarder.tsx
var import_lib14 = require("@cxm/lib");
var import_react15 = require("react");

// src/elements/borrower-terms.tsx
var import_react14 = require("react");
var import_TextLayer = require("react-pdf/dist/Page/TextLayer.css");
var import_AnnotationLayer = require("react-pdf/dist/Page/AnnotationLayer.css");
var import_lib13 = require("@cxm/lib");
var import_jsx_runtime31 = require("react/jsx-runtime");
var BorrowerTerms = ({ borrowerId }) => {
  const [termsContent, setTermsContent] = (0, import_react14.useState)();
  const [open, setOpen] = (0, import_react14.useState)(true);
  const [scrollFinished, setScrollFinished] = (0, import_react14.useState)(true);
  (0, import_react14.useEffect)(() => {
    fetch("https://cxmloans.com/content/BorrowerAgreement.html").then((x) => {
      console.log(x);
      return x.text();
    }).then((v) => setTermsContent(v));
  }, []);
  const acceptTermsQuery = (0, import_lib13.useApiMutation)(import_lib13.acceptTerms, ["me"]);
  const confirm = useConfirmDialogContext();
  const handleScroll = (e) => {
    const bottom = Math.abs(
      e.target.scrollTop
    ) > 700;
    if (bottom) {
      setScrollFinished(true);
    }
  };
  const iframeRef = (0, import_react14.useRef)(null);
  if (!termsContent)
    return /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_jsx_runtime31.Fragment, {});
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(Dialog, { open, onOpenChange: (v) => {
  }, children: /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)(DialogContent, { className: "flex flex-col h-[500px] max-h-[100vh] max-w-[90vw] w-[700px]", children: [
    /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(DialogHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("b", { children: "Borrower Terms and Conditions" }) }) }),
    /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("div", { className: "flex flex-grow border border-gray-300 overflow-y-auto", children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
      "iframe",
      {
        ref: iframeRef,
        className: "w-full",
        src: "https://cxmloans.com/content/BorrowerAgreement.html"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)("div", { className: "flex flex-row space-x-4 justify-end", children: [
      /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
        Button,
        {
          disabled: !scrollFinished || acceptTermsQuery.isLoading,
          onClick: async () => {
            await acceptTermsQuery.mutateAsync({ borrowerId });
            setOpen(false);
          },
          children: acceptTermsQuery.isLoading ? "Loading..." : "Accept"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
        Button,
        {
          variant: "secondary",
          onClick: async () => {
            var response = await confirm.openDialogAsync({
              title: "Decline Terms",
              message: "Use of CXM Loans requires accepting our terms and conditions. Are you sure you want to decline? You will be signed out."
            });
            if (response) {
              window.location.pathname = "/Account/SignOut";
            }
          },
          children: "Decline"
        }
      )
    ] })
  ] }) });
};
var borrower_terms_default = BorrowerTerms;

// src/elements/borrower-onboarder.tsx
var import_jsx_runtime32 = require("react/jsx-runtime");
var BorrowerOnboarder = () => {
  var me = (0, import_lib14.useApiQuery)("me", import_lib14.getSelf);
  var onboard = (0, import_lib14.useApiMutation)(import_lib14.onboardBorrower);
  const [borrowerId, setBorrowerId] = (0, import_react15.useState)();
  const [signedTerms, setSignedTerms] = (0, import_react15.useState)(true);
  (0, import_react15.useEffect)(() => {
    if (!me.isSuccess || !me.data) {
      return;
    }
    const borrowers = me.data.Borrowers;
    if (borrowers.length == 0) {
      (async () => {
        const result = await onboard.mutateAsync({});
        setSignedTerms(result.TermsAccepted);
        window.location.reload();
        if (window.location.pathname !== "/Profile") {
          window.location.pathname = "/Profile";
        }
      })();
    } else {
      var activeBorrower = me.data.ActiveBorrowerId;
      var borrower = borrowers.find(
        (x) => x.Id == activeBorrower
      );
      if (borrower) {
        setSignedTerms(borrower.TermsAccepted);
        setBorrowerId(borrower.Id);
      }
    }
  }, [me.isSuccess, me.data]);
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_jsx_runtime32.Fragment, { children: me.isSuccess && borrowerId != void 0 && !signedTerms && /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(borrower_terms_default, { borrowerId }) }) });
};
var borrower_onboarder_default = BorrowerOnboarder;

// src/elements/attest-terms.tsx
var import_react_pdf = require("react-pdf");
var import_react16 = require("react");
var import_jsx_runtime33 = require("react/jsx-runtime");
var AttestTerms = () => {
  const [pdfFile, setPdfFile] = (0, import_react16.useState)();
  (0, import_react16.useEffect)(() => {
    import_react_pdf.pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${import_react_pdf.pdfjs.version}/pdf.worker.js`;
    fetch("https://cxmloans.com/content/BorrowerAgreement.pdf").then(
      (x) => x.blob()
    ).then((v) => setPdfFile(new File([v], "terms.pdf")));
  });
  if (!pdfFile)
    return /* @__PURE__ */ (0, import_jsx_runtime33.jsx)("div", { children: "Loading..." });
  return /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(Dialog, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(DialogTrigger, { children: /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(Button, { children: "View Terms" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(DialogContent, { children: [
      "Test model",
      /* @__PURE__ */ (0, import_jsx_runtime33.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_react_pdf.Document, { file: pdfFile }) })
    ] })
  ] });
};
var attest_terms_default = AttestTerms;

// src/elements/injected-target-box.tsx
var import_lib15 = require("@cxm/lib");
var import_jsx_runtime34 = require("react/jsx-runtime");
var InjectedTargetBox = (props) => {
  const loanEvaluation = (0, import_lib15.useApiQuery)(
    "evaluation-" + props.loanappid,
    (0, import_lib15.getApiEvaluation)(props.portaltype, props.loanappid)
  );
  console.log(loanEvaluation);
  if (loanEvaluation.isLoading) {
    return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_jsx_runtime34.Fragment, {});
  }
  if (!loanEvaluation.isSuccess) {
    return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_jsx_runtime34.Fragment, {});
  }
  console.log("target box all good");
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(target_box_default, { results: loanEvaluation.data });
};
var injected_target_box_default = InjectedTargetBox;

// src/elements/loan-applications.tsx
var import_lib17 = require("@cxm/lib");
var import_react18 = require("react");

// src/components/loan-table.tsx
var import_react_table2 = require("@tanstack/react-table");
var import_react17 = require("react");

// src/components/dropdown-menu.tsx
var React17 = __toESM(require("react"));
var DropdownMenuPrimitive = __toESM(require("@radix-ui/react-dropdown-menu"));
var import_lucide_react8 = require("lucide-react");
var import_jsx_runtime35 = require("react/jsx-runtime");
var DropdownMenu = DropdownMenuPrimitive.Root;
var DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
var DropdownMenuSubTrigger = React17.forwardRef(({ className, inset, children, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(
  DropdownMenuPrimitive.SubTrigger,
  {
    ref,
    className: cn(
      "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent",
      inset && "pl-8",
      className
    ),
    ...props,
    children: [
      children,
      /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(import_lucide_react8.ChevronRight, { className: "ml-auto h-4 w-4" })
    ]
  }
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
var DropdownMenuSubContent = React17.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
  DropdownMenuPrimitive.SubContent,
  {
    ref,
    className: cn(
      "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
var DropdownMenuContent = React17.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(DropdownMenuPrimitive.Portal, { children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
  DropdownMenuPrimitive.Content,
  {
    ref,
    sideOffset,
    className: cn(
      "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
) }));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
var DropdownMenuItem = React17.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
  DropdownMenuPrimitive.Item,
  {
    ref,
    className: cn(
      "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      inset && "pl-8",
      className
    ),
    ...props
  }
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
var DropdownMenuCheckboxItem = React17.forwardRef(({ className, children, checked, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(
  DropdownMenuPrimitive.CheckboxItem,
  {
    ref,
    className: cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    ),
    checked,
    ...props,
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime35.jsx)("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(DropdownMenuPrimitive.ItemIndicator, { children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(import_lucide_react8.Check, { className: "h-4 w-4" }) }) }),
      children
    ]
  }
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
var DropdownMenuRadioItem = React17.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(
  DropdownMenuPrimitive.RadioItem,
  {
    ref,
    className: cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    ),
    ...props,
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime35.jsx)("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(DropdownMenuPrimitive.ItemIndicator, { children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(import_lucide_react8.Circle, { className: "h-2 w-2 fill-current" }) }) }),
      children
    ]
  }
));
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
var DropdownMenuLabel = React17.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
  DropdownMenuPrimitive.Label,
  {
    ref,
    className: cn(
      "px-2 py-1.5 text-sm font-semibold",
      inset && "pl-8",
      className
    ),
    ...props
  }
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
var DropdownMenuSeparator = React17.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
  DropdownMenuPrimitive.Separator,
  {
    ref,
    className: cn("-mx-1 my-1 h-px bg-muted", className),
    ...props
  }
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
var DropdownMenuShortcut = ({
  className,
  ...props
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
    "span",
    {
      className: cn("ml-auto text-xs tracking-widest opacity-60", className),
      ...props
    }
  );
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

// src/components/loan-table.tsx
var import_jsx_runtime36 = require("react/jsx-runtime");
var LoanTable = ({
  loans,
  loading,
  loanStatusOptions,
  tableConfig,
  noLoansMessage,
  initialSortingState,
  highlighted
}) => {
  const [sorting, setSorting] = (0, import_react17.useState)(initialSortingState);
  const [columnFilters, setColumnFilters] = (0, import_react17.useState)(
    () => {
      try {
        return JSON.parse(
          localStorage.getItem("specialist-table-column-filters") ?? "[]"
        );
      } catch (e) {
        return [];
      }
    }
  );
  const [columnVisibility, setColumnVisibility] = (0, import_react17.useState)(
    () => {
      try {
        return JSON.parse(
          localStorage.getItem("specialist-table-column-visibility") ?? "{}"
        );
      } catch (e) {
        return {};
      }
    }
  );
  (0, import_react17.useEffect)(() => {
    localStorage.setItem(
      "specialist-table-column-filters",
      JSON.stringify(columnFilters)
    );
  }, [columnFilters]);
  (0, import_react17.useEffect)(() => {
    localStorage.setItem(
      "specialist-table-column-visibility",
      JSON.stringify(columnVisibility)
    );
  }, [columnVisibility]);
  const table = (0, import_react_table2.useReactTable)({
    data: loans ?? [],
    state: {
      sorting,
      columnFilters,
      columnVisibility
    },
    onSortingChange: setSorting,
    columns: tableConfig,
    getCoreRowModel: (0, import_react_table2.getCoreRowModel)(),
    getSortedRowModel: (0, import_react_table2.getSortedRowModel)(),
    getFilteredRowModel: (0, import_react_table2.getFilteredRowModel)(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility
  });
  const fullLoanStatusOptions = [
    {
      "label": "All",
      "value": "All"
    },
    ...loanStatusOptions ?? []
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)("div", { className: "w-full max-w-full h-full flex flex-col space-y-1", children: [
    /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)("div", { className: "flex flex-row items-center mt-4", children: [
      /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)("div", { className: "flex flex-row items-center space-x-2", children: [
        loanStatusOptions && /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)("div", { className: "relative", children: [
          /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
            Select,
            {
              value: fullLoanStatusOptions.find(
                (x) => x.value == (columnFilters.find((x2) => x2.id == "PublicStatus")?.value ?? "All")
              ),
              options: fullLoanStatusOptions,
              className: "w-[200px]",
              onChange: (v) => {
                const column = table.getColumn("PublicStatus");
                const value = v.value;
                if (value == "All") {
                  column?.setFilterValue(void 0);
                } else {
                  column?.setFilterValue(v.value);
                }
              }
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime36.jsx)("div", { className: "absolute -top-5 text-sm text-muted-foreground", children: "Status" })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(DropdownMenu, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(DropdownMenuTrigger, { asChild: true }),
          /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(DropdownMenuContent, { align: "end", children: table.getAllColumns().filter((column) => column.getCanHide()).map((column) => {
            return /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
              DropdownMenuCheckboxItem,
              {
                className: "capitalize",
                checked: column.getIsVisible(),
                onCheckedChange: (value) => column.toggleVisibility(!!value),
                children: column.id
              },
              column.id
            );
          }) })
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
        Input,
        {
          className: "w-1/3 h-[42px] ml-auto",
          placeholder: "Search",
          onChange: (e) => table.setGlobalFilter(e.target.value)
        }
      )
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime36.jsx)("div", { className: "w-full rounded-md border overflow-auto h-[420px] min-h-0", children: /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(Table, { parentClassName: "min-h-0", className: "", children: [
      /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(TableHeader, { className: "sticky top-0 min-h-0 border-b-black border-b shadow-gray-300 shadow-sm", children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(TableRow, { className: "", children: headerGroup.headers.map((header) => /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
        TableCell,
        {
          className: "px-1 bg-white whitespace-nowrap text-center font-medium",
          children: header.isPlaceholder ? null : (0, import_react_table2.flexRender)(
            header.column.columnDef.header,
            header.getContext()
          )
        },
        header.id
      )) }, headerGroup.id)) }),
      loading ? /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(TableBody, { className: "text-center" }) : /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(TableBody, { className: "justify-start overflow-y-auto", children: table.getRowModel().rows.length > 0 ? table.getRowModel().rows.map((row) => {
        const rowHighlighted = highlighted && highlighted[row.index];
        return /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
          TableRow,
          {
            className: "h-[40px] cursor-pointer" + (rowHighlighted ? " bg-orange-100 border-orange-200 hover:bg-orange-50" : " hover:bg-gray-200"),
            onClick: () => {
              window.location.href = "/Loan/Details?applicationId=" + row.original.Id;
            },
            children: row.getVisibleCells().map((cell) => /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
              TableCell,
              {
                className: "text-center whitespace-nowrap",
                style: {
                  paddingLeft: "0.5rem !important",
                  paddingRight: "0.5rem !important"
                },
                children: (0, import_react_table2.flexRender)(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )
              },
              cell.id
            ))
          },
          row.id
        );
      }) : /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
        TableRow,
        {
          className: "hover:bg-gray-200 h-[60px] w-full bg-gray-100",
          children: /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
            TableCell,
            {
              colSpan: table.getAllColumns().length,
              className: "text-center col-span-10",
              style: {
                paddingLeft: "0.5rem !important",
                paddingRight: "0.5rem !important"
              },
              children: noLoansMessage ?? "No Loans"
            }
          )
        }
      ) })
    ] }) })
  ] });
};
var loan_table_default = LoanTable;

// src/elements/create-loanapp-dialog.tsx
var import_lib16 = require("@cxm/lib");
var import_jsx_runtime37 = require("react/jsx-runtime");
var CreateLoanAppDialog = ({ borrowerId, onCreateLoanApp }) => {
  const createLoanMutation = (0, import_lib16.useApiMutation)(import_lib16.createLoanApplication, [
    "loanApps",
    "me"
  ]);
  const confirmDialog = useConfirmDialogContext();
  return /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(import_jsx_runtime37.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
    Button,
    {
      variant: "outline",
      disabled: createLoanMutation.isLoading,
      onClick: async () => {
        const result = await confirmDialog.openDialogAsync({
          title: "Create Loan Application",
          message: "Create a new loan application?"
        });
        if (!result)
          return;
        const createdLoanApp = await createLoanMutation.mutateAsync({
          borrowerId,
          application: {
            LoanAmount: 1e6,
            LoanUse: 1,
            PersonalGuarantee: true
          }
        });
        onCreateLoanApp(createdLoanApp);
      },
      children: "New Loan Application"
    }
  ) });
};
var create_loanapp_dialog_default = CreateLoanAppDialog;

// src/elements/loan-applications.tsx
var import_jsx_runtime38 = require("react/jsx-runtime");
var loanAppTableConfig = [
  {
    accessorKey: "SubmittedAt",
    header: SortedHeader("Submitted"),
    cell: (v) => new Date(v.getValue()).toLocaleString("en", {
      month: "numeric",
      year: "numeric",
      day: "2-digit"
    })
  },
  {
    accessorKey: "CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "PublicStatus",
    accessorFn: (v) => (0, import_lib17.getStatusMessageString)(
      (0, import_lib17.getLoanStatusMessage)(v, import_lib17.PortalType.Borrower).message
    ),
    header: SortedHeader("Status"),
    enableColumnFilter: true,
    filterFn: "weakEquals"
  },
  {
    accessorKey: "LoanAmount",
    header: SortedHeader("Loan Amount"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "LoanType",
    header: SortedHeader("Loan Type"),
    cell: (v) => import_lib17.loanTypes.find((x) => x.id == v.getValue())?.fullName
  },
  {
    accessorKey: "SpecialistName",
    header: SortedHeader("Specialist"),
    cell: (v) => v.getValue() ?? "None Assigned"
  }
];
var LoanApplicationsTable = () => {
  const [borrowerId, setBorrowerId] = (0, import_react18.useState)();
  (0, import_lib17.useApiQuery)("me", import_lib17.getSelf, {
    success: (v) => {
      setBorrowerId(v.ActiveBorrowerId);
    }
  });
  (0, import_lib17.useApiQuery)(
    "loanApps",
    (0, import_lib17.getLoanApplications)(borrowerId || ""),
    {
      enabled: borrowerId != void 0,
      success: (v) => {
        setLoans(v);
      }
    }
  );
  const [loans, setLoans] = (0, import_react18.useState)([]);
  return /* @__PURE__ */ (0, import_jsx_runtime38.jsxs)("div", { className: "p-2", children: [
    /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(
      loan_table_default,
      {
        initialSortingState: [{
          id: "SubmittedAt",
          desc: true
        }],
        tableConfig: loanAppTableConfig,
        loanStatusOptions: import_lib17.lenderLoanStatusOptions,
        loans,
        loading: !loans
      }
    ),
    borrowerId && /* @__PURE__ */ (0, import_jsx_runtime38.jsx)("div", { className: "w-[200px] mt-4", children: /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(
      create_loanapp_dialog_default,
      {
        borrowerId,
        onCreateLoanApp: () => {
          window.location.pathname = "/Profile";
        }
      }
    ) })
  ] });
};
var LoanApplicationsTableOuter = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(LoanApplicationsTable, {}) });
};
var loan_applications_default = LoanApplicationsTableOuter;

// src/elements/term-sheet-table.tsx
var import_lib18 = require("@cxm/lib");
var import_react19 = require("react");
var import_react_table3 = require("@tanstack/react-table");
var import_lucide_react9 = require("lucide-react");
var import_jsx_runtime39 = require("react/jsx-runtime");
var TermSheetTableConfig = (portalType) => [
  {
    accessorKey: "LenderName",
    header: SortedHeader("Lender Name")
  },
  {
    accessorKey: "Status",
    header: SortedHeader("Status")
  },
  {
    accessorKey: "LoanAmount",
    header: SortedHeader("Amount"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "LoanType",
    header: SortedHeader("Type"),
    cell: (v) => import_lib18.loanTypes.find((x) => x.id == v.getValue())?.name
  },
  {
    accessorKey: "Id",
    header: "Offer Package",
    cell: (v) => {
      return /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
        "a",
        {
          href: process.env.REACT_APP_BASE_API_URL + "/api/offers/DownloadOfferPackage?offerId=" + v.getValue(),
          children: /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(Button, { variant: "outline", className: "h-[24px] px-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(import_lucide_react9.Download, { height: 12, width: 12, className: "mr-1" }),
            "Download"
          ] })
        }
      );
    }
  },
  {
    accessorKey: "Rate",
    header: SortedHeader("Interest Rate"),
    cell: (v) => formatPercent((0, import_lib18.roundToPlaces)(v.getValue(), 2))
  },
  {
    accessorKey: "Maturity",
    header: SortedHeader("Maturity"),
    cell: (v) => v.getValue() + " months"
  },
  {
    accessorKey: "Amortization",
    cell: (v) => v.getValue() + " months",
    header: SortedHeader("Amortization")
  },
  {
    accessorKey: "MonthlyPayment",
    header: SortedHeader("Est. Payment"),
    cell: (v) => currency(v.getValue()) + "/mo"
  },
  {
    accessorKey: "TotalUpfrontCashFees",
    header: SortedHeader("Initial Fees"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "TotalCashFees",
    header: SortedHeader("Total Fees"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "ApplicationFees",
    header: SortedHeader("Application Fees"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "TotalPercentageFees",
    header: SortedHeader("Total Fees (%)"),
    cell: (v) => formatPercent(v.getValue() * 100)
  },
  {
    accessorKey: "BackEndFees",
    header: SortedHeader("Back End Fees"),
    cell: (v) => formatPercent(v.getValue() * 100)
  },
  {
    accessorKey: "EarlyTerminationFees",
    header: SortedHeader("Early Termination Fees"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "Collaterals",
    header: SortedHeader("Collateral Count"),
    cell: (v) => v.getValue().length
  },
  {
    accessorKey: "Covenants",
    header: SortedHeader("Covenant Count"),
    cell: (v) => v.getValue().length
  },
  {
    accessorKey: "PersonalGuarantee",
    cell: (v) => v.getValue() ? "Yes" : "No",
    header: SortedHeader("Guarantee Required")
  },
  {
    accessorKey: "TargetCloseDate",
    header: SortedHeader("Target Close Date"),
    cell: (v) => new Date(v.getValue()).toLocaleString("en", {
      day: "numeric",
      month: "numeric",
      year: "numeric"
    })
  }
];
var TermSheetTable = (props) => {
  const [open, setOpen] = (0, import_react19.useState)(false);
  const [offerId, setOfferId] = (0, import_react19.useState)();
  const returnOffersMutation = (0, import_lib18.useApiMutation)(import_lib18.returnOffersToLenders, [
    "termSheets-" + props.loanappid,
    "reviewTermSheets-" + props.loanappid,
    "acceptedTermSheets-" + props.loanappid
  ]);
  const approveOffersMutation = (0, import_lib18.useApiMutation)(import_lib18.approveOffers, [
    "termSheets-" + props.loanappid,
    "reviewTermSheets-" + props.loanappid,
    "acceptedTermSheets-" + props.loanappid
  ]);
  const [rowSelection, setRowSelection] = (0, import_react19.useState)(
    {}
  );
  const maxEntriesBeforeSearch = 5;
  const [sorting, setSorting] = (0, import_react19.useState)([]);
  const table = (0, import_react_table3.useReactTable)({
    data: props.termSheets ?? [],
    columns: props.portaltype == import_lib18.PortalType.Management ? [
      {
        id: "select",
        header: ({ table: table2 }) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
          IndeterminateCheckbox,
          {
            ...{
              checked: table2.getIsAllRowsSelected(),
              indeterminate: table2.getIsSomeRowsSelected(),
              onChange: table2.getToggleAllRowsSelectedHandler()
            }
          }
        ),
        cell: ({ row }) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)("div", { className: "px-1", children: row.original.OfferStatus == import_lib18.OfferStatus.SubmittedByLender && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
          IndeterminateCheckbox,
          {
            ...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler()
            }
          }
        ) })
      },
      ...TermSheetTableConfig(props.portaltype)
    ] : TermSheetTableConfig(props.portaltype),
    state: {
      sorting,
      rowSelection
    },
    onSortingChange: setSorting,
    getSortedRowModel: (0, import_react_table3.getSortedRowModel)(),
    getCoreRowModel: (0, import_react_table3.getCoreRowModel)(),
    getFilteredRowModel: (0, import_react_table3.getFilteredRowModel)(),
    enableRowSelection: props.portaltype == import_lib18.PortalType.Management,
    //enable row selection for all rows
    onRowSelectionChange: setRowSelection
  });
  const confirmDialog = useConfirmDialogContext();
  return /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(import_jsx_runtime39.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)("div", { className: "w-full max-w-full p-1 h-full flex flex-col space-y-1", children: [
      /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)("div", { className: "w-full flex flex-row space-x-2", children: [
        maxEntriesBeforeSearch && props.termSheets && props.termSheets?.length > maxEntriesBeforeSearch && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
          Input,
          {
            className: "w-1/3 h-[42px]",
            placeholder: "Search",
            onChange: (e) => table.setGlobalFilter(e.target.value)
          }
        ),
        props.portaltype == import_lib18.PortalType.Management && /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(import_jsx_runtime39.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
            Button,
            {
              variant: "outline",
              onClick: async () => {
                const result = await confirmDialog.openDialogAsync({
                  title: "Submit Offers To Borrower",
                  message: `This will make ${Object.keys(rowSelection).length} offer(s) available to the borrower.`
                });
                if (result && props.termSheets != void 0) {
                  approveOffersMutation.mutateAsync(
                    Object.keys(rowSelection).filter(
                      (x) => (props.termSheets ?? [])[parseInt(x)]?.OfferStatus == import_lib18.OfferStatus.SubmittedByLender
                    ).map((x) => (props.termSheets ?? [])[parseInt(x)]?.Id)
                  );
                }
              },
              disabled: Object.keys(rowSelection).length == 0,
              children: "Submit To Borrower"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
            Button,
            {
              variant: "outline",
              disabled: Object.keys(rowSelection).length == 0,
              onClick: async () => {
                const result = await confirmDialog.openDialogAsync({
                  title: "Return Offers to Lender",
                  message: `This will return ${Object.keys(rowSelection).length} offer(s) to each lender. They will need to resubmit before it can be approved.`
                });
                if (result && props.termSheets) {
                  returnOffersMutation.mutateAsync(
                    Object.keys(rowSelection).filter(
                      (x) => (props.termSheets ?? [])[parseInt(x)]?.OfferStatus == import_lib18.OfferStatus.SubmittedByLender
                    ).map((x) => (props.termSheets ?? [])[parseInt(x)]?.Id)
                  );
                }
              },
              children: "Return To Lenders"
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime39.jsx)("h1", { style: { fontSize: "18px" }, children: /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)("b", { children: [
        "Click on an offer below to view details",
        props.portaltype == import_lib18.PortalType.Borrower ? " and accept or decline" : "",
        "."
      ] }) }),
      /* @__PURE__ */ (0, import_jsx_runtime39.jsx)("div", { className: "h-full w-full overflow-auto flex-grow rounded-md border relative", children: /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(Table, { parentClassName: "", className: "", children: [
        /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(TableHeader, { className: "", children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(TableRow, { className: "", children: headerGroup.headers.map((header) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
          TableCell,
          {
            className: "px-1 bg-white whitespace-nowrap text-center",
            children: header.isPlaceholder ? null : (0, import_react_table3.flexRender)(
              header.column.columnDef.header,
              header.getContext()
            )
          },
          header.id
        )) }, headerGroup.id)) }),
        !props.termSheets ? /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(TableBody, { className: "text-center", children: /* @__PURE__ */ (0, import_jsx_runtime39.jsx)("div", { className: "absolute w-full h-full text-center loading-animation" }) }) : /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(TableBody, { className: "justify-start", children: table.getRowModel().rows.map((row) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
          TableRow,
          {
            className: "hover:bg-gray-200 h-[30px] cursor-pointer text-sm" + (props.portaltype == import_lib18.PortalType.Management && row.original.OfferStatus == import_lib18.OfferStatus.SubmittedByLender ? " bg-orange-200 hover:bg-orange-100" : ""),
            children: row.getVisibleCells().map((cell) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
              TableCell,
              {
                className: "text-center whitespace-nowrap",
                style: {
                  paddingLeft: "0.5rem !important",
                  paddingRight: "0.5rem !important"
                },
                onClick: () => {
                  if (cell.column.id == "select") {
                    return;
                  }
                  setOfferId(row.original.Id);
                  setOpen(true);
                },
                children: (0, import_react_table3.flexRender)(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )
              },
              cell.id
            ))
          },
          row.id
        )) })
      ] }) })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(Dialog, { open, onOpenChange: (v) => setOpen(v), children: /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(DialogContent, { className: "w-auto max-w-screen", children: offerId && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
      offer_detail_default,
      {
        offerid: offerId,
        portaltype: props.portaltype
      }
    ) }) })
  ] });
};
var IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  ...rest
}) => {
  const ref = (0, import_react19.useRef)(null);
  (0, import_react19.useEffect)(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);
  return /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
    "input",
    {
      type: "checkbox",
      ref,
      className: className + " cursor-pointer",
      ...rest
    }
  );
};
var term_sheet_table_default = TermSheetTable;

// src/elements/loan-application-view.tsx
var import_lib21 = require("@cxm/lib");
var import_react20 = require("react");

// src/elements/progress-bar.tsx
var import_lib19 = require("@cxm/lib");
var import_lucide_react10 = require("lucide-react");
var import_jsx_runtime40 = require("react/jsx-runtime");
var AddLenderButton = ({ loanApp }) => {
  const unMatchedLenderQuery = (0, import_lib19.useApiQuery)(
    "unmatchedLenders",
    (0, import_lib19.getUnmatchedLenders)(loanApp.Id)
  );
  const assignLenderMutation = (0, import_lib19.useApiMutation)(import_lib19.assignAdditionalLender, [
    "unmatchedLenders"
  ]);
  if (![
    import_lib19.LoanApplicationStatus.OfferAccepted,
    import_lib19.LoanApplicationStatus.ReadyForBts,
    import_lib19.LoanApplicationStatus.BtsSubmitted,
    import_lib19.LoanApplicationStatus.BtsSignedByBorrower,
    import_lib19.LoanApplicationStatus.SignedBtsDenied,
    import_lib19.LoanApplicationStatus.SignedBtsApproved,
    import_lib19.LoanApplicationStatus.SpecialistBtsReview,
    import_lib19.LoanApplicationStatus.ClosingDocumentsSubmitted,
    import_lib19.LoanApplicationStatus.ClosingDocumentsSigned,
    import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved,
    import_lib19.LoanApplicationStatus.Closed,
    import_lib19.LoanApplicationStatus.Rejected,
    import_lib19.LoanApplicationStatus.Cancelled
  ].includes(loanApp.Status)) {
    return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_jsx_runtime40.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)(Dialog, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(DialogContent, { children: /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)("div", { className: "flex flex-col", children: [
        /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("h2", { children: "Add Lender" }),
        /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { className: "border border-gray-300 min-w-[300px] min-h-[20px] max-h-[300px] overflow-y-auto p-2", children: unMatchedLenderQuery.isSuccess ? /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { className: "flex flex-col space-y-1", children: unMatchedLenderQuery.data.length > 0 ? unMatchedLenderQuery.data.map((lender) => /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)("div", { className: "flex flex-row space-x-2 items-center", children: [
          /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: lender.LenderName }),
          /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
            Button,
            {
              className: "ml-auto",
              onClick: async () => {
                await assignLenderMutation.mutateAsync({
                  loanAppId: loanApp.Id,
                  lenderId: lender.Id
                });
              },
              children: "Add"
            }
          )
        ] })) : /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: "No Unmatched Lenders." }) }) : /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: "Loading..." }) })
      ] }) }),
      /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(DialogTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(Button, { className: "w-[120px] mt-4", children: "Add Lender" }) })
    ] }) });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_jsx_runtime40.Fragment, {});
};
var ProgressBar = ({ portaltype, loanappid }) => {
  const loanAppQuery = (0, import_lib19.useApiQuery)(
    "loanApp-" + loanappid,
    (0, import_lib19.getLoanApplication)(portaltype, loanappid)
  );
  const updateStatusMutation = (0, import_lib19.useApiMutation)(import_lib19.setApplicationStatus, [
    "loanApp-" + loanappid
  ]);
  const progressEntries = [
    "Business Metrics",
    "Loan Package",
    "Applied",
    "In Review",
    "Submitted",
    "Offer Selected",
    "Complete"
  ];
  const confirmDialog = useConfirmDialogContext();
  if (!loanAppQuery.isSuccess) {
    return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: "Loading" });
  }
  const loanApp = loanAppQuery.data;
  let displayProgress = Math.max(34, loanApp.ProgressBarValue);
  const statusMessage = (0, import_lib19.getLoanStatusMessage)(loanApp, portaltype);
  if (statusMessage.category == "Declined") {
    displayProgress = 71;
  }
  if (statusMessage.category == "Missed" || statusMessage.category == "Not Pursued") {
    displayProgress = 75;
  }
  const progressEntry = (title, idx, total) => {
    return /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)(
      "li",
      {
        className: "w-[2px] h-[40px] text-center z-10 relative rounded-none",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
            "div",
            {
              className: "w-full h-full border-gray-100 border-2 text-center z-10 relative rounded-none",
              style: { transform: "skewX(-10deg)" }
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
            "div",
            {
              style: { transform: "translate(-50%, 0)" },
              className: "text-sm font-medium left-[50%] -top-7 absolute whitespace-nowrap",
              children: /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("span", { children: title })
            }
          )
        ]
      },
      idx
    );
  };
  const sectionCompleteStatuses02 = [
    import_lib19.LoanApplicationStatus.LenderBorrowerCallComplete,
    import_lib19.LoanApplicationStatus.LenderRequestedInformation,
    import_lib19.LoanApplicationStatus.ReadyForBts,
    import_lib19.LoanApplicationStatus.SpecialistBtsReview,
    import_lib19.LoanApplicationStatus.OfferAccepted,
    import_lib19.LoanApplicationStatus.SpecialistBtsReview,
    import_lib19.LoanApplicationStatus.BtsSubmitted,
    import_lib19.LoanApplicationStatus.BtsSignedByBorrower,
    import_lib19.LoanApplicationStatus.SignedBtsDenied,
    import_lib19.LoanApplicationStatus.SignedBtsApproved,
    import_lib19.LoanApplicationStatus.ClosingDocumentsSubmitted,
    import_lib19.LoanApplicationStatus.ClosingDocumentsSigned,
    import_lib19.LoanApplicationStatus.SignedClosingDocumentsDenied,
    import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved,
    import_lib19.LoanApplicationStatus.Closed
  ];
  var sectionCompleteStatuses01 = [
    ...sectionCompleteStatuses02,
    import_lib19.LoanApplicationStatus.SpecialistApproved,
    import_lib19.LoanApplicationStatus.SpecialistDenied,
    import_lib19.LoanApplicationStatus.MoreInformationRequiredBySpecialist,
    import_lib19.LoanApplicationStatus.LendersReview,
    import_lib19.LoanApplicationStatus.LendersQna,
    import_lib19.LoanApplicationStatus.SpecialistOffersReview,
    import_lib19.LoanApplicationStatus.OffersSubmitted
  ];
  const sectionComplete01 = sectionCompleteStatuses01.includes(loanApp.Status);
  const sectionComplete02 = sectionCompleteStatuses02.includes(loanApp.Status);
  const sectionComplete03 = loanApp.Status == import_lib19.LoanApplicationStatus.Closed;
  console.log("status message", statusMessage);
  const checklist = [
    {
      title: "Introductory Call with Loan Specialist",
      isChecked: loanApp.IntroductoryCallComplete && (loanApp.Status == import_lib19.LoanApplicationStatus.DiligenceInProgress || loanApp.Status == import_lib19.LoanApplicationStatus.SpecialistReview || loanApp.Status == import_lib19.LoanApplicationStatus.DiligenceComplete || sectionComplete01),
      checkStatus: import_lib19.LoanApplicationStatus.IntroductoryCallComplete
    },
    {
      title: "Application and Borrower Review",
      isChecked: loanApp.IntroductoryCallComplete && (loanApp.DiligenceStatus == import_lib19.DiligenceStatus.Received || loanApp.DiligenceStatus == import_lib19.DiligenceStatus.Complete || sectionComplete01)
    },
    {
      title: "Final Borrower Sign-Off",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.DiligenceComplete || sectionComplete01
    },
    {
      title: "Final CXM Review and Submission",
      isChecked: sectionComplete01
    },
    {
      title: "Loan Matched and Sent to Qualified Lenders",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.LendersReview || loanApp.Status == import_lib19.LoanApplicationStatus.LendersQna || loanApp.Status == import_lib19.LoanApplicationStatus.OffersSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SpecialistOffersReview || loanApp.Status == import_lib19.LoanApplicationStatus.SpecialistBtsReview || sectionComplete02
    },
    {
      title: "Lender Review and Questions Submitted to Borrower",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.LendersQna || loanApp.Status == import_lib19.LoanApplicationStatus.OffersSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SpecialistOffersReview || sectionComplete02,
      checkStatus: import_lib19.LoanApplicationStatus.LendersQna
    },
    {
      title: "Borrower Completed Q&A",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.OffersSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SpecialistOffersReview || sectionComplete02,
      checkStatus: import_lib19.LoanApplicationStatus.SpecialistOffersReview
    },
    {
      title: "Lender Non-Binding Terms Submitted",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.OffersSubmitted || sectionComplete02
    },
    {
      title: "Term Sheet Review and  Selection",
      isChecked: sectionComplete02
    },
    {
      title: "Lender and Borrower Initial Call",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.LenderBorrowerCallComplete || loanApp.Status == import_lib19.LoanApplicationStatus.LenderRequestedInformation || loanApp.Status == import_lib19.LoanApplicationStatus.ReadyForBts || loanApp.Status == import_lib19.LoanApplicationStatus.SpecialistBtsReview || loanApp.Status == import_lib19.LoanApplicationStatus.BtsSignedByBorrower || loanApp.Status == import_lib19.LoanApplicationStatus.BtsSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SignedBtsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSigned || sectionComplete03,
      checkStatus: import_lib19.LoanApplicationStatus.LenderBorrowerCallComplete
    },
    {
      title: "Lender-requested Additional Information",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.ReadyForBts || loanApp.Status == import_lib19.LoanApplicationStatus.SpecialistBtsReview || loanApp.Status == import_lib19.LoanApplicationStatus.BtsSignedByBorrower || loanApp.Status == import_lib19.LoanApplicationStatus.BtsSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SignedBtsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSigned || sectionComplete03,
      checkStatus: import_lib19.LoanApplicationStatus.ReadyForBts
    },
    {
      title: "Review Binding Term Sheet",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.BtsSignedByBorrower || loanApp.Status == import_lib19.LoanApplicationStatus.SignedBtsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSigned || sectionComplete03
    },
    {
      title: "Term Sheet Signed and Fee Sent (if applicable)",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.SignedBtsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSigned || sectionComplete03
    },
    {
      title: "Due-Diligence and Underwriting",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSubmitted || loanApp.Status == import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSigned || sectionComplete03
    },
    {
      title: "Final Closing Documentation and Legal Review",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved || loanApp.Status == import_lib19.LoanApplicationStatus.ClosingDocumentsSigned || sectionComplete03
    },
    {
      title: "Final Documentation Executed by Borrower and Lender",
      isChecked: loanApp.Status == import_lib19.LoanApplicationStatus.SignedClosingDocumentsApproved || sectionComplete03
    },
    {
      title: "Funds Released",
      isChecked: sectionComplete03
    }
  ];
  const firstManual = checklist.findIndex(
    (x, idx) => portaltype == import_lib19.PortalType.Management && !x.isChecked && x.checkStatus && (idx == 0 || checklist[idx - 1]?.isChecked)
  );
  const getChecklist = () => {
    if (portaltype == import_lib19.PortalType.Borrower && (0, import_lib19.isPreFirstSubmit)(loanApp.Status)) {
      return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: "Complete your profile and submit your application to get started." });
    }
    if (portaltype == import_lib19.PortalType.Lender && (statusMessage.category == "Missed" || statusMessage.category == "Not Pursued")) {
      return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: "This loan application has been missed." });
    }
    if (portaltype == import_lib19.PortalType.Lender && statusMessage.category == "Declined") {
      return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: "This loan application has been declined." });
    }
    return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { className: "flex flex-col", children: checklist.map((x, idx) => /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)("div", { className: "flex items-center flex-row space-x-1", children: [
      x.isChecked ? /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_lucide_react10.Check, { className: "text-green-500" }) : /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_lucide_react10.X, { className: "text-red-500" }),
      " ",
      /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { children: x.title }),
      idx == firstManual && /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
        Button,
        {
          className: "ml-auto py-0",
          onClick: async () => {
            const result = await confirmDialog.openDialogAsync({
              title: "Manually Complete",
              message: `Manually Complete "${x.title}?"`
            });
            if (!result) {
              return;
            }
            await updateStatusMutation.mutateAsync({
              status: x.checkStatus,
              loanAppId: loanApp.Id
            });
            window.location.reload();
          },
          children: "Manually Complete"
        }
      )
    ] }, idx)) });
  };
  return /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)("div", { className: "flex flex-col overflow-x-auto", children: [
    /* @__PURE__ */ (0, import_jsx_runtime40.jsx)("div", { className: "h-[100px] w-full pt-[60px] px-[60px] min-w-[800px] mb-8", children: /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)("ul", { className: "relative w-full list-none flex flex-row items-center justify-between py-0 h-[40px] bottom-0 bg-gray-200 rounded-full px-8 border-gray-300 border z-1", children: [
      progressEntries.map(
        (x, idx) => progressEntry(x, idx, progressEntries.length)
      ),
      /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
        "div",
        {
          style: {
            width: (displayProgress <= 98 ? displayProgress - 1 : displayProgress).toString() + "%"
          },
          className: "absolute top-0 left-0 bg-green-400 h-[100%] rounded-l-full " + (displayProgress <= 98 ? "rounded-r-none" : " rounded-r-full"),
          children: displayProgress <= 98 && /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
            "div",
            {
              style: { transform: "skew(-10deg)" },
              className: "absolute -right-2 bg-green-400 w-[12px] h-full"
            }
          )
        }
      )
    ] }) }),
    getChecklist(),
    portaltype == import_lib19.PortalType.Management && ![
      import_lib19.LoanApplicationStatus.Screen1,
      import_lib19.LoanApplicationStatus.Screen2,
      import_lib19.LoanApplicationStatus.Screen3,
      import_lib19.LoanApplicationStatus.InProgress,
      import_lib19.LoanApplicationStatus.Attested,
      import_lib19.LoanApplicationStatus.SpecialistReview,
      import_lib19.LoanApplicationStatus.DiligenceInProgress,
      import_lib19.LoanApplicationStatus.DiligenceComplete,
      import_lib19.LoanApplicationStatus.SpecialistDenied,
      import_lib19.LoanApplicationStatus.Closed,
      import_lib19.LoanApplicationStatus.Cancelled
    ].includes(loanApp.Status) && /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(AddLenderButton, { loanApp })
  ] });
};
var progress_bar_default = ProgressBar;

// src/components/loan-overview.tsx
var import_lib20 = require("@cxm/lib");
var import_react_markdown = __toESM(require("react-markdown"));
var import_jsx_runtime41 = require("react/jsx-runtime");
var LoanOverview = ({ portalType, loanApp, companyName, evaluation, lenderCriteria }) => {
  const loanType = import_lib20.loanTypes.find((x) => x.id == loanApp.LoanType)?.fullName;
  const evalMetrics = evaluation ? import_lib20.loanRatios.map(
    (y) => evaluation.MetricAssessments.find((x) => x.Name == y)
  ) : [];
  return /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(import_jsx_runtime41.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("div", { className: "", children: [
    /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("div", { className: "grid grid-cols-1 md:grid-cols-2", children: [
      /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("div", { children: [
        /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("div", { className: "text-lg mb-[64px] mt-[32px]", children: [
          companyName,
          " is seeking a",
          " ",
          /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("b", { children: [
            currency(loanApp.LoanAmount),
            " ",
            loanType,
            " "
          ] }),
          "loan."
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
          DataRows,
          {
            className: "mt-1 mb-4 max-w-[450px]",
            data: {
              company: companyName,
              LoanUse: import_lib20.loanUseOptions.find((x) => x.value == loanApp.LoanUse)?.label,
              PersonalGuarantee: loanApp.PersonalGuarantee ? "Yes" : "No",
              "Submitted": new Date(loanApp.SubmittedAt).toLocaleDateString(
                "en",
                {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric"
                }
              ),
              LoanSpecialist: loanApp.SpecialistName ?? "Not Yet Assigned",
              EngagedLenders: loanApp.EngagedLenderCount == 0 ? "---" : loanApp.EngagedLenderCount
            }
          }
        )
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("div", { className: "flex flex-col", children: [
        evaluation && /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_jsx_runtime41.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("h1", { className: "text-2xl text-center", children: "CXM Financial Health Score" }),
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { className: "flex ", children: /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("h1", { className: "text-center text-3xl max-w-[400px] mx-auto flex-grow h-[80px]", children: evaluation.CxmScore != void 0 ? /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("div", { className: "flex flex-row items-center mt-[40px] mb-4", children: [
            /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { className: "w-[50px] text-sm", children: "0" }),
            /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { className: "flex-grow", children: /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)("div", { className: "bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 h-[10px] w-full relative rounded-full", children: [
              /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
                "div",
                {
                  className: "bg-black border border-white rounded-none -top-[3px] h-[16px] w-[6px] absolute",
                  style: {
                    left: (evaluation.CxmScore / 1e3 * 100).toString() + "%"
                  }
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
                "div",
                {
                  className: "absolute hidden",
                  style: {
                    width: "0px",
                    height: "0px",
                    borderLeft: "20px solid transparent",
                    borderRight: "20px solid transparent",
                    borderTop: "20px solid #000",
                    left: `calc(${evaluation.CxmScore / 1e3 * 100} - 50px)%`
                  }
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
                "div",
                {
                  className: "w-[100px] text-center -top-[40px] absolute",
                  style: {
                    left: `calc(${evaluation.CxmScore / 1e3 * 100}% - 50px)`
                  },
                  children: Math.round(evaluation.CxmScore)
                }
              )
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { className: "w-[50px] text-sm", children: "1000" })
          ] }) : /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("b", { children: "---" }) }) })
        ] }),
        evaluation && /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_jsx_runtime41.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("h2", { className: " font-medium", children: "Target Loan Terms" }),
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
            DataRows,
            {
              className: "",
              data: {
                LoanAmount: currency(loanApp.LoanAmount),
                LoanType: loanType,
                InterestRate: roundToPlaces(
                  evaluation.InterestRate,
                  2
                ) + "%",
                Maturity: evaluation.MaturityInMonths + " Months",
                MonthlyPayment: currency(
                  roundToPlaces(evaluation.MonthlyPayment, 0)
                )
              }
            }
          )
        ] })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("h2", { className: "text-xl mt-4 font-medium", children: "Company Overview" }),
    /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { className: "w-full border border-b-gray-200 px-4" }),
    loanApp.LenderSummary ? /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
      import_react_markdown.default,
      {
        className: "mt-2 text-sm px-2",
        components: {
          ul({ children }) {
            return /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("ul", { className: "mt-4 mb-4 list-disc list-inside", children });
          },
          p({ children }) {
            return /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("p", { className: "inline", children });
          }
        },
        children: loanApp.LenderSummary
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { children: "Overview analysis not yet completed. An overview analysis is triggered automatically by a final submission and can be manually executed by a loan specialist." }),
    evaluation && /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_jsx_runtime41.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("h2", { className: "text-xl mt-4 font-medium", children: "Loan Ratios" }),
      /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { className: "w-full border border-b-gray-200" }),
      /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("div", { className: "px-2 my-4", children: /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(Table, { className: "max-w-[600px] mt-1", children: /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(TableBody, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(TableRow, { className: "bg-primary/20 px-1", children: [
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: "Ratio" }),
          portalType == import_lib20.PortalType.Lender && /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_jsx_runtime41.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: "Min" }),
            /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: "Max" })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: "Value" }),
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1 text-right", children: "Assessment" })
        ] }),
        evalMetrics.filter((x) => x != void 0).map(
          (metric) => {
            return /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(TableRow, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: metric?.Name?.replaceAll("_", " ").replaceAll(
                "PPE",
                "Equipment"
              ) }),
              portalType == import_lib20.PortalType.Lender && lenderCriteria && /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_jsx_runtime41.Fragment, { children: [
                /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: (metric?.FairValue ?? 0) > 100 ? ">10000%" : roundToPlaces(
                  100 * (metric?.FairValue ?? 0),
                  0
                ) + "%" }),
                /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: (metric?.FairValue ?? 0) > 100 ? ">10000%" : roundToPlaces(
                  100 * (metric?.FairValue ?? 0),
                  0
                ) + "%" })
              ] }),
              /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(TableCell, { className: "font-medium px-1", children: (metric?.Value ?? 0) > 100 ? ">10000%" : roundToPlaces(100 * (metric?.Value ?? 0), 0) + "%" }),
              /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
                TableCell,
                {
                  className: "font-medium px-1 text-right",
                  style: {
                    color: import_lib20.metricQualities[metric?.Quality ?? 0]?.color
                  },
                  children: import_lib20.metricQualities[metric?.Quality ?? 0]?.name
                }
              )
            ] }, metric?.Name);
          }
        )
      ] }) }) })
    ] })
  ] }) });
};

// src/elements/loan-application-view.tsx
var import_lucide_react11 = require("lucide-react");
var import_awesome_phonenumber2 = require("awesome-phonenumber");
var import_jsx_runtime42 = require("react/jsx-runtime");
var TermSheetsView = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: props.termSheets && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "text-lg font-medium", children: "Non-Binding Term Sheets" }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "h-[600px]", children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
      term_sheet_table_default,
      {
        termSheets: props.termSheets.filter((x) => !x.IsBinding),
        loanappid: props.id,
        portaltype: props.portalType
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "text-lg font-medium", children: "Binding Term Sheets" }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "h-[600px]", children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
      term_sheet_table_default,
      {
        termSheets: props.termSheets.filter((x) => x.IsBinding),
        loanappid: props.id,
        portaltype: props.portalType
      }
    ) })
  ] }) });
};
var HiddenField = ({ children }) => {
  const [hidden, setHidden] = (0, import_react20.useState)(true);
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row", children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: hidden ? "*****" : children }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "ml-auto", children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
      Button,
      {
        className: "h-[20px]",
        variant: "outline",
        onClick: () => setHidden(!hidden),
        children: hidden ? "Show" : "Hide"
      }
    ) })
  ] });
};
var LoanViewDataRows = ({ data, fields: config, className, firstColumnClassName }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(Table, { className: cn(className, "table-fixed text-base max-w-[800px]"), children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(TableHeader, {}),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(TableBody, { children: Object.keys(data).filter(
      (x) => !config || !config[x]?.hidden
    ).map(
      (objKey) => {
        const key = objKey;
        return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(TableRow, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            TableCell,
            {
              className: cn(firstColumnClassName, "max-w-[400px]"),
              children: (config && config[key]?.label) ?? capitalize(key.toString())
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(TableCell, { className: "", children: data[key] })
        ] }, objKey);
      }
    ) })
  ] });
};
var gridPrimaryClass2 = "font-bold w-full text-center bg-primary text-sm text-white h-[32px] justify-center flex flex-row items-center border border-gray-200 leading-4";
var YearProfitLossMetrics2 = ({ val, title }) => {
  const inputParentClass = "h-[32px] border border-gray-200 p-1 rounded-none flex flex-row justify-center items-center";
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: title }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.Revenues ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.CostOfSales ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.GrossProfit ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.Sga ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.Ebitda ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.InterestExpense ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.TotalOtherIncome ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.TotalOtherExpenses ?? 0) }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: inputParentClass, children: currency(val.NetIncome ?? 0) })
  ] });
};
var AdditionalFileLink = ({ id, name, loanAppId, portalType }) => {
  const deleteAdditionalFileMutation = (0, import_lib21.useApiMutation)(
    import_lib21.deleteAdditionalLoanAppFile,
    ["loanApp-" + loanAppId]
  );
  const confirmDialog = useConfirmDialogContext();
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row max-w-[600px]", children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
      "a",
      {
        className: "hover:underline text-blue-500",
        href: `${process.env.REACT_APP_BASE_API_URL}/api/loan-applications/DownloadAdditionalFile?portalType=${portalType}&loanAppId=${loanAppId}&fileId=${id}`,
        children: name
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
      Button,
      {
        className: "ml-auto h-[20px]",
        variant: "destructive",
        onClick: async () => {
          const result = await confirmDialog.openDialogAsync({
            title: "Delete File"
          });
          if (result) {
            await deleteAdditionalFileMutation.mutateAsync({
              portalType,
              fileId: id,
              loanAppId
            });
          }
        },
        children: "Delete"
      }
    )
  ] });
};
var ReviewFileLink = ({ id, name, loanAppId }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
    "a",
    {
      className: "hover:underline text-blue-500",
      href: `${process.env.REACT_APP_BASE_API_URL}/api/loan-applications/DownloadBorrowerReviewFile?loanAppId=${loanAppId}&fileId=${id}`,
      children: name
    }
  );
};
var OfferFileLink = ({ name, portalType, id }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
    "a",
    {
      className: "hover:underline text-blue-500",
      href: `${process.env.REACT_APP_BASE_API_URL}/api/offers/DownloadOfferFile?portalType=${portalType}&fileId=${id}`,
      children: name
    }
  );
};
var ProfileFileLink = ({ id, borrowerId, portalType }) => {
  const fileQuery = (0, import_lib21.useApiQuery)(
    "loanAppFile-" + id,
    (0, import_lib21.getBorrowerFile)({
      borrowerId,
      fileId: id ?? ""
    }),
    {
      enabled: id != void 0 && id != null
    }
  );
  if (!id) {
    return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_jsx_runtime42.Fragment, {});
  }
  if (!fileQuery.isSuccess && fileQuery.isLoading) {
    return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", {});
  }
  if (!fileQuery.isSuccess) {
    return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_jsx_runtime42.Fragment, { children: "No File Uploaded" });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
    "a",
    {
      className: "hover:underline text-blue-500",
      href: `${process.env.REACT_APP_BASE_API_URL}/api/borrower/DownloadBorrowerProfileFile?portalType=${portalType}&borrowerId=${borrowerId}&fileId=${id}`,
      children: fileQuery.data.Name
    }
  );
};
var EngagedLendersView = ({ loanApp }) => {
  const [engagedLenders, setEngagedLenders] = (0, import_react20.useState)();
  (0, import_lib21.useApiQuery)("engagedLenders-" + loanApp.Id, (0, import_lib21.getEngagedLenders)(loanApp.Id), {
    success: (v) => {
      setEngagedLenders(v);
    }
  });
  if (!engagedLenders) {
    return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_jsx_runtime42.Fragment, {});
  }
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: "Engaged Lenders" }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { children: [
      "Unacknowledged:",
      " ",
      engagedLenders.filter((x) => x.Status == import_lib21.LenderApplicationStatus.New).length,
      " Acknowledged: ",
      engagedLenders.filter(
        (x) => x.Status == import_lib21.LenderApplicationStatus.InProgress && x.OfferStatusList.length == 0
      ).length,
      " Offer Submitted: ",
      engagedLenders.filter(
        (x) => x.Status == import_lib21.LenderApplicationStatus.InProgress && x.OfferStatusList.length > 0
      ).length,
      " Offer Accepted: ",
      engagedLenders.filter(
        (x) => x.Status == import_lib21.LenderApplicationStatus.Pursued
      ).length,
      " Offer Rejected: ",
      engagedLenders.filter(
        (x) => x.Status == import_lib21.LenderApplicationStatus.NotPursued
      ).length,
      " Missed: ",
      engagedLenders.filter(
        (x) => x.Status == import_lib21.LenderApplicationStatus.Missed
      ).length,
      " Declined: ",
      engagedLenders.filter(
        (x) => x.Status == import_lib21.LenderApplicationStatus.Declined
      ).length
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("ul", { className: "flex flex-col", children: engagedLenders.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { children: [
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("b", { children: x.LenderName }),
        " Status:",
        " ",
        (0, import_lib21.getEngagedLenderApplicationString)(x.Status, x.OfferStatusList),
        " "
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "pl-4", children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("b", { children: "Contacts" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("li", { className: "list-item", children: x.Users.map((x2) => /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("ul", { children: [
          x2.Firstname,
          " ",
          x2.Lastname,
          " ",
          x2.Email,
          " ",
          (0, import_awesome_phonenumber2.parsePhoneNumber)(
            "+" + x2?.PhoneNumber
          ).number?.national
        ] })) })
      ] })
    ] })) })
  ] });
};
var LoanApplicationView = (props) => {
  const generateSummaryMutation = (0, import_lib21.useApiMutation)(import_lib21.generateLenderSummary, [
    "loanApp-" + props.id
  ]);
  const loanAppQuery = (0, import_lib21.useApiQuery)(
    "loanApp-" + props.id,
    (0, import_lib21.getLoanApplication)(props.portaltype, props.id),
    {
      success: (v) => {
        setBorrowerId(v.BorrowerId);
        if (props.portaltype == import_lib21.PortalType.Management && !(0, import_lib21.isPreSubmit)(v.Status) && !v.LenderSummary) {
          generateSummaryMutation.mutate({
            applicationId: props.id
          });
        }
      }
    }
  );
  const [termSheets, setTermSheets] = (0, import_react20.useState)();
  (0, import_lib21.useApiQuery)(
    "termSheets-" + props.id,
    (0, import_lib21.getTermSheets)(props.portaltype, props.id),
    {
      success: (v) => {
        v.termSheets.sort((a, b) => {
          if (a.OfferStatus == import_lib21.OfferStatus.SubmittedByLender && b.OfferStatus != import_lib21.OfferStatus.SubmittedByLender) {
            return -1;
          } else {
            return 0;
          }
        });
        setTermSheets(v.termSheets);
      }
    }
  );
  const [lenderCriteria, setLenderCriteria] = (0, import_react20.useState)();
  const loanEvalQuery = (0, import_lib21.useApiQuery)(
    "loanAppEval-" + props.id,
    (0, import_lib21.getApiEvaluation)(props.portaltype, props.id)
  );
  const [borrowerId, setBorrowerId] = (0, import_react20.useState)();
  const loanUsersQuery = (0, import_lib21.useApiQuery)(
    "loanAppUsers-" + props.id,
    (0, import_lib21.getBorrowerUsers)(borrowerId || ""),
    {
      enabled: borrowerId != void 0 && props.portaltype == import_lib21.PortalType.Management
    }
  );
  const inputRef = (0, import_react20.useRef)(null);
  const uploadAdditionalFileMutation = (0, import_lib21.useApiMutation)(
    import_lib21.uploadAdditionalLoanAppFile,
    ["loanApp-" + props.id]
  );
  const [selectedTab, setSelectedTab] = (0, import_react20.useState)(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.has("tabId")) {
      try {
        return parseInt(params.get("tabId") ?? "", 10);
      } catch (e) {
      }
    }
    return parseInt(localStorage.getItem("loanApplicationTab") ?? "0", 10);
  });
  const currentDate = /* @__PURE__ */ new Date();
  const currentYear2 = currentDate.getFullYear();
  const lastMonth = /* @__PURE__ */ new Date();
  lastMonth.setMonth(currentDate.getMonth() - 1);
  const lastShortMonthString = lastMonth.toLocaleString("en", {
    month: "short"
  });
  (0, import_react20.useEffect)(() => {
    if (props.portaltype == import_lib21.PortalType.Management) {
      if (selectedTab < 0 || selectedTab > 6) {
        setSelectedTab(0);
      }
    } else {
      if (selectedTab < 0 || selectedTab > 5) {
        setSelectedTab(0);
      }
    }
  }, [selectedTab]);
  if (loanAppQuery.isLoading || loanEvalQuery.isLoading || !borrowerId) {
    return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", {});
  }
  if (!loanAppQuery.isSuccess) {
    return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: "Something went wrong." });
  }
  const loanApp = loanAppQuery.data;
  const borrowerProfile = loanAppQuery.data.BorrowerProfile;
  const portalType = props.portaltype;
  const iconSize = 15;
  let tabs = [
    {
      title: "Application Overview",
      icon: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.KanbanSquareDashed, { height: iconSize, width: iconSize }),
      label: "Overview",
      content: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_jsx_runtime42.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
        LoanOverview,
        {
          lenderCriteria,
          cxmScore: loanEvalQuery.data?.CxmScore,
          portalType: props.portaltype,
          evaluation: loanEvalQuery?.data,
          companyName: borrowerProfile.CompanyInformation?.CompanyName ?? "",
          loanApp
        }
      ) })
    },
    {
      label: "Progress",
      title: "Loan Progress",
      icon: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Milestone, { height: iconSize, width: iconSize }),
      content: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
        progress_bar_default,
        {
          loanappid: loanAppQuery.data.Id,
          portaltype: props.portaltype
        }
      )
    },
    {
      label: "Company Information",
      title: "Company Information",
      icon: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Info, { height: iconSize, width: iconSize }),
      content: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "font-medium", children: "General Business Details" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            data: {
              CompanyName: borrowerProfile.CompanyInformation?.CompanyName,
              CompanyWebsite: borrowerProfile.CompanyInformation?.CompanyWebsite,
              CompanyPhone: (0, import_awesome_phonenumber2.parsePhoneNumber)(
                "+" + borrowerProfile.CompanyInformation?.CompanyPhone
              ).number?.national,
              YearFormed: borrowerProfile.CompanyInformation?.YearFormed,
              NumberOfEmployees: borrowerProfile.CompanyInformation?.NumberOfEmployees,
              BusinessDescription: borrowerProfile.CompanyInformation?.BusinessDescription,
              Address: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col", children: [
                /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: borrowerProfile.CompanyInformation?.HeadQuartersAddress?.Line1 }),
                /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: borrowerProfile.CompanyInformation?.HeadQuartersAddress?.Line2 }),
                /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { children: [
                  borrowerProfile.CompanyInformation?.HeadQuartersAddress?.City,
                  ",",
                  " ",
                  borrowerProfile.CompanyInformation?.HeadQuartersAddress?.State,
                  " ",
                  borrowerProfile.CompanyInformation?.HeadQuartersAddress?.ZipCode
                ] })
              ] }),
              CompanyType: import_lib21.companyTypeOptions.find(
                (x) => x.value == borrowerProfile.CompanyInformation?.CompanyType
              )?.label ?? "",
              Industry: borrowerProfile.CompanyInformation?.Industry,
              SubIndustry: borrowerProfile.CompanyInformation?.SubIndustry,
              EmployerIdentificationNumber: borrowerProfile.CompanyInformation?.EmployerIdentificationNumber,
              OrgChart: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: borrowerProfile.CompanyInformation?.OrgChartFile,
                  borrowerId,
                  portalType
                }
              ),
              VeteranOwnedBusiness: borrowerProfile.CompanyInformation?.VeteranOwned ? "Yes" : "No",
              FemaleOwnedBusiness: borrowerProfile.CompanyInformation?.FemaleOwned ? "Yes" : "No",
              DisabilityOwnedBusiness: borrowerProfile.CompanyInformation?.DisabilityOwned ? "Yes" : "No",
              MinorityOwnedBusiness: borrowerProfile.CompanyInformation?.MinorityOwned ? "Yes" : "No",
              BusinessPlan: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: borrowerProfile.CompanyInformation?.BusinessPlanFile,
                  borrowerId,
                  portalType
                }
              ),
              TotalRevenuePercentOfTopFiveCustomers: formatPercent(
                borrowerProfile.CompanyInformation?.TotalRevenuePercentOfTopFiveCustomers ?? 0
              ),
              CustomerContractFiles: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.CompanyInformation?.CustomerContractFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: x,
                  borrowerId,
                  portalType
                },
                x
              )) })
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "font-medium", children: "Tax & Audit" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            data: {
              "Full Time CFO": borrowerProfile.TaxInformation?.FullTimeCFO ? "Yes" : "No",
              OutsideAccountant: borrowerProfile.TaxInformation?.OutsideAccountant ? "Yes" : "No",
              TaxReturns: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.TaxInformation?.TaxReturnFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: x,
                  borrowerId,
                  portalType
                },
                x
              )) }),
              BankStatements: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.TaxInformation?.LastYearBankStatementFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: x,
                  borrowerId,
                  portalType
                },
                x
              )) })
            },
            className: ""
          }
        ),
        portalType != import_lib21.PortalType.Lender && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "text-xl font-medium", children: "Contacts" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            LoanViewDataRows,
            {
              data: {
                "Name": borrowerProfile.CompanyInformation?.PrimaryContact?.FirstName + " " + borrowerProfile.CompanyInformation?.PrimaryContact?.LastName + " (Primary)",
                Title: borrowerProfile.CompanyInformation?.PrimaryContact?.Title,
                PhoneNumber: (0, import_awesome_phonenumber2.parsePhoneNumber)(
                  "+" + borrowerProfile.CompanyInformation?.PrimaryContact?.PhoneNumber
                ).number?.national,
                Email: borrowerProfile.CompanyInformation?.PrimaryContact?.Email
              },
              className: ""
            }
          ),
          (borrowerProfile.CompanyInformation?.SecondaryContacts ?? []).map(
            (x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "mt-4" }),
              /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                LoanViewDataRows,
                {
                  data: {
                    "Name": x.FirstName + " " + x.LastName,
                    Title: x.Title,
                    PhoneNumber: (0, import_awesome_phonenumber2.parsePhoneNumber)(
                      "+" + x.PhoneNumber
                    ).number?.national,
                    Email: x.Email
                  },
                  className: ""
                }
              )
            ] })
          )
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "text-xl font-medium", children: "Officers" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        (borrowerProfile.CompanyInformation?.Officers ?? []).map(
          (x, idx) => /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
            idx > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "mt-4" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              LoanViewDataRows,
              {
                data: {
                  "Name": x.FirstName + " " + x.LastName,
                  Title: x.Title,
                  Share: formatPercentNumber(x.PercentageShare / 100),
                  "Social Security Number": /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(HiddenField, { children: formatSSN(x.SocialSecurityNumber) }),
                  "Drivers License": /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(HiddenField, { children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                    ProfileFileLink,
                    {
                      id: x.DriversLicenseFile,
                      borrowerId,
                      portalType
                    }
                  ) })
                },
                className: ""
              }
            )
          ] })
        ),
        loanUsersQuery.isSuccess && props.portaltype == import_lib21.PortalType.Management && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "text-xl font-medium", children: "All Users" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            LoanViewDataRows,
            {
              data: Object.fromEntries(loanUsersQuery.data.map((x) => {
                return [x.Firstname + " " + x.Lastname, x.Email];
              })),
              className: ""
            }
          )
        ] })
      ] })
    },
    {
      label: "Financial Metrics",
      title: "Financial Metrics",
      icon: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.FileLineChart, { height: iconSize, width: iconSize }),
      content: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "font-medium", children: "Historical P&L Statements" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "grid grid-cols-6 mt-2 max-w-[900px]", children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "text-left", children: [
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2 }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Revenues" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "COGS" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Gross Profit" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "SG&A" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "EBITDA" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Interest Expense" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Total Other Income" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Total Other Expenses" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Net Income" })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            YearProfitLossMetrics2,
            {
              title: (currentYear2 - 3).toString(),
              val: borrowerProfile.ProfitLossMetrics?.PreviousYearsMetrics?.find((x) => x.Year == currentYear2 - 3) ?? {}
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            YearProfitLossMetrics2,
            {
              title: (currentYear2 - 2).toString(),
              val: borrowerProfile.ProfitLossMetrics?.PreviousYearsMetrics?.find((x) => x.Year == currentYear2 - 2) ?? {}
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            YearProfitLossMetrics2,
            {
              title: (currentYear2 - 1).toString(),
              val: borrowerProfile.ProfitLossMetrics?.PreviousYearsMetrics?.find((x) => x.Year == currentYear2 - 1) ?? {}
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            YearProfitLossMetrics2,
            {
              val: borrowerProfile?.ProfitLossMetrics?.TtmMetrics ?? {},
              title: `Since ${lastShortMonthString} ${currentYear2 - 1}`
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            YearProfitLossMetrics2,
            {
              val: borrowerProfile?.ProfitLossMetrics?.YtdMetrics ?? {},
              title: "Since Jan " + currentYear2
            }
          )
        ] }),
        borrowerProfile?.ProfitLossMetrics?.PreviousYearsFiles && borrowerProfile?.ProfitLossMetrics?.PreviousYearsFiles?.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col", children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("b", { children: "Historical P&L Files" }) }),
          borrowerProfile?.ProfitLossMetrics?.PreviousYearsFiles?.map(
            (x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              ProfileFileLink,
              {
                id: x,
                borrowerId,
                portalType
              }
            )
          )
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Forecasted P&L Statements" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "grid grid-cols-6 mt-2 max-w-[1000px]", children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "", children: [
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2 }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Revenues" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "COGS" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Gross Profit" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "SG&A" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "EBITDA" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Interest Expense" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Total Other Income" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Total Other Expenses" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: gridPrimaryClass2, children: "Net Income" })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            YearProfitLossMetrics2,
            {
              title: currentYear2.toString(),
              val: borrowerProfile.ProfitLossMetrics?.PreviousYearsMetrics?.find((x) => x.Year == currentYear2) ?? {}
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            YearProfitLossMetrics2,
            {
              title: (currentYear2 + 1).toString(),
              val: borrowerProfile.ProfitLossMetrics?.PreviousYearsMetrics?.find((x) => x.Year == currentYear2 + 1) ?? {}
            }
          )
        ] }),
        borrowerProfile?.ProfitLossMetrics?.CurrentAndNextYearProjectionFiles && borrowerProfile?.ProfitLossMetrics?.CurrentAndNextYearProjectionFiles?.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col", children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("b", { children: "Forecasted P&L Files" }) }),
          borrowerProfile?.ProfitLossMetrics?.CurrentAndNextYearProjectionFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            ProfileFileLink,
            {
              id: x,
              borrowerId,
              portalType
            },
            x
          ))
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          "a",
          {
            href: `${process.env.REACT_APP_BASE_API_URL}/api/loan-applications/DownloadFinancialExport?portalType=${portalType}&loanApplicationId=${props.id}`,
            children: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(Button, { variant: "outline", children: [
              /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Download, { className: "mr-2", height: 14, width: 14 }),
              "Export Financials"
            ] })
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "General Balance Sheet Metrics" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            className: "max-w-[1000px]",
            data: {
              MostRecentBalanceSheet: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: borrowerProfile.BalanceSheetMetrics?.MostRecentBalanceSheetFile,
                  borrowerId,
                  portalType
                }
              ),
              "Past 3 Calendar Year Balance Sheets": /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.BalanceSheetMetrics?.PreviousYearsBalanceSheetFiles?.map(
                (x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                  ProfileFileLink,
                  {
                    id: x,
                    borrowerId,
                    portalType
                  },
                  x
                )
              ) }),
              BalanceSheetAsOfDate: borrowerProfile.BalanceSheetMetrics?.BalanceSheetAsOfDate ? new Date(
                borrowerProfile.BalanceSheetMetrics?.BalanceSheetAsOfDate
              ).toLocaleString("en", {
                day: "numeric",
                month: "numeric",
                year: "numeric"
              }) : "",
              "Current Cash & Money Market Blaance": currency(
                borrowerProfile.BalanceSheetMetrics?.CurrentCash ?? 0
              ),
              CurrentAssets: currency(
                borrowerProfile.BalanceSheetMetrics?.CurrentAssets ?? 0
              ),
              TotalAssets: currency(
                borrowerProfile.BalanceSheetMetrics?.TotalAssets ?? 0
              ),
              CurrentLiabilities: currency(
                borrowerProfile.BalanceSheetMetrics?.CurrentLiabilities ?? 0
              ),
              TotalLiabilities: currency(
                borrowerProfile.BalanceSheetMetrics?.TotalLiabilities ?? 0
              )
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Accounts Receivable" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            className: "max-w-[1000px]",
            data: {
              AccountsReceivingAgingSchedule: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.BalanceSheetMetrics?.AccountsReceivableScheduleFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: x,
                  borrowerId,
                  portalType
                },
                x
              )) }),
              Total: currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsReceivableTotal ?? 0
              ),
              "0-30 Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsReceivable30Days ?? 0
              ),
              "31-60 Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsReceivable3160Days ?? 0
              ),
              "61-90 Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsReceivable6190Days ?? 0
              ),
              "90+ Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsReceivable90PlusDays ?? 0
              )
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Accounts Payable" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            className: "max-w-[1000px]",
            data: {
              AccountsPayableAgingSchedule: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.BalanceSheetMetrics?.AccountsPayableScheduleFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: x,
                  borrowerId,
                  portalType
                },
                x
              )) }),
              Total: currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsPayableTotal ?? 0
              ),
              "0-30 Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsPayable30Days ?? 0
              ),
              "31-60 Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsPayable3160Days ?? 0
              ),
              "61-90 Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsPayable6190Days ?? 0
              ),
              "90+ Days": currency(
                borrowerProfile.BalanceSheetMetrics?.AccountsPayable90PlusDays ?? 0
              )
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Inventory Details" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            className: "max-w-[1000px]",
            data: {
              TotalValue: currency(
                borrowerProfile.BalanceSheetMetrics?.InventoryAmount ?? 0
              ),
              SupportingDocs: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.BalanceSheetMetrics?.InventorySupportingFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: x,
                  borrowerId,
                  portalType
                },
                x
              )) }),
              InventoryBreakdown: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: borrowerProfile.BalanceSheetMetrics?.InventoryBreakdownFile,
                  borrowerId,
                  portalType
                }
              )
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Equipment Details" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            className: "max-w-[1000px]",
            data: {
              EstNetValue: currency(
                borrowerProfile.BalanceSheetMetrics?.EstEquipmentValue ?? 0
              ),
              EquipmentBreakdown: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: borrowerProfile.BalanceSheetMetrics?.EquipmentBreakdownFile,
                  borrowerId,
                  portalType
                }
              ),
              DebtAssociatedWithEquipment: borrowerProfile.BalanceSheetMetrics?.HasEquipmentDebt ? "Yes" : "No"
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Real Estate Details" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            className: "max-w-[1000px]",
            data: {
              EstNetValue: currency(
                borrowerProfile.BalanceSheetMetrics?.EstRealEstateValue ?? 0
              ),
              RealEstateBreakdown: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: borrowerProfile.BalanceSheetMetrics?.RealEstateBreakdownFile,
                  borrowerId,
                  portalType
                }
              ),
              DebtAssociatedWithRealEstate: borrowerProfile.BalanceSheetMetrics?.HasRealEstateDebt ? "Yes" : "No"
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: "text-xl font-medium", children: "Additional Debt" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          LoanViewDataRows,
          {
            className: "max-w-[1000px] mb-4",
            data: {
              Total: currency(
                borrowerProfile.BalanceSheetMetrics?.TotalAdditionalDebt ?? 0
              ),
              AdditionalDebtScheduleFiles: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col", children: borrowerProfile.BalanceSheetMetrics?.AdditionalDebtScheduleFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
                ProfileFileLink,
                {
                  id: x,
                  borrowerId,
                  portalType
                },
                x
              )) })
            }
          }
        ),
        (borrowerProfile.BalanceSheetMetrics?.AdditionalDebt ?? []).map(
          (x, idx) => /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
            idx > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "mt-4" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              LoanViewDataRows,
              {
                data: {
                  "Debt Type": import_lib21.loanTypes.find((y) => y.id == x.DebtType)?.fullName,
                  "Name": x.Lender,
                  InitialAmount: currency(x.InitialAmount ?? 0),
                  "Remaining Balance": currency(x.RemainingAmount ?? 0),
                  MonthlyPayment: currency(x.MonthlyPayment ?? 0),
                  InterestRate: formatPercentNumber(
                    (x.InterestRate ?? 0) / 100
                  ),
                  MaturityDebt: (x.MaturityDate ? new Date(x.MaturityDate) : /* @__PURE__ */ new Date()).toLocaleString("en", {
                    month: "long",
                    year: "numeric"
                  })
                },
                className: ""
              }
            )
          ] })
        )
      ] })
    },
    {
      label: "Term Sheets",
      title: "Lender Term Sheets",
      icon: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Handshake, { height: iconSize, width: iconSize }),
      content: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
        TermSheetsView,
        {
          termSheets,
          portalType: props.portaltype,
          id: props.id
        }
      )
    },
    {
      label: "Files",
      title: "Application Files",
      icon: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Files, { height: iconSize, width: iconSize }),
      content: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
        loanApp.NonBindingFiles && loanApp.NonBindingFiles.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Non Binding Files" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col my-2", children: loanApp.NonBindingFiles.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            OfferFileLink,
            {
              name: x.Name,
              id: x.Id,
              portalType
            },
            x.Id
          )) })
        ] }),
        loanApp.BindingFiles && loanApp.BindingFiles.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Binding Files" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col my-2", children: loanApp.BindingFiles.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            OfferFileLink,
            {
              name: x.Name,
              id: x.Id,
              portalType
            },
            x.Id
          )) })
        ] }),
        loanApp.CovenantFiles && loanApp.CovenantFiles.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Covenant Files" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col my-2", children: loanApp.CovenantFiles.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            OfferFileLink,
            {
              name: x.Name,
              id: x.Id,
              portalType
            },
            x.Id
          )) })
        ] }),
        loanApp.ClosingFiles && loanApp.ClosingFiles.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Closing Files" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col my-2", children: loanApp.ClosingFiles.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            OfferFileLink,
            {
              name: x.Name,
              id: x.Id,
              portalType
            },
            x.Id
          )) })
        ] }),
        loanApp.DiligenceFiles && loanApp.DiligenceFiles.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Borrower Review Files" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-col my-2", children: loanApp.DiligenceFiles.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            ReviewFileLink,
            {
              name: x.Name,
              id: x.PublicId,
              loanAppId: props.id,
              portalType
            },
            x.Id
          )) })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h2", { className: " font-medium", children: "Additional Docs" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "w-full border border-b-gray-200" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col my-2", children: [
          borrowerProfile.AdditionalDocs?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            ProfileFileLink,
            {
              id: x,
              borrowerId,
              portalType
            },
            x
          )),
          loanApp.AdditionalFiles?.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            AdditionalFileLink,
            {
              id: x.PublicId,
              loanAppId: props.id,
              name: x.Name,
              portalType
            },
            x.PublicId
          ))
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(
          Button,
          {
            onClick: () => inputRef.current?.click(),
            variant: "outline",
            className: "mt-2 px-2",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Upload, { className: "mr-1", width: 14, height: 14 }),
              " ",
              "Upload Additional Docs"
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          Input,
          {
            ref: inputRef,
            multiple: true,
            className: "max-w-[300px] hidden",
            type: "file",
            onChange: async (e) => {
              if (!e.target.files || e.target.files?.length == 0)
                return;
              var firstFile = e.target.files?.[0];
              if (!firstFile)
                return;
              const formData = new FormData();
              for (var i = 0; i < e.target.files.length; i++) {
                var file = e.target.files.item(i);
                if (file != null) {
                  formData.append("file" + i, file);
                }
              }
              await uploadAdditionalFileMutation.mutateAsync({
                formData,
                loanAppId: props.id
              });
            }
          }
        )
      ] })
    }
  ];
  if (portalType == import_lib21.PortalType.Management) {
    tabs = [
      ...tabs,
      {
        label: "Specialist",
        title: "Specialist Panel",
        icon: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Wrench, { height: iconSize, width: iconSize }),
        content: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col space-y-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              className: "max-w-[300px]",
              variant: "outline",
              onClick: async () => {
                await generateSummaryMutation.mutateAsync({
                  applicationId: props.id
                });
              },
              children: generateSummaryMutation.isLoading ? "Generating..." : "Regenerate Overview"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(EngagedLendersView, { loanApp }),
          loanEvalQuery.isSuccess && loanEvalQuery.data.DebugLenderList && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "overflow-auto", children: [
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h1", { children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("b", { children: "Calculation" }) }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col text-red-400 text-red", children: [
              /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("b", { children: [
                "Debug Lender Matches (",
                loanEvalQuery.data.DebugLenderList.length,
                ")"
              ] }) }),
              loanEvalQuery.data.DebugLenderList.map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: x.LenderName }, x.LenderName))
            ] }),
            loanEvalQuery.data && loanEvalQuery.data.ProbabilityTraceJson && (loanEvalQuery.data.ProbabilityTraceJson.steps.find((x) => x.name == "Narrative")?.details).split(". ").map((x) => /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { children: [
              x,
              "."
            ] }))
          ] })
        ] })
      }
    ];
  }
  const selectedTabContent = tabs[selectedTab]?.content;
  if (!selectedTabContent) {
    return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { children: "Something went wrong." });
  }
  const status = (0, import_lib21.getLoanStatusMessage)(loanApp, portalType);
  const getLoanActions = () => {
    switch (portalType) {
      case import_lib21.PortalType.Lender:
        return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row space-x-2", children: [
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ReadyForNonBindingOffer && /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "success",
                onClick: () => {
                  window.CreateOffer();
                },
                children: "Create Offer"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "destructive",
                onClick: () => {
                  window.DeclineOffer();
                },
                children: "Decline Loan"
              }
            )
          ] }),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.OfferNeedsRevision && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              variant: "success",
              onClick: () => {
                window.CreateOffer();
              },
              children: "Revise Offer"
            }
          ),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ReadyForBindingOffer && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              variant: "success",
              onClick: () => {
                window.CreateBindingOffer();
              },
              children: "Create Binding Offer"
            }
          ),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.BindingOfferNeedsRevision && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              variant: "success",
              onClick: () => {
                window.CreateBindingOffer();
              },
              children: "Revise Binding Offer"
            }
          ),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ReadyForClosingDocs && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              variant: "success",
              onClick: () => {
                window.AddClosingDocs();
              },
              children: "Add Closing Documents"
            }
          ),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ReadyToClose && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              variant: "success",
              onClick: () => {
                window.location.href = "/Loan/Close?loanApplicationId=" + loanApp.InternalId;
              },
              children: "Close Loan"
            }
          )
        ] });
      case import_lib21.PortalType.Borrower:
        return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row space-x-2", children: [
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ReadyForFinalSubmission && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "max-w-[210px]", children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(attestation_default, { id: props.id, isreattest: true }) }),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.OffersAvailableToReview && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              onClick: () => {
                localStorage.setItem("loanApplicationTab", "4");
                setSelectedTab(4);
              },
              children: "View Offers"
            }
          ),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.BindingOfferAvailableForReview && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              variant: "success",
              onClick: () => {
                window.openSignature();
              },
              children: "Sign Binding Offer"
            }
          ),
          status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ClosingDocsAvailableForReview && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            Button,
            {
              variant: "success",
              onClick: () => {
                window.openClose();
              },
              children: "Sign Closing Documents"
            }
          )
        ] });
      case import_lib21.PortalType.Management:
        return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_jsx_runtime42.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row space-x-2", children: [
            status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.RequiresManualAction && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                onClick: () => {
                  setSelectedTab(1);
                  localStorage.setItem("loanApplicationTab", "1");
                },
                children: "View Actions"
              }
            ),
            status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ReadyToSubmitToLenders && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(submit_loan_dialog_default, { loanappid: loanApp.Id }),
            status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.ReadyForBorrowerReview && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "success",
                onClick: () => {
                  window.BorrowerReview();
                },
                children: "Borrower Review"
              }
            ),
            status.requiresAction && (status.message == import_lib21.ApplicationStatusMessage.OffersAvailableToReview || status.message == import_lib21.ApplicationStatusMessage.BindingOfferAvailableForReview) && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "success",
                onClick: () => {
                  setSelectedTab(4);
                },
                children: "View Offers"
              }
            ),
            status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.SignedBTSReadyForReview && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "success",
                onClick: () => {
                  window.ReviewSignedBts();
                },
                children: "Review Signed Binding Term Sheet"
              }
            ),
            status.requiresAction && status.message == import_lib21.ApplicationStatusMessage.SignedClosingDocsAvailable && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "success",
                onClick: () => {
                  window.ReviewSignedClosingDocs();
                },
                children: "Review Signed Closing Docs"
              }
            )
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row space-x-2 ml-auto", children: [
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "outline",
                className: "ml-auto",
                onClick: () => {
                  window.assignSpecialistWindow();
                },
                children: "Assign Specialist"
              }
            ),
            [
              import_lib21.LoanApplicationStatus.BtsSubmitted,
              import_lib21.LoanApplicationStatus.OfferAccepted,
              import_lib21.LoanApplicationStatus.LenderBorrowerCallComplete,
              import_lib21.LoanApplicationStatus.LenderRequestedInformation,
              import_lib21.LoanApplicationStatus.SpecialistBtsReview,
              import_lib21.LoanApplicationStatus.ReadyForBts
            ].includes(loanApp.Status) && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(revert_accepted_button_default, { loanappid: loanApp.InternalId }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              Button,
              {
                variant: "destructive",
                className: "",
                onClick: () => {
                  window.cancelLoan();
                },
                children: "Cancel Loan"
              }
            )
          ] })
        ] });
    }
  };
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col space-y-2 w-full p-2", children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row space-x-2", children: [
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col", children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h1", { className: "text-[54px] font-light", children: borrowerProfile.CompanyInformation?.CompanyName }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "border-b border-b-gray-300 max-w-[600px]" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col rounded-md border border-gray-300 p-2 bg-gray-100 flex-grow", children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { children: [
          "Loan Actions - ",
          (0, import_lib21.getStatusMessageString)(status.message)
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "border-b border-b-gray-300 max-w-[600px]" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "flex flex-row min-h-[40px] mt-4", children: getLoanActions() })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-row space-x-2", children: [
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col space-y-1", children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          Tabs,
          {
            orientation: "vertical",
            onValueChange: (v) => {
              localStorage.setItem("loanApplicationTab", v);
              setSelectedTab(parseInt(v));
            },
            value: selectedTab.toString(),
            children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(TabsList, { className: "mx-auto flex flex-col h-auto bg-transparent", children: tabs.map((tab, idx) => {
              return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(
                TabsTrigger,
                {
                  className: "relative w-full text-left justify-start data-[state=active]:text-primary data-[state=active]:bg-primary/10 data-[state=active]:shadow-primary data-[state=active]:shadow-sm transition-all duration-300",
                  value: idx.toString(),
                  children: [
                    tab.icon,
                    " ",
                    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("span", { className: "ml-2", children: tab.label })
                  ]
                },
                idx
              );
            }) })
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("div", { className: "p-1 w-full", children: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(DropdownMenu, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
            DropdownMenuTrigger,
            {
              asChild: true,
              children: /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(
                Button,
                {
                  variant: "outline",
                  className: "text-left justify-start w-full px-2",
                  children: [
                    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_lucide_react11.Download, { className: "mr-2", height: 14, width: 14 }),
                    "Export"
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(DropdownMenuContent, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(DropdownMenuLabel, { className: "flex flex-row", children: "Export As" }),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(DropdownMenuSeparator, {}),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              "a",
              {
                href: `${process.env.REACT_APP_BASE_API_URL}/api/loan-applications/GetBorrowerPackageExport?portalType=${props.portaltype}&loanAppId=${props.id}&sheetOnly=false`,
                children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(DropdownMenuItem, { className: "p-1 cursor-pointer", children: "All (.zip)" })
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              "a",
              {
                href: `${process.env.REACT_APP_BASE_API_URL}/api/loan-applications/GetBorrowerPackageExport?portalType=${props.portaltype}&loanAppId=${props.id}&sheetOnly=true`,
                children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(DropdownMenuItem, { className: "p-1 cursor-pointer", children: "Data (.csv)" })
              }
            ),
            loanEvalQuery.isSuccess && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
              props.pdfDownload,
              {
                evaluation: loanEvalQuery.data,
                loanApp,
                profile: borrowerProfile,
                children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(DropdownMenuItem, { className: "p-1 cursor-pointer", children: "Printout (.pdf)" })
              }
            )
          ] })
        ] }) })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)("div", { className: "flex flex-col flex-grow min-w-0", children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)("h1", { className: "text-4xl p-2", children: tabs[selectedTab]?.title }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
          "div",
          {
            className: "p-4 rounded-md border border-gray-100 bg-white shadow-black/10 shadow-md " + (selectedTab != 4 ? "container ml-0" : ""),
            children: selectedTabContent
          }
        )
      ] })
    ] })
  ] }) });
};
var LoanApplicationViewOuter = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(LoanApplicationView, { ...props });
};
var loan_application_view_default = LoanApplicationViewOuter;

// src/components/footer.tsx
var import_jsx_runtime43 = require("react/jsx-runtime");
var Footer = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime43.jsx)("footer", { className: "h-[64px] mt-auto text-center font-semibold text-primary flex flex-row justify-center items-center border border-t-gray-200 mt-4", children: /* @__PURE__ */ (0, import_jsx_runtime43.jsx)("a", { href: "https://cxmloans.com/content/Connex Markets Inc. - Website Privacy Notice (2024.03).pdf", children: "Privacy Policy" }) });
};
var footer_default = Footer;

// src/elements/screenone.tsx
var import_jsx_runtime44 = require("react/jsx-runtime");
function ScreenOne() {
  return /* @__PURE__ */ (0, import_jsx_runtime44.jsxs)("div", { className: "min-h-screen flex flex-col", children: [
    /* @__PURE__ */ (0, import_jsx_runtime44.jsxs)("header", { className: "border border-b-gray-300 p-3 flex md:flex-row flex-col items-center", children: [
      /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("img", { src: "/cxm-text-logo.png", className: "h-[60px]" }),
      /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("span", { className: "sr-only", children: "CXM Loans" }),
      /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: process.env.REACT_APP_BASE_BORROWER_URL, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
        Button,
        {
          variant: "ghost",
          className: "text-primary text-xl md:ml-[40px]",
          children: "Dashboard"
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime44.jsxs)(DropdownMenu, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
          Button,
          {
            variant: "ghost",
            className: "text-primary text-xl md:ml-[40px] md:mr-[120px]",
            children: "Education"
          }
        ) }),
        /* @__PURE__ */ (0, import_jsx_runtime44.jsxs)(DropdownMenuContent, { align: "end", children: [
          /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuItem, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: "https://corporatefinanceinstitute.com/resources/accounting/financial-analysis-ratios-glossary/", children: "Financial Dictionary" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuItem, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: "https://fred.stlouisfed.org/", children: "Economic Info" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuItem, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: "https://www.bls.gov/iag/", children: "Industry Trends" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuItem, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: "https://cxmloans.com/content/Connex Markets Borrower Brochure.pdf", children: "5 Mistakes Killing your Chances of Getting Funded" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuItem, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: "https://www.bls.gov/eag/", children: "Regional Trends" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuItem, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: "https://cxmloans.com/content/5 Actions for Financial Resilience.pdf", children: "5 Actions to Fuel Financial Resilience" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(DropdownMenuItem, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("a", { href: "https://www.hbs.edu/faculty/Pages/item.aspx?num=47695", children: "Harvard Report On SMB's Great Read" }) })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime44.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(onboard_widget_default, {}) }),
    /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(footer_default, {})
  ] });
}
var screenone_default = ScreenOne;

// src/components/navbar.tsx
var import_lib22 = require("@cxm/lib");

// src/components/sheet.tsx
var React18 = __toESM(require("react"));
var SheetPrimitive = __toESM(require("@radix-ui/react-dialog"));
var import_class_variance_authority3 = require("class-variance-authority");
var import_lucide_react12 = require("lucide-react");
var import_jsx_runtime45 = require("react/jsx-runtime");
var Sheet = SheetPrimitive.Root;
var SheetTrigger = SheetPrimitive.Trigger;
var SheetPortal = SheetPrimitive.Portal;
var SheetOverlay = React18.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
  SheetPrimitive.Overlay,
  {
    className: cn(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    ),
    ...props,
    ref
  }
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;
var sheetVariants = (0, import_class_variance_authority3.cva)(
  "fixed z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom: "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
        right: "inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm"
      }
    },
    defaultVariants: {
      side: "right"
    }
  }
);
var SheetContent = React18.forwardRef(({ side = "right", className, children, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(SheetPortal, { children: [
  /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(SheetOverlay, {}),
  /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(
    SheetPrimitive.Content,
    {
      ref,
      className: cn(sheetVariants({ side }), className),
      ...props,
      children: [
        children,
        /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(SheetPrimitive.Close, { className: "absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary", children: [
          /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(import_lucide_react12.X, { className: "h-4 w-4" }),
          /* @__PURE__ */ (0, import_jsx_runtime45.jsx)("span", { className: "sr-only", children: "Close" })
        ] })
      ]
    }
  )
] }));
SheetContent.displayName = SheetPrimitive.Content.displayName;
var SheetHeader = ({
  className,
  ...props
}) => /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
  "div",
  {
    className: cn(
      "flex flex-col space-y-2 text-center sm:text-left",
      className
    ),
    ...props
  }
);
SheetHeader.displayName = "SheetHeader";
var SheetFooter = ({
  className,
  ...props
}) => /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
  "div",
  {
    className: cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    ),
    ...props
  }
);
SheetFooter.displayName = "SheetFooter";
var SheetTitle = React18.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
  SheetPrimitive.Title,
  {
    ref,
    className: cn("text-lg font-semibold text-foreground", className),
    ...props
  }
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;
var SheetDescription = React18.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
  SheetPrimitive.Description,
  {
    ref,
    className: cn("text-sm text-muted-foreground", className),
    ...props
  }
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

// src/components/navbar.tsx
var import_lucide_react14 = require("lucide-react");

// src/components/navigation.tsx
var React19 = __toESM(require("react"));
var NavigationMenuPrimitive = __toESM(require("@radix-ui/react-navigation-menu"));
var import_class_variance_authority4 = require("class-variance-authority");
var import_lucide_react13 = require("lucide-react");
var import_jsx_runtime46 = require("react/jsx-runtime");
var NavigationMenu = React19.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(
  NavigationMenuPrimitive.Root,
  {
    ref,
    className: cn(
      "relative z-10 flex max-w-max flex-1 items-center justify-center",
      className
    ),
    ...props,
    children: [
      children,
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(NavigationMenuViewport, {})
    ]
  }
));
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;
var NavigationMenuList = React19.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
  NavigationMenuPrimitive.List,
  {
    ref,
    className: cn(
      "group flex flex-1 list-none items-center justify-center space-x-1",
      className
    ),
    ...props
  }
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;
var navigationMenuTriggerStyle = (0, import_class_variance_authority4.cva)(
  "group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50"
);
var NavigationMenuTrigger = React19.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(
  NavigationMenuPrimitive.Trigger,
  {
    ref,
    className: cn(navigationMenuTriggerStyle(), "group", className),
    ...props,
    children: [
      children,
      " ",
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
        import_lucide_react13.ChevronDown,
        {
          className: "relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180",
          "aria-hidden": "true"
        }
      )
    ]
  }
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;
var NavigationMenuContent = React19.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
  NavigationMenuPrimitive.Content,
  {
    ref,
    className: cn(
      "left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto ",
      className
    ),
    ...props
  }
));
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;
var NavigationMenuLink = NavigationMenuPrimitive.Link;
var NavigationMenuViewport = React19.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime46.jsx)("div", { className: cn("absolute left-0 top-full flex justify-center"), children: /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
  NavigationMenuPrimitive.Viewport,
  {
    className: cn(
      "origin-top-center relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[var(--radix-navigation-menu-viewport-width)]",
      className
    ),
    ref,
    ...props
  }
) }));
NavigationMenuViewport.displayName = NavigationMenuPrimitive.Viewport.displayName;
var NavigationMenuIndicator = React19.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
  NavigationMenuPrimitive.Indicator,
  {
    ref,
    className: cn(
      "top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
      className
    ),
    ...props,
    children: /* @__PURE__ */ (0, import_jsx_runtime46.jsx)("div", { className: "relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-border shadow-md" })
  }
));
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName;

// src/components/navbar.tsx
var import_react_responsive2 = require("react-responsive");
var import_react21 = require("react");
var import_jsx_runtime47 = require("react/jsx-runtime");
var Navbar = (props) => {
  const [borrowerOptions, setBorrowerOptions] = (0, import_react21.useState)();
  const meQuery = (0, import_lib22.useApiQuery)("me", import_lib22.getSelf, {
    enabled: props.portaltype != void 0,
    success: (v) => {
      setBorrowerOptions(
        [
          ...v.Borrowers.map((x) => ({
            label: x.CompanyName,
            value: x.Id
          })),
          { label: "Create New Company", value: "newBorrower" }
        ]
      );
    }
  });
  const activeBorrowerMutation = (0, import_lib22.useApiMutation)(import_lib22.setActiveBorrower, ["me"]);
  const createNewBorrowerMutation = (0, import_lib22.useApiMutation)(import_lib22.createAdditionalBorrower, [
    "me"
  ]);
  const getLinks = () => {
    if (!props.portaltype) {
      return [];
    }
    const iconSize = 18;
    switch (props.portaltype) {
      case import_lib22.PortalType.Borrower:
        return [
          {
            label: "Dashboard",
            path: "/",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.LayoutDashboard, { height: iconSize, width: iconSize })
          },
          {
            label: "Profile",
            path: "/Profile",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.FilePieChart, { height: iconSize, width: iconSize })
          },
          {
            label: "Current Application",
            path: "/Loan",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.BookMarked, { height: iconSize, width: iconSize })
          },
          {
            label: "Loan Simulator",
            path: process.env.REACT_APP_SCREEN_ONE_URL,
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.GaugeIcon, { height: iconSize, width: iconSize })
          }
        ];
      case import_lib22.PortalType.Management:
        var links2 = [
          {
            label: "Dashboard",
            path: "/",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.LayoutDashboard, { height: iconSize, width: iconSize })
          },
          {
            label: "Loans",
            path: "/Loan",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.TableProperties, { height: iconSize, width: iconSize })
          },
          /*{
            label: "Transcripts",
            path: "/Home/ViewTranscripts",
            icon: <Pen height={iconSize} width={iconSize} />,
          },*/
          {
            label: "Borrowers",
            path: "/Home/BorrowerList",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.HandCoins, { height: iconSize, width: iconSize })
          },
          {
            label: "Lenders",
            path: "/Lender",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.Landmark, { height: iconSize, width: iconSize })
          },
          {
            label: "Referrals",
            path: "/Referrals",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.Forward, { height: iconSize, width: iconSize })
          },
          /*{
            label: "Appointments",
            path: "/Appointment/ViewAppointments",
            icon: <Calendar ,
          },*/
          {
            label: "Glossary Manager",
            path: "/GlossaryManager",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.Book, { height: iconSize, width: iconSize })
          }
        ];
        if (meQuery.isSuccess && meQuery.data.Roles.includes("Specialist")) {
          links2.push({
            label: "Dev Tools",
            path: "/Dev",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.Wrench, { height: iconSize, width: iconSize })
          });
        }
        return links2;
      case import_lib22.PortalType.Lender:
        return [
          {
            label: "Dashboard",
            path: "/",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.LayoutDashboard, { height: iconSize, width: iconSize })
          },
          {
            label: "Profile",
            path: "/Profile",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.List, { height: iconSize, width: iconSize })
          },
          {
            label: "Loan Criteria",
            path: "/Criteria",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.FilePieChart, { height: iconSize, width: iconSize })
          },
          {
            label: "Referrals",
            path: "/Referrals",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.Forward, { height: iconSize, width: iconSize })
          },
          {
            label: "Switch Lender",
            path: "/Account/SwitchLender",
            icon: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.ArrowLeftRight, { height: iconSize, width: iconSize })
          }
        ];
    }
  };
  const links = getLinks();
  const isMobile = props.portaltype && (0, import_react_responsive2.useMediaQuery)({ query: "(max-width: 1224px)" });
  const getPortalSubtitle = () => {
    if (!props.portaltype) {
      return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_jsx_runtime47.Fragment, {});
    }
    switch (props.portaltype) {
      case import_lib22.PortalType.Borrower:
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { children: "Borrower Portal" });
      case import_lib22.PortalType.Lender:
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { children: "Lender Portal" });
      case import_lib22.PortalType.Management:
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { children: "Management Portal" });
    }
  };
  const getEnvironmentSubtitle = () => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "staging":
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("b", { className: "text-red-500", children: "STAGING" });
      case "development":
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("b", { className: "text-red-500", children: "DEVELOPMENT" });
      case "sandbox":
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("b", { className: "text-red-500", children: "SANDBOX" });
      case "demo":
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("b", { className: "text-red-500", children: "DEMO" });
      default:
        return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_jsx_runtime47.Fragment, {});
    }
  };
  const confirmDialog = useConfirmDialogContext();
  const navContent = /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_jsx_runtime47.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)("div", { className: "w-full px-2", children: [
    /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { className: "w-full text-left mt-2 text-secondary", children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("b", { children: getPortalSubtitle() }) }),
    /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { className: "border border-b-gray-300 mb-2" }),
    meQuery.isSuccess && props.portaltype == import_lib22.PortalType.Lender && /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { className: "text-center w-full mb-2", children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("b", { children: meQuery.data.ActiveLenderName }) })
  ] }) });
  return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_jsx_runtime47.Fragment, { children: isMobile ? /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)("header", { className: "flex h-20 w-full shrink-0 items-center px-4 md:px-6 border border-b-gray-100", children: [
    /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)(Sheet, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(SheetTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)(Button, { className: "", size: "icon", variant: "outline", children: [
        /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_lucide_react14.MenuIcon, { className: "h-6 w-6" }),
        /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("span", { className: "sr-only", children: "Toggle navigation menu" })
      ] }) }),
      /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)(SheetContent, { side: "left", children: [
        /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)("a", { href: "#", children: [
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("img", { src: "/cxm-text-logo.png", className: "h-[60px]" }),
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("span", { className: "sr-only", children: "CXM Loans" })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)("div", { className: "grid gap-2 py-6", children: [
          navContent,
          links.map((x) => /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
            "a",
            {
              className: "flex w-full items-center py-2 text-lg font-semibold",
              href: x.path,
              children: x.label
            },
            x.path
          )),
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
            "a",
            {
              className: "flex w-full items-center py-2 text-lg font-semibold",
              href: "#",
              children: "Account Settings"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
            "a",
            {
              className: "flex w-full items-center py-2 text-lg font-semibold",
              href: "#",
              children: "Sign Out"
            }
          )
        ] })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)("a", { href: "#", className: "ml-auto", children: [
      /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("img", { src: "/cxm-text-logo.png", className: "h-[30px]" }),
      /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("span", { className: "sr-only", children: "CXM Loans" })
    ] })
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_jsx_runtime47.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
    NavigationMenu,
    {
      className: "items-start flex flex-col justify-start w-[250px]",
      style: {
        borderRight: "2px solid",
        borderRightColor: "rgb(239 233 229)"
      },
      orientation: "vertical",
      children: /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)(NavigationMenuList, { className: "flex flex-col justify-start flex-grow-1 items-start text-lg h-[100vh] px-4 py-2 w-[250px]", children: [
        /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("span", { className: "sr-only", children: "CXM Loans" }),
        /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)("div", { className: "px-2 my-4", children: [
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
            "img",
            {
              width: 180,
              height: 60,
              src: "/cxm-text-logo.png"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { className: "w-full text-center", children: getEnvironmentSubtitle() })
        ] }),
        navContent,
        links.map((x) => {
          const isActive = x.path == "/" ? window.location.pathname == "/" : window.location.pathname.includes(x.path ?? "");
          return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(NavigationMenuLink, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)(
            "a",
            {
              className: "group inline-flex mb-2 items-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-gray-100 hover:text-primary focus:bg-gray-100 focus:text-gray-900 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-gray-100/50 data-[state=open]:bg-gray-100/50 dark:bg-gray-950 dark:hover:bg-primary/10 dark:hover:text-gray-50 dark:focus:bg-gray-800 dark:focus:text-gray-50 dark:data-[active]:bg-gray-800/50 dark:data-[state=open]:bg-gray-800/50 w-full text-left" + (isActive ? " bg-primary/10 text-primary" : ""),
              href: x.path,
              children: [
                x.icon,
                /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("span", { className: "ml-2", children: x.label })
              ]
            },
            x.path
          ) });
        }),
        props.portaltype == import_lib22.PortalType.Borrower && borrowerOptions && borrowerOptions.length > 1 && /* @__PURE__ */ (0, import_jsx_runtime47.jsxs)(import_jsx_runtime47.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("div", { className: "text-sm text-secondary", children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("b", { children: "Current Company" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
            Select,
            {
              className: "w-full mb-2",
              options: borrowerOptions,
              value: borrowerOptions.find(
                (x) => x.value == meQuery.data.ActiveBorrowerId
              ),
              onChange: async (v) => {
                const id = v.value;
                if (id == meQuery.data.ActiveBorrowerId) {
                  return;
                }
                if (id == "newBorrower") {
                  const result = await confirmDialog.openDialogAsync({
                    title: "Create a new Company",
                    message: "Are you sure you want to create a new company profile? This company will have its own, entirely seperate set of users and loan applications. You can switch your current company in the sidebar. For now, only CXM administrators can delete companies."
                  });
                  if (!result) {
                    return;
                  }
                  await createNewBorrowerMutation.mutateAsync({});
                  window.location.pathname = "/Profile";
                  return;
                }
                await activeBorrowerMutation.mutateAsync(id);
                window.location.reload();
              }
            }
          )
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(NavigationMenuLink, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
          "a",
          {
            className: "group inline-flex h-9 w-max items-center justify-center mt-auto rounded-md px-4 py-2 text-md font-medium transition-colors hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-gray-100/50 data-[state=open]:bg-gray-100/50 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus:bg-gray-800 dark:focus:text-gray-50 dark:data-[active]:bg-gray-800/50 dark:data-[state=open]:bg-gray-800/50 text-sm",
            href: "https://cxmloans.com/content/Connex Markets Inc. - Website Privacy Notice (2024.03).pdf",
            children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)("span", { className: "", children: "Privacy Policy" })
          }
        ) })
      ] })
    }
  ) }) });
};
var NavbarOuter = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(Navbar, { ...props }) });
};
var navbar_default = NavbarOuter;

// src/components/utility-bar.tsx
var import_lib23 = require("@cxm/lib");
var import_lucide_react15 = require("lucide-react");
var import_jsx_runtime48 = require("react/jsx-runtime");
var UtilityNavbar = (props) => {
  const meQuery = (0, import_lib23.useApiQuery)("me", import_lib23.getSelf, {
    enabled: props.portaltype != void 0
  });
  const notificationsQuery = (0, import_lib23.useApiQuery)("notifications", import_lib23.getNotifications, {
    enabled: props.portaltype != void 0
  });
  return /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)("div", { className: "ml-auto flex flex-row space-x-1 items-center", children: [
    props.portaltype == import_lib23.PortalType.Borrower && /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(
      user_invite_default,
      {
        initialrole: "Delegate",
        triggerOverride: /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(
          Button,
          {
            variant: "outline",
            className: "rounded-full w-[40px] h-[40px] p-0 ",
            children: /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(import_lucide_react15.UserPlus, { className: "mx-auto" })
          }
        )
      }
    ),
    notificationsQuery.isSuccess && /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)(DropdownMenu, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(DropdownMenuTrigger, { className: "border border-gray-300 rounded-full w-[40px] h-[40px]", children: /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(import_lucide_react15.Bell, { className: "mx-auto" }) }),
      /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)(DropdownMenuContent, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(DropdownMenuLabel, { children: "Notifications" }),
        /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(DropdownMenuSeparator, {}),
        notificationsQuery.data.map((notif) => /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(DropdownMenuItem, { children: notif.NotificationMessage }))
      ] })
    ] }),
    meQuery.isSuccess && /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)(DropdownMenu, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)(DropdownMenuTrigger, { className: "border border-gray-300 py-2 rounded-full px-3", children: [
        meQuery.data.Firstname,
        " ",
        meQuery.data.Lastname
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)(DropdownMenuContent, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(DropdownMenuLabel, { children: "My Account" }),
        /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(DropdownMenuSeparator, {}),
        /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(DropdownMenuItem, { children: "Account Settings" }),
        /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(
          DropdownMenuItem,
          {
            onClick: () => {
              window.location.pathname = "/Account/SignOut";
            },
            children: "Sign Out"
          }
        )
      ] })
    ] })
  ] });
};
var utility_bar_default = UtilityNavbar;

// src/elements/loan-app-selector.tsx
var import_lib24 = require("@cxm/lib");
var import_react22 = require("react");
var import_jsx_runtime49 = require("react/jsx-runtime");
var LoanAppSelector = () => {
  const [borrowerId, setBorrowerId] = (0, import_react22.useState)();
  const [currentLoanApp, setCurrentLoanApp] = (0, import_react22.useState)();
  const [loanAppOptions, setLoanAppOptions] = (0, import_react22.useState)();
  const [fullCurrentLoanApp, setFullCurrentLoanApp] = (0, import_react22.useState)();
  (0, import_lib24.useApiQuery)("me", import_lib24.getSelf, {
    success: (v) => {
      const borrower = v.Borrowers.find(
        (x) => x.Id == v.ActiveBorrowerId
      );
      if (borrower) {
        setCurrentLoanApp(borrower.ActiveLoanApplication);
      }
      setBorrowerId(v.ActiveBorrowerId);
    }
  });
  (0, import_lib24.useApiQuery)("loanApps", (0, import_lib24.getLoanApplications)(borrowerId ?? ""), {
    enabled: borrowerId != void 0,
    success: (v) => {
      var fullCurrentLoanApp2 = v.find(
        (x) => x.Id == currentLoanApp
      );
      setFullCurrentLoanApp(fullCurrentLoanApp2);
      setLoanAppOptions(
        v.map((x) => ({
          label: (0, import_lib24.getLoanAppTitle)(x),
          value: x.Id
        }))
      );
    }
  });
  const setActiveLoanAppMutation = (0, import_lib24.useApiMutation)(import_lib24.setActiveLoanApplication, []);
  if (!currentLoanApp || !loanAppOptions || loanAppOptions.length < 2) {
    return /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(import_jsx_runtime49.Fragment, {});
  }
  return /* @__PURE__ */ (0, import_jsx_runtime49.jsxs)("div", { className: "mt-4", children: [
    /* @__PURE__ */ (0, import_jsx_runtime49.jsx)("b", { children: "Select Loan Application" }),
    /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(
      Select,
      {
        menuPortalTarget: document.body,
        menuPosition: "absolute",
        className: "max-w-[400px] z-10",
        options: loanAppOptions,
        value: loanAppOptions.find((x) => x.value == currentLoanApp),
        onChange: async (v) => {
          const id = v.value;
          if (id == currentLoanApp) {
            return;
          }
          await setActiveLoanAppMutation.mutateAsync(id);
          window.location.reload();
        }
      }
    )
  ] });
};
var loan_app_selector_default = LoanAppSelector;

// src/elements/offer-detail.tsx
var import_lib25 = require("@cxm/lib");
var import_lucide_react16 = require("lucide-react");
var import_jsx_runtime50 = require("react/jsx-runtime");
var OfferDetail = ({ offerid, portaltype }) => {
  const offerDetailQuery = (0, import_lib25.useApiQuery)(
    "offer-" + offerid,
    (0, import_lib25.getOffer)(portaltype, offerid)
  );
  const acceptMutation = (0, import_lib25.useApiMutation)(import_lib25.acceptOffer, []);
  const declineMutation = (0, import_lib25.useApiMutation)(import_lib25.declineOffer, []);
  const confirmDialog = useConfirmDialogContext();
  if (!offerDetailQuery.isSuccess)
    return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: "Loading..." });
  const offerDetail = offerDetailQuery.data;
  console.log(offerDetail);
  return /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)("div", { className: "w-screen md:max-w-[800px]", children: [
    /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)("div", { className: "flex flex-row justify-start space-x-4", children: [
      /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("h1", { className: "text-xl mb-4", children: /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)("b", { children: [
        offerDetail.IsBinding ? "Binding Term Sheet" : "Non-Binding Term Sheet",
        " from ",
        offerDetail.LenderName
      ] }) }),
      portaltype == import_lib25.PortalType.Borrower && offerDetail.OfferStatus == import_lib25.OfferStatus.ApprovedBySpecialist && /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)(import_jsx_runtime50.Fragment, { children: [
        !offerDetail.IsBinding && /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(
          Button,
          {
            className: "w-[120px] text-xl",
            variant: "success",
            onClick: async () => {
              if (!await confirmDialog.openDialogAsync({
                title: "Accept Offer",
                message: `Accept this offer from ${offerDetail.LenderName}?`
              })) {
                return;
              }
              await acceptMutation.mutateAsync(offerid);
              window.location.href = "/Loan/Details/" + offerDetail.PublicApplicationId;
            },
            children: "Accept"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(
          Button,
          {
            variant: "destructive",
            className: "w-[120px] text-xl",
            onClick: async () => {
              if (!await confirmDialog.openDialogAsync({
                title: "Decline Offer",
                message: `Decline this offer from ${offerDetail.LenderName}?`
              })) {
                return;
              }
              await declineMutation.mutateAsync(offerid);
              window.location.href = "/Loan/Details/" + offerDetail.PublicApplicationId;
            },
            children: "Decline"
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(
      DataRows,
      {
        className: "text-base max-w-[800px]",
        data: {
          LoanType: import_lib25.loanTypes.find((x) => x.id == offerDetail.LoanType)?.fullName,
          LoanAmount: currency(offerDetail.LoanAmount),
          MonthlyPayment: currency(offerDetail.MonthlyPayment),
          Rate: formatPercentNumber(offerDetail.Rate / 100),
          Maturity: offerDetail.Maturity + " Months",
          Amortization: offerDetail.Amortization + " Months",
          Collaterals: offerDetail.Collaterals,
          Covenants: offerDetail.Covenants,
          PersonalGuaranteeRequired: offerDetail.GuaranteesRequired,
          ApplicationFees: currency(offerDetail.ApplicationFees),
          BackEndFees: formatPercentNumber(offerDetail.BackEndFees),
          OriginationFees: formatPercentNumber(offerDetail.OriginationFees),
          CxmOriginationFees: formatPercentNumber(
            offerDetail.CxmOriginationFees
          ),
          EarlyTerminationFees: offerDetail.EarlyTerminationFees,
          TargetCloseDate: new Date(offerDetail.TargetCloseDate).toLocaleString(
            "en",
            {
              year: "numeric",
              day: "numeric",
              month: "numeric"
            }
          ),
          TotalFees: `${currency(offerDetail.TotalCashFees)} (${formatPercentNumber(offerDetail.TotalPercentageFees)})`,
          Files: offerDetail.Files
        },
        fields: {
          Files: {
            render(v) {
              if (v.length == 0) {
                return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: "None" });
              }
              return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { className: "flex flex-col", children: v.map((file) => /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(
                "a",
                {
                  className: "hover:underline text-blue-500",
                  href: (portaltype != import_lib25.PortalType.Borrower ? "/Loan/DownloadOfferFile?fileId=" : "/LoanOffers/DownloadOfferFile?fileId=") + file.Id,
                  children: file.Name
                }
              )) });
            }
          },
          CxmOriginationFees: {
            label: "CXM Origination Fees"
          },
          EarlyTerminationFees: {
            render(v) {
              return currency(v);
            }
          },
          Maturity: {
            render(v) {
              return v.toString();
            }
          },
          PersonalGuaranteeRequired: {
            render(v) {
              return v ? "Yes" : "No";
            }
          },
          Collaterals: {
            render(v) {
              if (v.length == 0)
                return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: "None" });
              return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: v.map((collateral) => /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: collateral }, collateral)) });
            }
          },
          Covenants: {
            render(v) {
              if (v.length == 0)
                return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: "None" });
              return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: v.map((covenant) => /* @__PURE__ */ (0, import_jsx_runtime50.jsx)("div", { children: covenant }, covenant)) });
            }
          }
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(
      "a",
      {
        href: process.env.REACT_APP_BASE_API_URL + "/api/offers/DownloadOfferPackage?offerId=" + offerDetail.Id,
        children: /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)(Button, { variant: "outline", className: "px-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(import_lucide_react16.Download, { className: "mr-2", height: 14, width: 14 }),
          "Download Offer (.zip)"
        ] })
      }
    )
  ] });
};
var OfferDetailOuter = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(OfferDetail, { ...props }) });
};
var offer_detail_default = OfferDetailOuter;

// src/elements/specialist-loan-table.tsx
var import_lib26 = require("@cxm/lib");
var import_react23 = require("react");
var import_jsx_runtime51 = require("react/jsx-runtime");
var LoanAppTableConfig = [
  {
    accessorKey: "SubmittedAt",
    header: SortedHeader("Submitted"),
    cell: (v) => new Date(v.getValue()).toLocaleString("en", {
      month: "numeric",
      year: "numeric",
      day: "2-digit"
    })
  },
  {
    accessorKey: "CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "PublicStatus",
    accessorFn: (v) => (0, import_lib26.getLoanStatusMessage)(v, import_lib26.PortalType.Management).category,
    header: SortedHeader("Status"),
    enableColumnFilter: true,
    filterFn: "weakEquals"
  },
  {
    accessorKey: "LastStatusChange",
    accessorFn: (v) => (/* @__PURE__ */ new Date()).getTime() - v.LastStatusChange,
    cell: (v) => timeSince(v.getValue()),
    header: SortedHeader("Time In Status")
  },
  {
    accessorKey: "PublicStatusDetail",
    accessorFn: (v) => (0, import_lib26.getStatusMessageString)(
      (0, import_lib26.getLoanStatusMessage)(v, import_lib26.PortalType.Lender).message
    ),
    header: "Status Details"
  },
  {
    accessorKey: "LoanAmount",
    header: SortedHeader("Loan Amount"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "LoanType",
    header: SortedHeader("Loan Type"),
    cell: (v) => import_lib26.loanTypes.find((x) => x.id == v.getValue())?.fullName
  },
  {
    accessorKey: "SpecialistName",
    header: SortedHeader("Specialist"),
    cell: (v) => v.getValue() ?? "None Assigned"
  }
];
var SpecialistLoanTable = () => {
  const allLoansQuery = (0, import_lib26.useApiQuery)("allLoanApps", import_lib26.getAllLoans, {
    success: (x) => {
      const loans = x.map((y) => ({
        ...y,
        CompanyName: y.CompanyName ?? "Missing Company Name",
        Active: !["Closed", "Declined", "Cancelled"].includes(
          (0, import_lib26.getLoanStatusMessage)(y, import_lib26.PortalType.Management).category
        )
      }));
      setInActiveLoans(
        loans.filter((x2) => !x2.Active)
      );
      setActiveLoans(
        loans.filter((x2) => x2.Active)
      );
    }
  });
  const [activeLoans, setActiveLoans] = (0, import_react23.useState)([]);
  const [inActiveLoans, setInActiveLoans] = (0, import_react23.useState)([]);
  return /* @__PURE__ */ (0, import_jsx_runtime51.jsxs)("div", { className: "container", children: [
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)("h1", { className: "text-4xl", children: "Active Loans" }),
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)("div", { className: "w-full max-w-[600px] border-b border-b-gray-300 mb-3" }),
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(
      loan_table_default,
      {
        initialSortingState: [
          {
            id: "SubmittedAt",
            desc: true
          }
        ],
        tableConfig: LoanAppTableConfig,
        loanStatusOptions: import_lib26.specialistLoanStatusOptions,
        loans: activeLoans,
        loading: allLoansQuery.isLoading
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)("h1", { className: "text-4xl mt-8", children: "Inactive Loans" }),
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)("div", { className: "w-full max-w-[600px] border-b border-b-gray-300 mb-3" }),
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(
      loan_table_default,
      {
        initialSortingState: [
          {
            id: "SubmittedAt",
            desc: true
          }
        ],
        tableConfig: LoanAppTableConfig,
        loanStatusOptions: import_lib26.specialistLoanStatusOptions,
        loans: inActiveLoans,
        loading: allLoansQuery.isLoading
      }
    )
  ] });
};
var specialist_loan_table_default = SpecialistLoanTable;

// src/elements/lender-referrals.tsx
var import_react_table4 = require("@tanstack/react-table");
var import_lib27 = require("@cxm/lib");
var import_react24 = require("react");
var import_zod8 = require("@hookform/resolvers/zod");
var import_react_hook_form7 = require("react-hook-form");
var import_react_phone_input_24 = __toESM(require("react-phone-input-2"));
var import_lodash5 = require("lodash");
var import_jsx_runtime52 = require("react/jsx-runtime");
var ReferralTableConfig = [
  {
    accessorKey: "CreatedAt",
    header: SortedHeader("Submitted"),
    cell: (v) => new Date(v.getValue()).toLocaleString("en", {
      month: "numeric",
      year: "numeric",
      day: "2-digit"
    })
  },
  {
    accessorKey: "CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "LoanAsk",
    header: SortedHeader("Loan Amount"),
    cell: (v) => v.getValue() ? (0, import_lib27.currency)(v.getValue()) : "-"
  },
  {
    accessorKey: "LoanType",
    header: SortedHeader("Loan Type"),
    cell: (v) => import_lib27.loanTypes.find((x) => x.id == v.getValue())?.fullName ?? "-"
  }
];
var MoneyField = ({ label, name, dontAllowNegative }) => {
  const [focused, setFocused] = (0, import_react24.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
    FormField,
    {
      name,
      render: ({ field }) => {
        const [stringValue, setStringValue] = (0, import_react24.useState)(
          field.value ? field.value : ""
        );
        return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(FormItem, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormLabel, { children: label }),
          /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
            Input,
            {
              type: "text",
              ...field,
              onFocus: () => setFocused(true),
              onBlur: () => {
                field.onBlur();
                setFocused(false);
              },
              value: displayMoney(stringValue),
              onChange: (e) => {
                var stringVal = formatMoney(
                  e.target.value
                );
                setStringValue(
                  stringVal
                );
                if (stringVal == "-" || stringVal == "") {
                  field.onChange(0);
                } else {
                  field.onChange(parseInt(stringVal));
                }
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormMessage, { className: "text-right" })
        ] });
      }
    }
  );
};
var LenderReferrals = () => {
  const referralsQuery = (0, import_lib27.useApiQuery)("lenderReferrals", import_lib27.getReferralsForLender, {
    success: (x) => {
      setReferrals(x.map((y) => ({
        ...y
      })));
    }
  });
  const submitReferralMutation = (0, import_lib27.useApiMutation)(import_lib27.submitReferral, [
    "lenderReferrals"
  ]);
  const uploadReferralFileMutation = (0, import_lib27.useApiMutation)(import_lib27.uploadReferralFiles, []);
  const [referrals, setReferrals] = (0, import_react24.useState)([]);
  const [sorting, setSorting] = (0, import_react24.useState)([
    {
      id: "SubmittedAt",
      desc: true
    }
  ]);
  const table = (0, import_react_table4.useReactTable)({
    data: referrals ?? [],
    state: {
      sorting
    },
    onSortingChange: setSorting,
    columns: ReferralTableConfig,
    getCoreRowModel: (0, import_react_table4.getCoreRowModel)(),
    getSortedRowModel: (0, import_react_table4.getSortedRowModel)(),
    getFilteredRowModel: (0, import_react_table4.getFilteredRowModel)()
  });
  const form = (0, import_react_hook_form7.useForm)({
    resolver: (0, import_zod8.zodResolver)(import_lib27.referralDataModel),
    defaultValues: {
      ReferralFiles: []
    }
  });
  const [referralDialogOpen, setReferralDialogOpen] = (0, import_react24.useState)(false);
  const [referralSubmitted, setReferralSubmitted] = (0, import_react24.useState)(false);
  const [files, setFiles] = (0, import_react24.useState)([]);
  return /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(import_jsx_runtime52.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)("div", { className: "w-full max-w-full p-1 h-full flex flex-col space-y-1", children: [
    /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)("div", { className: "flex flex-row", children: [
      /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
        Input,
        {
          className: "w-1/3 h-[42px]",
          placeholder: "Search",
          onChange: (e) => table.setGlobalFilter(e.target.value)
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(
        Dialog,
        {
          open: referralDialogOpen,
          onOpenChange: (v) => {
            if (v) {
              setReferralSubmitted(false);
            }
            setReferralDialogOpen(v);
          },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(DialogTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Button, { className: "ml-auto", children: "New Referral" }) }),
            /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(DialogContent, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(DialogHeader, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(DialogTitle, { children: "New Referral" }) }),
              /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Form, { ...form, children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                "form",
                {
                  onSubmit: form.handleSubmit(async (v) => {
                    v.ReferralFiles = files.map((x) => x.PublicId);
                    await submitReferralMutation.mutateAsync({
                      referral: v
                    });
                    form.reset();
                    setReferralSubmitted(true);
                  }),
                  onError: (e) => {
                    console.log(e);
                  },
                  children: referralSubmitted ? /* @__PURE__ */ (0, import_jsx_runtime52.jsx)("div", { children: "Your referral has been submitted." }) : /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(import_jsx_runtime52.Fragment, { children: [
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      FormField,
                      {
                        name: "CompanyName",
                        render: ({ field }) => {
                          return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(
                            FormItem,
                            {
                              className: "",
                              children: [
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormLabel, { children: "Company Name" }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Input, { ...field }) }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormMessage, { className: "text-right" })
                              ]
                            }
                          );
                        }
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      FormField,
                      {
                        name: "ContactName",
                        render: ({ field }) => {
                          return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(
                            FormItem,
                            {
                              className: "",
                              children: [
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormLabel, { children: "Contact Name" }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Input, { ...field }) }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormMessage, { className: "text-right" })
                              ]
                            }
                          );
                        }
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      FormField,
                      {
                        name: "ContactEmail",
                        render: ({ field }) => {
                          return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(
                            FormItem,
                            {
                              className: "",
                              children: [
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormLabel, { children: "Contact Email" }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Input, { ...field }) }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormMessage, { className: "text-right" })
                              ]
                            }
                          );
                        }
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      FormField,
                      {
                        name: "ContactPhone",
                        render: ({ field }) => {
                          return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(
                            FormItem,
                            {
                              className: "",
                              children: [
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormLabel, { children: "Contact Phone" }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormControl, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                                  import_react_phone_input_24.default,
                                  {
                                    specialLabel: "",
                                    country: "us",
                                    inputProps: {
                                      className: "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                    },
                                    value: field.value,
                                    onChange: (e) => {
                                      field.onChange(e);
                                    }
                                  }
                                ) }),
                                /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormMessage, { className: "text-right" })
                              ]
                            }
                          );
                        }
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      MoneyField,
                      {
                        label: "Loan Amount",
                        name: "LoanAsk"
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      FormField,
                      {
                        name: "LoanType",
                        render: ({ field }) => {
                          return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(FormItem, { children: [
                            /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormLabel, { children: "Loan Type" }),
                            /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormControl, { children: field.disabled ? /* @__PURE__ */ (0, import_jsx_runtime52.jsx)("div", { className: "text-heavy", children: import_lib27.loanTypeOptions.find(
                              (opt) => opt.value == field.value
                            )?.label }) : /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                              Select,
                              {
                                options: import_lib27.loanTypeOptions,
                                ...field,
                                value: (0, import_lodash5.isObject)(field.value) ? field.value : import_lib27.loanTypeOptions.find(
                                  (opt) => opt.value == field.value
                                ),
                                onChange: (v) => field.onChange(v.value)
                              }
                            ) }),
                            /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(FormMessage, { className: "text-right" })
                          ] });
                        }
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)("div", { className: "mt-4 hidden", children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)("b", { children: "Upload Related Files" }) }),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      Input,
                      {
                        type: "file",
                        className: "hidden",
                        value: "",
                        onChange: async (e) => {
                          if (!e.target.files || e.target.files?.length == 0)
                            return;
                          const formData = new FormData();
                          for (var i = 0; i < e.target.files.length; i++) {
                            var file = e.target.files.item(i);
                            if (file != null) {
                              formData.append("file" + i, file);
                            }
                          }
                          const result = await uploadReferralFileMutation.mutateAsync({
                            formData
                          });
                          setFiles((f) => [
                            ...f,
                            ...result
                          ]);
                        }
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)("div", { className: "flex flex-col my-2", children: files.map((x) => /* @__PURE__ */ (0, import_jsx_runtime52.jsx)("div", { children: x.Name })) }),
                    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
                      Button,
                      {
                        disabled: submitReferralMutation.isLoading,
                        className: "mt-4 w-full",
                        children: submitReferralMutation.isLoading ? "Loading..." : "Submit"
                      }
                    )
                  ] })
                }
              ) })
            ] })
          ]
        }
      )
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)("div", { className: "h-full w-full overflow-auto flex-grow rounded-md border relative", children: /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(Table, { parentClassName: "", className: "", children: [
      /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(TableHeader, { className: "", children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(TableRow, { className: "", children: headerGroup.headers.map((header) => /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
        TableCell,
        {
          className: "bg-white whitespace-nowrap text-center",
          children: header.isPlaceholder ? null : (0, import_react_table4.flexRender)(
            header.column.columnDef.header,
            header.getContext()
          )
        },
        header.id
      )) }, headerGroup.id)) }),
      referralsQuery.isLoading ? /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(TableBody, { className: "text-center", children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(TableRow, { className: "absolute w-full h-full text-center loading-animation" }) }) : /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(TableBody, { className: "justify-start", children: table.getRowModel().rows.map((row) => /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
        TableRow,
        {
          className: "hover:bg-gray-200 h-[60px]",
          children: row.getVisibleCells().map((cell) => /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
            TableCell,
            {
              className: "text-center",
              style: {
                paddingLeft: "0.5rem !important",
                paddingRight: "0.5rem !important"
              },
              children: (0, import_react_table4.flexRender)(
                cell.column.columnDef.cell,
                cell.getContext()
              )
            },
            cell.id
          ))
        },
        row.id
      )) })
    ] }) })
  ] }) });
};
var lender_referrals_default = LenderReferrals;

// src/elements/specialist-referrals.tsx
var import_react_table5 = require("@tanstack/react-table");
var import_lib28 = require("@cxm/lib");
var import_react25 = require("react");
var import_awesome_phonenumber3 = require("awesome-phonenumber");
var import_react_dialog2 = require("@radix-ui/react-dialog");
var import_jsx_runtime53 = require("react/jsx-runtime");
var ReferralTableConfig2 = [
  {
    accessorKey: "CreatedAt",
    header: SortedHeader("Submitted"),
    cell: (v) => new Date(v.getValue()).toLocaleString("en", {
      month: "numeric",
      year: "numeric",
      day: "2-digit"
    })
  },
  {
    accessorKey: "Data.CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "Data.LoanAsk",
    header: SortedHeader("Loan Amount"),
    cell: (v) => v.getValue() ? (0, import_lib28.currency)(v.getValue()) : "-"
  },
  {
    accessorKey: "Data.LoanType",
    header: SortedHeader("Loan Type"),
    cell: (v) => import_lib28.loanTypes.find((x) => x.id == v.getValue())?.fullName ?? "-"
  },
  {
    accessorKey: "Data.ContactName",
    header: SortedHeader("Contact Name")
  },
  {
    accessorKey: "Data.ContactEmail",
    header: SortedHeader("Contact Email")
  },
  {
    accessorKey: "Data.ContactPhone",
    header: SortedHeader("Contact Phone"),
    cell: (v) => v.getValue() ? (0, import_awesome_phonenumber3.parsePhoneNumber)("+" + v.getValue()).number?.national : "-"
  }
];
var SpecialistReferrals = () => {
  const referralsQuery = (0, import_lib28.useApiQuery)("allReferrals", import_lib28.getAllReferrals, {
    success: (x) => {
      setReferrals(x.map((y) => ({
        ...y
      })));
    }
  });
  const [referrals, setReferrals] = (0, import_react25.useState)([]);
  const [sorting, setSorting] = (0, import_react25.useState)([
    {
      id: "SubmittedAt",
      desc: true
    }
  ]);
  const table = (0, import_react_table5.useReactTable)({
    data: referrals ?? [],
    state: {
      sorting
    },
    onSortingChange: setSorting,
    columns: ReferralTableConfig2,
    getCoreRowModel: (0, import_react_table5.getCoreRowModel)(),
    getSortedRowModel: (0, import_react_table5.getSortedRowModel)(),
    getFilteredRowModel: (0, import_react_table5.getFilteredRowModel)()
  });
  const [detailDialogOpen, setDetailDialogOpen] = (0, import_react25.useState)(false);
  const [detail, setDetail] = (0, import_react25.useState)();
  return /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(import_jsx_runtime53.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(
      Dialog,
      {
        open: detailDialogOpen && detail != void 0,
        onOpenChange: setDetailDialogOpen,
        children: /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(DialogContent, { className: "container", children: [
          /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(import_react_dialog2.DialogTitle, { className: "text-xl", children: [
            "Referral - ",
            detail?.Data.CompanyName
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(
            DataRows,
            {
              className: "text-md max-w-[500px]",
              data: {
                ...detail?.Data,
                ContactPhone: (0, import_awesome_phonenumber3.parsePhoneNumber)(
                  "+" + (detail?.Data.ContactPhone ?? "")
                ).number?.national,
                LoanAsk: detail?.Data.LoanAsk ? (0, import_lib28.currency)(detail?.Data.LoanAsk) : "-",
                LoanType: import_lib28.loanTypes.find((x) => x.id == detail?.Data.LoanType)?.fullName ?? "-"
              },
              fields: {
                ReferralFiles: {
                  hidden: true
                }
              }
            }
          ),
          (detail?.Data.ReferralFiles?.length ?? 0) > 0 && /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(
            "a",
            {
              className: "max-w-[240px]",
              href: process.env.REACT_APP_BASE_API_URL + `/api/referrals/DownloadReferralPackage?referralId=${detail?.Id}`,
              children: /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(Button, { className: "w-full", children: "Download Referral Package" })
            }
          )
        ] })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)("div", { className: "w-full max-w-full p-1 h-full flex flex-col space-y-1", children: [
      /* @__PURE__ */ (0, import_jsx_runtime53.jsx)("div", { className: "flex flex-row", children: /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(
        Input,
        {
          className: "w-1/3 h-[42px]",
          placeholder: "Search",
          onChange: (e) => table.setGlobalFilter(e.target.value)
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime53.jsx)("div", { className: "h-full w-full overflow-auto flex-grow rounded-md border relative", children: /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(Table, { parentClassName: "", className: "", children: [
        /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(TableHeader, { className: "", children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(TableRow, { className: "", children: headerGroup.headers.map((header) => /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(
          TableCell,
          {
            className: "bg-white whitespace-nowrap text-center",
            children: header.isPlaceholder ? null : (0, import_react_table5.flexRender)(
              header.column.columnDef.header,
              header.getContext()
            )
          },
          header.id
        )) }, headerGroup.id)) }),
        referralsQuery.isLoading ? /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(TableBody, { className: "text-center", children: /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(TableRow, { className: "absolute w-full h-full text-center loading-animation" }) }) : /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(TableBody, { className: "justify-start", children: table.getRowModel().rows.map((row) => /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(
          TableRow,
          {
            className: "hover:bg-gray-200 h-[60px] cursor-pointer",
            onClick: () => {
              setDetail(row.original);
              setDetailDialogOpen(true);
            },
            children: row.getVisibleCells().map((cell) => /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(
              TableCell,
              {
                className: "text-center",
                style: {
                  paddingLeft: "0.5rem !important",
                  paddingRight: "0.5rem !important"
                },
                children: (0, import_react_table5.flexRender)(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )
              },
              cell.id
            ))
          },
          row.id
        )) })
      ] }) })
    ] })
  ] });
};
var specialist_referrals_default = SpecialistReferrals;

// src/elements/lender-loan-table.tsx
var import_lib29 = require("@cxm/lib");
var import_react26 = require("react");
var import_jsx_runtime54 = require("react/jsx-runtime");
var loanAppTableConfig2 = [
  {
    accessorKey: "SubmittedAt",
    header: SortedHeader("Submitted"),
    cell: (v) => new Date(v.getValue()).toLocaleString("en", {
      month: "numeric",
      year: "numeric",
      day: "2-digit"
    })
  },
  {
    accessorKey: "CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "PublicStatus",
    accessorFn: (v) => (0, import_lib29.getLoanStatusMessage)(v, import_lib29.PortalType.Lender).category,
    header: SortedHeader("Status"),
    enableColumnFilter: true,
    filterFn: "weakEquals"
  },
  {
    accessorKey: "LastStatusChange",
    accessorFn: (v) => (/* @__PURE__ */ new Date()).getTime() - v.LastStatusChange,
    cell: (v) => timeSince(v.getValue()),
    header: SortedHeader("Time In Status")
  },
  {
    accessorKey: "PublicStatusDetail",
    accessorFn: (v) => (0, import_lib29.getStatusMessageString)(
      (0, import_lib29.getLoanStatusMessage)(v, import_lib29.PortalType.Lender).message
    ),
    header: "Status Details"
  },
  {
    accessorKey: "LoanAmount",
    header: SortedHeader("Loan Amount"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "LoanType",
    header: SortedHeader("Loan Type"),
    cell: (v) => import_lib29.loanTypes.find((x) => x.id == v.getValue())?.fullName
  },
  {
    accessorKey: "SpecialistName",
    header: SortedHeader("Specialist"),
    cell: (v) => v.getValue() ?? "None Assigned"
  }
];
var LenderLoanTable = () => {
  const allLoansQuery = (0, import_lib29.useApiQuery)("lenderLoans", import_lib29.getLenderLoans, {
    success: (x) => {
      setLoans(x.map((y) => ({
        ...y,
        CompanyName: y.CompanyName ?? "Missing Company Name"
      })));
    }
  });
  const [loans, setLoans] = (0, import_react26.useState)([]);
  return /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(
    loan_table_default,
    {
      initialSortingState: [{
        id: "SubmittedAt",
        desc: true
      }],
      tableConfig: loanAppTableConfig2,
      loanStatusOptions: import_lib29.lenderLoanStatusOptions,
      loans,
      loading: allLoansQuery.isLoading
    }
  );
};
var lender_loan_table_default = LenderLoanTable;

// src/elements/header.tsx
var import_lib30 = require("@cxm/lib");
var import_react27 = require("react");
var import_lucide_react17 = require("lucide-react");
var import_jsx_runtime55 = require("react/jsx-runtime");
var isValid = (value) => {
  if (!value) {
    return false;
  }
  return value.trim().length > 0;
};
var Header = ({ portaltype }) => {
  const [profileDialogOpen, setProfileDialogOpen] = (0, import_react27.useState)(false);
  const [profileDialogForced, setProfileDialogForced] = (0, import_react27.useState)(false);
  const meQuery = (0, import_lib30.useApiQuery)("me", import_lib30.getSelf, {
    enabled: portaltype != void 0,
    success: (data) => {
      if (!isValid(data.Firstname) || !isValid(data.Lastname) || !isValid(data.PhoneNumber) || data.PhoneNumber.length < 5) {
        setProfileDialogForced(true);
        setProfileDialogOpen(true);
      }
    }
  });
  const [notifications, setNotifications] = (0, import_react27.useState)();
  const [unreadCount, setUnreadCount] = (0, import_react27.useState)(0);
  const checkNotifsMutation = (0, import_lib30.useApiMutation)(import_lib30.checkNotifs, [
    "notifications",
    "me"
  ]);
  const [lastMarkAllAsReadTime, setLastMarkAllAsReadTime] = (0, import_react27.useState)(0);
  const [firstUnreadNotification, setFirstUnreadNotification] = (0, import_react27.useState)();
  (0, import_lib30.useApiQuery)("notifications", import_lib30.getNotifications, {
    enabled: portaltype != void 0 && meQuery.isSuccess,
    success: (v) => {
      const notifs = v.sort(
        (x) => x.CreatedDateMillis
      ).map(
        (x) => {
          x.Timestamp = new Date(x.CreatedDateMillis);
          x.Unread = x.Level != import_lib30.NotificationLevel.Seen || lastMarkAllAsReadTime > x.CreatedDateMillis;
          return x;
        }
      );
      setNotifications(
        notifs
      );
      const unreadCount2 = notifs.filter((x) => x.Unread).length;
      setUnreadCount(unreadCount2);
      setFirstUnreadNotification(notifs.find((x) => x.Unread));
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)("div", { className: "w-full flex flex-row p-1 border-b", children: [
    meQuery.isSuccess ? /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)("div", { className: "ml-auto flex flex-row space-x-2 items-center justify-between mb-2 mt-2", children: [
      firstUnreadNotification && /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)("div", { className: "flex flex-row bg-orange-200 rounded-md py-1 px-2 border border-orange-300", children: [
        /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { className: "mr-2", children: firstUnreadNotification.NotificationMessage }),
        /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { className: "ml-auto", children: firstUnreadNotification.Timestamp.toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          hour: "numeric",
          minute: "numeric"
        }) })
      ] }),
      notifications && /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(DropdownMenu, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(
          DropdownMenuTrigger,
          {
            className: "border rounded-md w-[40px] h-[40px] relative " + (unreadCount <= 0 ? "border-gray-300" : "border-orange-300 bg-orange-200 border-4"),
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
                import_lucide_react17.Bell,
                {
                  className: "fill-black text-black mx-auto ",
                  style: {
                    transform: "rotate(20deg)"
                  }
                }
              ),
              unreadCount > 0 && /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { className: "absolute -top-2 -right-2 text-sm rounded-full bg-orange-400 w-[20px] h-[20px]", children: /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("b", { children: unreadCount }) })
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(DropdownMenuContent, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(DropdownMenuLabel, { className: "flex flex-row", children: [
            /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { children: "Notifications" }),
            /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
              "a",
              {
                className: "ml-auto text-primary",
                onClick: async (e) => {
                  setLastMarkAllAsReadTime(
                    (/* @__PURE__ */ new Date()).getMilliseconds()
                  );
                  await checkNotifsMutation.mutateAsync({});
                },
                href: "#",
                children: /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("u", { children: "Mark all as read" })
              }
            )
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(DropdownMenuSeparator, {}),
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { className: "flex flex-col max-h-[300px] overflow-auto", children: notifications.map((notif, idx) => /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(
            DropdownMenuItem,
            {
              className: "flex flex-row" + (notif.Unread ? " bg-orange-100" : ""),
              children: [
                /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { className: "mr-2", children: notif.NotificationMessage }),
                /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { className: "ml-auto", children: notif.Timestamp.toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  hour: "numeric",
                  minute: "numeric"
                }) })
              ]
            },
            idx
          )) })
        ] })
      ] }),
      portaltype == import_lib30.PortalType.Borrower && /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
        user_invite_default,
        {
          initialrole: "Delegate",
          triggerOverride: /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
            Button,
            {
              variant: "outline",
              className: "ml-auto rounded-md w-[40px] h-[40px] p-0 ",
              children: /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(import_lucide_react17.UserPlus, { className: "fill-black mx-auto" })
            }
          )
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(DropdownMenu, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(DropdownMenuTrigger, { className: "border border-gray-300 py-2 rounded-md px-3", children: [
          meQuery.data.Firstname,
          " ",
          meQuery.data.Lastname
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(DropdownMenuContent, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(DropdownMenuLabel, { children: "My Account" }),
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(DropdownMenuSeparator, {}),
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
            DropdownMenuItem,
            {
              onClick: () => {
                setProfileDialogForced(false);
                setProfileDialogOpen(true);
              },
              children: "My Profile"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
            DropdownMenuItem,
            {
              onClick: () => {
                window.location.pathname = "/Account/SignOut";
              },
              children: "Sign Out"
            }
          )
        ] })
      ] })
    ] }) : /* @__PURE__ */ (0, import_jsx_runtime55.jsx)("div", { className: "h-[46px]" }),
    meQuery.isSuccess && /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
      update_profile_dialog_default,
      {
        dialogOpen: profileDialogOpen,
        isForced: profileDialogForced,
        setDialogOpen: setProfileDialogOpen,
        initialProfile: meQuery.data
      }
    )
  ] });
};
var header_default = Header;

// src/elements/submit-loan-dialog.tsx
var import_react28 = require("react");
var import_lib31 = require("@cxm/lib");
var import_jsx_runtime56 = require("react/jsx-runtime");
var SubmitLoanDialog = ({ loanappid }) => {
  const [deadline, setDeadline] = (0, import_react28.useState)(() => {
    const now = /* @__PURE__ */ new Date();
    now.setDate(now.getDate() + 7);
    return now;
  });
  const [selectedLenders, setSelectedLenders] = (0, import_react28.useState)();
  const [lenderFilter, setLenderFilter] = (0, import_react28.useState)("");
  const [calculatedMatches, setCalculatedMatches] = (0, import_react28.useState)();
  const [allLenders, setAllLenders] = (0, import_react28.useState)();
  const submitMutation = (0, import_lib31.useApiMutation)(import_lib31.submitLoanApplication, []);
  (0, import_lib31.useApiQuery)(
    "matchedLenders-" + loanappid,
    (0, import_lib31.getCalculatedMatchedLenders)(loanappid),
    {
      success: (v) => {
        setCalculatedMatches(v.map((x) => x.Id));
        setSelectedLenders(v.map((x) => x.Id));
      }
    }
  );
  (0, import_lib31.useApiQuery)(
    "lenders",
    import_lib31.getLenders,
    {
      success: (v) => {
        setAllLenders(
          v.sort((a, b) => stringSorter(a.LenderName, b.LenderName))
        );
      }
    }
  );
  const toggleLender = (id) => {
    if (!selectedLenders) {
      return;
    }
    let lenders = selectedLenders;
    if (selectedLenders?.includes(id)) {
      lenders = selectedLenders.filter((x) => x != id);
    } else {
      lenders = [...selectedLenders, id];
    }
    if (allLenders) {
      setSelectedLenders(
        lenders.sort(
          (a, b) => stringSorter(
            allLenders.find((x) => x.Id == a)?.LenderName ?? "",
            allLenders.find((y) => y.Id == b)?.LenderName ?? ""
          )
        )
      );
    }
  };
  const toggleButton = (id) => {
    if (selectedLenders?.includes(id)) {
      return /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(
        Button,
        {
          className: "ml-auto h-[20px]",
          variant: "destructive",
          onClick: () => toggleLender(id),
          children: "Remove"
        }
      );
    } else {
      return /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(
        Button,
        {
          className: "ml-auto h-[20px]",
          variant: "success",
          onClick: () => toggleLender(id),
          children: "Add"
        }
      );
    }
  };
  const lenderView = (lender) => {
    return /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)("div", { className: "w-full flex flex-row items-center", children: [
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { children: lender.LenderName }),
      calculatedMatches?.includes(lender.Id) ? /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { className: "ml-2 text-blue-400 text-xs", children: "Matched" }) : /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_jsx_runtime56.Fragment, {}),
      toggleButton(lender.Id)
    ] });
  };
  const [open, setOpen] = (0, import_react28.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(Dialog, { open, onOpenChange: (v) => setOpen(v), children: [
    /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(DialogTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(Button, { variant: "success", children: "Submit To Lenders" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(DialogContent, { className: "sm:min-w-[400px]", children: [
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(DialogHeader, { children: "Submit To Lenders" }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)("div", { children: [
        /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { className: "text-sm", children: "Deadline" }),
        /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(
          DatePicker,
          {
            value: deadline,
            onChange: (v) => setDeadline(v)
          }
        )
      ] }),
      selectedLenders && calculatedMatches && allLenders ? /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(import_jsx_runtime56.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)("div", { className: "overflow-y-auto max-h-[400px]", children: [
          /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { className: "text-sm", children: "Selected Lenders" }),
          /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { className: "w-full border-b-gray-300 border-b" }),
          selectedLenders.map((x) => {
            const lender = allLenders.find((y) => y.Id == x);
            if (!lender) {
              return /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_jsx_runtime56.Fragment, {});
            }
            return lenderView(lender);
          })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)("div", { className: "overflow-y-auto max-h-[400px]", children: [
          /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)("div", { className: "flex flex-row items-center", children: [
            /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { className: "text-sm", children: "All Lenders" }),
            /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(
              Input,
              {
                className: "ml-auto h-[18px] max-w-[200px]",
                placeholder: "Search...",
                value: lenderFilter,
                onChange: (v) => setLenderFilter(v.target.value)
              }
            )
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { className: "w-full border-b-gray-300 border-b" }),
          allLenders.filter(
            (x) => lenderFilter.trim() == "" ? true : x.LenderName.toLowerCase().includes(
              lenderFilter.toLowerCase()
            )
          ).map((x) => {
            return lenderView(x);
          })
        ] })
      ] }) : /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { children: "Fetching lenders..." }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)("div", { className: "w-full", children: [
        /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(
          Button,
          {
            variant: "success",
            onClick: async () => {
              await submitMutation.mutateAsync({
                loanAppId: loanappid,
                data: {
                  Deadline: deadline?.getTime(),
                  LenderIds: selectedLenders
                }
              });
              setOpen(false);
              window.location.reload();
            },
            className: "w-full",
            disabled: deadline == void 0 || submitMutation.isLoading,
            children: "Submit"
          }
        ),
        deadline == void 0 && /* @__PURE__ */ (0, import_jsx_runtime56.jsx)("div", { className: "text-red-500", children: "Select a deadline." })
      ] })
    ] })
  ] });
};
var SubmitLoanDialogOuter = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(SubmitLoanDialog, { ...props }) });
};
var submit_loan_dialog_default = SubmitLoanDialogOuter;

// src/elements/review-offers-dialog.tsx
var import_react29 = require("react");
var import_lib32 = require("@cxm/lib");
var import_jsx_runtime57 = require("react/jsx-runtime");
var ReviewOffersDialog = ({ loanappid }) => {
  const [open, setOpen] = (0, import_react29.useState)(false);
  const offersQuery = (0, import_lib32.useApiQuery)(
    "reviewOffers-" + loanappid,
    (0, import_lib32.getOffersForReview)(loanappid)
  );
  return /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(Dialog, { open, onOpenChange: (v) => setOpen(v), children: [
    /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(DialogTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(Button, { variant: "outline", children: "Review Offers" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(DialogContent, { className: "overflow-y-auto w-full flex flex-col sm:min-w-[600px] md:min-w-[700px] lg:min-w-[1000px] min-h-[400px] max-h-screen", children: [
      /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(DialogHeader, { children: "Review Offers" }),
      /* @__PURE__ */ (0, import_jsx_runtime57.jsx)("div", { className: "overflow-x-auto", children: !offersQuery.isLoading ? /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(
        term_sheet_table_default,
        {
          loanappid,
          portaltype: import_lib32.PortalType.Management
        }
      ) : /* @__PURE__ */ (0, import_jsx_runtime57.jsx)("div", { children: "Loading..." }) })
    ] })
  ] });
};
var ReviewOffersDialogOuter = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(ReviewOffersDialog, { ...props }) });
};
var review_offers_dialog_default = ReviewOffersDialogOuter;

// src/elements/lender-dashboard.tsx
var import_lib33 = require("@cxm/lib");
var import_react30 = require("react");
var import_jsx_runtime58 = require("react/jsx-runtime");
var loanAppTableConfig3 = [
  {
    accessorKey: "SubmittedAt",
    header: SortedHeader("Submitted"),
    accessorFn: (v) => new Date(v.SubmittedAt).toLocaleString("en", {
      month: "numeric",
      day: "numeric",
      year: "2-digit"
    })
  },
  {
    accessorKey: "CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "PublicStatus",
    accessorFn: (v) => (0, import_lib33.getLoanStatusMessage)(v, import_lib33.PortalType.Lender).category,
    header: SortedHeader("Status"),
    enableColumnFilter: true,
    filterFn: "weakEquals"
  },
  {
    accessorKey: "LastStatusChange",
    accessorFn: (v) => (/* @__PURE__ */ new Date()).getTime() - v.LastStatusChange,
    cell: (v) => timeSince(v.getValue()),
    header: SortedHeader("Time In Status")
  },
  {
    accessorKey: "OfferDeadline",
    accessorFn: (v) => new Date(v.OfferDeadline).toLocaleString("en", {
      month: "numeric",
      day: "numeric",
      year: "2-digit"
    }),
    header: SortedHeader("Offer Deadline"),
    enableColumnFilter: true,
    filterFn: "weakEquals"
  },
  {
    accessorKey: "PublicStatusDetail",
    accessorFn: (v) => (0, import_lib33.getStatusMessageString)(
      (0, import_lib33.getLoanStatusMessage)(v, import_lib33.PortalType.Lender).message
    ),
    header: "Status Details"
  },
  {
    accessorKey: "LoanAmount",
    header: SortedHeader("Loan Amount"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "LoanType",
    header: SortedHeader("Loan Type"),
    cell: (v) => import_lib33.loanTypes.find((x) => x.id == v.getValue())?.fullName
  },
  {
    accessorKey: "SpecialistName",
    header: SortedHeader("Specialist"),
    cell: (v) => v.getValue() ?? "None Assigned"
  }
];
var LenderDashboard = () => {
  const allLoansQuery = (0, import_lib33.useApiQuery)("lenderLoans", import_lib33.getLenderLoans, {
    success: (x) => {
      var sortedLoans = x.map((y) => ({
        ...y,
        CompanyName: y.CompanyName ?? "Missing Company Name",
        Active: !["Closed", "Declined", "Cancelled"].includes(
          (0, import_lib33.getLoanStatusMessage)(y, import_lib33.PortalType.Lender).category
        )
      })).sort((a, b) => {
        const statusA = (0, import_lib33.getLoanStatusMessage)(a, import_lib33.PortalType.Lender);
        const statusB = (0, import_lib33.getLoanStatusMessage)(b, import_lib33.PortalType.Lender);
        if (statusA.requiresAction && !statusB.requiresAction) {
          return -1;
        } else if (!statusA.requiresAction && statusB.requiresAction) {
          return 1;
        } else {
          return b.SubmittedAt - a.SubmittedAt;
        }
      });
      const activeLoans = sortedLoans.filter((x2) => x2.Active);
      setInActiveLoans(
        sortedLoans.filter((x2) => !x2.Active)
      );
      setHighlights(
        activeLoans.map(
          (x2) => (0, import_lib33.getLoanStatusMessage)(x2, import_lib33.PortalType.Lender).requiresAction
        )
      );
      setLoans(
        activeLoans
      );
    }
  });
  const [loans, setLoans] = (0, import_react30.useState)([]);
  const [inActiveLoans, setInActiveLoans] = (0, import_react30.useState)([]);
  const [highlights, setHighlights] = (0, import_react30.useState)([]);
  return /* @__PURE__ */ (0, import_jsx_runtime58.jsxs)("div", { className: "container", children: [
    /* @__PURE__ */ (0, import_jsx_runtime58.jsx)("h1", { className: "text-4xl", children: "Active Loans" }),
    /* @__PURE__ */ (0, import_jsx_runtime58.jsx)("div", { className: "w-full max-w-[600px] border-b border-b-gray-300 mb-3" }),
    /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(
      loan_table_default,
      {
        initialSortingState: [],
        tableConfig: loanAppTableConfig3,
        loanStatusOptions: import_lib33.lenderLoanStatusOptions,
        highlighted: highlights,
        loans,
        noLoansMessage: "No Active Loans.",
        loading: allLoansQuery.isLoading
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime58.jsx)("h1", { className: "text-4xl", children: "Inactive Loans" }),
    /* @__PURE__ */ (0, import_jsx_runtime58.jsx)("div", { className: "w-full max-w-[600px] border-b border-b-gray-300 mb-3" }),
    /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(
      loan_table_default,
      {
        initialSortingState: [{
          id: "LastStatusChange",
          desc: true
        }],
        tableConfig: loanAppTableConfig3,
        loanStatusOptions: import_lib33.lenderLoanStatusOptions,
        loans: inActiveLoans,
        noLoansMessage: "No Inactive Loans.",
        loading: allLoansQuery.isLoading
      }
    )
  ] });
};
var lender_dashboard_default = LenderDashboard;

// src/elements/specialist-dashboard.tsx
var import_lib34 = require("@cxm/lib");
var import_react31 = require("react");
var import_jsx_runtime59 = require("react/jsx-runtime");
var LoanAppReceivingOffersConfig = [
  {
    accessorKey: "SubmittedAt",
    header: SortedHeader("Submitted"),
    accessorFn: (v) => new Date(v.SubmittedAt).toLocaleString("en", {
      month: "numeric",
      day: "numeric",
      year: "2-digit"
    })
  },
  {
    accessorKey: "CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "PublicStatus",
    accessorFn: (v) => (0, import_lib34.getLoanStatusMessage)(v, import_lib34.PortalType.Management).category,
    header: SortedHeader("Status"),
    enableColumnFilter: true,
    filterFn: "weakEquals"
  },
  {
    accessorKey: "LastStatusChange",
    accessorFn: (v) => (/* @__PURE__ */ new Date()).getTime() - v.LastStatusChange,
    cell: (v) => timeSince(v.getValue()),
    header: SortedHeader("Time In Status")
  },
  {
    accessorKey: "OfferCount",
    header: SortedHeader("Offers"),
    accessorFn: (v) => v.NonBindingLenderOfferStatuses?.length ?? 0
  },
  {
    accessorKey: "Deadline",
    header: SortedHeader("Offer Deadline"),
    accessorFn: (v) => new Date(v.OfferDeadline).toLocaleString("en", {
      month: "numeric",
      day: "numeric",
      year: "2-digit",
      hour: "2-digit"
    })
  },
  {
    accessorKey: "SpecialistName",
    header: SortedHeader("Specialist"),
    cell: (v) => v.getValue() ?? "None Assigned"
  }
];
var LoanAppTableConfig2 = [
  {
    accessorKey: "SubmittedAt",
    header: SortedHeader("Submitted"),
    accessorFn: (v) => new Date(v.SubmittedAt).toLocaleString("en", {
      month: "numeric",
      day: "numeric",
      year: "2-digit"
    })
  },
  {
    accessorKey: "CompanyName",
    header: SortedHeader("Company Name")
  },
  {
    accessorKey: "PublicStatus",
    accessorFn: (v) => (0, import_lib34.getLoanStatusMessage)(v, import_lib34.PortalType.Management).category,
    header: SortedHeader("Status"),
    enableColumnFilter: true,
    filterFn: "weakEquals"
  },
  {
    accessorKey: "LastStatusChange",
    accessorFn: (v) => (/* @__PURE__ */ new Date()).getTime() - v.LastStatusChange,
    cell: (v) => timeSince(v.getValue()),
    header: SortedHeader("Time In Status")
  },
  {
    accessorKey: "PublicStatusDetail",
    accessorFn: (v) => (0, import_lib34.getStatusMessageString)(
      (0, import_lib34.getLoanStatusMessage)(v, import_lib34.PortalType.Management).message
    ),
    header: "Status Details"
  },
  {
    accessorKey: "LoanAmount",
    header: SortedHeader("Loan Amount"),
    cell: (v) => currency(v.getValue())
  },
  {
    accessorKey: "LoanType",
    header: SortedHeader("Loan Type"),
    cell: (v) => import_lib34.loanTypes.find((x) => x.id == v.getValue())?.fullName
  },
  {
    accessorKey: "SpecialistName",
    header: SortedHeader("Specialist"),
    cell: (v) => v.getValue() ?? "None Assigned"
  }
];
var SpecialistDashboard = () => {
  const allLoansQuery = (0, import_lib34.useApiQuery)("allLoanApps", import_lib34.getAllLoans, {
    success: (x) => {
      const statusList = {};
      x.forEach((x2) => {
        statusList[(0, import_lib34.getLoanStatusMessage)(x2, import_lib34.PortalType.Management).category] = (statusList[(0, import_lib34.getLoanStatusMessage)(x2, import_lib34.PortalType.Management).category] ?? 0) + 1;
      });
      setLoansReceiving(
        x.filter(
          (x2) => (0, import_lib34.getLoanStatusMessage)(x2, import_lib34.PortalType.Management).category == "Receiving Offers"
        ).map((y) => ({
          ...y,
          CompanyName: y.CompanyName ?? "Missing Company Name"
        }))
      );
      setLoans(
        x.filter(
          (x2) => (0, import_lib34.getLoanStatusMessage)(x2, import_lib34.PortalType.Management).requiresAction
        ).map((y) => ({
          ...y,
          CompanyName: y.CompanyName ?? "Missing Company Name"
        }))
      );
    }
  });
  const [loans, setLoans] = (0, import_react31.useState)();
  const [loansReceiving, setLoansReceiving] = (0, import_react31.useState)();
  return /* @__PURE__ */ (0, import_jsx_runtime59.jsxs)("div", { className: "container", children: [
    /* @__PURE__ */ (0, import_jsx_runtime59.jsx)("h1", { className: "text-4xl", children: "Loans Requiring Action" }),
    /* @__PURE__ */ (0, import_jsx_runtime59.jsx)("div", { className: "w-full max-w-[600px] border-b border-b-gray-300 mb-3" }),
    /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(
      loan_table_default,
      {
        initialSortingState: [{
          id: "SubmittedAt",
          desc: true
        }],
        tableConfig: LoanAppTableConfig2,
        loanStatusOptions: import_lib34.specialistLoanStatusOptions,
        loans: loans ?? [],
        noLoansMessage: "No Loans Requiring Action.",
        loading: allLoansQuery.isLoading
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime59.jsx)("h1", { className: "text-4xl mt-12", children: "Loans Receiving Offers" }),
    /* @__PURE__ */ (0, import_jsx_runtime59.jsx)("div", { className: "w-full max-w-[600px] border-b border-b-gray-300 mb-3" }),
    /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(
      loan_table_default,
      {
        initialSortingState: [{
          id: "LastStatusChange",
          desc: true
        }],
        tableConfig: LoanAppReceivingOffersConfig,
        loans: loansReceiving ?? [],
        noLoansMessage: "No Loans Receiving Offers.",
        loading: allLoansQuery.isLoading
      }
    )
  ] });
};
var specialist_dashboard_default = SpecialistDashboard;

// src/elements/revert-accepted-button.tsx
var import_lib35 = require("@cxm/lib");
var import_jsx_runtime60 = require("react/jsx-runtime");
var RevertAcceptedButton = ({ loanappid }) => {
  const revertMutation = (0, import_lib35.useApiMutation)(import_lib35.revertTermSheet, []);
  const confirmDialog = useConfirmDialogContext();
  return /* @__PURE__ */ (0, import_jsx_runtime60.jsx)(
    Button,
    {
      onClick: async () => {
        const result = await confirmDialog.openDialogAsync({
          title: "Undo Accepted Term Sheet?",
          message: "This will revert the loan app to before the non-binding terms where accepted by the borrower. You should only do this if the borrower has specifically requested it."
        });
        if (result) {
          await revertMutation.mutateAsync(loanappid);
          window.location.reload();
        }
      },
      variant: "outline",
      children: "Revert Accepted Term Sheet"
    }
  );
};
var RevertAcceptedButtonOuter = (props) => {
  return /* @__PURE__ */ (0, import_jsx_runtime60.jsx)(ConfirmDialogProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime60.jsx)(RevertAcceptedButton, { ...props }) });
};
var revert_accepted_button_default = RevertAcceptedButtonOuter;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AttestTerms,
  Attestation,
  BorrowerOnboarder,
  BorrowerProfileEditor,
  BorrowerTerms,
  Button,
  Header,
  InjectedTargetBox,
  LenderDashboard,
  LenderLoanTable,
  LenderProfileEditor,
  LenderReferrals,
  LoanAppSelector,
  LoanApplicationView,
  LoanApplicationsTable,
  LoanOfferDetail,
  MessageForm,
  Messaging,
  Navbar,
  OnboardWidget,
  ProfileDisplay,
  ProgressBar,
  RevertAcceptedButton,
  ReviewOffersDialog,
  ScreenOne,
  SpecialistDashboard,
  SpecialistLoanTable,
  SpecialistReferrals,
  SubmitLoanDialog,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  TemplateTable,
  TermSheetTable,
  UpdateProfileDialog,
  UserInvite,
  UtilityNavbar,
  buttonVariants
});
