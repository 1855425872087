"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ApplicationStatusMessage: () => ApplicationStatusMessage,
  BorrowerAccess: () => BorrowerAccess,
  CompanyType: () => CompanyType,
  DiligenceStatus: () => DiligenceStatus,
  Industries: () => industries_default,
  LenderApplicationStatus: () => LenderApplicationStatus,
  LoanApplicationStatus: () => LoanApplicationStatus,
  NotificationLevel: () => NotificationLevel,
  OfferStatus: () => OfferStatus,
  PortalType: () => PortalType,
  PortalTypeNumber: () => PortalTypeNumber,
  PublicLoanApplicationStatus: () => PublicLoanApplicationStatus,
  RateReference: () => RateReference,
  SubIndustries: () => subindustries_default,
  acceptOffer: () => acceptOffer,
  acceptTerms: () => acceptTerms,
  additionalDebtModel: () => additionalDebtModel,
  analyzeDocuments: () => analyzeDocuments,
  approveOffers: () => approveOffers,
  assignAdditionalLender: () => assignAdditionalLender,
  attestLoanApplication: () => attestLoanApplication,
  cancelLoan: () => cancelLoan,
  checkNotifs: () => checkNotifs,
  companyTypeOptions: () => companyTypeOptions,
  contactModel: () => contactModel,
  createAdditionalBorrower: () => createAdditionalBorrower,
  createLoanApplication: () => createLoanApplication,
  currency: () => currency,
  declineOffer: () => declineOffer,
  defaultLenderCriteria: () => defaultLenderCriteria,
  deleteAdditionalLoanAppFile: () => deleteAdditionalLoanAppFile,
  deleteBorrowerFile: () => deleteBorrowerFile,
  downloadBorrowerFile: () => downloadBorrowerFile,
  downloadLoanAppFinancials: () => downloadLoanAppFinancials,
  finishMergeLink: () => finishMergeLink,
  fullBorrowerProfileModel: () => fullBorrowerProfileModel,
  fullLenderCriteriaModel: () => fullLenderCriteriaModel,
  generateLenderSummary: () => generateLenderSummary,
  getAcceptedOffers: () => getAcceptedOffers,
  getAllLoans: () => getAllLoans,
  getAllReferrals: () => getAllReferrals,
  getApiEvaluation: () => getApiEvaluation,
  getApiGlossary: () => getApiGlossary,
  getBorrowerActionPartial: () => getBorrowerActionPartial,
  getBorrowerFile: () => getBorrowerFile,
  getBorrowerProfile: () => getBorrowerProfile,
  getBorrowerUsers: () => getBorrowerUsers,
  getCalculatedMatchedLenders: () => getCalculatedMatchedLenders,
  getEngagedLenderApplicationString: () => getEngagedLenderApplicationString,
  getEngagedLenders: () => getEngagedLenders,
  getGlossary: () => getGlossary,
  getLenderCriteria: () => getLenderCriteria,
  getLenderLoans: () => getLenderLoans,
  getLenderTemplatePartial: () => getLenderTemplatePartial,
  getLenders: () => getLenders,
  getLoanAppMessages: () => getLoanAppMessages,
  getLoanAppTitle: () => getLoanAppTitle,
  getLoanApplication: () => getLoanApplication,
  getLoanApplications: () => getLoanApplications,
  getLoanStatusMessage: () => getLoanStatusMessage,
  getMetrics: () => getMetrics,
  getNotifications: () => getNotifications,
  getOffer: () => getOffer,
  getOffersForReview: () => getOffersForReview,
  getRecipients: () => getRecipients,
  getReferralsForLender: () => getReferralsForLender,
  getSelf: () => getSelf,
  getStatusMessageString: () => getStatusMessageString,
  getTermSheets: () => getTermSheets,
  getUnmatchedLenders: () => getUnmatchedLenders,
  industryOptions: () => industryOptions,
  init: () => init,
  inviteUser: () => inviteUser,
  isPreFirstSubmit: () => isPreFirstSubmit,
  isPreSubmit: () => isPreSubmit,
  lenderCriteriaModel: () => lenderCriteriaModel,
  lenderLoanStatusOptions: () => lenderLoanStatusOptions,
  loanApplicationModel: () => loanApplicationModel,
  loanRatios: () => loanRatios,
  loanTypeOptions: () => loanTypeOptions,
  loanTypeString: () => loanTypeString,
  loanTypes: () => loanTypes,
  loanUseOptions: () => loanUseOptions,
  metricQualities: () => metricQualities,
  numberedIndustryOptions: () => numberedIndustryOptions,
  numberedSubIndustryOptions: () => numberedSubIndustryOptions,
  officerModel: () => officerModel,
  onboardBorrower: () => onboardBorrower,
  referralDataModel: () => referralDataModel,
  request: () => request,
  returnOffersToLenders: () => returnOffersToLenders,
  revertTermSheet: () => revertTermSheet,
  roundToPlaces: () => roundToPlaces,
  sendLoanAppMessage: () => sendLoanAppMessage,
  sendMessage: () => sendMessage,
  setActiveBorrower: () => setActiveBorrower,
  setActiveLoanApplication: () => setActiveLoanApplication,
  setApplicationStatus: () => setApplicationStatus,
  setLoanAppReferral: () => setLoanAppReferral,
  specialistLoanStatusOptions: () => specialistLoanStatusOptions,
  startMergeLink: () => startMergeLink,
  statusColors: () => statusColors,
  subIndustryOptions: () => subIndustryOptions,
  submitApiEvaluation: () => submitApiEvaluation,
  submitEvaluation: () => submitEvaluation,
  submitLoanApplication: () => submitLoanApplication,
  submitReferral: () => submitReferral,
  submitScreenOneEvaluation: () => submitScreenOneEvaluation,
  updateBorrowerProfile: () => updateBorrowerProfile,
  updateLenderCriteria: () => updateLenderCriteria,
  updateLoanApplication: () => updateLoanApplication,
  updateProfile: () => updateProfile,
  updateProfileFromMerge: () => updateProfileFromMerge,
  uploadAdditionalLoanAppFile: () => uploadAdditionalLoanAppFile,
  uploadBorrowerFile: () => uploadBorrowerFile,
  uploadMessageAttachment: () => uploadMessageAttachment,
  uploadPrivateAdditionalLoanAppFile: () => uploadPrivateAdditionalLoanAppFile,
  uploadReferralFiles: () => uploadReferralFiles,
  useApiMutation: () => useApiMutation,
  useApiQuery: () => useApiQuery,
  yearProfitLossMetricsModel: () => yearProfitLossMetricsModel
});
module.exports = __toCommonJS(src_exports);

// src/api.ts
var import_react_query = require("react-query");
var MAX_RETRIES = 3;
var HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];
var useApiQuery = (key, fn, config) => (0, import_react_query.useQuery)({
  queryKey: key,
  queryFn: fn,
  onSuccess: config?.success,
  onError: config?.error,
  retry: (failureCount, error) => {
    if (failureCount > MAX_RETRIES) {
      return false;
    }
    if (globalThis.isAxiosError(error) && HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)) {
      return false;
    }
    return true;
  },
  enabled: config?.enabled,
  ...config?.options
});
var useApiMutation = (fn, invalidateKey = [], config) => {
  const queryClient = (0, import_react_query.useQueryClient)();
  return (0, import_react_query.useMutation)({
    mutationFn: fn,
    onSuccess: (data) => {
      invalidateKey && invalidateKey.forEach((key) => {
        queryClient.invalidateQueries(key);
      });
      config?.success && config.success(data);
    },
    onError: config?.error,
    retry: (failureCount, error) => {
      if (failureCount > MAX_RETRIES) {
        return false;
      }
      if (globalThis.isAxiosError(error) && HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)) {
        return false;
      }
      return true;
    },
    ...config?.options
  });
};
var init = (client, isAxiosError) => {
  globalThis.axiosClient = client;
  globalThis.isAxiosError = isAxiosError;
};
var request = async (options) => {
  const onSuccess = (response) => {
    return response?.data;
  };
  const onError = (error) => {
    return Promise.reject(
      error
    );
  };
  if (!globalThis.axiosClient) {
    console.error(
      "Tried to make axios request without initializing axios. Call init() first."
    );
    return;
  }
  return globalThis.axiosClient({ ...options }).then(onSuccess).catch(onError);
};

// src/user.ts
var getSelf = () => request({
  url: "/users/me",
  method: "GET"
});
var NotificationLevel = /* @__PURE__ */ ((NotificationLevel2) => {
  NotificationLevel2[NotificationLevel2["Created"] = 0] = "Created";
  NotificationLevel2[NotificationLevel2["Seen"] = 1] = "Seen";
  NotificationLevel2[NotificationLevel2["Suppressed"] = 2] = "Suppressed";
  return NotificationLevel2;
})(NotificationLevel || {});
var getNotifications = () => request({
  url: "/users/Notifications",
  method: "GET"
});
var setActiveBorrower = (borrowerId) => request({
  url: "/borrower/SetActiveBorrower?borrowerId=" + borrowerId,
  method: "POST"
});
var setActiveLoanApplication = (loanAppId) => request({
  url: "/borrower/SetActiveLoanApplication?loanAppId=" + loanAppId,
  method: "POST"
});
var checkNotifs = ({}) => request({
  url: "/users/DismissAllNotifications",
  method: "POST"
});
var updateProfile = (data) => request({
  url: "/users/UpdateProfile",
  method: "POST",
  data
});
var inviteUser = (data) => request({
  url: "/users/Invite",
  method: "POST",
  data
});

// src/lender.ts
var import_zod = __toESM(require("zod"));
var updateLenderCriteria = (data) => request({
  url: "/lenders/UpdateLenderCriteria?lenderId=" + data.lenderId,
  method: "POST",
  data: data.profile
});
var getLenderCriteria = (lenderId) => () => request({
  url: "/lenders/GetLenderCriteria?lenderId=" + lenderId,
  method: "GET"
});
var getLenderTemplatePartial = (loanType) => () => request({
  url: process.env.REACT_APP_BASE_LENDER_URL + "/Profile/GetLoanTypeTemplatesPartial?loanType=" + loanType,
  method: "GET"
});
var getLenders = () => request({
  url: "/lenders/all",
  method: "GET"
});
var RateReference = /* @__PURE__ */ ((RateReference2) => {
  RateReference2[RateReference2["LIBOR"] = 0] = "LIBOR";
  RateReference2[RateReference2["SOFR"] = 1] = "SOFR";
  return RateReference2;
})(RateReference || {});
var LenderApplicationStatus = /* @__PURE__ */ ((LenderApplicationStatus2) => {
  LenderApplicationStatus2[LenderApplicationStatus2["New"] = 0] = "New";
  LenderApplicationStatus2[LenderApplicationStatus2["InProgress"] = 1] = "InProgress";
  LenderApplicationStatus2[LenderApplicationStatus2["Pursued"] = 2] = "Pursued";
  LenderApplicationStatus2[LenderApplicationStatus2["NotPursued"] = 3] = "NotPursued";
  LenderApplicationStatus2[LenderApplicationStatus2["Declined"] = 4] = "Declined";
  LenderApplicationStatus2[LenderApplicationStatus2["Missed"] = 5] = "Missed";
  return LenderApplicationStatus2;
})(LenderApplicationStatus || {});
var getEngagedLenderApplicationString = (status, offerStatus) => {
  switch (status) {
    case 0 /* New */:
      return "Unacknowledged";
    case 1 /* InProgress */:
      if (offerStatus.length > 0) {
        return "Offer Submitted";
      }
      return "Acknowledged";
    case 2 /* Pursued */:
      return "Offer Accepted";
    case 3 /* NotPursued */:
      return "Offer Rejected";
    case 4 /* Declined */:
      return "Declined";
    case 5 /* Missed */:
      return "Missed";
  }
};
var lenderCriteriaModel = import_zod.default.object({
  TTM_Revenue_Min: import_zod.default.number().nullish(),
  TTM_Revenue_Max: import_zod.default.number().nullish(),
  TTM_EBITDA_Min: import_zod.default.number().nullish(),
  TTM_EBITDA_Max: import_zod.default.number().nullish(),
  FMV_PPE_Min: import_zod.default.number().nullish(),
  FMV_PPE_Max: import_zod.default.number().nullish(),
  AR_Min: import_zod.default.number().nullish(),
  AR_Max: import_zod.default.number().nullish(),
  Inventory_Min: import_zod.default.number().nullish(),
  Inventory_Max: import_zod.default.number().nullish(),
  Years_In_Business_Min: import_zod.default.number().nullish(),
  Years_In_Business_Max: import_zod.default.number().nullish(),
  Rate_Min: import_zod.default.number().nullish(),
  Rate_Max: import_zod.default.number().nullish(),
  Rate_Spread_Min: import_zod.default.number().nullish(),
  Rate_Spread_Max: import_zod.default.number().nullish(),
  Rate_Reference: import_zod.default.number().nullish(),
  Maturity_Min: import_zod.default.number().nullish(),
  Maturity_Max: import_zod.default.number().nullish(),
  Equipment_Orderly_Liquidation_Min: import_zod.default.number().nullish(),
  Equipment_Orderly_Liquidation_Max: import_zod.default.number().nullish(),
  Equipment_Forced_Liquidation_Min: import_zod.default.number().nullish(),
  Equipment_Forced_Liquidation_Max: import_zod.default.number().nullish(),
  Loan_Amount_Min: import_zod.default.number().nullish(),
  Loan_Amount_Max: import_zod.default.number().nullish(),
  Personal_Guarantee: import_zod.default.boolean().nullish(),
  Audit_Review: import_zod.default.boolean().nullish(),
  Has_CFO: import_zod.default.boolean().nullish(),
  External_Accountant: import_zod.default.boolean().nullish(),
  Loan_To_Revenue_Min: import_zod.default.number().nullish(),
  Loan_To_Revenue_Max: import_zod.default.number().nullish(),
  Loan_To_EBITDA_Min: import_zod.default.number().nullish(),
  Loan_To_EBITDA_Max: import_zod.default.number().nullish(),
  Advance_AR_Rate_Min: import_zod.default.number().nullish(),
  Advance_AR_Rate_Max: import_zod.default.number().nullish(),
  Advance_Equip_Rate_Min: import_zod.default.number().nullish(),
  Advance_Equip_Rate_Max: import_zod.default.number().nullish(),
  Advance_Inventory_Rate_Min: import_zod.default.number().nullish(),
  Advance_Inventory_Rate_Max: import_zod.default.number().nullish(),
  Advance_Cash_Rate_Min: import_zod.default.number().nullish(),
  Advance_Cash_Rate_Max: import_zod.default.number().nullish(),
  Revenue_Mixes: import_zod.default.array(import_zod.default.number()).nullish(),
  Industries: import_zod.default.array(import_zod.default.number()).nullish(),
  Sub_Industries: import_zod.default.array(import_zod.default.number()).nullish(),
  Regions: import_zod.default.array(import_zod.default.number()).nullish(),
  Loan_Types: import_zod.default.array(import_zod.default.number()).nullish(),
  Loan_Uses: import_zod.default.array(import_zod.default.number()).nullish()
});
var fullLenderCriteriaModel = import_zod.default.object({
  DefaultCriteria: lenderCriteriaModel,
  LoanTypeOverrides: import_zod.default.object({
    LoanType: import_zod.default.number(),
    Criteria: lenderCriteriaModel
  }).array()
});
var defaultLenderCriteria = {
  DefaultCriteria: {},
  LoanTypeOverrides: []
};

// src/strings.ts
var formatterCache = {};
function currency(input, fractionDigits = 0, locale = "en-US", currency2 = "USD") {
  let localeCache = formatterCache[locale];
  if (!localeCache) {
    localeCache = formatterCache[locale] = {};
  }
  let formatter = localeCache[currency2];
  if (!formatter) {
    formatter = localeCache[currency2] = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency2,
      maximumFractionDigits: fractionDigits
    });
  }
  return formatter.format(input);
}

// src/messages.ts
var sendMessage = (data) => request({
  url: "/messages/send",
  method: "POST",
  data
});
var uploadMessageAttachment = (data) => request({
  url: `/messages/UploadMessageFileAttachments`,
  method: "POST",
  data: data.formData,
  headers: {
    "Content-Type": "multipart/form-data"
  },
  onUploadProgress: data.onUploadProgress,
  onDownloadProgress: data.onDownloadProgress
});
var getLoanAppMessages = (data) => () => request({
  url: `/messages/GetLoanAppMessages?portalType=${data.portalType}&loanApplicationId=${data.loanAppId}`,
  method: "GET"
});
var sendLoanAppMessage = (data) => request({
  url: `/messages/SendLoanAppMessage?portalType=${data.portalType}&loanApplicationId=${data.loanAppId}`,
  method: "POST",
  data: data.message
});
var getRecipients = (data) => () => request({
  url: "/messages/recipients",
  method: "GET",
  params: data
});

// src/core.ts
var PortalType = /* @__PURE__ */ ((PortalType3) => {
  PortalType3["Lender"] = "lender";
  PortalType3["Borrower"] = "borrower";
  PortalType3["Management"] = "management";
  return PortalType3;
})(PortalType || {});
var PortalTypeNumber = (val) => {
  switch (val) {
    case 0:
      return "management" /* Management */;
    case 1:
      return "lender" /* Lender */;
    case 2:
      return "borrower" /* Borrower */;
    default:
      return "borrower" /* Borrower */;
  }
};

// src/calc.ts
var getMetrics = () => request({
  url: process.env.REACT_APP_BASE_BORROWER_URL + "/api/Evaluation/Inputs",
  method: "GET",
  withCredentials: false
});
var getGlossary = () => request({
  url: process.env.REACT_APP_BASE_BORROWER_URL + "/api/GetGlossary",
  method: "GET",
  withCredentials: false
});
var getApiGlossary = () => request({
  url: "/borrower/GetGlossary",
  method: "GET",
  withCredentials: false
});
var submitScreenOneEvaluation = (data) => request({
  url: `/borrower/EvaluateLanding?existingId=${data.evalId ? data.evalId : "none"}&findBestLoanType=${data.findBestLoanType}`,
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  },
  data: data.data,
  withCredentials: false
});
var getApiEvaluation = (portalType, loanAppId) => () => request({
  url: `/borrower/Evaluate?portalType=${portalType}&loanAppId=${loanAppId}`,
  method: "GET"
});
var submitApiEvaluation = (data) => request({
  url: `/borrower/Evaluate?portalType=${data.portalType}&loanAppId=${data.loanAppId}`,
  method: "GET"
});
var submitEvaluation = (inputs) => request({
  url: process.env.REACT_APP_BASE_BORROWER_URL + "/api/Evaluation/Result",
  method: "POST",
  withCredentials: false,
  data: inputs
});

// src/borrower.ts
var import_zod2 = __toESM(require("zod"));
var BorrowerAccess = /* @__PURE__ */ ((BorrowerAccess2) => {
  BorrowerAccess2[BorrowerAccess2["Owner"] = 0] = "Owner";
  BorrowerAccess2[BorrowerAccess2["Editor"] = 1] = "Editor";
  BorrowerAccess2[BorrowerAccess2["ReadOnly"] = 2] = "ReadOnly";
  return BorrowerAccess2;
})(BorrowerAccess || {});
var startMergeLink = () => request({
  url: "/borrower/StartMergeLink",
  method: "POST"
});
var finishMergeLink = (publicToken) => request({
  url: "/borrower/FinishMergeLink?publicToken=" + publicToken,
  method: "POST"
});
var updateProfileFromMerge = () => request({
  url: "/borrower/UpdateProfileFromMerge",
  method: "POST"
});
var getBorrowerActionPartial = (loanAppId) => () => request({
  url: "/borrower/GetBorrowerLoanActions?loanAppId=" + loanAppId,
  method: "GET"
});
var analyzeDocuments = (data) => request({
  url: `/borrower/AnalyzeDocuments?borrowerId=${data.borrowerId}`,
  method: "POST",
  data: data.profile
});
var getBorrowerProfile = (portalType, borrowerId) => () => request({
  url: `/borrower/GetBorrowerProfile?borrowerId=${borrowerId}&portalType=${portalType}`,
  method: "GET"
});
var deleteBorrowerFile = (data) => request({
  url: `/borrower/DeleteBorrowerProfileFile?borrowerId=${data.borrowerId}&fileId=${data.fileId}`,
  method: "POST"
});
var getBorrowerUsers = (borrowerId) => () => request({
  url: `/borrower/GetBorrowerUsers?borrowerId=${borrowerId}`,
  method: "POST"
});
var downloadBorrowerFile = (data) => request({
  url: `/borrower/DownloadBorrowerProfileFile?borrowerId=${data.borrowerId}&fileId=${data.fileId}`,
  method: "GET",
  onDownloadProgress: data.onDownloadProgress
});
var uploadBorrowerFile = (data) => request({
  url: `/borrower/UploadBorrowerProfileFile?borrowerId=${data.borrowerId}&fileName=${data.fileName}`,
  method: "POST",
  data: data.formData,
  headers: {
    "Content-Type": "multipart/form-data"
  },
  onUploadProgress: data.onUploadProgress,
  onDownloadProgress: data.onDownloadProgress
});
var acceptTerms = (data) => request({
  url: `/borrower/AcceptTerms?borrowerId=${data.borrowerId}`,
  method: "POST"
});
var getBorrowerFile = (data) => () => request({
  url: `/borrower/GetBorrowerProfileFile?borrowerId=${data.borrowerId}&fileId=${data.fileId}`,
  method: "GET"
});
var updateBorrowerProfile = (data) => request({
  url: "/borrower/UpdateBorrowerProfile?borrowerId=" + data.borrowerId,
  method: "POST",
  data: data.profile
});
var onboardBorrower = (data) => request({
  url: "/borrower/OnboardBorrower",
  method: "POST",
  data
});
var createAdditionalBorrower = () => request({
  url: "/borrower/CreateAdditionalBorrower",
  method: "POST"
});
var yearProfitLossMetricsModel = import_zod2.default.object({
  Year: import_zod2.default.number().nullish(),
  Revenues: import_zod2.default.number().nullish(),
  CostOfSales: import_zod2.default.number().nullish(),
  GrossProfit: import_zod2.default.number().nullish(),
  Sga: import_zod2.default.number().nullish(),
  Ebitda: import_zod2.default.number().nullish(),
  InterestExpense: import_zod2.default.number().nullish(),
  TotalOtherIncome: import_zod2.default.number().nullish(),
  TotalOtherExpenses: import_zod2.default.number().nullish(),
  NetIncome: import_zod2.default.number().nullish()
});
var CompanyType = /* @__PURE__ */ ((CompanyType2) => {
  CompanyType2[CompanyType2["LLC"] = 0] = "LLC";
  CompanyType2[CompanyType2["SCorp"] = 1] = "SCorp";
  CompanyType2[CompanyType2["CCorp"] = 2] = "CCorp";
  CompanyType2[CompanyType2["Partnership"] = 3] = "Partnership";
  CompanyType2[CompanyType2["SoleProprietorship"] = 4] = "SoleProprietorship";
  return CompanyType2;
})(CompanyType || {});
var companyTypeOptions = [
  {
    label: "LLC",
    value: 0 /* LLC */
  },
  {
    label: "S-Corp",
    value: 1 /* SCorp */
  },
  {
    label: "C-Corp",
    value: 2 /* CCorp */
  },
  {
    label: "Partnership",
    value: 3 /* Partnership */
  },
  {
    label: "Sole Proprietorship",
    value: 4 /* SoleProprietorship */
  }
];
var additionalDebtModel = import_zod2.default.object({
  DebtType: import_zod2.default.number().nullish(),
  Lender: import_zod2.default.string().nullish(),
  InitialAmount: import_zod2.default.number().nullish(),
  RemainingAmount: import_zod2.default.number().nullish(),
  MonthlyPayment: import_zod2.default.number().nullish(),
  MaturityDate: import_zod2.default.number().nullish(),
  InterestRate: import_zod2.default.number().nullish()
});
var contactModel = import_zod2.default.object({
  FirstName: import_zod2.default.string(),
  LastName: import_zod2.default.string(),
  Title: import_zod2.default.string(),
  Email: import_zod2.default.string().email(),
  PhoneNumber: import_zod2.default.string()
});
var officerModel = import_zod2.default.object({
  FirstName: import_zod2.default.string(),
  LastName: import_zod2.default.string(),
  Title: import_zod2.default.string(),
  PercentageShare: import_zod2.default.number(),
  SocialSecurityNumber: import_zod2.default.string().min(9, "Must be 9 digits"),
  DriversLicenseFile: import_zod2.default.string().nullish()
});
var fullBorrowerProfileModel = import_zod2.default.object({
  CompanyInformation: import_zod2.default.object({
    CompanyName: import_zod2.default.string().nullish(),
    CompanyWebsite: import_zod2.default.string().nullish(),
    CompanyPhone: import_zod2.default.string().nullish(),
    YearFormed: import_zod2.default.number().max((/* @__PURE__ */ new Date()).getFullYear()).min(1500).nullish(),
    CompanyType: import_zod2.default.number().nullish(),
    HeadQuartersAddress: import_zod2.default.object({
      Line1: import_zod2.default.string().nullish(),
      Line2: import_zod2.default.string().nullish(),
      ZipCode: import_zod2.default.string().nullish(),
      State: import_zod2.default.string().nullish(),
      City: import_zod2.default.string().nullish()
    }).nullish(),
    BusinessDescription: import_zod2.default.string().nullish(),
    NumberOfEmployees: import_zod2.default.string().nullish(),
    Industry: import_zod2.default.string().nullish(),
    SubIndustry: import_zod2.default.string().nullish(),
    EmployerIdentificationNumber: import_zod2.default.string().nullish(),
    VeteranOwned: import_zod2.default.boolean().nullish(),
    FemaleOwned: import_zod2.default.boolean().nullish(),
    DisabilityOwned: import_zod2.default.boolean().nullish(),
    MinorityOwned: import_zod2.default.boolean().nullish(),
    BusinessPlanFile: import_zod2.default.string().nullish(),
    OrgChartFile: import_zod2.default.string().nullish(),
    GrowthPlansDescription: import_zod2.default.string().nullish(),
    PrimaryContact: contactModel.nullish(),
    SecondaryContacts: contactModel.array().nullish(),
    Officers: officerModel.array().nullish(),
    NonManagementShareholdersOverTenPercent: import_zod2.default.boolean().nullish(),
    RevenueMix: import_zod2.default.number().array().nullish(),
    TotalRevenuePercentOfTopFiveCustomers: import_zod2.default.number().nullish(),
    CustomerContractFiles: import_zod2.default.string().array().nullish()
  }).nullish(),
  TaxInformation: import_zod2.default.object({
    FullTimeCFO: import_zod2.default.boolean().nullish(),
    OutsideAccountant: import_zod2.default.boolean().nullish(),
    TaxReturnFiles: import_zod2.default.string().array().nullish(),
    LastYearBankStatementFiles: import_zod2.default.string().array().nullish()
  }).nullish(),
  ProfitLossMetrics: import_zod2.default.object({
    FiscalYearEnd: import_zod2.default.number().nullish(),
    PreviousYearsFiles: import_zod2.default.string().array().nullish(),
    PreviousYearsMetrics: yearProfitLossMetricsModel.array(),
    TtmMetrics: yearProfitLossMetricsModel.nullish(),
    YtdMetrics: yearProfitLossMetricsModel.nullish(),
    CurrentAndNextYearProjectionFiles: import_zod2.default.string().array().nullish(),
    ForecastedYearsMetrics: yearProfitLossMetricsModel.array().nullish()
  }),
  BalanceSheetMetrics: import_zod2.default.object({
    MostRecentBalanceSheetFile: import_zod2.default.string().nullish(),
    PreviousYearsBalanceSheetFiles: import_zod2.default.string().array().nullish(),
    BalanceSheetAsOfDate: import_zod2.default.number().nullish(),
    CurrentCash: import_zod2.default.number().nullish(),
    CurrentAssets: import_zod2.default.number().nullish(),
    TotalAssets: import_zod2.default.number().nullish(),
    CurrentLiabilities: import_zod2.default.number().nullish(),
    TotalLiabilities: import_zod2.default.number().nullish(),
    AccountsReceivableScheduleFiles: import_zod2.default.string().array().nullish(),
    AccountsReceivableTotal: import_zod2.default.number().nullish(),
    AccountsReceivable30Days: import_zod2.default.number().nullish(),
    AccountsReceivable3160Days: import_zod2.default.number().nullish(),
    AccountsReceivable6190Days: import_zod2.default.number().nullish(),
    AccountsReceivable90PlusDays: import_zod2.default.number().nullish(),
    InventoryOver500k: import_zod2.default.boolean().nullish(),
    InventoryAmount: import_zod2.default.number().nullish(),
    InventorySupportingFiles: import_zod2.default.string().array().nullish(),
    InventoryBreakdownFile: import_zod2.default.string().nullish(),
    EquipmentOver500k: import_zod2.default.boolean().nullish(),
    EstEquipmentValue: import_zod2.default.number().nullish(),
    EquipmentBreakdownFile: import_zod2.default.string().nullish(),
    EquipmentExternalAppraisalFile: import_zod2.default.string().nullish(),
    HasEquipmentDebt: import_zod2.default.boolean().nullish(),
    TotalDebtSecuredByEquipment: import_zod2.default.number().nullish(),
    RealEstateOver500k: import_zod2.default.boolean().nullish(),
    EstRealEstateValue: import_zod2.default.number().nullish(),
    RealEstateBreakdownFile: import_zod2.default.string().nullish(),
    RealEstateExternalAppraisalFile: import_zod2.default.string().nullish(),
    HasRealEstateDebt: import_zod2.default.boolean().nullish(),
    TotalMortgages: import_zod2.default.number().nullish(),
    AccountsPayableScheduleFiles: import_zod2.default.string().array().nullish(),
    AccountsPayableTotal: import_zod2.default.number().nullish(),
    AccountsPayable30Days: import_zod2.default.number().nullish(),
    AccountsPayable3160Days: import_zod2.default.number().nullish(),
    AccountsPayable6190Days: import_zod2.default.number().nullish(),
    AccountsPayable90PlusDays: import_zod2.default.number().nullish(),
    HasAdditionalDebt: import_zod2.default.boolean().nullish(),
    TotalAdditionalDebt: import_zod2.default.number().nullish(),
    AdditionalDebtScheduleFiles: import_zod2.default.string().array().nullish(),
    AdditionalDebt: additionalDebtModel.array().nullish()
  }).nullish(),
  AdditionalDocs: import_zod2.default.string().array().nullish()
});

// src/loanapplications.ts
var import_zod3 = __toESM(require("zod"));

// src/subindustries.ts
var subindustries = {
  "1": {
    "name": "Crop Production",
    "code": "111",
    "parent": 0
  },
  "2": {
    "name": "Animal Production and Aquaculture",
    "code": "112",
    "parent": 0
  },
  "3": {
    "name": "Forestry and Logging",
    "code": "113",
    "parent": 0
  },
  "4": {
    "name": "Fishing, Hunting and Trapping",
    "code": "114",
    "parent": 0
  },
  "5": {
    "name": "Support Activities for Agriculture and Forestry",
    "code": "115",
    "parent": 0
  },
  "6": {
    "name": "Oil and Gas Extraction",
    "code": "211",
    "parent": 1
  },
  "7": {
    "name": "Mining (except Oil and Gas)",
    "code": "212",
    "parent": 1
  },
  "8": {
    "name": "Support Activities for Mining",
    "code": "213",
    "parent": 1
  },
  "9": {
    "name": "Utilities",
    "code": "221",
    "parent": 2
  },
  "10": {
    "name": "Construction of Buildings",
    "code": "236",
    "parent": 3
  },
  "11": {
    "name": "Heavy and Civil Engineering Construction",
    "code": "237",
    "parent": 3
  },
  "12": {
    "name": "Specialty Trade Contractors",
    "code": "238",
    "parent": 3
  },
  "13": {
    "name": "Food Manufacturing",
    "code": "311",
    "parent": 4
  },
  "14": {
    "name": "Beverage and Tobacco Product Manufacturing",
    "code": "312",
    "parent": 4
  },
  "15": {
    "name": "Textile Mills",
    "code": "313",
    "parent": 4
  },
  "16": {
    "name": "Textile Product Mills",
    "code": "314",
    "parent": 4
  },
  "17": {
    "name": "Apparel Manufacturing",
    "code": "315",
    "parent": 4
  },
  "18": {
    "name": "Leather and Allied Product Manufacturing",
    "code": "316",
    "parent": 4
  },
  "19": {
    "name": "Wood Product Manufacturing",
    "code": "321",
    "parent": 4
  },
  "20": {
    "name": "Paper Manufacturing",
    "code": "322",
    "parent": 4
  },
  "21": {
    "name": "Printing and Related Support Activities",
    "code": "323",
    "parent": 4
  },
  "22": {
    "name": "Petroleum and Coal Products Manufacturing",
    "code": "324",
    "parent": 4
  },
  "23": {
    "name": "Chemical Manufacturing",
    "code": "325",
    "parent": 4
  },
  "24": {
    "name": "Plastics and Rubber Products Manufacturing",
    "code": "326",
    "parent": 4
  },
  "25": {
    "name": "Nonmetallic Mineral Product Manufacturing",
    "code": "327",
    "parent": 4
  },
  "26": {
    "name": "Primary Metal Manufacturing",
    "code": "331",
    "parent": 4
  },
  "27": {
    "name": "Fabricated Metal Product Manufacturing",
    "code": "332",
    "parent": 4
  },
  "28": {
    "name": "Machinery Manufacturing",
    "code": "333",
    "parent": 4
  },
  "29": {
    "name": "Computer and Electronic Product Manufacturing",
    "code": "334",
    "parent": 4
  },
  "30": {
    "name": "Electrical Equipment, Appliance, and Component Manufacturing",
    "code": "335",
    "parent": 4
  },
  "31": {
    "name": "Transportation Equipment Manufacturing",
    "code": "336",
    "parent": 4
  },
  "32": {
    "name": "Furniture and Related Product Manufacturing",
    "code": "337",
    "parent": 4
  },
  "33": {
    "name": "Miscellaneous Manufacturing",
    "code": "339",
    "parent": 4
  },
  "34": {
    "name": "Merchant Wholesalers, Durable Goods",
    "code": "423",
    "parent": 5
  },
  "35": {
    "name": "Merchant Wholesalers, Nondurable Goods",
    "code": "424",
    "parent": 5
  },
  "36": {
    "name": "Wholesale Electronic Markets and Agents and Brokers",
    "code": "425",
    "parent": 5
  },
  "37": {
    "name": "Motor Vehicle and Parts Dealers",
    "code": "441",
    "parent": 6
  },
  "38": {
    "name": "Furniture and Home Furnishings Stores",
    "code": "442",
    "parent": 6
  },
  "39": {
    "name": "Electronics and Appliance Stores",
    "code": "443",
    "parent": 6
  },
  "40": {
    "name": "Building Material and Garden Equipment and Supplies Dealers",
    "code": "444",
    "parent": 6
  },
  "41": {
    "name": "Food and Beverage Stores",
    "code": "445",
    "parent": 6
  },
  "42": {
    "name": "Health and Personal Care Stores",
    "code": "446",
    "parent": 6
  },
  "43": {
    "name": "Gasoline Stations",
    "code": "447",
    "parent": 6
  },
  "44": {
    "name": "Clothing and Clothing Accessories Stores",
    "code": "448",
    "parent": 6
  },
  "45": {
    "name": "Sporting Goods, Hobby, Musical Instrument, and Book Stores",
    "code": "451",
    "parent": 6
  },
  "46": {
    "name": "General Merchandise Stores",
    "code": "452",
    "parent": 6
  },
  "47": {
    "name": "Miscellaneous Store Retailers",
    "code": "453",
    "parent": 6
  },
  "48": {
    "name": "Nonstore Retailers",
    "code": "454",
    "parent": 6
  },
  "49": {
    "name": "Air Transportation",
    "code": "481",
    "parent": 7
  },
  "50": {
    "name": "Rail Transportation",
    "code": "482",
    "parent": 7
  },
  "51": {
    "name": "Water Transportation",
    "code": "483",
    "parent": 7
  },
  "52": {
    "name": "Truck Transportation",
    "code": "484",
    "parent": 7
  },
  "53": {
    "name": "Transit and Ground Passenger Transportation",
    "code": "485",
    "parent": 7
  },
  "54": {
    "name": "Pipeline Transportation",
    "code": "486",
    "parent": 7
  },
  "55": {
    "name": "Scenic and Sightseeing Transportation",
    "code": "487",
    "parent": 7
  },
  "56": {
    "name": "Support Activities for Transportation",
    "code": "488",
    "parent": 7
  },
  "57": {
    "name": "Postal Service",
    "code": "491",
    "parent": 7
  },
  "58": {
    "name": "Couriers and Messengers",
    "code": "492",
    "parent": 7
  },
  "59": {
    "name": "Warehousing and Storage",
    "code": "493",
    "parent": 7
  },
  "60": {
    "name": "Publishing Industries (except Internet)",
    "code": "511",
    "parent": 8
  },
  "61": {
    "name": "Motion Picture and Sound Recording Industries",
    "code": "512",
    "parent": 8
  },
  "62": {
    "name": "Broadcasting (except Internet)",
    "code": "515",
    "parent": 8
  },
  "63": {
    "name": "Telecommunications",
    "code": "517",
    "parent": 8
  },
  "64": {
    "name": "Data Processing, Hosting, and Related Services",
    "code": "518",
    "parent": 8
  },
  "65": {
    "name": "Other Information Services",
    "code": "519",
    "parent": 8
  },
  "66": {
    "name": "Monetary Authorities-Central Bank",
    "code": "521",
    "parent": 9
  },
  "67": {
    "name": "Credit Intermediation and Related Activities",
    "code": "522",
    "parent": 9
  },
  "68": {
    "name": "Securities, Commodity Contracts, and Other Financial Investments and Related Activities",
    "code": "523",
    "parent": 9
  },
  "69": {
    "name": "Insurance Carriers and Related Activities",
    "code": "524",
    "parent": 9
  },
  "70": {
    "name": "Funds, Trusts, and Other Financial Vehicles",
    "code": "525",
    "parent": 9
  },
  "71": {
    "name": "Real Estate",
    "code": "531",
    "parent": 10
  },
  "72": {
    "name": "Rental and Leasing Services",
    "code": "532",
    "parent": 10
  },
  "73": {
    "name": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
    "code": "533",
    "parent": 10
  },
  "74": {
    "name": "Professional, Scientific, and Technical Services",
    "code": "541",
    "parent": 11
  },
  "75": {
    "name": "Management of Companies and Enterprises",
    "code": "551",
    "parent": 12
  },
  "76": {
    "name": "Administrative and Support Services",
    "code": "561",
    "parent": 13
  },
  "77": {
    "name": "Waste Management and Remediation Services",
    "code": "562",
    "parent": 13
  },
  "78": {
    "name": "Educational Services",
    "code": "611",
    "parent": 14
  },
  "79": {
    "name": "Ambulatory Health Care Services",
    "code": "621",
    "parent": 15
  },
  "80": {
    "name": "Hospitals",
    "code": "622",
    "parent": 15
  },
  "81": {
    "name": "Nursing and Residential Care Facilities",
    "code": "623",
    "parent": 15
  },
  "82": {
    "name": "Social Assistance",
    "code": "624",
    "parent": 15
  },
  "83": {
    "name": "Performing Arts, Spectator Sports, and Related Industries",
    "code": "711",
    "parent": 16
  },
  "84": {
    "name": "Museums, Historical Sites, and Similar Institutions",
    "code": "712",
    "parent": 16
  },
  "85": {
    "name": "Amusement, Gambling, and Recreation Industries",
    "code": "713",
    "parent": 16
  },
  "86": {
    "name": "Accommodation",
    "code": "721",
    "parent": 17
  },
  "87": {
    "name": "Food Services and Drinking Places",
    "code": "722",
    "parent": 17
  },
  "88": {
    "name": "Repair and Maintenance",
    "code": "811",
    "parent": 18
  },
  "89": {
    "name": "Personal and Laundry Services",
    "code": "812",
    "parent": 18
  },
  "90": {
    "name": "Religious, Grantmaking, Civic, Professional, and Similar Organizations",
    "code": "813",
    "parent": 18
  },
  "91": {
    "name": "Private Households",
    "code": "814",
    "parent": 18
  },
  "92": {
    "name": "Executive, Legislative, and Other General Government Support",
    "code": "921",
    "parent": 19
  },
  "93": {
    "name": "Justice, Public Order, and Safety Activities",
    "code": "922",
    "parent": 19
  },
  "94": {
    "name": "Administration of Human Resource Programs",
    "code": "923",
    "parent": 19
  },
  "95": {
    "name": "Administration of Environmental Quality Programs",
    "code": "924",
    "parent": 19
  },
  "96": {
    "name": "Administration of Housing Programs, Urban Planning, and Community Development",
    "code": "925",
    "parent": 19
  },
  "97": {
    "name": "Administration of Economic Programs",
    "code": "926",
    "parent": 19
  },
  "98": {
    "name": "Space Research and Technology",
    "code": "927",
    "parent": 19
  },
  "99": {
    "name": "National Security and International Affairs",
    "code": "928",
    "parent": 19
  }
};
var subindustries_default = subindustries;

// src/industries.ts
var sampleData = {
  "agriculture": {
    displayName: "Agriculture, Forestry, Fishing and Hunting",
    loanAmount: "$2,000,000",
    maturity: "9 Years",
    interestRate: "6.5%",
    monthlyPayment: "$24,509",
    id: 0
  },
  "mining": {
    displayName: "Mining, Quarrying, and Oil and Gas Extraction",
    loanAmount: "$2,000,000",
    maturity: "9 Years",
    interestRate: "6.5%",
    monthlyPayment: "$24,509",
    id: 1
  },
  "utilities": {
    displayName: "Utilities",
    loanAmount: "$3,000,000",
    maturity: "8 Years",
    interestRate: "1.2%",
    monthlyPayment: "$32,789",
    id: 2
  },
  "construction": {
    displayName: "Construction",
    loanAmount: "1,000,000",
    maturity: " 10 Years",
    interestRate: "7.5%",
    monthlyPayment: "$411,870",
    id: 3
  },
  "manufacturing": {
    displayName: "Manufacturing",
    loanAmount: "$4,000,000",
    maturity: "5 Years",
    interestRate: "5%",
    monthlyPayment: "$75,484",
    id: 4
  },
  "wholesaleTrade": {
    displayName: "Wholesale Trade",
    loanAmount: "$3,000,000",
    maturity: "8 Years",
    interestRate: "1.2%",
    monthlyPayment: "$32,789",
    id: 5
  },
  "retailTrade": {
    displayName: "Retail Trade",
    loanAmount: "$3,000,000",
    maturity: "8 Years",
    interestRate: "1.2%",
    monthlyPayment: "$32,789",
    id: 6
  },
  "transportation": {
    displayName: "Transportation and Warehousing",
    loanAmount: "$3,000,000",
    maturity: "8 Years",
    interestRate: "1.2%",
    monthlyPayment: "$32,789",
    id: 7
  },
  "information": {
    displayName: "Information",
    loanAmount: "$4,000,000",
    maturity: "7 Years",
    interestRate: "8.9%",
    monthlyPayment: "$64,153",
    id: 8
  },
  "finance": {
    displayName: "Finance and Insurance",
    loanAmount: "$5,000,000",
    maturity: "6 Years",
    interestRate: "7.3%",
    monthlyPayment: "$85,967",
    id: 9
  },
  "realEstate": {
    displayName: "Real Estate and Rental and Leasing",
    loanAmount: "$5,000,000",
    maturity: "6 Years",
    interestRate: "7.3%",
    monthlyPayment: "$85,967",
    id: 10
  },
  "professional": {
    displayName: "Professional, Scientific, and Technical Services",
    loanAmount: "$6,000,000",
    maturity: "5 Years",
    interestRate: "3%",
    monthlyPayment: "$107,812",
    id: 11
  },
  "management": {
    displayName: "Management of Companies and Enterprises",
    loanAmount: "$6,000,000",
    maturity: "5 Years",
    interestRate: "3%",
    monthlyPayment: "$107,812",
    id: 12
  },
  "administrative": {
    displayName: "Administrative and Support and Waste Management and Remediation Services",
    loanAmount: "$6,000,000",
    maturity: "5 Years",
    interestRate: "3%",
    monthlyPayment: "$107,812",
    id: 13
  },
  "education": {
    displayName: "Educational Services",
    loanAmount: "$7,000,000",
    maturity: "4 Years",
    interestRate: "5%",
    monthlyPayment: "$161,205",
    id: 14
  },
  "healthCare": {
    displayName: "Health Care and Social Assistance",
    loanAmount: "$8,000,000",
    maturity: "3 Years",
    interestRate: "2.4%",
    monthlyPayment: "$230,540",
    id: 15
  },
  "recreation": {
    displayName: "Arts, Entertainment, and Recreation",
    loanAmount: "$9,000,000",
    maturity: "2 Years",
    interestRate: "4.8%",
    monthlyPayment: "$394,036",
    id: 16
  },
  "accomodation": {
    displayName: "Accommodation and Food Services",
    loanAmount: "$9,000,000",
    maturity: "2 Years",
    interestRate: "4.8%",
    monthlyPayment: "$394,036",
    id: 17
  },
  "other": {
    displayName: "Other Services (Except Public Administration)",
    loanAmount: "$10,000,000",
    maturity: "1 Years",
    interestRate: "4.61%",
    monthlyPayment: "$854,289",
    id: 18
  },
  "publicAdmin": {
    displayName: "Public Administration",
    loanAmount: "$2,000,000",
    maturity: "3 Years",
    interestRate: "6%",
    monthlyPayment: "$60,844",
    id: 19
  }
};
var industries_default = sampleData;

// src/loanoffers.ts
var OfferStatus = /* @__PURE__ */ ((OfferStatus2) => {
  OfferStatus2[OfferStatus2["SubmittedByLender"] = 0] = "SubmittedByLender";
  OfferStatus2[OfferStatus2["SubmittedToLenderForImprovement"] = 1] = "SubmittedToLenderForImprovement";
  OfferStatus2[OfferStatus2["ApprovedBySpecialist"] = 2] = "ApprovedBySpecialist";
  OfferStatus2[OfferStatus2["AcceptedByBorrower"] = 3] = "AcceptedByBorrower";
  OfferStatus2[OfferStatus2["RejectedByBorrower"] = 4] = "RejectedByBorrower";
  OfferStatus2[OfferStatus2["Closed"] = 5] = "Closed";
  return OfferStatus2;
})(OfferStatus || {});
var approveOffers = (loanOffers) => request({
  url: "/offers/ApproveOffers",
  method: "POST",
  data: {
    OfferIds: loanOffers
  }
});
var returnOffersToLenders = (loanOffers) => request({
  url: "/offers/ReturnOffersToLenders",
  method: "POST",
  data: {
    OfferIds: loanOffers
  }
});
var acceptOffer = (offerId) => request({
  url: "/offers/AcceptLoanOffer?offerId=" + offerId,
  method: "POST"
});
var declineOffer = (offerId) => request({
  url: "/offers/DeclineLoanOffer?offerId=" + offerId,
  method: "POST"
});
var getOffersForReview = (loanAppId) => () => request({
  url: `/offers/GetOffersForReview?loanAppId=${loanAppId}`,
  method: "GET"
});
var getTermSheets = (portalType, loanAppId) => () => request({
  url: `/loan-applications/GetTermSheets?portalType=${portalType}&loanAppId=${loanAppId}`,
  method: "GET"
});
var getOffer = (portalType, offerId) => () => request({
  url: `/loan-applications/GetOffer?portalType=${portalType}&offerId=${offerId}`,
  method: "GET"
});
var getAcceptedOffers = (portalType, loanAppId) => () => request({
  url: `/loan-applications/GetAcceptedOffers?portalType=${portalType}&loanAppId=${loanAppId}`,
  method: "GET"
});

// src/loanapplications.ts
var specialistLoanStatusOptions = [
  {
    label: "New",
    value: "New"
  },
  {
    label: "In Review",
    value: "In Review"
  },
  {
    label: "Receiving",
    value: "Receiving"
  },
  {
    label: "Non-Binding",
    value: "Non-Binding"
  },
  {
    label: "Binding",
    value: "Binding"
  },
  {
    label: "Closing",
    value: "Closing"
  },
  {
    label: "Declined",
    value: "Declined"
  },
  {
    label: "Cancelled",
    value: "Cancelled"
  },
  {
    label: "Closed",
    value: "Closed"
  }
];
var statusColors = {
  "New": "#00c88d",
  "In Review": "#00C7B1",
  "Receiving Offers": "#FFB259",
  "Non-Binding": "#0095ff",
  "Binding": "#004bff",
  "Closing": "#002785",
  "Declined": "#b30000",
  "Cancelled": "#b30000",
  "Closed": "#6b6b6b"
};
var lenderLoanStatusOptions = [
  ...specialistLoanStatusOptions,
  {
    label: "Missed",
    value: "Missed"
  },
  {
    label: "Not Pursued",
    value: "Not Pursued"
  }
];
var isPreSubmit = (status) => [
  28 /* Screen1 */,
  0 /* Screen2 */,
  1 /* Screen3 */,
  2 /* InProgress */,
  4 /* SpecialistReview */
].includes(status);
var isPreFirstSubmit = (status) => [
  28 /* Screen1 */,
  0 /* Screen2 */,
  1 /* Screen3 */,
  2 /* InProgress */
].includes(status);
var deleteAdditionalLoanAppFile = (data) => request({
  url: `/loan-applications/DeleteAdditionalFile?portalType=${data.portalType}&loanAppId=${data.loanAppId}&fileId=${data.fileId}`,
  method: "POST"
});
var uploadAdditionalLoanAppFile = (data) => request({
  url: `/loan-applications/UploadAdditionalFiles?portalType=${data.portalType}&loanAppId=${data.loanAppId}`,
  method: "POST",
  data: data.formData,
  headers: {
    "Content-Type": "multipart/form-data"
  },
  onUploadProgress: data.onUploadProgress,
  onDownloadProgress: data.onDownloadProgress
});
var getEngagedLenders = (id) => () => request({
  url: `/loan-applications/GetEngagedLenders?loanAppId=${id}`,
  method: "GET"
});
var uploadPrivateAdditionalLoanAppFile = (data) => request({
  url: `/loan-applications/UploadAdditionalPrivateFiles?portalType=${data.portalType}&loanAppId=${data.loanAppId}`,
  method: "POST",
  data: data.formData,
  headers: {
    "Content-Type": "multipart/form-data"
  },
  onUploadProgress: data.onUploadProgress,
  onDownloadProgress: data.onDownloadProgress
});
var loanRatios = [
  "EBITDA_To_Revenue",
  "EBITDA_To_Loan_Amount",
  "Loan_Amount_To_Receivables",
  "Inventory_To_Loan_Amount",
  "PPE_To_Loan_Amount",
  "Cash_To_Loan_Amount",
  "Loan_Amount_To_Revenue"
];
var metricQualities = [
  {
    name: "Very Poor",
    color: "#FF0000"
  },
  {
    name: "Poor",
    color: "#FF8000"
  },
  {
    name: "Fair",
    color: "#000000"
  },
  {
    name: "Good",
    // color: "#99FF33",
    color: "#00CC00"
  },
  {
    name: "Great",
    color: "#009900"
  },
  {
    name: "Excellent",
    color: "#3659d9"
  }
];
var getLoanAppTitle = (x) => {
  return `${currency(x.LoanAmount)} - ${loanTypes.find((y) => y.id == x.LoanType)?.fullName} - ${new Date(x.CreatedAt).toLocaleString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric"
  })}`;
};
var loanUseOptions = [
  {
    label: "Acquisition",
    value: 1
  },
  {
    label: "Growth Capital",
    value: 2
  },
  {
    label: "General/Working Capital",
    value: 3
  },
  {
    label: "Inventory, Equipment or Other Asset Purchase",
    value: 4
  },
  {
    label: "Refinance",
    value: 5
  },
  {
    label: "Shareholder Liquidity",
    value: 6
  }
];
var loanApplicationModel = import_zod3.default.object({
  Id: import_zod3.default.string(),
  LoanType: import_zod3.default.number(),
  LoanAmount: import_zod3.default.number(),
  PersonalGuarantee: import_zod3.default.boolean(),
  LoanUse: import_zod3.default.number()
});
var DiligenceStatus = /* @__PURE__ */ ((DiligenceStatus2) => {
  DiligenceStatus2[DiligenceStatus2["Pending"] = 0] = "Pending";
  DiligenceStatus2[DiligenceStatus2["Sent"] = 1] = "Sent";
  DiligenceStatus2[DiligenceStatus2["Received"] = 2] = "Received";
  DiligenceStatus2[DiligenceStatus2["Complete"] = 3] = "Complete";
  return DiligenceStatus2;
})(DiligenceStatus || {});
var LoanApplicationStatus = /* @__PURE__ */ ((LoanApplicationStatus2) => {
  LoanApplicationStatus2[LoanApplicationStatus2["Screen2"] = 0] = "Screen2";
  LoanApplicationStatus2[LoanApplicationStatus2["Screen3"] = 1] = "Screen3";
  LoanApplicationStatus2[LoanApplicationStatus2["InProgress"] = 2] = "InProgress";
  LoanApplicationStatus2[LoanApplicationStatus2["Attested"] = 3] = "Attested";
  LoanApplicationStatus2[LoanApplicationStatus2["SpecialistReview"] = 4] = "SpecialistReview";
  LoanApplicationStatus2[LoanApplicationStatus2["DiligenceInProgress"] = 5] = "DiligenceInProgress";
  LoanApplicationStatus2[LoanApplicationStatus2["DiligenceComplete"] = 6] = "DiligenceComplete";
  LoanApplicationStatus2[LoanApplicationStatus2["SpecialistApproved"] = 7] = "SpecialistApproved";
  LoanApplicationStatus2[LoanApplicationStatus2["SpecialistDenied"] = 8] = "SpecialistDenied";
  LoanApplicationStatus2[LoanApplicationStatus2["MoreInformationRequiredBySpecialist"] = 9] = "MoreInformationRequiredBySpecialist";
  LoanApplicationStatus2[LoanApplicationStatus2["LendersReview"] = 10] = "LendersReview";
  LoanApplicationStatus2[LoanApplicationStatus2["LendersQna"] = 11] = "LendersQna";
  LoanApplicationStatus2[LoanApplicationStatus2["SpecialistOffersReview"] = 12] = "SpecialistOffersReview";
  LoanApplicationStatus2[LoanApplicationStatus2["OffersSubmitted"] = 13] = "OffersSubmitted";
  LoanApplicationStatus2[LoanApplicationStatus2["OfferAccepted"] = 14] = "OfferAccepted";
  LoanApplicationStatus2[LoanApplicationStatus2["BtsSubmitted"] = 15] = "BtsSubmitted";
  LoanApplicationStatus2[LoanApplicationStatus2["BtsSignedByBorrower"] = 16] = "BtsSignedByBorrower";
  LoanApplicationStatus2[LoanApplicationStatus2["SignedBtsDenied"] = 17] = "SignedBtsDenied";
  LoanApplicationStatus2[LoanApplicationStatus2["SignedBtsApproved"] = 18] = "SignedBtsApproved";
  LoanApplicationStatus2[LoanApplicationStatus2["ClosingDocumentsSubmitted"] = 19] = "ClosingDocumentsSubmitted";
  LoanApplicationStatus2[LoanApplicationStatus2["ClosingDocumentsSigned"] = 20] = "ClosingDocumentsSigned";
  LoanApplicationStatus2[LoanApplicationStatus2["SignedClosingDocumentsDenied"] = 21] = "SignedClosingDocumentsDenied";
  LoanApplicationStatus2[LoanApplicationStatus2["Closed"] = 22] = "Closed";
  LoanApplicationStatus2[LoanApplicationStatus2["Rejected"] = 23] = "Rejected";
  LoanApplicationStatus2[LoanApplicationStatus2["Cancelled"] = 24] = "Cancelled";
  LoanApplicationStatus2[LoanApplicationStatus2["SpecialistBtsReview"] = 25] = "SpecialistBtsReview";
  LoanApplicationStatus2[LoanApplicationStatus2["SignedClosingDocumentsApproved"] = 26] = "SignedClosingDocumentsApproved";
  LoanApplicationStatus2[LoanApplicationStatus2["IntroductoryCallComplete"] = 27] = "IntroductoryCallComplete";
  LoanApplicationStatus2[LoanApplicationStatus2["Screen1"] = 28] = "Screen1";
  LoanApplicationStatus2[LoanApplicationStatus2["LenderBorrowerCallComplete"] = 29] = "LenderBorrowerCallComplete";
  LoanApplicationStatus2[LoanApplicationStatus2["LenderRequestedInformation"] = 30] = "LenderRequestedInformation";
  LoanApplicationStatus2[LoanApplicationStatus2["ReadyForBts"] = 31] = "ReadyForBts";
  return LoanApplicationStatus2;
})(LoanApplicationStatus || {});
var loanTypes = [
  {
    name: "ABL",
    key: "Abl",
    fullName: "Asset Backed",
    color: "#7f47de",
    id: 0,
    probability: 1,
    weighting: 1
  },
  {
    name: "A/R Factoring",
    key: "ArFactoring",
    fullName: "Accounts Receivable Factoring",
    color: "#93268f",
    id: 1,
    probability: 1,
    weighting: 1
  },
  {
    name: "SBA",
    key: "Sba",
    fullName: "Small Business Administration",
    color: "#425dc8",
    id: 2,
    probability: 1,
    weighting: 1
  },
  {
    name: "Equipment",
    key: "Equipment",
    fullName: "Equipment",
    color: "#4abee4",
    id: 3,
    probability: 1,
    weighting: 1
  },
  {
    name: "SBIC",
    key: "Sbic",
    fullName: "Small Business Investment Company",
    color: "#95de47",
    id: 4,
    probability: 1,
    weighting: 1
  },
  {
    name: "Cash Flow",
    key: "CashFlow",
    fullName: "Cash Flow",
    color: "#f37d0a",
    id: 5,
    probability: 1,
    weighting: 1
  },
  {
    name: "Junior/Mezz",
    key: "JuniorMezz",
    fullName: "Junior/Mezzanine",
    color: "#e44a72",
    id: 6,
    probability: 1,
    weighting: 1
  },
  {
    name: "Venture/Rev",
    key: "VentureRev",
    fullName: "Venture/Revenue",
    color: "#4ae4cc",
    id: 7,
    probability: 1,
    weighting: 1
  }
];
var submitLoanApplication = (data) => request({
  url: "/loan-applications/SubmitLoanApplication?loanAppId=" + data.loanAppId,
  method: "POST",
  data: data.data
});
var loanTypeOptions = loanTypes.map((type) => ({
  value: type.id,
  label: type.fullName
}));
var loanTypeString = (loanType) => {
  return loanTypes.find((x) => x.id == loanType)?.name || "Invalid";
};
var updateLoanApplication = (data) => request({
  url: "/loan-applications/UpdateLoanApplication?loanAppId=" + data.loanAppId,
  method: "POST",
  data: data.loanApp
});
var industryOptions = Object.values(industries_default).map((industry) => ({
  label: industry.displayName,
  value: industry.displayName
})).sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  } else if (a.label > b.label) {
    return 1;
  }
  return 0;
});
var numberedIndustryOptions = Object.values(industries_default).map((industry) => ({
  label: industry.displayName,
  value: industry.id + 1
})).sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  } else if (a.label > b.label) {
    return 1;
  }
  return 0;
});
var subIndustryOptions = Object.entries(subindustries_default).map(([_, industry]) => ({
  label: industry.name,
  value: industry.name,
  parent: Object.values(industries_default).find((x) => x.id == industry.parent)?.displayName
})).sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  } else if (a.label > b.label) {
    return 1;
  }
  return 0;
});
var numberedSubIndustryOptions = Object.entries(subindustries_default).map(([key, industry]) => ({
  label: industry.name,
  value: parseInt(key) - 1,
  parent: industry.parent
})).sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  } else if (a.label > b.label) {
    return 1;
  }
  return 0;
});
var PublicLoanApplicationStatus = /* @__PURE__ */ ((PublicLoanApplicationStatus2) => {
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["Unsubmitted"] = 0] = "Unsubmitted";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["SpecialistReview"] = 1] = "SpecialistReview";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["ReadyForBorrowerSubmit"] = 2] = "ReadyForBorrowerSubmit";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["AwaitingOffers"] = 3] = "AwaitingOffers";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["SpecialistReviewingOffers"] = 4] = "SpecialistReviewingOffers";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["BorrowerReviewingOffers"] = 5] = "BorrowerReviewingOffers";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["AwaitingBindingOffer"] = 6] = "AwaitingBindingOffer";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["SpecialistReviewingBindingOffer"] = 7] = "SpecialistReviewingBindingOffer";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["BorrowerReviewingBindingOffer"] = 8] = "BorrowerReviewingBindingOffer";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["AwaitingClosingDocs"] = 9] = "AwaitingClosingDocs";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["BorrowerSigningClosingDocs"] = 10] = "BorrowerSigningClosingDocs";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["SpecialistReviewingSignedClosingDocs"] = 11] = "SpecialistReviewingSignedClosingDocs";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["AwaitingClose"] = 12] = "AwaitingClose";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["Missed"] = 13] = "Missed";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["Declined"] = 14] = "Declined";
  PublicLoanApplicationStatus2[PublicLoanApplicationStatus2["Closed"] = 15] = "Closed";
  return PublicLoanApplicationStatus2;
})(PublicLoanApplicationStatus || {});
var ApplicationStatusMessage = /* @__PURE__ */ ((ApplicationStatusMessage2) => {
  ApplicationStatusMessage2[ApplicationStatusMessage2["Missed"] = 0] = "Missed";
  ApplicationStatusMessage2[ApplicationStatusMessage2["Declined"] = 1] = "Declined";
  ApplicationStatusMessage2[ApplicationStatusMessage2["NotPursued"] = 2] = "NotPursued";
  ApplicationStatusMessage2[ApplicationStatusMessage2["Unsubmitted"] = 3] = "Unsubmitted";
  ApplicationStatusMessage2[ApplicationStatusMessage2["InReview"] = 4] = "InReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ReadyForBorrowerReview"] = 5] = "ReadyForBorrowerReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ReadyForFinalSubmission"] = 6] = "ReadyForFinalSubmission";
  ApplicationStatusMessage2[ApplicationStatusMessage2["WaitingForBorrowerFinalAttest"] = 7] = "WaitingForBorrowerFinalAttest";
  ApplicationStatusMessage2[ApplicationStatusMessage2["RequiresManualAction"] = 8] = "RequiresManualAction";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ReadyToSubmitToLenders"] = 9] = "ReadyToSubmitToLenders";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ReadyForNonBindingOffer"] = 10] = "ReadyForNonBindingOffer";
  ApplicationStatusMessage2[ApplicationStatusMessage2["SentToLendersForReview"] = 11] = "SentToLendersForReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["OfferNeedsRevision"] = 12] = "OfferNeedsRevision";
  ApplicationStatusMessage2[ApplicationStatusMessage2["OffersInReview"] = 13] = "OffersInReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["OffersAvailableToReview"] = 14] = "OffersAvailableToReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["BorrowerReviewingOffers"] = 15] = "BorrowerReviewingOffers";
  ApplicationStatusMessage2[ApplicationStatusMessage2["WaitingForLenderBindingTerms"] = 16] = "WaitingForLenderBindingTerms";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ReadyForBindingOffer"] = 17] = "ReadyForBindingOffer";
  ApplicationStatusMessage2[ApplicationStatusMessage2["BindingOfferNeedsRevision"] = 18] = "BindingOfferNeedsRevision";
  ApplicationStatusMessage2[ApplicationStatusMessage2["BindingOfferInReview"] = 19] = "BindingOfferInReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["LenderCreatingBindingTermSheet"] = 20] = "LenderCreatingBindingTermSheet";
  ApplicationStatusMessage2[ApplicationStatusMessage2["BindingOfferAvailableForReview"] = 21] = "BindingOfferAvailableForReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["BorrowerReviewingBindingOffer"] = 22] = "BorrowerReviewingBindingOffer";
  ApplicationStatusMessage2[ApplicationStatusMessage2["SpecialistReviewingSignedBTS"] = 23] = "SpecialistReviewingSignedBTS";
  ApplicationStatusMessage2[ApplicationStatusMessage2["SignedBTSReadyForReview"] = 24] = "SignedBTSReadyForReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ReadyForClosingDocs"] = 25] = "ReadyForClosingDocs";
  ApplicationStatusMessage2[ApplicationStatusMessage2["WaitingForLenderClosingDocs"] = 26] = "WaitingForLenderClosingDocs";
  ApplicationStatusMessage2[ApplicationStatusMessage2["BorrowerReviewingClosingDocs"] = 27] = "BorrowerReviewingClosingDocs";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ClosingDocsAvailableForReview"] = 28] = "ClosingDocsAvailableForReview";
  ApplicationStatusMessage2[ApplicationStatusMessage2["SpecialistReviewingSignedClosingDocs"] = 29] = "SpecialistReviewingSignedClosingDocs";
  ApplicationStatusMessage2[ApplicationStatusMessage2["SignedClosingDocsAvailable"] = 30] = "SignedClosingDocsAvailable";
  ApplicationStatusMessage2[ApplicationStatusMessage2["ReadyToClose"] = 31] = "ReadyToClose";
  ApplicationStatusMessage2[ApplicationStatusMessage2["WaitingForFinalClose"] = 32] = "WaitingForFinalClose";
  ApplicationStatusMessage2[ApplicationStatusMessage2["Cancelled"] = 33] = "Cancelled";
  ApplicationStatusMessage2[ApplicationStatusMessage2["Closed"] = 34] = "Closed";
  return ApplicationStatusMessage2;
})(ApplicationStatusMessage || {});
var getStatusMessageString = (statusMessage) => {
  switch (statusMessage) {
    case 0 /* Missed */:
      return "Missed";
    case 1 /* Declined */:
      return "Declined";
    case 2 /* NotPursued */:
      return "Not Pursued";
    case 3 /* Unsubmitted */:
      return "Unsubmitted";
    case 4 /* InReview */:
      return "In Review";
    case 5 /* ReadyForBorrowerReview */:
      return "Ready for Borrower Review";
    case 6 /* ReadyForFinalSubmission */:
      return "Ready for Borrower Final Attest and Submission";
    case 7 /* WaitingForBorrowerFinalAttest */:
      return "Waiting for Borrower Final Attestation";
    case 8 /* RequiresManualAction */:
      return "Requires Manual Action";
    case 9 /* ReadyToSubmitToLenders */:
      return "Ready to Submit to Lenders";
    case 10 /* ReadyForNonBindingOffer */:
      return "Ready for Non-Binding Offer";
    case 11 /* SentToLendersForReview */:
      return "Sent to Lenders for Review";
    case 12 /* OfferNeedsRevision */:
      return "Offer Needs Revision";
    case 13 /* OffersInReview */:
      return "Offers In Review";
    case 14 /* OffersAvailableToReview */:
      return "Offers Available for Review";
    case 15 /* BorrowerReviewingOffers */:
      return "Borrower Reviewing Offers";
    case 16 /* WaitingForLenderBindingTerms */:
      return "Waiting for Lender Binding Terms";
    case 17 /* ReadyForBindingOffer */:
      return "Ready for Binding Offer";
    case 18 /* BindingOfferNeedsRevision */:
      return "Binding Offer needs revision";
    case 19 /* BindingOfferInReview */:
      return "Binding Offer in review";
    case 20 /* LenderCreatingBindingTermSheet */:
      return "Witing for Binding Term Sheet";
    case 21 /* BindingOfferAvailableForReview */:
      return "Binding offer Available for Review";
    case 22 /* BorrowerReviewingBindingOffer */:
      return "Borrower Reviewing Binding Offer";
    case 23 /* SpecialistReviewingSignedBTS */:
      return "Specialist Reviewing Signed BTS";
    case 24 /* SignedBTSReadyForReview */:
      return "Signed BTS Ready for Review";
    case 25 /* ReadyForClosingDocs */:
      return "Ready for Closing Docs";
    case 26 /* WaitingForLenderClosingDocs */:
      return "Waiting for Lender Closing Docs";
    case 27 /* BorrowerReviewingClosingDocs */:
      return "Borrower Reviewing Closing Docs";
    case 28 /* ClosingDocsAvailableForReview */:
      return "Closing Docs Available for Review";
    case 29 /* SpecialistReviewingSignedClosingDocs */:
      return "Specialist Reviewing Signed Closing Docs";
    case 30 /* SignedClosingDocsAvailable */:
      return "Signed Closing Docs Available";
    case 31 /* ReadyToClose */:
      return "Ready to Close";
    case 32 /* WaitingForFinalClose */:
      return "Waiting for Final Close";
    case 33 /* Cancelled */:
      return "Cancelled";
    case 34 /* Closed */:
      return "Closed";
  }
};
var getLoanStatusMessage = (application, portalType) => {
  const status = application.Status;
  let message = 0 /* Missed */;
  let category = "";
  let requiresAction = false;
  if (portalType == "lender" /* Lender */) {
    switch (application.LenderApplicationStatus) {
      case 5 /* Missed */:
        return {
          message: 0 /* Missed */,
          category: "Missed",
          requiresAction: false
        };
      case 4 /* Declined */:
        return {
          message: 1 /* Declined */,
          category: "Declined",
          requiresAction: false
        };
      case 3 /* NotPursued */:
        return {
          message: 2 /* NotPursued */,
          category: "Not Pursued",
          requiresAction: false
        };
      case 1 /* InProgress */:
      case 2 /* Pursued */:
        break;
    }
  }
  switch (status) {
    case 28 /* Screen1 */:
    case 0 /* Screen2 */:
    case 1 /* Screen3 */:
    case 2 /* InProgress */:
      message = 3 /* Unsubmitted */;
      category = "New";
      break;
    case 3 /* Attested */:
      category = "New";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 4 /* InReview */;
          break;
        case "borrower" /* Borrower */:
          message = 4 /* InReview */;
          break;
        case "management" /* Management */:
          message = 8 /* RequiresManualAction */;
          requiresAction = true;
          break;
      }
      break;
    case 4 /* SpecialistReview */:
      if (application.DiligenceStatus == 3 /* Complete */ || application.DiligenceStatus == 2 /* Received */) {
        switch (portalType) {
          case "lender" /* Lender */:
            message = 4 /* InReview */;
            break;
          case "borrower" /* Borrower */:
            message = 6 /* ReadyForFinalSubmission */;
            requiresAction = true;
            break;
          case "management" /* Management */:
            message = 7 /* WaitingForBorrowerFinalAttest */;
            break;
        }
      } else {
        switch (portalType) {
          case "lender" /* Lender */:
            message = 4 /* InReview */;
            break;
          case "borrower" /* Borrower */:
            message = 4 /* InReview */;
            break;
          case "management" /* Management */:
            message = 5 /* ReadyForBorrowerReview */;
            requiresAction = true;
            break;
        }
      }
      category = "In Review";
      break;
    case 6 /* DiligenceComplete */:
      category = "In Review";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 4 /* InReview */;
          break;
        case "borrower" /* Borrower */:
          message = 4 /* InReview */;
          break;
        case "management" /* Management */:
          message = 9 /* ReadyToSubmitToLenders */;
          requiresAction = true;
          break;
      }
      break;
    case 30 /* LenderRequestedInformation */:
    case 14 /* OfferAccepted */:
    case 29 /* LenderBorrowerCallComplete */:
      category = "Non-Binding";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 4 /* InReview */;
          break;
        case "borrower" /* Borrower */:
          message = 4 /* InReview */;
          break;
        case "management" /* Management */:
          message = 8 /* RequiresManualAction */;
          requiresAction = true;
          break;
      }
      break;
    case 27 /* IntroductoryCallComplete */:
    case 5 /* DiligenceInProgress */:
      category = "Non-Binding";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 4 /* InReview */;
          break;
        case "borrower" /* Borrower */:
          message = 4 /* InReview */;
          break;
        case "management" /* Management */:
          message = 5 /* ReadyForBorrowerReview */;
          requiresAction = true;
          break;
      }
      break;
    case 7 /* SpecialistApproved */:
    case 10 /* LendersReview */:
    case 11 /* LendersQna */:
      category = "Receiving Offers";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 10 /* ReadyForNonBindingOffer */;
          requiresAction = true;
          break;
        case "borrower" /* Borrower */:
          message = 11 /* SentToLendersForReview */;
          break;
        case "management" /* Management */:
          message = 11 /* SentToLendersForReview */;
          break;
      }
      break;
    case 12 /* SpecialistOffersReview */:
      category = "Receiving Offers";
      if (portalType == "lender" /* Lender */ && application.NonBindingLenderOfferStatuses && application.NonBindingLenderOfferStatuses.find(
        (x) => x == 1 /* SubmittedToLenderForImprovement */
      ) != void 0 && application.NonBindingLenderOfferStatuses.every(
        (x) => x == 1 /* SubmittedToLenderForImprovement */ || x == 4 /* RejectedByBorrower */
      )) {
        message = 12 /* OfferNeedsRevision */;
        requiresAction = true;
      } else if (portalType == "lender" /* Lender */ && application.NonBindingLenderOfferStatuses && application.NonBindingLenderOfferStatuses.find(
        (x) => x == 0 /* SubmittedByLender */ || x == 2 /* ApprovedBySpecialist */
      ) == void 0) {
        message = 10 /* ReadyForNonBindingOffer */;
        requiresAction = true;
        break;
      } else if (portalType == "lender" /* Lender */ && application.NonBindingLenderOfferStatuses && application.NonBindingLenderOfferStatuses.find(
        (x) => x == 2 /* ApprovedBySpecialist */
      ) != void 0) {
        message = 15 /* BorrowerReviewingOffers */;
        break;
      } else if (portalType == "borrower" /* Borrower */ && application.NonBindingLenderOfferStatuses && application.NonBindingLenderOfferStatuses.find(
        (x) => x == 2 /* ApprovedBySpecialist */
      ) != null) {
        message = 14 /* OffersAvailableToReview */;
        requiresAction = true;
        break;
      } else {
        switch (portalType) {
          case "lender" /* Lender */:
            message = 13 /* OffersInReview */;
            break;
          case "borrower" /* Borrower */:
            message = 11 /* SentToLendersForReview */;
            break;
          case "management" /* Management */:
            message = 14 /* OffersAvailableToReview */;
            requiresAction = true;
            break;
        }
      }
      break;
    case 13 /* OffersSubmitted */:
      category = "Receiving Offers";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 15 /* BorrowerReviewingOffers */;
          break;
        case "borrower" /* Borrower */:
          message = 14 /* OffersAvailableToReview */;
          requiresAction = true;
          break;
        case "management" /* Management */:
          message = 15 /* BorrowerReviewingOffers */;
          break;
      }
      break;
    case 31 /* ReadyForBts */:
      category = "Binding";
      switch (portalType) {
        case "lender" /* Lender */:
          if (application.BindingLenderOfferStatuses && application.BindingLenderOfferStatuses.find(
            (x) => x == 1 /* SubmittedToLenderForImprovement */ || x == 4 /* RejectedByBorrower */
          ) != void 0) {
            message = 18 /* BindingOfferNeedsRevision */;
            requiresAction = true;
          } else {
            message = 17 /* ReadyForBindingOffer */;
            requiresAction = true;
          }
          break;
        case "borrower" /* Borrower */:
          message = 16 /* WaitingForLenderBindingTerms */;
          break;
        case "management" /* Management */:
          message = 16 /* WaitingForLenderBindingTerms */;
          break;
      }
      break;
    case 25 /* SpecialistBtsReview */:
      category = "Binding";
      if (portalType == "lender" /* Lender */ && application.BindingLenderOfferStatuses && application.BindingLenderOfferStatuses.find(
        (x) => x == 1 /* SubmittedToLenderForImprovement */
      ) != void 0 && application.BindingLenderOfferStatuses.every(
        (x) => x == 1 /* SubmittedToLenderForImprovement */ || x == 4 /* RejectedByBorrower */
      )) {
        message = 18 /* BindingOfferNeedsRevision */;
        requiresAction = true;
      } else {
        switch (portalType) {
          case "lender" /* Lender */:
            message = 19 /* BindingOfferInReview */;
            break;
          case "borrower" /* Borrower */:
            message = 20 /* LenderCreatingBindingTermSheet */;
            break;
          case "management" /* Management */:
            message = 21 /* BindingOfferAvailableForReview */;
            requiresAction = true;
            break;
        }
      }
      break;
    case 15 /* BtsSubmitted */:
      category = "Binding";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 22 /* BorrowerReviewingBindingOffer */;
          break;
        case "borrower" /* Borrower */:
          message = 21 /* BindingOfferAvailableForReview */;
          requiresAction = true;
          break;
        case "management" /* Management */:
          message = 22 /* BorrowerReviewingBindingOffer */;
          break;
      }
      break;
    case 16 /* BtsSignedByBorrower */:
      category = "Binding";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 23 /* SpecialistReviewingSignedBTS */;
          break;
        case "borrower" /* Borrower */:
          message = 23 /* SpecialistReviewingSignedBTS */;
          break;
        case "management" /* Management */:
          message = 24 /* SignedBTSReadyForReview */;
          requiresAction = true;
          break;
      }
      break;
    case 18 /* SignedBtsApproved */:
      category = "Closing";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 25 /* ReadyForClosingDocs */;
          requiresAction = true;
          break;
        case "borrower" /* Borrower */:
          message = 26 /* WaitingForLenderClosingDocs */;
          break;
        case "management" /* Management */:
          message = 26 /* WaitingForLenderClosingDocs */;
          break;
      }
      break;
    case 19 /* ClosingDocumentsSubmitted */:
      category = "Closing";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 27 /* BorrowerReviewingClosingDocs */;
          break;
        case "borrower" /* Borrower */:
          message = 28 /* ClosingDocsAvailableForReview */;
          requiresAction = true;
          break;
        case "management" /* Management */:
          message = 27 /* BorrowerReviewingClosingDocs */;
          break;
      }
      break;
    case 20 /* ClosingDocumentsSigned */:
      category = "Closing";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 29 /* SpecialistReviewingSignedClosingDocs */;
          break;
        case "borrower" /* Borrower */:
          message = 29 /* SpecialistReviewingSignedClosingDocs */;
          break;
        case "management" /* Management */:
          message = 30 /* SignedClosingDocsAvailable */;
          requiresAction = true;
          break;
      }
      break;
    case 26 /* SignedClosingDocumentsApproved */:
      category = "Closing";
      switch (portalType) {
        case "lender" /* Lender */:
          message = 31 /* ReadyToClose */;
          requiresAction = true;
          break;
        case "borrower" /* Borrower */:
          message = 32 /* WaitingForFinalClose */;
          break;
        case "management" /* Management */:
          message = 32 /* WaitingForFinalClose */;
          break;
      }
      break;
    case 8 /* SpecialistDenied */:
    case 9 /* MoreInformationRequiredBySpecialist */:
    case 23 /* Rejected */:
      category = "Declined";
      message = 1 /* Declined */;
      break;
    case 24 /* Cancelled */:
      message = 33 /* Cancelled */;
      category = "Cancelled";
      break;
    case 22 /* Closed */:
      message = 34 /* Closed */;
      category = "Closed";
      break;
  }
  return {
    category,
    message,
    requiresAction
  };
};
var getLoanApplication = (portalType, loanAppId) => () => request({
  url: `/loan-applications/GetLoanApplication?portalType=${portalType}&loanAppId=${loanAppId}`,
  method: "GET"
});
var getLoanApplications = (borrowerId) => () => request({
  url: "/loan-applications/GetLoanApplications?borrowerId=" + borrowerId,
  method: "GET"
});
var generateLenderSummary = (data) => request({
  url: `/loan-applications/GenerateLenderSummary?loanAppId=${data.applicationId}`,
  method: "POST"
});
var downloadLoanAppFinancials = (data) => request({
  url: `/loan-applications/DownloadFinancialExport?loanApplicationId=${data.applicationId}&portalType=${data.portalType}`,
  method: "GET"
});
var getAllLoans = () => request({
  url: "/loan-applications/GetAllLoans",
  method: "GET"
});
var getLenderLoans = () => request({
  url: "/loan-applications/GetLenderLoans",
  method: "GET"
});
var getCalculatedMatchedLenders = (loanAppId) => () => request({
  url: "/loan-applications/GetCalculatedMatchedLenders?loanAppId=" + loanAppId,
  method: "GET"
});
var getUnmatchedLenders = (loanAppId) => () => request({
  url: "/loan-applications/GetUnmatchedLenders?loanAppId=" + loanAppId,
  method: "GET"
});
var assignAdditionalLender = (data) => request({
  url: `/loan-applications/AssignAdditionalLender?loanAppId=${data.loanAppId}&lenderId=${data.lenderId}`,
  method: "POST"
});
var cancelLoan = (loanAppId) => request({
  url: `/loan-applications/Cancel?loanAppId=${loanAppId}`,
  method: "POST"
});
var revertTermSheet = (loanAppId) => request({
  url: `/loan-applications/RevertTermSheet?loanAppId=${loanAppId}`,
  method: "POST"
});
var setApplicationStatus = (data) => request({
  url: `/loan-applications/SetApplicationStatus?loanAppId=${data.loanAppId}&status=${data.status}`,
  method: "POST"
});
var attestLoanApplication = (data) => request({
  url: `/loan-applications/AttestLoanApplication?loanAppId=${data.loanAppId}&isReattest=${data.isReattest}`,
  method: "POST"
});
var createLoanApplication = (data) => request({
  url: "/loan-applications/CreateLoanApplication?borrowerId=" + data.borrowerId,
  method: "POST",
  data: data.application
});

// src/numbers.ts
var roundToPlaces = (input, places) => {
  var multiplier = Math.pow(10, places);
  return Math.round(input * multiplier) / multiplier;
};

// src/referral.ts
var import_zod4 = __toESM(require("zod"));
var referralDataModel = import_zod4.default.object({
  CompanyName: import_zod4.default.string(),
  ContactName: import_zod4.default.string(),
  ContactEmail: import_zod4.default.string().email(),
  ContactPhone: import_zod4.default.string().refine((v) => v.length >= 9, {
    message: "Invalid phone number."
  }),
  LoanAsk: import_zod4.default.number().optional(),
  LoanType: import_zod4.default.number().optional(),
  ReferralFiles: import_zod4.default.string().array()
});
var submitReferral = (data) => request({
  url: `/referrals/submitReferral`,
  method: "POST",
  data: data.referral
});
var setLoanAppReferral = (data) => request({
  url: `/referrals/SetLoanAppReferral?loanAppId=${data.loanAppId}&referralId=${data.referralId}`,
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  }
});
var uploadReferralFiles = (data) => request({
  url: `/referrals/UploadReferralFiles`,
  method: "POST",
  data: data.formData,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});
var getReferralsForLender = () => request({
  url: `/referrals/GetReferralsForLender`,
  method: "GET"
});
var getAllReferrals = () => request({
  url: `/referrals/GetAllReferrals`,
  method: "GET"
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ApplicationStatusMessage,
  BorrowerAccess,
  CompanyType,
  DiligenceStatus,
  Industries,
  LenderApplicationStatus,
  LoanApplicationStatus,
  NotificationLevel,
  OfferStatus,
  PortalType,
  PortalTypeNumber,
  PublicLoanApplicationStatus,
  RateReference,
  SubIndustries,
  acceptOffer,
  acceptTerms,
  additionalDebtModel,
  analyzeDocuments,
  approveOffers,
  assignAdditionalLender,
  attestLoanApplication,
  cancelLoan,
  checkNotifs,
  companyTypeOptions,
  contactModel,
  createAdditionalBorrower,
  createLoanApplication,
  currency,
  declineOffer,
  defaultLenderCriteria,
  deleteAdditionalLoanAppFile,
  deleteBorrowerFile,
  downloadBorrowerFile,
  downloadLoanAppFinancials,
  finishMergeLink,
  fullBorrowerProfileModel,
  fullLenderCriteriaModel,
  generateLenderSummary,
  getAcceptedOffers,
  getAllLoans,
  getAllReferrals,
  getApiEvaluation,
  getApiGlossary,
  getBorrowerActionPartial,
  getBorrowerFile,
  getBorrowerProfile,
  getBorrowerUsers,
  getCalculatedMatchedLenders,
  getEngagedLenderApplicationString,
  getEngagedLenders,
  getGlossary,
  getLenderCriteria,
  getLenderLoans,
  getLenderTemplatePartial,
  getLenders,
  getLoanAppMessages,
  getLoanAppTitle,
  getLoanApplication,
  getLoanApplications,
  getLoanStatusMessage,
  getMetrics,
  getNotifications,
  getOffer,
  getOffersForReview,
  getRecipients,
  getReferralsForLender,
  getSelf,
  getStatusMessageString,
  getTermSheets,
  getUnmatchedLenders,
  industryOptions,
  init,
  inviteUser,
  isPreFirstSubmit,
  isPreSubmit,
  lenderCriteriaModel,
  lenderLoanStatusOptions,
  loanApplicationModel,
  loanRatios,
  loanTypeOptions,
  loanTypeString,
  loanTypes,
  loanUseOptions,
  metricQualities,
  numberedIndustryOptions,
  numberedSubIndustryOptions,
  officerModel,
  onboardBorrower,
  referralDataModel,
  request,
  returnOffersToLenders,
  revertTermSheet,
  roundToPlaces,
  sendLoanAppMessage,
  sendMessage,
  setActiveBorrower,
  setActiveLoanApplication,
  setApplicationStatus,
  setLoanAppReferral,
  specialistLoanStatusOptions,
  startMergeLink,
  statusColors,
  subIndustryOptions,
  submitApiEvaluation,
  submitEvaluation,
  submitLoanApplication,
  submitReferral,
  submitScreenOneEvaluation,
  updateBorrowerProfile,
  updateLenderCriteria,
  updateLoanApplication,
  updateProfile,
  updateProfileFromMerge,
  uploadAdditionalLoanAppFile,
  uploadBorrowerFile,
  uploadMessageAttachment,
  uploadPrivateAdditionalLoanAppFile,
  uploadReferralFiles,
  useApiMutation,
  useApiQuery,
  yearProfitLossMetricsModel
});
